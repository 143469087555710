import { Component, OnInit, Input } from '@angular/core';


@Component({
    selector: 'comprobante-tarjeta-envio',
    templateUrl: './tarjeta.component.html',
    styleUrls: ['./tarjeta.component.css'],
    host: {'class': 'comprobante-tarjeta-envio'}

})
export class TarjetaComprobanteEnvioComponent {

    @Input() transporte:string  | undefined ='EnvioCourier';
    @Input() solicitudAnalisisId:string='';
    constructor() {
        console.log("tarjeta");
    }
    
    esEnvioCourier():boolean{
        return this.transporte?.toUpperCase() === 'ENVIOCOURIER'
    }
    esLaboratorio():boolean{
        return this.transporte?.toUpperCase() === 'LABORATORIO'
    }

    esSinTransporte():boolean{
        return !this.transporte || this.transporte?.toUpperCase() === 'SINTRANSPORTE'
    }

}
