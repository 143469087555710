import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Objects } from 'src/app/shared';
import { CajaDeMuestrasService } from '../../caja-muestras/caja-muestras-services/caja-muestras.service';
import { CajaMuestrasModels } from 'src/app/caja-muestras/caja-muestras-models/caja-muestras.models';
import { AuthenticationService } from '../auth/authentication.service';
import { EventEmitterService } from './event-emitter.service';
import { LogService } from './log.service';

@Injectable({ providedIn: 'root' })
export class SampleBoxDataProvider {

    sampleBoxDataObserver: Subject<Objects.SampleBoxData>;
    sampleBoxData!: Objects.SampleBoxData;
    lastUpdateSampleBoxData: Date | undefined;
    constructor(private cajaDeMuestrasSrv: CajaDeMuestrasService,
        public authService: AuthenticationService,
        private eventEmiiterSrv: EventEmitterService,
        private _logService: LogService,

    ) {
        this.sampleBoxData = {
            clienteId: '',
            faenaId: '',
            fechaActualizacion: new Date(),
            fechaCreacion: new Date(),
            muestras: []
        }
        this.sampleBoxDataObserver = new BehaviorSubject(this.sampleBoxData);
        new Promise((resolve, reject) => {
            this._callGetCajaDeMuestras(resolve, reject, false);
        });

    }

    loadCajaDeMuestras(force?: boolean): Promise<Objects.SampleBoxData> {

        return new Promise((resolve, reject) => {
            if (this.sampleBoxData.usuario && force !== true) {
                resolve(this.sampleBoxData);
            } else {

                this._callGetCajaDeMuestras(resolve, reject);
            }

        })


    }
    private _callGetCajaDeMuestras(resolve: (value: Objects.SampleBoxData | PromiseLike<Objects.SampleBoxData>) => void, reject: (reason?: any) => void, showLoading: boolean = true) {
        this.sampleBoxData.clienteId = this.authService.currentClientValue.clienteId;
        this.sampleBoxData.faenaId = this.authService.currentClientValue.faena.faenaId;
        this.cajaDeMuestrasSrv.GetCajaDeMuestras({ clienteId: this.sampleBoxData.clienteId, faenaId: this.sampleBoxData.faenaId }, { showLoading: showLoading })
            .then(resp => {
                if (resp.success && resp.data) {
                    this.sampleBoxData = resp.data;
                    let idsMuestra: string[] = []
                    this.sampleBoxData.muestras = this.sampleBoxData.muestras.filter((muestra) => idsMuestra.indexOf(muestra.muestraId) === -1 && idsMuestra.push(muestra.muestraId))
                } else {
                    this.sampleBoxData.muestras = [];
                }
                this.lastUpdateSampleBoxData = new Date();
                resolve(this.sampleBoxData);
                this.sendSampleBoxDataSubscriptor();
            }).catch(err => {
                reject(err);
            });
    }


    addMuestra(muestra: Objects.RegistroMuestra): Promise<CajaMuestrasModels.SetCajaDeMuestraResponse> {
        return new Promise((resolve) => {
            this.loadCajaDeMuestras(true).then(() => {
                this.sampleBoxData.muestras.push(muestra);
                this.persistirMuestras().then(r => resolve(r));
            })
        });
    }

    /**
     * @param muestra
     * @returns
     */
    addMuestraSCP(muestra: Objects.RegistroMuestra): Promise<CajaMuestrasModels.SetCajaDeMuestraResponse> {
        return new Promise((resolve) => {
            this.sampleBoxData.muestras.push(muestra);
            this.persistirMuestras().then(r => resolve(r));
        });
    }


    async addMuestras(muestras: Objects.RegistroMuestra[]) {
        await this.loadCajaDeMuestras().then(() => {
            this.sampleBoxData.muestras = this.sampleBoxData.muestras.concat(muestras);
            this.persistirMuestras();
        });
    }

    setMuestras(muestras: Objects.RegistroMuestra[]): Promise<CajaMuestrasModels.SetCajaDeMuestraResponse> {
        return new Promise((resolve) => {
            this.loadCajaDeMuestras().then(data => {
                this.sampleBoxData.muestras = muestras;
                this.persistirMuestras().then(r => {
                    resolve(r);
                });
            });
        });
    }

    process(): Promise<CajaMuestrasModels.SetCajaDeMuestraProcessResponse> {
        return new Promise((resolve, reject) => {
            this.loadCajaDeMuestras(true).then(sampleBoxData => {
                sampleBoxData.usuario = this.authService.current_user;

                let requestCaja: CajaMuestrasModels.SetCajaDeMuestraRequest = {
                    cajaMuestras: sampleBoxData
                };

                this.cajaDeMuestrasSrv.SetCajaDeMuestraProcess(requestCaja).then(resp => {
                    if (resp.code !== 200) {
                        this.loadCajaDeMuestras();
                    }
                    if (resp.success) {
                        this.eventEmiiterSrv.sendResult(this.eventEmiiterSrv.enumEvents.ENVIO_CAJA_MUESTRA, { success: true, data: resp });
                    }

                    resolve(resp);
                });
            });

        });
    }
    private persistirMuestras(): Promise<CajaMuestrasModels.SetCajaDeMuestraResponse> {
        return new Promise((resolve, reject) => {
            this.sampleBoxData.usuario = this.authService.current_user;
            let requestCaja: CajaMuestrasModels.SetCajaDeMuestraRequest = {
                cajaMuestras: this.sampleBoxData
            };
            this.cajaDeMuestrasSrv.SetCajaDeMuestras(requestCaja).then(resp => {
                if (resp.code !== 200) {

                    let rq: Objects.LogInput = {
                        accion: 'New',
                        elemento: this.sampleBoxData.clienteId,
                        tipoElemento: 'Caja de muestras',
                        usuario: this.authService.usuario.nombre,
                        usuarioId: this.authService.usuario.userId,
                        ambiente: 'Web Cliente'
                    }
                    this._logService.createLog(rq, true);

                    this.loadCajaDeMuestras();
                }
                resolve(resp);
            }).catch(e => reject(e));
        });
    }
    setSampleBoxData(sampleBoxData: Objects.SampleBoxData) {
        this.sampleBoxData = sampleBoxData;
        this.sendSampleBoxDataSubscriptor();
    }

    sendSampleBoxDataSubscriptor() {
        this.sampleBoxDataObserver.next(this.sampleBoxData);
    }
}
