    <div class="d-flex justify-content-start mt-2">
        <div class="form-group w-100" >
            <label class="form-label">Transportista</label>
            <select id="cmbtransportista" name="cmbtransportista" class="form-select w-100" aria-label="Seleccion transportista" 
            [(ngModel)]="model.courier"
            [value]="model.courier"
            #inputNuevoValorTran
            required>
            <option value="" selected>Seleccione</option>
            <option value="chileexpress" [selected]="transporte?.courier==='chileexpress'">Chilexpress</option>
            <option value="correoschile" [selected]="transporte?.courier==='correoschile'">Correos de Chile</option>
            <option value="sintransporte" [selected]="transporte?.courier==='sintransporte'">Cliente</option>

        </select>
    </div>                                                   
    </div>                                                   
    <div class="d-flex justify-content-start mt-2">
        <div class="form-group">
            <label class="form-label">Orden de transporte</label>
            <input type="text" class="form-control"  [(ngModel)]="model.numeroOT" maxlength="16"
            #inputNuevoValorOrden [value]="model.numeroOT" alphaNumericCharacters />                                            
        </div>
    </div>   
    <div class="d-flex justify-content-end mt-2">                                            
        <a class="btn btn-link" [routerLink]="" (click)="cancelar()">
            <b>Cancelar</b>
        </a>
        <button class="btn btn-primary" [routerLink]=""  [disabled]="!esValido()" (click)="aceptar()">
            <b>Listo</b>
        </button>
    </div>
