import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { promise } from "protractor";
import { Observable } from "rxjs";
import { Tools } from "src/app/shared";
import { Enums } from "src/app/shared/models/constants";
import { environment } from "src/environments/environment";
import { UsuarioNuevoModels } from "../usuario-nuevo-models/usuario-nuevo.models";

@Injectable({
    providedIn: 'root'
})
export class UsuarioNuevoService {

    constructor(
        private http: HttpClient,
        private tools: Tools) {
    }

    SetUsuario(request: UsuarioNuevoModels.SetUsuarioRequest): Observable<UsuarioNuevoModels.SetUsuarioResponse> { 
        return this.http.post<UsuarioNuevoModels.SetUsuarioResponse>(`${environment.apiUrl}${environment.routes.usuario}`, request);
    }

    GetFaenas(request: UsuarioNuevoModels.GetFaenasRequest): Promise<UsuarioNuevoModels.GetFaenasResponse> {
        return this.http.get<UsuarioNuevoModels.GetFaenasResponse>(`${environment.apiUrl}${environment.routes.solicitantes}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    }

    GetUsuarioInfo(request: UsuarioNuevoModels.GetUsuarioRequest): Promise<UsuarioNuevoModels.GetUsuarioResponse> {
        return this.http.get<UsuarioNuevoModels.GetUsuarioResponse>(`${environment.apiUrl}${environment.routes.usuario}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    }

}