
  <div  id="modal__new-limit">

    <div class="modal__header">
        <div class="d-flex flex-column">
          <h2 class="modal__title mb-0 text-nowrap" id="modal-title">{{model.isEdit ? 'Editar': 'Crear'}} protocolo específico</h2>

        </div>
        <button type="button" class="btn-close" [mat-dialog-close]="false">
      </button>
    </div>
    <mat-dialog-content class=" modal__body">
      <div class="row justify-content-start justify-content-md-between align-items-md-end mb-2">
        <div class="order-2 order-md-1 text-start mt-2 col-8 col-md-6 col-lg-6 mb-2" >
          <div class="form-group text-start">
            <label for="faena" class="form-label">Faenas</label>
            <ng-select
              name="faena" [appendTo]="'body'" [placeholder]="'Seleccionar'" #faena="ngModel" [(ngModel)]="model.selectFaena"
              [multiple]="true" [searchable]="true" blockSpecialCharacters
              [clearable]="false" (ngModelChange)="filtrarComponentes()">
              <ng-option *ngFor="let item of model.faenas.lista" [value]="item">{{item.faenaId}} - {{item.nombre}}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-8 col-md-6 col-lg-3 mt-4">
          <div class="form-group">
            <label for="tipoProtocolo" class="form-label">Tipo Protocolo</label>
            <ng-select name="tipoProtocolo"  [appendTo]="'body'" [placeholder]="'Seleccionar'" [disabled]="model.selectFaena.length<1 || model.isEdit" [clearable]="false" #tipoProtocolo="ngModel" [(ngModel)]="model.selectTipoProtocolo" (ngModelChange)="filtrarLimitesTipo()"

            >
              <ng-option *ngFor="let item of model.tipoProtocolo.lista" [value]="item">{{item.tag}}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="col-8 col-md-6 col-lg-3 mt-4" *ngIf="model.selectTipoProtocolo?.tag =='Lubricante'">
          <div class="form-group">
            <label for="tipoLubricante" class="form-label">Tipo Lubricante</label>
            <ng-select name="tipoLubricante" [appendTo]="'body'" [placeholder]="'Seleccionar'"  [disabled]="model.selectFaena.length<1" #tipoLubricante="ngModel" [(ngModel)]="model.selectLubricante">
              <ng-option *ngFor="let item of model.lubricante.lista" [value]="item">{{item.nombre}}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="col-8 col-md-6 col-lg-3 mt-4">
          <div class="form-group">
            <label for="tipoEquipo" class="form-label">Tipo Equipo</label>
            <ng-select name="tipoEquipo" [appendTo]="'body'" [placeholder]="'Seleccionar'"  [disabled]="model.selectIdComponentes.length>0 ||model.selectFaena.length<1" #tipoEquipo="ngModel" [(ngModel)]="model.selectTipoEquipo">
              <ng-option *ngFor="let item of model.tipoEquipo.lista" [value]="item">{{item.nombre}}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="col-8 col-md-6 col-lg-3 mt-4">
          <div class="form-group">
            <label for="marcaEquipo" class="form-label">Marca Equipo</label>
            <ng-select name="marcaEquipo" [appendTo]="'body'" [placeholder]="'Seleccionar'"  [disabled]="model.selectIdComponentes.length>0 ||model.selectFaena.length<1" #marcaEquipo="ngModel" [(ngModel)]="model.selectMarcaEquipo"
            (ngModelChange)="model.selecModeloEquipo = undefined">
              <ng-option *ngFor="let item of model.marcaEquipo.lista" [value]="item">{{item.nombre}}</ng-option>
            ></ng-select>
          </div>
        </div>
        <div class="col-8 col-md-6 col-lg-3 mt-4">
          <div class="form-group">
            <label for="modeloEquipo" class="form-label">Modelo Equipo</label>
            <ng-select name="modeloEquipo" [appendTo]="'body'" [placeholder]="'Seleccionar'"  [disabled]="model.selectIdComponentes.length>0 ||model.selectFaena.length<1" #modeloEquipo="ngModel" [disabled]="model.selectIdComponentes.length>0 ||!model.selectMarcaEquipo" [(ngModel)]="model.selecModeloEquipo">
              <ng-option *ngFor="let item of model.selectMarcaEquipo?.modelo" [value]="item">{{item}}</ng-option>
            ></ng-select>
          </div>
        </div>
        <div class="col-8 col-md-6 col-lg-3 mt-4">
          <div class="form-group">
            <label for="tipoComponente" class="form-label">Tipo Componente</label>
            <ng-select name="tipoComponente" [appendTo]="'body'" [placeholder]="'Seleccionar'"  [disabled]="model.selectIdComponentes.length>0 ||model.selectFaena.length<1" #tipoComponente="ngModel" [(ngModel)]="model.selectTipoComponente">
              <ng-option *ngFor="let item of model.tipoComponente.lista" [value]="item">{{item.nombre}}</ng-option>
            ></ng-select>
          </div>
        </div>
        <div class="col-8 col-md-6 col-lg-3 mt-4">
          <div class="form-group">
            <label for="marcaComponente" class="form-label">Marca Componente</label>
            <ng-select name="marcaComponente" [appendTo]="'body'" [placeholder]="'Seleccionar'"  [disabled]="model.selectIdComponentes.length>0 ||model.selectFaena.length<1" #marcaComponente="ngModel" [(ngModel)]="model.selectMarcaComponente"
            (ngModelChange)="model.selectModeloComponente = undefined"
            >
              <ng-option *ngFor="let item of model.marcaComponente.lista" [value]="item">{{item.nombre}}</ng-option>
            ></ng-select>
          </div>
        </div>
        <div class="col-8 col-md-6 col-lg-3 mt-4">
          <div class="form-group">
            <label for="modeloComponente" class="form-label">Modelo Componente</label>
            <ng-select name="modeloComponente" [appendTo]="'body'" [placeholder]="'Seleccionar'" #modeloComponente="ngModel"  [disabled]="model.selectIdComponentes.length>0 ||model.selectFaena.length<1 || !model.selectMarcaComponente" [(ngModel)]="model.selectModeloComponente">
              <ng-option *ngFor="let item of model.selectMarcaComponente?.modelo" [value]="item">{{item}}</ng-option>
            ></ng-select>
          </div>
        </div>
        <div class="col-8 col-md-6 col-lg-3 mt-4">
          <div class="form-group">
            <label for="codigoComponente" class="form-label">Código Componente</label>
            <ng-select name="codigoComponente" [appendTo]="'body'" [placeholder]="'Seleccionar'" [disabled]="model.selectFaena.length<1" [multiple]="true" [searchable]="true"
            [clearable]="true" #codigoComponente="ngModel" [(ngModel)]="model.selectIdComponentes" (ngModelChange)="selecionaIdComponente()">
              <ng-option *ngFor="let item of model.idComponentes.auxLista" [value]="item">{{item.componenteId}}</ng-option>
            ></ng-select>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-2" *ngIf="model.selectFaena.length > 0">
        <button class="text-decoration-underline btn btn-link" (click)="selectEnsayos()">
          Seleccionar ensayos a personalizar
        </button>
      </div>
      <div class="row" *ngIf="model.selectFaena.length > 0 && model.protocolo?.limites!.length>0">
        <div class="col-12">
            <form #formLimites="ngForm">
              <div class="table-responsive p-1 mt-4">
                <table class="w-100 mb-3 table-acction" aria-describedby="Listado de Limites y valores por ensayo">
                    <thead class="pb-2">
                      <tr>
                        <th>Ensayo</th>
                        <th>Limite Alerta Inferior</th>
                        <th>Limite Precaucion Inferior</th>
                        <th>Limite Precaucion Superior</th>
                        <th>Limite Alerta Superior</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="p-2" *ngFor="let element of model.protocolo?.limites; let i = index">
                        <td class="ensayo">
                          <div class="mt-4 mb-5">{{ nombrarEnsayo(element.ensayoId)?.nombre }}</div>
                        </td>
                        <td>
                          <div class="margen" *ngIf="tipoDato(element.ensayoId) === 'integer'">
                            <input
                              #min="ngModel"
                              [name]="'min' + i"
                              [(ngModel)]="element.min"
                              [ngClass]="{
                                'is-invalid':
                                  (min.invalid || !validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                  (min.dirty || min.touched)
                              }"
                              type="text"
                              class="form-control input"
                              onlyNumberInteger
                              (change)="quitarCeros(element.ensayoId, 'integer', 'min')"
                              maxlength="10"
                              required
                            />
                            <div
                              *ngIf="
                                (min.invalid || !validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                (min.dirty || min.touched)
                              "
                              class="invalid-feedback"
                            >
                              <div *ngIf="min.errors?.required">Valor ingresado no esta dentro de los límites</div>
                              <div
                                *ngIf="
                                  (!validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                  !min.errors?.required
                                "
                              >
                                Valor ingresado no esta dentro de los límites
                              </div>
                            </div>
                          </div>

                          <div class="margen" *ngIf="tipoDato(element.ensayoId) === 'float'">
                            <input
                              #min="ngModel"
                              [name]="'min' + i"
                              [(ngModel)]="element.min"
                              [ngClass]="{
                                'is-invalid':
                                  (min.invalid || !validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                  (min.dirty || min.touched)
                              }"
                              type="text"
                              class="form-control input"
                              onlyFloat
                              (change)="quitarCeros(element.ensayoId, 'float', 'min')"
                              maxlength="10"
                              required
                            />
                            <div
                              *ngIf="
                                (min.invalid || !validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                (min.dirty || min.touched)
                              "
                              class="invalid-feedback"
                            >
                              <div *ngIf="min.errors?.required">Valor ingresado no esta dentro de los límites</div>
                              <div
                                *ngIf="
                                  (!validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                  !min.errors?.required
                                "
                              >
                                Valor ingresado no esta dentro de los límites
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="margen" *ngIf="tipoDato(element.ensayoId) === 'integer'">
                            <input
                              #allowMin="ngModel"
                              [name]="'allowMin' + i"
                              [(ngModel)]="element.allowMin"
                              [ngClass]="{
                                'is-invalid':
                                  (allowMin.invalid ||
                                    !validarPrecauciones(element.ensayoId) ||
                                    !validarAlertasPrecauciones(element.ensayoId)) &&
                                  (allowMin.dirty || allowMin.touched)
                              }"
                              type="text"
                              class="form-control input"
                              onlyNumberInteger
                              (change)="quitarCeros(element.ensayoId, 'integer', 'allowMin')"
                              maxlength="10"
                            />
                            <div
                              *ngIf="
                                (allowMin.invalid ||
                                  !validarPrecauciones(element.ensayoId) ||
                                  !validarAlertasPrecauciones(element.ensayoId)) &&
                                (allowMin.dirty || allowMin.touched)
                              "
                              class="invalid-feedback"
                            >
                              <div *ngIf="!validarPrecauciones(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)">
                                Valor ingresado no esta dentro de los límites
                              </div>
                            </div>
                          </div>

                          <div class="margen" *ngIf="tipoDato(element.ensayoId) === 'float'">
                            <input
                              #allowMin="ngModel"
                              [name]="'allowMin' + i"
                              [(ngModel)]="element.allowMin"
                              [ngClass]="{
                                'is-invalid':
                                  (allowMin.invalid ||
                                    !validarPrecauciones(element.ensayoId) ||
                                    !validarAlertasPrecauciones(element.ensayoId)) &&
                                  (allowMin.dirty || allowMin.touched)
                              }"
                              type="text"
                              class="form-control input"
                              onlyFloat
                              (change)="quitarCeros(element.ensayoId, 'float', 'allowMin')"
                              maxlength="10"
                            />
                            <div
                              *ngIf="
                                (allowMin.invalid ||
                                  !validarPrecauciones(element.ensayoId) ||
                                  !validarAlertasPrecauciones(element.ensayoId)) &&
                                (allowMin.dirty || allowMin.touched)
                              "
                              class="invalid-feedback"
                            >
                              <div *ngIf="!validarPrecauciones(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)">
                                Valor ingresado no esta dentro de los límites
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="margen" *ngIf="tipoDato(element.ensayoId) === 'integer'">
                            <input
                              #allowMax="ngModel"
                              [name]="'allowMax' + i"
                              [(ngModel)]="element.allowMax"
                              [ngClass]="{
                                'is-invalid':
                                  (allowMax.invalid ||
                                    !validarPrecauciones(element.ensayoId) ||
                                    !validarAlertasPrecauciones(element.ensayoId)) &&
                                  (allowMax.dirty || allowMax.touched)
                              }"
                              type="text"
                              class="form-control input"
                              onlyNumberInteger
                              (change)="quitarCeros(element.ensayoId, 'integer', 'allowMax')"
                              maxlength="10"
                              required
                            />
                            <div
                              *ngIf="
                                (allowMax.invalid ||
                                  !validarPrecauciones(element.ensayoId) ||
                                  !validarAlertasPrecauciones(element.ensayoId)) &&
                                (allowMax.dirty || allowMax.touched)
                              "
                              class="invalid-feedback"
                            >
                              <div *ngIf="!validarPrecauciones(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)">
                                Valor ingresado no esta dentro de los límites
                              </div>
                            </div>
                          </div>

                          <div class="margen" *ngIf="tipoDato(element.ensayoId) === 'float'">
                            <input
                              #allowMax="ngModel"
                              [name]="'allowMax' + i"
                              [(ngModel)]="element.allowMax"
                              [ngClass]="{
                                'is-invalid':
                                  (allowMax.invalid ||
                                    !validarPrecauciones(element.ensayoId) ||
                                    !validarAlertasPrecauciones(element.ensayoId)) &&
                                  (allowMax.dirty || allowMax.touched)
                              }"
                              type="text"
                              class="form-control input"
                              onlyFloat
                              (change)="quitarCeros(element.ensayoId, 'float', 'allowMax')"
                              maxlength="10"
                              required
                            />
                            <div
                              *ngIf="
                                (allowMax.invalid ||
                                  !validarPrecauciones(element.ensayoId) ||
                                  !validarAlertasPrecauciones(element.ensayoId)) &&
                                (allowMax.dirty || allowMax.touched)
                              "
                              class="invalid-feedback"
                            >
                              <div *ngIf="!validarPrecauciones(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)">
                                Valor ingresado no esta dentro de los límites
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="margen" *ngIf="tipoDato(element.ensayoId) === 'integer'">
                            <input
                              #max="ngModel"
                              [name]="'max' + i"
                              [(ngModel)]="element.max"
                              [ngClass]="{
                                'is-invalid':
                                  (max.invalid || !validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                  (max.dirty || max.touched)
                              }"
                              type="text"
                              class="form-control input"
                              onlyNumberInteger
                              (change)="quitarCeros(element.ensayoId, 'integer', 'max')"
                              maxlength="10"
                              required
                            />
                            <div
                              *ngIf="
                                (max.invalid || !validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                (max.dirty || max.touched)
                              "
                              class="invalid-feedback"
                            >
                              <div *ngIf="max.errors?.required">Valor ingresado no esta dentro de los límites</div>
                              <div
                                *ngIf="
                                  (!validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                  !max.errors?.required
                                "
                              >
                                Valor ingresado no esta dentro de los límites
                              </div>
                            </div>
                          </div>

                          <div class="margen" *ngIf="tipoDato(element.ensayoId) === 'float'">
                            <input
                              #max="ngModel"
                              [name]="'max' + i"
                              [(ngModel)]="element.max"
                              [ngClass]="{
                                'is-invalid':
                                  (max.invalid || !validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                  (max.dirty || max.touched)
                              }"
                              type="text"
                              class="form-control input"
                              onlyFloat
                              (change)="quitarCeros(element.ensayoId, 'float', 'max')"
                              maxlength="10"
                              required
                            />
                            <div
                              *ngIf="
                                (max.invalid || !validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                (max.dirty || max.touched)
                              "
                              class="invalid-feedback"
                            >
                              <div *ngIf="max.errors?.required">Valor ingresado no esta dentro de los límites</div>
                              <div
                                *ngIf="
                                  (!validarAlertas(element.ensayoId) || !validarAlertasPrecauciones(element.ensayoId)) &&
                                  !max.errors?.required
                                "
                              >
                              Valor ingresado no esta dentro de los límites
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="margen">
                            <div class="d-flex justify-content-center align-items-center p-1">
                              <a (click)="quitarEnsayo(element.ensayoId)" class="link-danger">
                                <i class="bi bi-trash-fill ico--small"></i>
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
            </form>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-between " [ngClass]="{' justify-content-sm-end': !model.isEdit}">
      <button *ngIf="model.isEdit" type="button" class="btn btn-danger" (click)="deleteModal()">
        Eliminar
      </button>
      <div>
        <button type="button" class="btn btn-light mx-4" data-bs-dismiss="modal" [mat-dialog-close]="false">
          Cancelar
        </button>

        <button type="button" class="btn btn-primary" (click)="guardar()" [disabled]="!canSave() || formLimites.invalid">
          {{ model.isEdit ? "Actualizar": "Guardar"}}
        </button>
      </div>

    </mat-dialog-actions>
  </div>


