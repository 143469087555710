import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Tools } from "src/app/shared";
import { environment } from "src/environments/environment";
import { ComponenteNuevoModels } from "../componente-nuevo-models/componente-nuevo.models";

@Injectable({
    providedIn: 'root'
})
export class ComponenteNuevoService {

    constructor(
        private http: HttpClient,
        private tools: Tools) {
    }

    GetMarcaComponenteInfo(request: ComponenteNuevoModels.GetMarcaComponenteRequest): Promise<ComponenteNuevoModels.GetMarcaComponenteResponse> {
        return this.http.get<ComponenteNuevoModels.GetMarcaComponenteResponse>(`${environment.apiUrl}${environment.routes.tipoComponente}`, { params: this.tools.toHttpParams(request) }).toPromise(); 
    }

    GetLubricantes(): Promise<ComponenteNuevoModels.GetLubricanteResponse> {
        return this.http.get<ComponenteNuevoModels.GetLubricanteResponse>(`${environment.apiUrl}${environment.routes.getLubricante}`).toPromise();
    }

    GetComponenteInfo(request: ComponenteNuevoModels.GetComponenteVerRequest): Promise<ComponenteNuevoModels.GetComponenteVerResponse> {
        return this.http.get<ComponenteNuevoModels.GetComponenteVerResponse>(`${environment.apiUrl}${environment.routes.component}`, { params: this.tools.toHttpParams(request) }).toPromise(); 
    }

    GetEquipoInfo(request: ComponenteNuevoModels.GetEquipoRequest): Promise<ComponenteNuevoModels.GetEquipoResponse> {
        return this.http.get<ComponenteNuevoModels.GetEquipoResponse>(`${environment.apiUrl}${environment.routes.equipo}`, { params: this.tools.toHttpParams(request) }).toPromise(); 
    } 

    setComponenteNuevo(request: ComponenteNuevoModels.SetComponenteNuevoRequest): Observable<ComponenteNuevoModels.SetComponenteNuevoResponse> {
        return this.http.post<ComponenteNuevoModels.SetComponenteNuevoResponse>(`${environment.apiUrl}${environment.routes.component}`, request.componente);
    }

    setComponenteNuevoEquipo(request: ComponenteNuevoModels.SetComponenteNuevoEquipoRequest): Observable<ComponenteNuevoModels.SetComponenteNuevoResponse> {
        return this.http.post<ComponenteNuevoModels.SetComponenteNuevoResponse>(`${environment.apiUrl}${environment.routes.component}`, request.componente);
    }

    setEquipoCambio(request:ComponenteNuevoModels.SetEquipoNuevoRequest): Observable<ComponenteNuevoModels.SetEquipoNuevoResponse> {
        return this.http.post<ComponenteNuevoModels.SetEquipoNuevoResponse>(`${environment.apiUrl}${environment.routes.equipo}`, request.equipo);
    }

    GetEquipos(request: ComponenteNuevoModels.GetEquiposRequest) : Promise<ComponenteNuevoModels.GetEquiposResponse> {
        return this.http.get<ComponenteNuevoModels.GetEquiposResponse>(`${environment.apiUrl}${environment.routes.equipo}`, { params: this.tools.toHttpParams(request) }).toPromise();
    }

    GetMarcaEquipoInfo(request: ComponenteNuevoModels.GetMarcaEquipoRequest): Promise<ComponenteNuevoModels.GetMarcaEquipoResponse> {
        return this.http.get<ComponenteNuevoModels.GetMarcaEquipoResponse>(`${environment.apiUrl}${environment.routes.tipoEquipo}`, { params: this.tools.toHttpParams(request) }).toPromise(); 
    }
}