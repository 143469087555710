import { NG_VALIDATORS, AbstractControl, Validator, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Directive, HostListener, Output, EventEmitter } from '@angular/core';
@Directive({
    selector: '[validaMuestraId]',
    providers: [{provide: NG_VALIDATORS, useExisting: ValidaMuestraIdDirective, multi: true}]
  })
  export class ValidaMuestraIdDirective implements Validator {

    regexStr = /([0-9]|k|K)/g;
    regexStrPaste = /^[0-9]+[0-9\-]+[-\0-9kK]{1}$/g;
    @Output() changeValue = new EventEmitter<string>();
    @HostListener('keypress', ['$event']) onKeyPress(event:any) {
        return  new RegExp(this.regexStr).test(event.key);
    }
    @HostListener('paste', ['$event']) onPaste(e:any) {
        const pasteData = e.clipboardData.getData('text/plain');
        if(new RegExp(this.regexStrPaste).test(pasteData)){
          e.clipboardData.setData("text/plain",pasteData.replace('-',''));
        }else{
          e.preventDefault();

        }
    }


    validate(control: AbstractControl): ValidationErrors | null {
      return  this.esValido(control.value)(control);
    }
    constructor(){

    }
    esValido(muestraId:string):ValidatorFn{
      return (control: AbstractControl): {[key: string]: any} | null => {
        const forbidden = !this.validaFormato(muestraId);
        return forbidden ? {validacionRut: {value: control.value}} : null;
      };
    }



    private validaFormato(rut: string): boolean{
      if (!/^[0-9]+[0-9kK]{1}$/.test( rut )||rut[0]=="0")
          return false;


      var digv = rut.slice(-1);
      var cuerpo = rut.slice(0,-1);
      if (digv == 'K') digv = 'k' ;
        return (this.getDv(cuerpo) == digv );
      }

      private getDv(T: any){
          var M=0,S=1;
          for(;T;T=Math.floor(T/10))
            S=(S + T % 10 * (9-M++ % 6)) % 11;
          return S ? S-1 : 'k';
      }
  }
