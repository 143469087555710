import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HomeModels } from '../home-models/home.models';
import { environment } from 'src/environments/environment';
import { Tools } from 'src/app/shared';

@Injectable({
    providedIn: 'root'
})
export class HomeService {

    constructor(
        private http: HttpClient,
        private tools: Tools) {
    }

    GetCliente(request: HomeModels.GetClienteRequest): Promise<HomeModels.GetClienteResponse> {
        return this.http.get<HomeModels.GetClienteResponse>(`${environment.apiUrl}${environment.routes.getCliente}`, { params: this.tools.toHttpParams(request) }).toPromise();
    }

}
