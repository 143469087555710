<h2 mat-dialog-title>Selecciona cliente y/o faena</h2>
<div mat-dialog-content>
  <p id="selecciona-cliente-faena">Selecciona el cliente y faena para cargar toda la información. Ya en el interior puedes cambiar de cliente y faena si así lo requieres.</p>
  <div class="d-flex flex-column gap-3">
    <div class="form-group">
        <label for="tipo" class="form-label">Cliente</label>
        <ng-select id="cliente" [(ngModel)]="clienteSeleccionado" name="cliente" [appendTo]="'body'" [placeholder]="'Seleccionar'" [clearable]="false" required
          (change)="getFaenas()"
        >
          <ng-option *ngFor="let cliente of clientes" [value]="cliente">{{cliente.razonSocial}}</ng-option>
        </ng-select>
    </div>
    <div class="form-group">
        <label for="tipo" class="form-label">Faena</label>
        <ng-select id="faena" [(ngModel)]="faenaSeleccionada" name="faena" [appendTo]="'body'" [placeholder]="'Seleccionar'" [clearable]="false" required>
          <ng-option *ngFor="let faena of faenas" [value]="faena">{{faena.numeroSap}} - {{faena.nombre}}</ng-option>
        </ng-select>
    </div>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end mt-3">
  <button id="ingresar" class="btn btn-primary d-flex align-items-center" (click)="ingresar()" [disabled]="!clienteSeleccionado.clienteId || !faenaSeleccionada.faenaId">Ingresar</button>
</div>
