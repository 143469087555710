import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SampleDetailComponent } from 'src/app/sample/sample-detail/sample-detail.component';
import { Objects } from 'src/app/shared';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { MuestraProviderService } from 'src/app/shared/services/muestraProvider.service';

@Component({
    selector: 'app-nav-menu',
    styleUrls: ['./nav-menu.component.css'],
    templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent implements OnInit{
    muestraId: string = "";
    searching: boolean = false;
    constructor(private router: Router,
        private muestraProviderService: MuestraProviderService,
        private snackBar: MatSnackBar,
        private auhtService: AuthenticationService,
        private dialog: MatDialog

    ) {
    }

    ngOnInit(): void {
        this.muestraId = '';
    }

    isActive(route: string): boolean {
        return route === this.router.url;
    }

    isActiveMulti(route: string[]): boolean {
        return route.some(r => this.router.url.startsWith(r));
    }

    isVisibleTableau(): boolean {
        return true;
    }

    openTableau(): void {
        window.location.href = '/external-content';
    }

    async getSample() {
        this.muestraId = this.muestraId.trim();
        if (this.muestraId != '') {
            this.searching = true;
            const request = {
                clienteId:this.auhtService.currentClientValue.clienteId,
                faenaId: this.auhtService.currentClientValue.faena.faenaId,
                correlativo: this.muestraId
            }

            this.muestraProviderService.GetMuestras(request).then(muestra => {
                if (muestra.data.length && muestra.data[0].cliente.clienteId == this.auhtService.currentClientValue.clienteId && muestra.data[0].cliente.faena.faenaId == this.auhtService.currentClientValue.faena.faenaId) {
                    this.showModalSample(muestra.data[0])
                } else {
                    this.snackBar.open(`No se encontraron resultados a tu búsqueda`, 'Cerrar', {
                        duration: 5000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'right'
                    })
                    this.searching = false;
                }
            }).catch(error => {
                this.snackBar.open(`No se encontraron resultados a tu búsqueda`, 'Cerrar', {
                    duration: 5000,
                    verticalPosition: 'bottom',
                    horizontalPosition: 'right'
                });
            }).finally(() => {
                this.searching = false;
            });
        }
    }

    showModalSample(muestra: Objects.RegistroMuestra): void {
        this.searching = false;
        this.dialog.open(SampleDetailComponent, {
            panelClass: 'ds-modal-lg',
            data: {
                muestra: muestra
            }
        });
    }

}
