import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { Objects } from "src/app/shared";
import { MantComponentesModels } from '../../mant-componentes-models/mant-componentes.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MantComponentesService } from '../../mant-componentes-services/mant-componentes.service';
import { SnackBarService } from 'src/app/shared/services/snackBar.service';
@Component({
  selector: 'app-mover-component',
  templateUrl: './mover-component.component.html',
  styleUrls: ['./mover-component.component.css']
})
export class MoverComponentComponent implements OnInit {
    is_request_call=false;
    clientes:Objects.Cliente[] = [];
    clienteSeleccionado:Objects.Cliente | undefined;
    faenas:Objects.Faena[] = [];
    faenaSeleccionada:Objects.Faena | undefined;

    model:MantComponentesModels.MoveComponentRequest = {
        componentes_id:[],
        deudor_from:'',
        deudor_to:'',
        solicitante_from:'',
        solicitante_to:''
    }

    constructor(private authService: AuthenticationService,
        public dialogRef: MatDialogRef<MoverComponentComponent>,
        private services: MantComponentesService,
        public snackBar: SnackBarService,
        @Inject(MAT_DIALOG_DATA) public data: string[],

    ) {
    }

    ngOnInit(): void {
        this.model.componentes_id = this.data;
        this.model.deudor_from = this.authService.currentClientValue.clienteId;
        this.model.solicitante_from = this.authService.currentClientValue.faena.faenaId;
        this.clientes = this.authService.currentUserValue.clientes;
        this.clientes.forEach(cliente => {
            if (cliente.clienteId === this.authService.currentClientValue.clienteId) {
                cliente.faenas = cliente.faenas.filter(f => f.faenaId !== this.authService.currentClientValue.faena.faenaId)
            }
        });
    }

    confirmar() {
        this.model.deudor_to = this.clienteSeleccionado?.clienteId || '';
        this.model.solicitante_to = this.faenaSeleccionada?.faenaId || '';
        this.is_request_call = true;
        this.services.MoveComponent(this.model).then(r => {
            if(r.success && r.code == 200){
                this.snackBar.updateSuccess('Se moveran los componentes, puede tardar unos minutos en ver los cambios');
                setTimeout(() => {
                    this.dialogRef.close('actualizar');
                }, 5000);
            }
            if(r.code == 500){
                this.snackBar.danger('Error al mover componentes');
                this.is_request_call = false;
                this.dialogRef.close('error');
            }
        }).catch(e => {
            this.is_request_call = false;
            this.dialogRef.close('error');
        });
    }



}


