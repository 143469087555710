import { Component, OnInit, ViewChild } from '@angular/core';
import { PendienteFacturacionModels } from './pendientes-facturacion-models/pendientes-facturacion-models';
import { PendienteFacturacionViewModels } from './pendientes-facturacion-models/pendientes-facturacion-view-models';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentosVentasProvider } from 'src/app/shared/services/documentoVentasProvider.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RequestCounterTools } from 'src/app/shared/tools/request-counter.tools';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalDocumentDetailComponent } from './pendientes-facturacion-components/modal-document-detail/modal-document-detail.component';
import { SelectionModel } from '@angular/cdk/collections';
import { PendienteFacturacionService } from './pendientes-facturacion-services/pendientes-facturacion-service';
import { SnackBarService } from 'src/app/shared/services/snackBar.service';

@Component({
    selector: 'app-pendientes-facturacion',
    templateUrl: './pendientes-facturacion.component.html',
    styleUrls: ['./pendientes-facturacion.component.css'],
})
export class PendientesFacturacionComponent implements OnInit {
    selection = new SelectionModel<PendienteFacturacionModels.DocumentoVentas>(true, []);
    is_request_call: boolean = false;
    isCheckedCobradoCeBe: boolean = false;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    loadingDocuments: RequestCounterTools = new RequestCounterTools();

    displayedColumns: string[] = ['select', 'document', 'cliente', 'faena', 'creador', 'fechaCreacion', 'periodo', 'estado', 'total'];

    dataSource = new MatTableDataSource<PendienteFacturacionModels.DocumentoVentas>([]);
    estado: any = [];
    clientes: any = [];
    constructor(
        private service: DocumentosVentasProvider,
        private authService: AuthenticationService,
        private pendienteFacturacionService: PendienteFacturacionService,
        public dialog: MatDialog,
        private snackSrv: SnackBarService
    ) {}

    ngOnInit(): void {
        var today = new Date();
        var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        this.model.period_from = moment(firstDay).format('YYYY-MM-DD');
        this.model.period_to = moment(today).format('YYYY-MM-DD');
    }

    model: PendienteFacturacionViewModels.ViewModel = {
        docsVentas: [],
        docsVentasFiltrados: [],
        muestras: [],
        period_from: '',
        period_to: '',
        estados: [
            { label: 'Facturados', code: 'facturado' },
            { label: 'Pendientes de facturar', code: 'pendiente' },
        ],
        estados_seleccion: undefined,
        informes: [],
        doc_ventas_reporte: [],
        pagination: { page: 0, pageSize: 10, totalElements: 0 },
        filter: '',
    };

    changeEstadoPedido() {
        this.reloadTable();
    }
    changeCobradoACeBe() {
        this.reloadTable();
    }

    private LoadPaginate(): void {
        this.dataSource.sortingDataAccessor = (doc: PendienteFacturacionModels.DocumentoVentas, sortHeaderId: string) => {
            switch (sortHeaderId) {
                case 'document':
                    return doc.documentoVentasId;
                default:
                    return 1;
            }
        };

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    private loadTable(data: PendienteFacturacionModels.DocumentoVentas[]) {
        this.selection.clear();
        this.model.docsVentas = data;
        this.reloadTable();
    }

    private reloadTable() {
        const estadoSeleccionado = this.model.estados_seleccion?.code;
        const estados = ['pendiente', 'liberado', 'liberado_manual', 'eliminado', 'compensado', 'enviado', 'creado'];

        this.dataSource = new MatTableDataSource<PendienteFacturacionModels.DocumentoVentas>(
            this.model.docsVentas
                .filter((doc) => !this.isCheckedCobradoCeBe || doc.pedido_cost?.is_envio_cebe)
                .filter((doc) => {
                    const estadoPedidoCost = doc.pedido_cost?.estado.toLowerCase();
                    const estadoPedidoZeroCost = doc.pedido_zero_cost?.estado.toLowerCase();

                    if (!estadoSeleccionado) {
                        return true;
                    } else if (estadoSeleccionado === 'pendiente') {
                        if (estados.includes(estadoPedidoCost) && doc.pedido_cost.planes_analisis.length > 0) {
                            return estadoPedidoCost !== 'compensado' || doc.pedido_cost.is_envio_cebe;
                        }
                        return estados.includes(estadoPedidoZeroCost) && doc.pedido_zero_cost.planes_analisis.length > 0;
                    } else {
                        if (['facturado', 'compensado'].includes(estadoPedidoCost) && doc.pedido_cost.planes_analisis.length > 0) {
                            return estadoPedidoCost !== 'compensado' || !doc.pedido_cost.is_envio_cebe;
                        }
                        return (
                            ['facturado', 'compensado'].includes(estadoPedidoZeroCost) && doc.pedido_zero_cost.planes_analisis.length > 0
                        );
                    }
                })
        );

        this.LoadPaginate();
    }

    get_documents(force: boolean = false) {
        this.is_request_call = true;        
        this.loadingDocuments.startRequest();

        if (force) {
            this.loadTable([]);
        }

        this.service
            .getDocumentosVentas(
                this.model.period_from ? moment(this.model.period_from).format('DD/MM/YYYY') : '',
                this.model.period_to ? moment(this.model.period_to).add(1, 'days').format('DD/MM/YYYY') : '',

                this.model.estados_seleccion == undefined
                    ? []
                    : this.model.estados_seleccion?.code == 'facturado'
                    ? ['facturado', 'compensado']
                    : ['pendiente', 'liberado', 'liberado_manual', 'eliminado', 'compensado', 'enviado', 'creado'],
                [this.authService.currentClientValue.clienteId],
                [this.authService.currentClientValue.faena.faenaId],
                true
            )
            .subscribe((response) => {
                this.is_request_call = !this.service.doc_ventas_finish;
                if (this.service.doc_ventas_finish) {
                    this.loadTable(response);
                    this.loadingDocuments.endRequest();
                }
            });
    }

    public set_max_date(event: any): void {
        const selectedDate = new Date(event.target.value);
        const currentDate = new Date();

        if (selectedDate > currentDate || isNaN(selectedDate.getTime())) {
            event.target.value = moment(currentDate).format('YYYY-MM-DD');

            if (event.target.name === 'period_from') {
                this.model.period_from = moment(currentDate).format('YYYY-MM-DD');
            } else if (event.target.name === 'period_to') {
                this.model.period_to = moment(currentDate).format('YYYY-MM-DD');
            }
        }
    }

    handlePageEvent(event: PageEvent): void {
        this.model.pagination.totalElements = event.length;
        this.model.pagination.pageSize = event.pageSize;
        this.model.pagination.page = event.pageIndex;

        this.LoadPaginate();
    }

    openDocument(element: PendienteFacturacionModels.DocumentoVentas) {
        this.is_request_call = true;
        this.pendienteFacturacionService.GetDocumentoVentasById({
            clienteId: element.cliente.clienteId,
            faenaId: element.cliente.faena.faenaId,
            documentoVentasId: element.documentoVentasId,
            is_dv_id: true,
            period_from: this.model.period_from ? moment(this.model.period_from).format('DD/MM/YYYY') : '',
            period_to: this.model.period_to ? moment(this.model.period_to).add(1, 'days').format('DD/MM/YYYY') : ''
        }).then((response) => {
            if (response.code == 200) {
                const dialog = this.dialog.open(ModalDocumentDetailComponent, {
                    width: '1000px',
                    disableClose: true,
                    data: { document: response.data.items.shift() },
                });
            }
        }).finally(()=>{
            this.is_request_call = false;
        });        
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    checkboxLabel(row?: PendienteFacturacionModels.DocumentoVentas): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.documentoVentasId}`;
    }

    enviarProformas() {
        this.loadingDocuments.startRequest();
        let documentosCompletos = this.selection.selected;
        let documentos =
            documentosCompletos.map((doc) => ({
                documentoId: doc.documentoVentasId,
                destinatarios: [this.authService.currentUserValue.email],
            })) || [];

        this.pendienteFacturacionService
            .SetDocumentoVentasId({ documentos })
            .then((r) => {
                r.code == 200 ? this.snackSrv.success('Documentos enviados') : this.snackSrv.warning('Error al enviar documentos');
            })
            .catch((e) => {
                this.snackSrv.warning('Error al enviar documentos');
            })
            .finally(() => {
                this.loadingDocuments.endRequest();
            });
    }
}
