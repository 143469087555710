import { AbstractControl, ValidationErrors, AsyncValidator, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Directive } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../auth/authentication.service';
import { RegistraService } from '../../ingreso-muestra/registra-muestra/registra-services/registra.service';
import { map } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';
@Directive({
    selector: '[unicoNumeroEnvaseRegistrado]',
    providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UnicoNumeroEnvaseRegistradoValidatorDirective, multi: true}]  })
  export class UnicoNumeroEnvaseRegistradoValidatorDirective implements AsyncValidator {

    constructor( private authService: AuthenticationService,
      private service: RegistraService,
      private spinner:SpinnerService ) {
    }
    validate(control: AbstractControl): Observable<ValidationErrors | null> {
      this.spinner.off();
      const obs = this.service.setMuestraValid({
        clienteId:this.authService.currentClientValue.clienteId,
        faenaId:this.authService.currentClientValue.faena.faenaId,
        muestraId:control.value
      }).pipe(map((r) => {
          return r.data.isValid ? null : {
            unicoNumeroEnvaseRegistrado: 'Id de muestra.'
        };
      }));
      return obs;
    }

  }
