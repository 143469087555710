import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { StorageTools } from '../tools/storage.tools';
import { AuthService } from './auth.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private storage: StorageTools,
        private oauthService: OAuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.oauthService.hasValidAccessToken() && !request.url.endsWith('/token')) {
            request = request.clone({
                setHeaders: {
                    // 'Cache-Control': 'no-store, no-cache',
                    Authorization: `Bearer ${this.oauthService.getAccessToken()}`
                }
            });
        } else {
        }
        return next.handle(request);
    }
}
