import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Tools, Objects } from 'src/app/shared';
import { CajaMuestrasModels } from 'src/app/caja-muestras/caja-muestras-models/caja-muestras.models';
import { Enums } from 'src/app/shared/models/constants';

@Injectable({
    providedIn: 'root'
})
export class CajaDeMuestrasService {

    constructor(
        private http: HttpClient,
        private tools: Tools) {
    }


    GetCajaDeMuestras(request: CajaMuestrasModels.GetCajaDeMuestraRequest,opt?:CajaMuestrasModels.optionsRequest): Promise<CajaMuestrasModels.GetCajaDeMuestraResponse> {
        return this.http.get<CajaMuestrasModels.GetCajaDeMuestraResponse>(`${environment.apiUrl}${environment.routes.getCajaMuestra}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, opt?.showLoading) }).toPromise();
    }

    SetCajaDeMuestras(request: CajaMuestrasModels.SetCajaDeMuestraRequest): Promise<CajaMuestrasModels.SetCajaDeMuestraResponse> {
        return this.http.post<CajaMuestrasModels.SetCajaDeMuestraResponse>(`${environment.apiUrl}${environment.routes.getCajaMuestra}`, request).toPromise();
    }

    SetCajaDeMuestraProcess(request: CajaMuestrasModels.SetCajaDeMuestraProcessRequest): Promise<CajaMuestrasModels.SetCajaDeMuestraProcessResponse> {
        return this.http.post<CajaMuestrasModels.SetCajaDeMuestraProcessResponse>(`${environment.apiUrl}${environment.routes.setCajaMuestraProcess}`, request).toPromise();
    }

     GetCondicionComercial(request: CajaMuestrasModels.GetCondicionComercialRequest,opt?:CajaMuestrasModels.optionsRequest): Promise<CajaMuestrasModels.GetCondicionComercialResponse> {
        return this.http.get<CajaMuestrasModels.GetCondicionComercialResponse>(`${environment.apiUrl}${environment.routes.getCondicionComercial}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, opt?.showLoading) }).toPromise();
    }


}
