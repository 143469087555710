import { Component, HostListener, OnInit } from '@angular/core';
import { ModalCajaMuestrasViewModels } from '../modal-solicitud-models/modal-solicitud.view.models';
import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/shared';
import * as moment from 'moment';
import { SolicitudDeAnalisisService } from '../../doc-solicitud-de-analisis/solicitud-services/solicitud.service';
import { SolicitudDeAnalisisModels } from '../../doc-solicitud-de-analisis/solicitud-models/solicitud.models';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { MuestrasModels } from 'src/app/mantenedores/mant-muestras/mant-muestras-models/mant-muestras.models';
import { MuestrasService } from 'src/app/mantenedores/mant-muestras/mant-muestras-service/mant-muestras.service';

@Component({
    selector: 'modal-solicitud-analisis-componente',
    templateUrl: './modal-solicitud.component.html',
    styleUrls: ['./modal-solicitud.component.css']
})
export class ModalSolicitudAnalisisComponent implements OnInit {
    estadosMuestra:{[key:string]:string}=Enums.EstadosMuestra;
    model:ModalCajaMuestrasViewModels.ViewModel={
        filtroDeBusqueda:'',
        muestras:[],
        estadoMuestra:{
            registered:'Registrada',
            received:'Recepcionada',
            received_observations:'Recepcionada c/ observaciones',
            not_received:'No recepcionada',
            in_analysis:'En análisis',
            pending_approval:'En análisis',
            reported:'Reportada',
            registrada: 'Registrada',
            assigned_batch: 'Recepcionada',
            recibido: 'Recepcionada',
            sending: 'Recepcionada'
        },
    };

    constructor (
        private authService: AuthenticationService,
        private service: SolicitudDeAnalisisService,
        public dialogRef: NgbActiveModal,
        private serviceSample: MuestrasService,
    ) {}

    ngOnInit(): void {
        if(this.model.input){
            this.getSolicitudById(this.model.input.solicitud.solicitudAnalisisId);
        }
    }

    private getSolicitudById(solicitudAnalisisId: string) {

        const request: SolicitudDeAnalisisModels.GetSolicitudDeAnalisisRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId:this.authService.currentClientValue.faena.faenaId,
            solicitudAnalisisId: solicitudAnalisisId
        };

        this.service.GetSolicitudAnalisis(request)
                    .then(response =>{
                        if( response.code === 200) {
                            this.model.input = JSON.parse(JSON.stringify(response.data));
                            this.model.muestras = this.model.input?.solicitud.muestras!;
                            console.log("this.model.input!.solicitud.fechaCreacion", this.model.input!.solicitud.fechaCreacion);
                            if(this.model.input!.solicitud.fechaCreacion.indexOf("T") === -1) {
                                this.model.input!.solicitud.fechaCreacion = moment(this.model.input!.solicitud.fechaCreacion, 'DD/MM/YYYY HH:mm:ss').format("YYYY-MM-DDTHH:mm");
                            }

                            this.model.muestras.forEach(t =>{
                                if(t.fechaMuestreo.indexOf("T") === -1) {
                                    t.fechaMuestreo = moment(t.fechaMuestreo, 'DD/MM/YYYY HH:mm:ss').format("YYYY-MM-DDTHH:mm");
                                }
                                if(t.fechaIngreso.indexOf("T") === -1) {
                                    t.fechaIngreso = moment(t.fechaIngreso, 'DD/MM/YYYY HH:mm:ss').format("YYYY-MM-DDTHH:mm");
                                }

                              });
                        }
        });
    }

    filter(){
        let filtro = this.model.filtroDeBusqueda.trim().toUpperCase();
        if(filtro){

            this.model.muestras =  this.model.input?this.model.input.solicitud.muestras.filter( m => {
                return m.muestraId.toUpperCase().includes(filtro)  ||
                m.lubricante?.nombre?.toUpperCase().includes(filtro) ||
                m.componenteId?.toLocaleUpperCase().includes(filtro) ||
                m.componente.tipoComponente?.nombre?.toUpperCase().includes(filtro) ||
                m.componente.marca.toUpperCase().includes(filtro) ||
                m.componente.modelo?.toUpperCase().includes(filtro) ||
                m.componente.equipo?.tipoEquipo?.nombre.toUpperCase().includes(filtro)     ||
                m.componente.equipo?.marca?.toUpperCase().includes(filtro)  ||
                m.componente.equipo?.modelo?.toUpperCase().includes(filtro) ||
                m.usuario?.nombre?.toUpperCase().includes(filtro) ||
                m.usuario?.apellido?.toUpperCase().includes(filtro)
            }):[]
        }else{
            this.model.muestras = this.model.input?this.model.input.solicitud.muestras:[];
        }
    }

    close(){
        this.dialogRef.close();
    }

    invStringToDate(fecha:string):Date{
        let dateNumbers =  fecha.split("-").map(v=>+v);
        let date =new Date(dateNumbers[2],dateNumbers[1],dateNumbers[0]);
        return date;

    }

    esHoy(date?:any):boolean{
            let now = new Date();
            let dt =new Date(date);
            return date?(dt.getDay()+dt.getMonth()+dt.getFullYear()) == (now.getDay()+now.getMonth()+now.getFullYear()) :false;
    }

    childsTabReport: { [ref: string]: { bottleNumber: string, tab: Window, sample?: MuestrasModels.Muestra } } = {};

    public getPDF(muestra: MuestrasModels.Muestra): void {

        const ref = new Date().getTime();
        const url = '/reports2?ref=' + ref;

        let tab = window.open(url, '_blank');
        if (tab) {
            this.childsTabReport[ref] = {
                tab: tab,
                bottleNumber: muestra.muestraId,
                sample: muestra
            }
        }
    }

    @HostListener('window:message', ['$event'])
    onMessage(e: any) {
        if (e.data.action === 'showReport' && e.origin === window.location.origin) {
            switch (e.data.event) {
                case 'init':
                    let ref = this.childsTabReport[e.data.ref];
                    if (ref) {
                        ref.tab.window.postMessage({ action: 'loadReport', bottleNumber: ref.bottleNumber, sample: ref.sample }, window.location.origin);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    loadSampleAndGetPDF(muestraId: string) {
        let opts: {
            force: boolean;
            fnCallback?: () => {};
            spinner: boolean;
            ignoreStore?: boolean;
            fnContinue?: (requests: MuestrasModels.GetMuestraRequest[]) => boolean;
        } = { force: true, spinner: true, ignoreStore: true };

        let request: MuestrasModels.GetMuestraRequest = {
            laboratorioId: '',
            muestraId: muestraId,
        };

        this.serviceSample.getMuestraById(request, opts).then((response) => {
            const sample = response.data[0];
            this.getPDF(sample);
        });
    }
}
