<main class="content" id="inicio">
  <div class="container-fluid p-0">
    <div class="row justify-content-center pt-5">
      <div class="col-md-4 text-center mb-3 d-flex">
        <button class="bg-btn-inicio" [routerLink]="['/reportes/historico']">
          <img src="../../../assets/images/table.svg" alt="" class="mb-1" />
          <p>RESULTADOS HISTÓRICOS</p>
        </button>
      </div>
      <div class="col-md-4 text-center mb-3 d-flex">
        <button class="bg-btn-inicio" [routerLink]="['/reportes/componentes']">
          <img src="../../../assets/images/componenetes.svg" alt="" class="mb-1" />
          <p>DESCARGAR REPORTES DE COMPONENTES</p>
        </button>
      </div>
    </div>
  </div>
</main>
