import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/shared/auth/authentication.service";
import { ToastService } from "src/app/shared/components/ng-toast/toast-service";
import { EventEmitterService } from "src/app/shared/services/event-emitter.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { environment } from "src/environments/environment";
import { MantEquiposModels } from "../../mant-equipos-models/mant-equipos.models";
import { MantEquiposViewModels } from "../../mant-equipos-models/mant-equipos.view.models";
import { MantEquiposService } from "../../mant-equipos-services/mant-equipos.service";
import { EquipoNuevoComponent } from "../equipo-nuevo/equipo-nuevo-views/equipo-nuevo.component";
import { EquipoVerComponent } from "../equipo-ver/equipo-ver-views/equipo-ver.component";

@Component({
    selector: 'app-mant-equipos',
    templateUrl: './mant-equipos.component.html',
    styleUrls: ['./mant-equipos.component.css']
})
export class MantEquiposComponent implements OnInit{

    displayedColumns: string[] = ['equipoId', 'tipo', 'alias'];

    dataSource = new MatTableDataSource<MantEquiposModels.Equipo>([]);

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    myControl = new FormControl();
    filteredOptions!: Observable<MantEquiposModels.Equipo[]>;
    subscribers: Subscription[] = [];

    model: MantEquiposViewModels.ViewModel = {
        pagination: { page: 0, pageSize: 10, totalElements: 0 },
        checkAll: false,
        equipos: [],
        equipoBuscado: '',
        equipoFiltro: '',
        equiposEncontrados: [],
        componentesSeleccionados: [],
        limiteSeleccionCompleto: false,
        limiteSeleccion: environment.config.maxIngresoMuestras,
        esSeleccionMultiple: false,
        alertaSinResultado: false,
        cargandoEquipos: false,
        forceRefresh: false,
        loadingTable: false,
    }

    constructor(
        private authService: AuthenticationService,
        private service: MantEquiposService,
        private spinner: SpinnerService,
        private modalService: NgbModal,
        private eventEmiiterSrv: EventEmitterService,
        private toastService: ToastService
    ){

    }

    ngOnInit(): void{
        this.LoadPaginate();
        this.model.loadingTable = true;
        this.spinner.off(true);
        this.getEquipos();
        this.eventEmiiterSrv.event("FLOW_MANTENEDOR").subscribe(result=>{
            this.model.equipoFiltro = '';
            this.getEquipos(true);

            switch(result.accion){
                case 'NUEVO':
                    this.toastService.show(`El equipo ${result.id} fue creado`, { classname: 'bg-success text-light', delay: 2000 });
                    break;
                case 'EDITAR':
                    this.toastService.show(`El equipo ${result.id} fue actualizado`, { classname: 'bg-primary text-light', delay: 2000 });
                    break;
                case 'ELIMINAR':
                    this.toastService.show(`El equipo ${result.id} fue eliminado`, { classname: 'bg-danger text-light', delay: 2000 });
                    break;
                default:
                    break;
            }
        });
    }

    ngOnDestroy() {
        this.eventEmiiterSrv.completeEvent("FLOW_MANTENEDOR");
        this.destroySubs();
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    recargarTabla(): void {
        this.model.equiposEncontrados=[];
        this.LoadPaginate();
        this.getEquipos(true);
    }

    getEquipos(forceRefresh: boolean = false): void{
        this.model.fechaCargaSolicitudes= new Date();
        this.LoadPaginate();
        this.destroySubs();
        const request: MantEquiposModels.GetEquiposRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId
        };
        this.model.cargandoEquipos = true;
        let sub = this.service.GetEquipos(request, forceRefresh).subscribe(response => {
            if (response.success && response.code != -1) {
                this.model.equipos = response.data.filter(e => e.equipoId != null);
                this.model.equiposEncontrados = this.model.equipos;
                this.dataSource = new MatTableDataSource<MantEquiposModels.Equipo>(this.model.equiposEncontrados);
                this.LoadPaginate();
                this.model.cargandoEquipos = false;
            }
        });
        this.subscribers.push(sub);
    }

    private destroySubs() {
        this.subscribers.forEach(s=>{
            s.unsubscribe();
        })
    }

    onFocusInputSearch(){
        if(this.model.cargandoEquipos){
            this.spinner.startLoading();
        }
    }

    enterInputSearch(){
        this.model.pagination.page = 0;
        this.filtrarEquipos();
    }

    filtrarEquipos(): void {
        if(this.model.equipoFiltro){
            if (typeof(this.model.equipoFiltro) === 'string') {
                this.model.equiposEncontrados = !this.model.equipoFiltro && this.model.equipoFiltro === '' ? [] :
                    this.model.equipos.filter(option =>
                        option.equipoId.toLowerCase().includes(this.model.equipoFiltro.toLowerCase()) ||
                        option.marca.toLowerCase().includes(this.model.equipoFiltro.toLowerCase()) ||
                        option.modelo?.toLowerCase().includes(this.model.equipoFiltro.toLowerCase()) ||
                        option.tipoEquipo.nombre.toLowerCase().includes(this.model.equipoFiltro.toLowerCase()) ||
                        option.alias?.find(e=>e.toLowerCase().includes(this.model.equipoFiltro.toLowerCase())) ||
                        option.tags?.find(e=>e.toLowerCase().includes(this.model.equipoFiltro.toLowerCase()))
                    );
                if (this.model.equiposEncontrados.length === 0){
                    this.model.alertaSinResultado=true;
                } else {
                    this.model.alertaSinResultado=false;
                    this.dataSource = new MatTableDataSource<MantEquiposModels.Equipo>(this.model.equiposEncontrados);
                    this.LoadPaginate();
                }
            } else {
                this.model.equipoFiltro = '';
                this.LoadPaginate();
            }
        }else{
            this.model.equiposEncontrados = this.model.equipos;
            this.model.alertaSinResultado=false;
            this.LoadPaginate();
        }
    }

    handlePageEvent(event: PageEvent): void {
        this.model.pagination.totalElements = event.length;
        this.model.pagination.pageSize = event.pageSize;
        this.model.pagination.page = event.pageIndex;

        this.LoadPaginate();
    }

    LoadPaginate(): void {
        this.dataSource.sortingDataAccessor = (equipo: MantEquiposModels.Equipo, sortHeaderId: string) => {
            switch(sortHeaderId) {
                case 'equipoId': return equipo.equipoId;
                case 'tipo': return equipo.tipoEquipo ? equipo.tipoEquipo.nombre + equipo.marca : '';
                case 'alias': return equipo.alias ? equipo.alias.toString() : '';
                default: return 1;
            }
        }

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    verEquipo(equipo: MantEquiposModels.Equipo){
        const modalRef=this.modalService.open(EquipoVerComponent, {
            centered:true,
            size: 'xl'
        });
        modalRef.componentInstance.equipoInput.equipo = Object.assign({}, equipo) ;
    }

    nuevoEquipo(){
        this.modalService.open(EquipoNuevoComponent, {
            centered:true,
            size: 'lg'
        });
    }
}
