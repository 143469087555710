import { Component, OnInit, Inject } from '@angular/core';
import { CajaMuestrasViewModels } from '../../caja-muestras-models/caja-muestras.view.models';
import {  Router } from '@angular/router';
import { SampleBoxDataProvider } from '../../../shared/services/sampleBoxDataProvider.service';

@Component({
    selector: 'caja-muestas-icon',
    templateUrl: './caja-muestas-icon.component.html',
    styleUrls: ['./caja-muestas-icon.component.css']
})
export class CajaDeMuestrasIconComponent {

    model: CajaMuestrasViewModels.ViewModel = {
        data: {
            clienteId:'',
            faenaId:'',
            fechaActualizacion:new Date(),
            fechaCreacion: new Date(),
            muestras:[]

        }     
    };
    
    constructor(
        private router: Router,
        public appData:SampleBoxDataProvider
    ) {       
        this.model.data=appData.sampleBoxData;
        appData.sampleBoxDataObserver.subscribe(data=>{
                this.model.data=data;
        });
    }
    
    irACajaDeMuestras(){
        this.router.navigate(['/sample-box']);
    }   
  
}
