import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { RegistraMuestraViewModels } from '../registro-multiple-models/registro-multiple.view.models';
import { RegistraService } from '../registro-multiple-services/registro-multiple.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SampleBoxDataProvider } from '../../../shared/services/sampleBoxDataProvider.service';
import { Objects } from 'src/app/shared';
import { RegistraMuestraModels } from '../registro-multiple-models/registro-multiple.models';
import { FormGroup } from '@angular/forms';
import { ModalConfirmService } from '../../../shared/services/modalConfirm.service';
import { LogService } from 'src/app/shared/services/log.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { RegistroModalComentarioComponent } from '../registro-modal-comentario/registro-modal-comentario.component';
import { CondicionesComercialesService } from 'src/app/condiciones-comerciales/condiciones-comerciales-services/condiciones-comerciales.service';
import { CondicionesComercialesModels } from 'src/app/condiciones-comerciales/condiciones-comerciales-models/condiciones-comerciales.models';

@Component({
    selector: 'registro-multiple-componente',
    templateUrl: './registro-multiple.component.html',
    styleUrls: ['./registro-multiple.component.css']
})
export class RegistraMuestraMultipleComponent implements OnInit {
    download_planes: boolean = true;
    model: RegistraMuestraViewModels.ViewModel = {
        registrosMuestras: [],
        planesCondicionComercial: [],
        componentes: [],
        errores: [],
        todaysdate: new Date(),
        registrandoEnProceso: false,
    };
    planesAnalisisFiltrados: CondicionesComercialesModels.PlanAnalisis[]=[];
    planesComponenteList: { [componenteId: string]: { planes: Objects.PlanAnalisis[]}}={};

    constructor(
        private authService: AuthenticationService,
        private service: RegistraService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: ModalConfirmService,
        public dialog: MatDialog,
        public appData: SampleBoxDataProvider,
        private _changeDetectorRef: ChangeDetectorRef,
        private _logService: LogService,
        private condicionesComercialesService: CondicionesComercialesService

    ) {

    }

    enter(e: any): void {

        e.preventDefault();
    }

    ngOnInit(): void {
        this.download_planes = true;
        let data = this.route.snapshot.queryParams.data;
        this.model.componentes = JSON.parse(data);
        this.getCondicionComercial().then(() => {
            this.getPlanes();
        });
        this.model.componentes.forEach(componente => {
            this.planesComponenteList[componente.componenteId]={planes:[]}
            let muestra: Objects.RegistroMuestra = {
                componente: componente,
                fechaMuestreo: '',
                muestraId: '',
                rellenoDesdeUltimoCambio: '',
                unidadUsoCambioLubricante: 'km',
                unidadUsoTotalComponente: 'km',
                usoCambioLubricante: '',
                usoTotalComponente: '',
                lubricante: componente.lubricante,
                planAnalisis: undefined,
                otroLubricante: '',
                usuario: this.authService.current_user
            };
            this.model.registrosMuestras.push(muestra);
            this.model.errores.push({ muestraIdUtilizado: false });
        });
    }


    getPlanes(){
        const request: RegistraMuestraModels.GetPlanesAnalisisRequest = {
            planAnalisisId: ''
        };
        this.condicionesComercialesService.getPlanesAnalisis(request).subscribe((response) => {
            if (response.success && response.code === 200) {
                this.planesAnalisisFiltrados=response.data.filter(p=>this.model.planesCondicionComercial.some(pc=>pc.planAnalisisId==p.planAnalisisId));
                this.model.componentes.forEach(c=>{
                    this.planesComponenteList[c.componenteId]={
                    planes: this.planesAnalisisFiltrados
                        .filter(p => p.tiposComponentes.find(tc => tc.tipoComponenteId == c.tipoComponente.tipoComponenteId))
                        .map(p => ({ nombre: p.nombre, planAnalisisId: p.planAnalisisId }))
                    }
                });
                this.download_planes = false;
            }
        });
    }

    getCondicionComercial(): Promise<void> {
        return new Promise((resolve, reject) => {
            const request: CondicionesComercialesModels.GetCondicionComercialRequest = {
                clienteId: this.authService.currentClientValue.clienteId,
                faenaId: this.authService.currentClientValue.faena.faenaId
            };
            this.condicionesComercialesService.getCondicionComercial(request).subscribe((response) => {
                if (response.success && response.code === 200) {
                    this.model.planesCondicionComercial = response.data.planes;
                    resolve();
                }
                if(response.success&&response.code === 500){
                    reject();
                }
            }, error => {
                reject(error);
            });
        });
    }


    registrarMuestras() {

        if (this.model.registrandoEnProceso) {
            return;
        }
        this.model.registrandoEnProceso = true;
        this.appData.loadCajaDeMuestras(true).then(data => {
            let duplicados: string[] = [];
            this.model.registrosMuestras.filter((r, index) => {
                r.fechaIngreso = new Date();
                return data.muestras.filter(muestra => {
                    return muestra.muestraId === r.muestraId;
                }).length > 0
            }).forEach(e => {
                duplicados.push(e.muestraId);
                let nroActual = e.muestraId;
                e.muestraId = "";
                setTimeout(() => {
                    e.muestraId = nroActual;
                }, 200);
            });

            if (duplicados.length > 0) {

                this.model.registrandoEnProceso = false;
                return;
            }

            let promises: Promise<RegistraMuestraModels.setMuestraValidResponse>[] = [];
            this.model.registrosMuestras.forEach(rm => {
                promises.push(this.service.setMuestraValid({
                    clienteId: this.authService.currentClientValue.clienteId,
                    faenaId: this.authService.currentClientValue.faena.faenaId,
                    muestraId: rm.muestraId
                }).toPromise());
            });
            Promise.all(promises).then(validationResponses => {
                if (validationResponses.filter(muestra => !muestra.data.isValid).length > 0) {
                    validationResponses.forEach((err, index) => {
                        if (!err.data.isValid) {
                            this.model.errores[index].muestraIdUtilizado = true;
                        }
                    });
                } else {

                    this.registroMultipleLog(this.model.registrosMuestras);

                    this.appData.addMuestras(this.model.registrosMuestras);
                    let registroMuestra = JSON.stringify(this.model.registrosMuestras);
                    this.router.navigate(['/comprobante-muestra'], {
                        queryParams: { registroMuestras: registroMuestra }
                        , skipLocationChange: true
                    });
                }

            }).finally(() => {
                this.model.registrandoEnProceso = false;
                this._changeDetectorRef.detectChanges();
            });
        });
    }

    private registroMultipleLog(muestras: Objects.RegistroMuestra[]): void {
        muestras.forEach(m => {
            let rq: Objects.LogInput = {
                accion: 'New',
                elemento: m.muestraId,
                tipoElemento: 'Registro multiple muestra',
                usuario: this.authService.usuario.nombre,
                usuarioId: this.authService.usuario.userId,
                ambiente: 'Web Cliente'
            }
            this._logService.createLog(rq, true);
        })
    }

    cancelar() {
        this.router.navigate(['/busqueda-componente']);
    }

    eliminarRegistroMuestra(componenteId: string, index: number) {

        const modalConfirm = this.modalService.open({ title: 'Eliminar Muestra', msg: '¿Está seguro de eliminar el ID Componente	<strong>' + componenteId + '</strong>?' });
        modalConfirm.result.then(isOK => {
            if (isOK) {
                delete this.model.errores[index];
                this.model.registrosMuestras = this.model.registrosMuestras.filter(rm => {
                    return rm.componente.componenteId != componenteId
                })

                if (this.model.registrosMuestras.length === 0) {
                    this.cancelar();
                }
            }
        });
    }

    changeNumeroEnvase(e: RegistraMuestraModels.Error, h: any) {
        this.validarK(h);
        e.muestraIdUtilizado = false;
        this._changeDetectorRef.detectChanges();
    }
    existenErrores(): boolean {
        return this.model.errores.filter(e => {
            return e.muestraIdUtilizado === true;
        }).length > 0;
    }
    isDirtyOrtouched(form: FormGroup, name: string, idComponent: string, patron: string = ''): boolean {
        return form.controls[name + patron + idComponent].touched || form.controls[name + patron + idComponent].dirty;
    }
    sampleHasErrors(index: number) {
        return this.model.errores[index].muestraIdUtilizado;
    }
    validarK(k: any) {

        this.model.registrosMuestras[k].muestraId = this.model.registrosMuestras[k].muestraId.toUpperCase();

    }

    comentar(muestra: Objects.RegistroMuestra){
        const modal = this.dialog.open(RegistroModalComentarioComponent, {
            panelClass: 'ds-modal',
            disableClose: true,
            data: {
                comentario: muestra.comentarioCliente
            }
        });

        modal.afterClosed().subscribe((response) => {
            switch (response.status) {
                case "saved":
                    muestra.comentarioCliente = response.data.comentario;
                    break;
                case "cancel":
                    break
                default:
                    break;
            }
        });

    }
}
