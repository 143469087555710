import { Component, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class StorageTools {
    private objectsList: Map<string, string> = new Map();

    constructor(
    ) {
    }

    private imprimeLocal() {
        for (let i = 0; i < sessionStorage.length; i++){

        }
    }

    public containsKey(keyParam: string): boolean {
        let result = false;
        for (let i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i)) {
                result = true;
            }
        }
        return result;
    }

    public setItem(key: string, value: any): void {

        if (typeof (value) === 'object') {
            sessionStorage.setItem(key, JSON.stringify(value));
            this.objectsList.set(key, JSON.stringify(value));
        } else {
            console.log("key", key, "value", value);
            sessionStorage.setItem(key, value);
            this.objectsList.set(key, value);
        }
    }

    public getItem(keyParam: string): any {

        return sessionStorage.getItem(keyParam) ?? null;
    }

    public remove(keyParam: string): void {
        sessionStorage.remove(keyParam);
        this.objectsList.delete(keyParam);
    }

    public clear(): void {
        sessionStorage.clear();
        this.objectsList.clear();
    }
}
