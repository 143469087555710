<google-chart #pollutionChart  
            [title]="configChart.title"
            [type]="configChart.type"
            [data]="configChart.data"
            [options]="configChart.options"
            [width]="configChart.width"
            [height]="configChart.height"    
            (ready)="onReadypqFeCuChart($event)"
        >
</google-chart>
<img [src]="imgSrc" id="imgPollutionChart"  [hidden]="true"  (load)="onReadyImage()">
<div class="d-flex justify-content-center loading" *ngIf="isLoading">
    <mat-spinner matSuffix [diameter]="18"   ></mat-spinner>
</div>