import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Enums, Tools } from "src/app/shared";
import { ServiceSubjectTimeProvide } from "src/app/shared/tools/service-subject-provider.tools";
import { ServiceSubjectTime } from "src/app/shared/tools/service-subject-time.tools";
import { environment } from "src/environments/environment";
import { LimitesModels } from "../mant-limites-models/limites.models";

@Injectable({
    providedIn: 'root'
})
export class LimitesServices {
    subjetGetListComponents: ServiceSubjectTime<LimitesModels.GetTipoComponenteRequest,LimitesModels.GetTipoComponenteResponse>;
    subjetGetListEquipos: ServiceSubjectTime<LimitesModels.GetTipoEquipoRequest,LimitesModels.GetTipoEquipoResponse>;
    subjetGetListTagEquipos: ServiceSubjectTime<LimitesModels.GetTagEquipoRequest,LimitesModels.GetTagEquipoResponse>;
    subjetGetProtocolsSpecific: ServiceSubjectTime<LimitesModels.GetProtocolsSpecificRequest,LimitesModels.GetProtocolsSpecificResponse>;
    subjetGetListAnalysis: ServiceSubjectTime<LimitesModels.GetEnsayosRequest,LimitesModels.GetEnsayosResponse>;
    subjetGetListPlans: ServiceSubjectTime<LimitesModels.GetPlanesAnalisisRequest,LimitesModels.GetPlanesAnalisisResponse>;
    subjetGetListCategories: ServiceSubjectTime<LimitesModels.GetCategoriesRequest,LimitesModels.GetCategoriesResponse>;




    constructor(
        private http: HttpClient,
        private tools: Tools,
        serviceSubject: ServiceSubjectTimeProvide,
    ) {
        this.subjetGetListComponents = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.tipoComponente}`);
        this.subjetGetListEquipos = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.tipoEquipo}`);
        this.subjetGetListTagEquipos = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.equipo}`);
        this.subjetGetProtocolsSpecific = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.protocolospersonalizado}`);
        this.subjetGetListAnalysis = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.ensayos}`);
        this.subjetGetListPlans = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.plananalisis}`);
        this.subjetGetListCategories = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.categorias}`);
    }




    //Ensayos
    GetEnsayos(request: LimitesModels.GetEnsayosRequest, forceRefresh: boolean = false): Observable<LimitesModels.GetEnsayosResponse> {
        return this.subjetGetListAnalysis._httpGet(request, {force: forceRefresh, spinner: false});
    }

    GetEnsayos2(request: LimitesModels.GetEnsayosRequest, forceRefresh: boolean = true): Promise<LimitesModels.GetEnsayosResponse> {
        return  this.http.get<LimitesModels.GetEnsayosResponse>(`${environment.apiUrl}${environment.routes.ensayos}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    }

    //Planes
    GetPlanesAnalisis(request: LimitesModels.GetPlanesAnalisisRequest, forceRefresh: boolean = false): Observable<LimitesModels.GetPlanesAnalisisResponse> {
        return this.subjetGetListPlans._httpGet(request, {force: forceRefresh, spinner: false});
    }
    //Categoria Lubricantes
        GetCategoryLubricants(request: LimitesModels.GetCategoriesRequest, opts: { force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = { force: false, spinner: false, ignoreStore: false }): Observable<LimitesModels.GetCategoriesResponse> {
            return this.subjetGetListCategories._httpGet(request, opts);
        }


    //Componentes
    GetTiposComponentes(request: LimitesModels.GetTipoComponenteRequest, forceRefresh: boolean = false): Observable<LimitesModels.GetTipoComponenteResponse> {
        return this.subjetGetListComponents._httpGet(request, {force: forceRefresh, spinner: false});
    }

    GetComponentes(request: LimitesModels.GetComponentesRequest, forceRefresh: boolean = false): Observable<LimitesModels.GetComponentesResponse> {
        return  this.http.get<LimitesModels.GetComponentesResponse>(`${environment.apiUrl}${environment.routes.componente}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) });
     }

     GetProtocolsSpecific(request: LimitesModels.GetProtocolsSpecificRequest, forceRefresh: boolean = false): Observable<LimitesModels.GetProtocolsSpecificResponse> {
        return this.http.get<LimitesModels.GetProtocolsSpecificResponse>(`${environment.apiUrl}${environment.routes.protocolospersonalizado}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) });
    }



    //Equipos
    GetTiposEquipos(request: LimitesModels.GetTipoEquipoRequest, forceRefresh: boolean = false): Observable<LimitesModels.GetTipoEquipoResponse> {
        return this.subjetGetListEquipos._httpGet(request, {force: forceRefresh, spinner: false});
    }

    GetTagEquipos(request: LimitesModels.GetTagEquipoRequest, forceRefresh: boolean = false): Observable<LimitesModels.GetTagEquipoResponse> {
        return this.subjetGetListTagEquipos._httpGet(request, {force: forceRefresh, spinner: false});
    }

    //SET

    SetProtocolSpecific(request: LimitesModels.SetProtocolSpecificRequest, opts: { spinner: boolean } = { spinner: true }): Promise<LimitesModels.SetProtocolSpecificResponse> {
        return this.http.post<LimitesModels.SetProtocolSpecificResponse>(`${environment.apiUrl}${environment.routes.protocolospersonalizado}`, request.protocol, { context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    //DEL

    DeletePerso(request: LimitesModels.DelPersoRequest, opts: { spinner: boolean } = { spinner: true }): Promise<LimitesModels.DelPersoResponse> {
        return this.http.delete<LimitesModels.DelPersoResponse>(`${environment.apiUrl}${environment.routes.protocolospersonalizado}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
      }

    //Reportes
    GetReporte(request: LimitesModels.GetReporteRequest, opts: { spinner: boolean } = { spinner: true }): Promise<LimitesModels.GetReporteResponse> {
        return this.http.get<LimitesModels.GetReporteResponse>(`${environment.apiUrl}${environment.routes.protocolospersonalizadoReporte}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }
    SetReporte(request: LimitesModels.SetReporteRequest, opts: { spinner: boolean } = { spinner: true }): Promise<LimitesModels.SetReporteResponse> {
        return this.http.post<LimitesModels.SetReporteResponse>(`${environment.apiUrl}${environment.routes.protocolospersonalizadoReporte}`, request, { context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }
}
