import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { Objects } from 'src/app/shared/models/objects';

@Component({
    selector: 'app-modal-view-video-tableau',
    templateUrl: './modal-view-video-tableau.component.html',
    styleUrls: ['./modal-view-video-tableau.component.css'],
})
export class ModalViewVideoComponent {
    clienteSeleccionado: Objects.Cliente = {} as Objects.Cliente;
    clientes: Objects.Cliente[] = [];
    faenaSeleccionada: Objects.Faena = {} as Objects.Faena;
    faenas: Objects.Faena[] = [];

    constructor(
        private authService: AuthenticationService,
        public dialogRef: MatDialogRef<ModalViewVideoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}
