import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tools } from './objects.tools';
import { ServiceSubjectTime } from './service-subject-time.tools';



@Injectable({
    providedIn: 'root'
})
export  class ServiceSubjectTimeProvide{

    constructor(
        private http: HttpClient,
        private tools: Tools
    ) {}


    getServiceSubject(url:string):ServiceSubjectTime<any,any>{
        return new ServiceSubjectTime(url,this.http,this.tools);
    }

}