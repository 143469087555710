<div class="modal-header align-items-start">
  <h5 class="modal-title">
    <div class="d-flex flex-column">
      <span>Fotos de ensayos</span>
      <small>Frasco 23423453445</small>
    </div>
  </h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="grid grid--gallery">
    <div class="d-flex flex-column gap-2" *ngFor="let imagen of imagenes">
      <img class="rounded" src="{{imagen.url}}" alt="">
      <small>{{imagen.ensayoNombre}}</small>
    </div>
  </div>
</div>
