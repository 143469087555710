<div class="modal-header">
  <h5 class="modal-title">¿Estás seguro de la personalización?</h5>
</div>
<div class="modal-body">
  <p>Se ha seleccionado {{ensayos === 1 ? "1 ensayo." : ensayos + " ensayos."}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light mx-4" data-bs-dismiss="modal" (click)="volver()">Editar</button>
  <button type="button" class="btn btn-primary m-0"  (click)="confirmar()">Confirmar</button>
</div>
