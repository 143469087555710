import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {

    loading$: Subject<boolean> = new Subject();
    isOn: boolean=true;
    blockOn: boolean = false;
 
    constructor(){ 
    }
    off(blockOn: boolean = false){
        this.blockOn = blockOn;
        this.isOn=false;
    }
    on(){
        this.isOn=true;
    }
    startLoading() {
        if(this.isOn){            
            this.loading$.next(true);
        }else if(!this.blockOn){
            this.on();
        }
    }

    stopLoading() {
        this.loading$.next(false);
    }
}