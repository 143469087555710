<div class="report" id='report' *ngIf="docVenta" [style.display]="visible?'block':'none'" style="width: 1300px;">
  <div id='proformareport' style="width: 1300px;">
      <div class="report__top">
          <div class="report__title">
              <h1 style="vertical-align: top; "><strong style="font-weight: bold;">Proforma</strong></h1>
          </div>
          <div class="report__brand">
              <img class="color" class="convert-on-pdf" src="../../../../../../../../assets/images/logo-mobil-assistance_400_61.png" alt="">
              <img class="black"  src="../../../../../../../../assets/images/logo-mobil-assistance-negro.svg" alt="">
          </div>
      </div>

      <div class="report__header mb-3 d-flex justify-content-between" style="width: 1300px;">
          <div class="description">
              <div class="description__definition">CLIENTE</div>
              <div class="description__term">
                  <span class="d-block" style="font-weight: bold;">{{nombre_cliente_1 | replace : ' ' : '   '}}</span>
                  <span class="d-block" style="font-weight: bold;" *ngIf="nombre_cliente_2 != ''">{{nombre_cliente_2 | replace : '   ' : ' '}}</span>
                  <small class="d-block">{{docVenta.cliente.numeroSap}}</small>
              </div>
          </div>
          <div class="description">
              <div class="description__definition">FAENA</div>
              <div class="description__term">
                  <span class="d-block" style="font-weight: bold;">{{docVenta.cliente.faena.nombre | replace : ' ' :'   '}}</span>
                  <small class="d-block">{{docVenta.cliente.faena.numeroSap}}</small>
              </div>
          </div>
          <div class="description">
              <div class="description__definition">PERIODO</div>
              <div class="description__term">
                  <strong class="d-block" style="font-weight: bold;" >Desde {{docVenta.desde_periodo}}</strong>
                  <strong class="d-block" style="font-weight: bold;">Hasta {{docVenta.hasta_periodo}}</strong>

              </div>
          </div>
          <div class="proforma-card">
              <div class="description">
                  <div class="description__definition txt--sm">MUESTRAS</div>
                  <div class="description__term">
                      <h3 class="d-block" style="font-weight: bold;">{{total_muestras}}</h3>
                  </div>
              </div>
              <div class="description">
                  <div class="description__definition txt--sm">PRECIO TOTAL</div>
                  <div class="description__term">
                      <h3 class="d-block" style="text-align:right">${{total_cost | number}}</h3>
                  </div>
              </div>
          </div>
      </div>

      <div class="report__detail flex-column" style="width: 1300px;">
          <div class="w-100" *ngIf="muestras!.length>0">
              <table class="table table--detail w-100">
                  <thead>
                      <tr>
                          <th class="text-dark">Frasco</th>
                          <th class="text-dark">Muestra</th>
                          <th class="text-dark">ID equipo</th>
                          <th class="text-dark">Plan de análisis</th>
                          <th class="text-dark">Fecha ingreso</th>
                          <th class="text-dark">Fecha aprobación</th>

                          <th class="text-dark">Precio</th>
                          <th class="text-dark">Precio CLP</th>
                          <th class="text-dark">Descuento</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of muestras">
                          <td>{{item.muestra.muestraId}}</td>
                          <td>{{item.muestra.correlativo}}</td>
                          <td>{{item.muestra.componenteId}}</td>
                          <td>{{item.muestra.planAnalisis.nombre}}</td>
                          <td>{{ format_date(item.muestra.fechaIngreso) }}</td>
                          <td>{{ format_date(item.muestra.fechaAprobacion) }}</td>
                          <td>
                              {{ item.precio.currency && item.precio.currency !== '' ? (item.precio.currency !== 'CLP' ? format_currency(item.precio.currency)+(item.precio.precioListaTM | number: '1.0-2') : '-') : "-" }}
                          </td>
                          <td>$ {{item.precio.precioLista | number}}</td>
                          <td>$ {{item.precio.descuento | number}}</td>
                      </tr>
                      <tr *ngIf="total_muestras===0">
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                      </tr>
                  </tbody>
                  <tfoot>
                      <tr>
                          <td>TOTAL</td>
                          <td colspan="6">{{total_muestras}} muestras</td>
                          <td>${{total_precio_lista | number}}</td>
                          <td>${{total_descuento | number}}</td>
                      </tr>
                  </tfoot>
              </table>

          </div>
      </div>
  </div>

  <div class=" py-4 d-flex justify-content-end" id="footer" style="width: 1300px;">
      <img class="convert-on-pdf" src="../../../../../../../../assets/images/logo-copec-85.png" alt="copec">
  </div>
  <div class="printed" *ngIf="printing">
      <h2>Generando PDF de proforma</h2>
      <p>Estamos generando la proforma, esto podría tardar unos minutos.</p>
  </div>
</div>
