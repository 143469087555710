import { Directive, Input, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[htmlTooltip]'
})
export class HtmlTooltipDirective {
  @Input('htmlTooltip') tooltipContent: string = '';

  private tooltipElement: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'custom-tooltip');
    this.renderer.appendChild(document.body, this.tooltipElement);
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip() {
    this.tooltipElement.innerHTML = this.tooltipContent;
    const rect = this.el.nativeElement.getBoundingClientRect();
    const top = rect.top + window.scrollY - this.tooltipElement.offsetHeight - 5;
    const left = rect.left + window.scrollX + rect.width / 2 - this.tooltipElement.offsetWidth / 2;
    this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
    this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
  }

  private hideTooltip() {
    this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
  }
}
