import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "src/app/shared/auth/authentication.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { EquipoNuevoModels } from "../equipo-nuevo-models/equipo-nuevo.models";
import { EquipoNuevoViewModels } from "../equipo-nuevo-models/equipo-nuevo.view.models";
import { EquipoNuevoService } from "../equipo-nuevo-services/equipo-nuevo.service";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EquipoVerComponent } from "../../equipo-ver/equipo-ver-views/equipo-ver.component";
import { ComponenteAsignarEquipoComponent } from "src/app/mantenedores/mant-componentes/mant-componentes-views/comp-asignar-equipo/comp-asignar-equipo-views/comp-asignar-equipo.component";
import { ComponenteNuevoComponent } from "src/app/mantenedores/mant-componentes/mant-componentes-views/componente-nuevo/componente-nuevo-views/componente-nuevo.component";
import { EventEmitterService } from "src/app/shared/services/event-emitter.service";
import { LogService } from "src/app/shared/services/log.service";
import { Objects } from "src/app/shared";

@Component({
    selector: 'app-equipo-nuevo',
    templateUrl: './equipo-nuevo.component.html',
    styleUrls: ['./equipo-nuevo.component.css']
})
export class EquipoNuevoComponent implements OnInit {

    @Input() equipoInput: EquipoNuevoModels.EquipoInput = {
        equipo: {
            clienteId: '',
            faenaId: '',
            equipoId: '',
            tipoEquipo: {
                tipoEquipoId: '',
                nombre: ''
            },
            marca: '',
            modelo: '',
            modeloOtro: false,
            descriptor: '',
            alias: [],
            tags: [],
            componentes: []
        },
        vieneDesdeMuestra: false,
    }

    model: EquipoNuevoViewModels.ViewModel = {
        equipo: {
            clienteId: '',
            faenaId: '',
            equipoId: '',
            tipoEquipo: {
                tipoEquipoId: '',
                nombre: ''
            },
            marca: '',
            modelo: '',
            modeloOtro: false,
            descriptor: '',
            alias: [],
            tags: [],
            componentes: []
        },
        tipoEquipos: [],
        marcas: [],
        modelos: [],
        verOtraMarca: false,
        verOtroModelo: false,
        otraMarca: '',
        otroModelo: '',
        verMasCampos: false,
        selectable: true,
        removable: true,
        estaEditando: false,
        idValidado: false,
        idError: false,
        loading: false,
        loadingEquipoId: false,
    }

    equipoSave: EquipoNuevoModels.EquipoSet = {
        clienteId: '',
        faenaId: '',
        equipoId: '',
        tipoEquipo: {
            tipoEquipoId: '',
            nombre: ''
        },
        marca: '',
        modelo: '',
        modeloOtro: false,
        descriptor: '',
        alias: [],
        tags: [],
        componentes: []
    };

    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    constructor(
        private authService: AuthenticationService,
        private spinner: SpinnerService,
        public dialogRef: NgbActiveModal,
        private service: EquipoNuevoService,
        private modalService: NgbModal,
        private eventEmiiterSrv: EventEmitterService,
        private _logService: LogService,
    ) { }

    ngOnInit(): void {
        this.spinner.off(true);
        this.getMarcaEquipo();
        this.model.equipo = this.equipoInput.equipo;
        this.model.equipoInmutado = Object.assign({}, this.equipoInput.equipo);

        console.log("this.model.equipo", this.model.equipo);

        if (this.equipoInput.vieneDesdeVerEquipo) {
            this.model.estaEditando = true;
            this.model.verMasCampos = true;
            this.model.equipo = this.equipoInput.equipo;
        }
    }

    limipiaId(): void {
        this.model.idError = false;
        this.model.idValidado = false;
    }

    validaId(): void {
        this.model.idError = false;
        this.model.loadingEquipoId = true;
        let existeEquipo: boolean = false;

        if (this.model.equipo.equipoId.trim() !== '') {
            const request: EquipoNuevoModels.GetEquipoVerRequest = {
                clienteId: this.authService.currentClientValue.clienteId,
                faenaId: this.authService.currentClientValue.faena.faenaId,
                equipoId: this.model.equipo.equipoId.trim()
            };
            this.service.GetEquipoInfo(request)
                .then(response => {
                    if (response.success) {
                        if (response.data.length != 0) {
                            existeEquipo = true;
                        }
                    } else {
                    }
                })
                .finally(() => {
                    this.model.idError = existeEquipo;
                    this.model.loadingEquipoId = false;
                    this.model.idValidado = true;
                })
        } else {
            this.model.idError = false;
            this.model.loadingEquipoId = false;
        }
    }

    getMarcaEquipo() {
        this.model.loading = true;
        let tipoMarcaModelo: EquipoNuevoModels.tipoMarcaModeloEquipo;
        const request: EquipoNuevoModels.GetMarcaEquipoRequest = {
        };
        this.service.GetMarcaEquipoInfo(request)
            .then(response => {
                if (response.success) {
                    tipoMarcaModelo = response.data;
                    this.model.tipoEquipos = tipoMarcaModelo.tiposEquipos.sort((a, b) => a.nombre.localeCompare(b.nombre));
                    this.model.marcas = tipoMarcaModelo.marcas.sort((a, b) => a.nombre.localeCompare(b.nombre));

                    this.model.equipo.tipoEquipo = this.model.tipoEquipos.find(te => te.tipoEquipoId === this.equipoInput.equipo.tipoEquipo.tipoEquipoId)!;
                }
            })
            .finally(() => {
                this.model.loading = false;
            })
    }

    cancelar() {
        this.dialogRef.close();
        if (this.equipoInput.vieneDesdeAsignarEquipo) {
            const modalRef = this.modalService.open(ComponenteAsignarEquipoComponent, {
                centered: true,
                size: 'xl'
            });

            modalRef.componentInstance.componenteNuevo.componente = Object.assign({}, this.equipoInput.componente);
            modalRef.componentInstance.componenteNuevo.equipoAntiguo = Object.assign({}, this.equipoInput.equipoAntiguo);
        }

        if (this.equipoInput.vieneDesdeVerEquipo) {
            const modalRef = this.modalService.open(EquipoVerComponent, {
                centered: true,
                size: 'xl'
            });

            modalRef.componentInstance.equipoInput.equipo = Object.assign({}, this.model.equipoInmutado);
        }
    }

    masCampos() {
        this.model.verMasCampos = true;
    }

    cambiaMarca() {
        if (this.model.equipo.marca === 'otro') {
            this.model.modelos = [];
            this.model.equipo.modelo = '';
            this.model.verOtraMarca = true;
            this.model.otroModelo = '';
            this.model.verOtroModelo = false;
        } else {
            this.model.modelos = [];
            this.model.equipo.modelo = '';
            this.model.modelos = this.model.marcas.find(element => element.nombre === this.model.equipo.marca)?.modelo!;
            this.model.verOtraMarca = false;
            this.model.otroModelo = '';
            this.model.verOtroModelo = false;
        }
    }

    cambiaModelo() {
        if (this.model.equipo.modelo === 'otro') {
            this.model.verOtroModelo = true;
        } else {
            this.model.verOtroModelo = false;
        }
    }

    guardarEquipo(): void {
        if (this.model.idValidado || this.model.estaEditando) {
            this.spinner.on();
            this.model.equipo.clienteId = this.authService.currentClientValue.clienteId;
            this.model.equipo.faenaId = this.authService.currentClientValue.faena.faenaId;

            const request: EquipoNuevoModels.SetEquipoNuevoRequest = {
                equipo: this.setEquipo()
            };
            this.service.setEquipoNuevo(request).subscribe(data => {
                if (data.success) {
                    let rq: Objects.LogInput = {
                        accion: 'New',
                        elemento: this.model.equipo.equipoId,
                        tipoElemento: 'Componente',
                        usuario: this.authService.usuario.nombre,
                        usuarioId: this.authService.usuario.userId,
                        ambiente: 'Web Cliente'
                    }
                    this._logService.createLog(rq, true);
                    if (this.model.estaEditando) {

                        let equipoInput: EquipoNuevoModels.EquipoInput = {
                            equipo: this.model.equipo,
                            estaEditando: true,
                            vieneDesdeMuestra: false,
                        }

                        this.spinner.off();
                        this.dialogRef.close();
                        const modalRef = this.modalService.open(EquipoVerComponent, {
                            centered: true,
                            size: 'xl'
                        });
                        modalRef.componentInstance.equipoInput = Object.assign({}, equipoInput);
                    }
                    else if (this.equipoInput.vieneDesdeAsignarEquipo) {
                        let componenteInput: EquipoNuevoModels.ComponenteInput = {
                            componente: this.equipoInput.componente!,
                            equipo: this.model.equipo,
                            equipoAntiguo: this.equipoInput.equipoAntiguo,
                            vieneDesdeVerComponente: this.equipoInput.vieneComponenteVer == true ? true : false,
                            vuelveNuevoComponente: this.equipoInput.vieneComponenteVer == false ? true : false,
                            tieneEquipoCargado: true,
                        }

                        this.spinner.off();
                        this.dialogRef.close();
                        const modalRef = this.modalService.open(ComponenteNuevoComponent, {
                            centered: true,
                            size: 'lg'
                        });
                        modalRef.componentInstance.compDesdeAsignarEquipo = Object.assign({}, componenteInput);
                    } else if (this.equipoInput.vieneDesdeMuestra) {
                        let equipoInput: EquipoNuevoModels.EquipoInput = {
                            equipo: this.model.equipo,
                            vieneDesdeMuestra: this.equipoInput.vieneDesdeMuestra,
                        }

                        this.spinner.off();
                        this.dialogRef.close();
                        const modalRef = this.modalService.open(EquipoVerComponent, {
                            centered: true,
                            size: 'xl'
                        });
                        modalRef.componentInstance.equipoInput = Object.assign({}, equipoInput);
                    } else {
                        this.eventEmiiterSrv.sendResult("FLOW_MANTENEDOR", { accion: 'NUEVO', id: this.model.equipo.equipoId });
                        this.dialogRef.close();
                    }
                }
            });
        }
    }

    private setEquipo() {
        let componentes: string[] = [];
        if (this.model.estaEditando) {
            if (this.model.equipo.componentes) {
                this.model.equipo.componentes.forEach(element => {
                    componentes.push(element.componenteId);
                });
            }
        }
        this.equipoSave.clienteId = this.authService.currentClientValue.clienteId;
        this.equipoSave.faenaId = this.authService.currentClientValue.faena.faenaId;
        this.equipoSave.equipoId = this.model.equipo.equipoId.trim();
        this.equipoSave.tipoEquipo = this.model.equipo.tipoEquipo;
        this.equipoSave.marca = this.model.equipo.marca != 'otro' ? this.model.equipo.marca : this.model.otraMarca.trim();
        this.equipoSave.modelo = this.model.equipo.modelo != 'otro' ? this.model.equipo.modelo : this.model.otroModelo.trim();
        this.equipoSave.modeloOtro = this.model.equipo.modelo != 'otro' ? false:true;
        this.equipoSave.descriptor = this.model.equipo.descriptor ? this.model.equipo.descriptor.trim() : '';
        this.equipoSave.alias = this.model.equipo.alias;
        this.equipoSave.tags = this.model.equipo.tags;
        this.equipoSave.componentes = componentes;
        return this.equipoSave;
    }

    addAlia(event: any): void {
        const value = (event.value || '').trim();
        if (value) {
            this.model.equipo.alias?.push(value);
        }
        event.input.value = '';
    }

    removeAlia(alia: string): void {
        const index = this.model.equipo.alias?.indexOf(alia);

        if (index != undefined && index >= 0) {
            this.model.equipo.alias?.splice(index, 1);
        }
    }

    addTag(event: any): void {
        const value = (event.value || '').trim();
        if (value) {
            this.model.equipo.tags?.push(value);
        }
        event.input.value = '';
    }

    removeTag(tag: string): void {
        const index = this.model.equipo.tags?.indexOf(tag);

        if (index != undefined && index >= 0) {
            this.model.equipo.tags?.splice(index, 1);
        }
    }

}
