import { Subscription } from 'rxjs';



export class SubscribersTools {


    subscribers: {name:string,sub:Subscription,active?:boolean}[] = [];
    queueStop:string[]=[];

    constructor() {
        
    }


    public addSubscribe(name:string,sub:Subscription){
        if(this.queueStop.indexOf(name)!==-1){
           this.queueStop=this.queueStop.filter(n=>n!==name);
           sub.unsubscribe();
           return;
        }
        this.subscribers.filter(s=>!name || s.name===name).forEach(s=>{
            s.sub.unsubscribe();
            s.active=false;
        })
        this.subscribers.push({name:name,sub:sub});
      }
    public destroySubs(name?:string) {
        this.subscribers.filter(s=>!name || s.name===name).forEach(s=>{
            s.sub.unsubscribe();
            s.active=false;
        })
        if(name && this.subscribers.filter(s=>s.name===name).length===0 &&  this.queueStop.indexOf(name) ===-1){
            this.queueStop.push(name);
        }
    }
}