import { Component, OnInit, ViewChild } from '@angular/core';

import { SolicitudDeAnalisisViewModels } from '../../mant-solicitud-analisis-models/mant-solicitud-analisis.view.models';
import { SolicitudDeAnalisisService } from '../../mant-solicitud-analisis-services/mant-solicitud-analisis.service';
import {  SolicitudDeAnalisisModels } from '../../mant-solicitud-analisis-models/mant-solicitud-analisis.models';
import { SpinnerService } from '../../../../shared/services/spinner.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../shared/auth/authentication.service';
import { EventEmitterService } from '../../../../shared/services/event-emitter.service';
import { ToastService } from '../../../../shared/components/ng-toast/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSolicitudAnalisisComponent } from '../../../../shared/components/modal-solicitud-analisis/modal-solicitud-views/modal-solicitud.component';
import * as moment from 'moment';

@Component({
    selector: 'mantenedor-solicitud-de-analisis',
    templateUrl: './mant-solicitud-analisis.component.html',
    styleUrls: ['./mant-solicitud-analisis.component.css']
})
export class MantSolicitudDeAnalisisComponent implements OnInit {

    displayedColumns: string[] = ['solicitud.solicitudAnalisisId', 'transportista', 'ordenTransporte','solicitante','fecha','muestras', 'estado','resultados'];


    typeSearch = false;
    dataSource = new MatTableDataSource<SolicitudDeAnalisisModels.SolicitudDeAnalisis>([]);

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    myControl = new FormControl();
    filteredOptions!: Observable<SolicitudDeAnalisisModels.SolicitudDeAnalisis[]>;
    subscribers: {name:string,sub:Subscription,active?:boolean}[] = [];

    model: SolicitudDeAnalisisViewModels.ViewModel = {
        pagination: { page: 0, pageSize: 10, totalElements: 0 },
        checkAll: false,
        solicitudes: [],
        solicitudBuscada: '',
        filtroDeBusqueda:'',
        solicitudesEncontradas: [],
        alertaSinResultado: false,
        cargando: false,
        esClienteConEnvioCourier:false,
        condicionComercial:{

            transporte:""
        },
        estadosDeSolicitud:{
            reported: 'Reportada',
            in_process:'Recibida',
            registered:'Registrada',
            pending:'Pendiente',
            pending_ot:'Pendiente OT',
            courier:'En courier',
            received:'Recibida',
            received_observations:'Recibida c/ observaciones',
            scheduled: 'Programada'
        },
        courierDescripcion:{
            correoschile:'Correos de Chile',
            chileexpress: 'Chilexpress',
            jaacs: 'Transportes Jaacs',
            sintransporte: 'Cliente'
        },
        popovers:[]
    }

    constructor(
        private authService: AuthenticationService,
        private service: SolicitudDeAnalisisService,
        private spinner: SpinnerService,
        private router: Router,
        private eventEmiiterSrv: EventEmitterService,
        public toastService: ToastService,
        private modalService: NgbModal
    ){

    }

    ngOnInit(): void{
        this.getSolicitudes();
        let req:SolicitudDeAnalisisModels.GetCondicionComercialRequest;
        req={
            clienteId:this.authService.currentClientValue.clienteId,
            faenaId:this.authService.currentClientValue.faena.faenaId
        }
       this.service.getCondicionComercial(req).subscribe(response=>{
            if(response.success){
                this.model.condicionComercial=response.data
            }

       })

        this.addSubscribe('carta',this.eventEmiiterSrv.event(this.eventEmiiterSrv.enumEvents.DESCARGA_CARTA).subscribe(result=>{

            let req=<SolicitudDeAnalisisModels.SetSolicitudDeAnalisisEstadoRequest>result.data;

            let op=this.model.solicitudesEncontradas.find(e=>e.solicitud.solicitudAnalisisId===req.solicitudAnalisisId);
            if(op && result.success){
                op.updating=true;
                this.toastService.show(`La solicitud número ${req.solicitudAnalisisId} fue actualizada`, { classname: 'bg-primary text-light', delay: 2000 });
            }
            this.getSolicitudes(true);
        }));

        this.eventEmiiterSrv.createSubcriberUnique(this.eventEmiiterSrv.enumEvents.ENVIO_CAJA_MUESTRA,'sub-tabla-sol-por-envio-caja',()=>{
            this.getSolicitudes(true);
        });

    }

    private addSubscribe(name:string,sub:Subscription){
        this.subscribers.push({name:name,sub:sub});
    }

    ngOnDestroy() {
        this.destroySubs();
        this.eventEmiiterSrv.completeEvent(this.eventEmiiterSrv.enumEvents.DESCARGA_CARTA);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    public recargarTabla(){
        this.model.solicitudesEncontradas=[];
        this.LoadPaginate();
        this.getSolicitudes(true);
    }

    private getSolicitudes(force?:boolean){
        this.destroySubs('solicitudes');
        const request: SolicitudDeAnalisisModels.GetSolicitudDeAnalisisRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId:this.authService.currentClientValue.faena.faenaId
        };
        this.model.cargando = true;
        this.spinner.off()
        this.addSubscribe('solicitudes',this.service.getSolicitudAnalisis(request,force).subscribe(response => {
            if (response.success) {

                this.model.solicitudes = response.data;
                this.model.fechaCargaSolicitudes=response.date;
                this.model.solicitudesEncontradas = this.model.solicitudes;
                this.spinner.stopLoading();
                this.model.cargando = false;
            }
            this.LoadPaginate();
        }));
    }


    private destroySubs(name?:string) {
        this.subscribers.filter(s=>!name || s.name===name).forEach(s=>{
            s.sub.unsubscribe();
            s.active=false;
        })
    }

    onFocusInputSearch(){
        if(this.model.cargando){
            this.spinner.startLoading();
        }
    }

    keyupInputSearch(){
        this.filtrarSolicitudes();
    }

    filtrarSolicitudes(): void {

        if(this.model.filtroDeBusqueda){

            this.model.pagination.page=0;
                let filtro =this.model.filtroDeBusqueda.toLowerCase();
                this.model.solicitudesEncontradas = this.model.filtroDeBusqueda.trim() === '' ? [] :
                    this.model.solicitudes.filter(option =>
                        option.solicitud.solicitudAnalisisId.toLowerCase().includes(filtro) ||
                        (option.transporte?.courier ? option.transporte.courier.toLowerCase().includes(this.model.filtroDeBusqueda.toLowerCase()):"")||
                        (option.transporte?.numeroOT ? option.transporte.numeroOT.includes(this.model.filtroDeBusqueda) :"") ||
                        option.solicitud.cliente.faena.nombre?.toLowerCase().includes(filtro) ||
                        option.solicitud.fechaCreacion.includes(filtro)||
                        option.estado.toLowerCase().includes(filtro)
                    );
                if (this.model.solicitudesEncontradas.length === 0){
                    this.model.alertaSinResultado=true;
                    this.LoadPaginate();
                } else {
                    this.model.alertaSinResultado=false;
                    this.LoadPaginate();
                }

        }else{
            this.model.solicitudesEncontradas = this.model.solicitudes;
            this.model.alertaSinResultado=false;
            this.LoadPaginate();
        }
    }


    popoverAgregarTransportistaYOrden(registro:SolicitudDeAnalisisModels.SolicitudDeAnalisis,transporte?:SolicitudDeAnalisisModels.Transporte){
       if(transporte){
           this.model.solicitudesEncontradas.forEach((elementoActual, index)=>{
               if(elementoActual.solicitud.solicitudAnalisisId===registro.solicitud.solicitudAnalisisId){
                   elementoActual.transporte={
                       numeroOT: transporte.numeroOT,
                        courier : transporte.courier
                   };
                   elementoActual.updating=true;
                }
            });
            this.actualizarTransporte(registro.solicitud.solicitudAnalisisId,transporte);
        }
    }

   actualizarTransporte(solicitudAnalisisId:string,transporte:SolicitudDeAnalisisModels.Transporte){
        let request:SolicitudDeAnalisisModels.SetSolicitudDeAnalisisEstadoRequest={
            clienteId:this.authService.currentClientValue.clienteId,
            faenaId:this.authService.currentClientValue.faena.faenaId,
            userId:this.authService.usuario.userId,
            solicitudAnalisisId:solicitudAnalisisId,
            transporte:transporte
        }
        this.spinner.off();
        this.service.setSolicitudAnalisisEstado(request).toPromise().then(r=>{
            this.getSolicitudes(true);
            if(r.success){
                this.toastService.show(`La solicitud número ${solicitudAnalisisId} fue actualizada`, { classname: 'bg-primary text-light', delay: 2000 });
            }
        });
   }

    handlePageEvent(event: PageEvent): void {
        this.model.pagination.totalElements = event.length;
        this.model.pagination.pageSize = event.pageSize;
        this.model.pagination.page = event.pageIndex;
        this.LoadPaginate();
    }

    LoadPaginate(): void {

        const records = this.model.solicitudesEncontradas;
        records?.forEach(t =>{
            if(t.solicitud.horaCreacion.indexOf("T") === -1) {
                t.solicitud.horaCreacion = moment(t.solicitud.horaCreacion, 'HH:mm:ss').format("YYYY-MM-DDTHH:mm");
            }
            if(t.solicitud.fechaCreacion.indexOf("T") === -1) {
                t.solicitud.fechaCreacion = moment(t.solicitud.fechaCreacion, 'DD/MM/YYYY HH:mm:ss').format("YYYY-MM-DDTHH:mm");
            }
          });
        this.dataSource = new MatTableDataSource<SolicitudDeAnalisisModels.SolicitudDeAnalisis>(records);
        this.dataSource.sortingDataAccessor = (solicitud: SolicitudDeAnalisisModels.SolicitudDeAnalisis, sortHeaderId: string) => {
            switch(sortHeaderId) {
                case 'solicitud.solicitudAnalisisId': return +solicitud.solicitud.solicitudAnalisisId;
                case 'transportista': return solicitud.transporte?this.model.courierDescripcion[solicitud.transporte.courier]:'';
                case 'ordenTransporte': return solicitud.transporte && solicitud.transporte.numeroOT?+solicitud.transporte.numeroOT:0;
                case 'solicitante': return solicitud.solicitud.cliente.faena?.nombre;
                case 'fecha': return solicitud.solicitud.fechaCreacion;
                case 'muestras': return solicitud.solicitud.muestras.length;
                case 'estado': return solicitud.estado;
                default: return 1;
              }
        }
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

    }

    irABusquedaComponente(){
        this.router.navigate(['/busqueda-componente']);
    }

    verSolicitud(solicitud:SolicitudDeAnalisisModels.SolicitudDeAnalisis){

        const modalRef=this.modalService.open(ModalSolicitudAnalisisComponent, {
            centered:true,
            size: 'xl'
        });
        modalRef.componentInstance.model.input= solicitud;


    }

    toUpperCase(e:string):string{
        return e.toUpperCase();
    }

    changeAutoClose(solicitud:SolicitudDeAnalisisModels.SolicitudDeAnalisis,aElement:any){
        this.model.popovers.forEach(e => {
            e.element.close();
        });
        this.model.popovers=[];
        this.model.popovers.push({id:solicitud.solicitud.solicitudAnalisisId,element:aElement});
    }

    public isReceived(status:string):boolean{
        return ['reported', 'in_process','received','received_observations'].indexOf(status) > -1
    }

    esEnvioCourier():boolean{
        return  this.model?.condicionComercial.transporte.toUpperCase().toUpperCase() === 'ENVIOCOURIER'
    }


}
