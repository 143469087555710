import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Tools } from "src/app/shared";
import { environment } from "src/environments/environment";
import { SolicitudDeInsumosModels } from "../solicitud-de-insumos-models/solicitud-de-insumos.models";
import { ServiceSubjectTime } from '../../shared/tools/service-subject-time.tools';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CondicionesComercialesService {

    subjetGetInsumos:ServiceSubjectTime<SolicitudDeInsumosModels.GetInsumosRequest,SolicitudDeInsumosModels.GetInsumosResponse>;

    constructor(
        private http: HttpClient,
        private tools: Tools
    ) {
        this.subjetGetInsumos = new ServiceSubjectTime(`${environment.apiUrl}${environment.routes.insumos}`,http,tools);


     }


    getInsumos(request:SolicitudDeInsumosModels.GetInsumosRequest,force:boolean): Observable<SolicitudDeInsumosModels.GetInsumosResponse>{
          return  this.subjetGetInsumos.httpGet(request,force);
    
    }

    setInsumos(request:SolicitudDeInsumosModels.SetInsumoRequest): Promise<SolicitudDeInsumosModels.SetInsumoResponse>{
        return this.http.post<SolicitudDeInsumosModels.SetInsumoResponse>(`${environment.apiUrl}${environment.routes.insumos}`, request).toPromise();
    }
   
}