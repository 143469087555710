import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmar-personalizacion',
  templateUrl: './confirmar-personalizacion.component.html',
  styleUrls: ['./confirmar-personalizacion.component.css']
})
export class ConfirmarPersonalizacionComponentLimites {
    @Input() ensayos: number=0;
  constructor(
    public dialogRef: NgbActiveModal,
  ) { }


  volver(){
    this.dialogRef.close()
  }

  confirmar(){
    this.dialogRef.close(true);
  }

}
