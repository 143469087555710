import { Component, OnInit, Input } from '@angular/core';


import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AuthenticationService } from '../../../auth/authentication.service';
import { SpinnerService } from '../../../services/spinner.service';
import { CartaAutRetiroViewModels } from '../carta-models/carta.view.models';
import { CartaAutRetiroService } from '../carta-services/carta.service';
import { CartaAutRetiroModels } from '../carta-models/carta.models';
import { EventEmitterService } from '../../../services/event-emitter.service';

@Component({
    selector: 'descarga-carta-autorizacion-retiro',
    templateUrl: './carta.component.html',
    styleUrls: ['./carta.component.css']
})
export class CartaAutRetiroComponent implements OnInit {
    model:  CartaAutRetiroViewModels.ViewModel={
        couriers:{
            CHILE_EXPRESS:{nombre:"CHILEXPRESS S.A.",sigla:"chileexpress",labelLink:"Carta para Chilexpress"},
            CORREOS_CHILE:{nombre:"CORREOS DE CHILE",sigla:"correoschile",labelLink:"Carta para Correos de Chile"}
        }
    };
    @Input() courier:string=this.model.couriers.CHILE_EXPRESS.sigla;

    constructor(
        public authService: AuthenticationService,
        private spinner:SpinnerService,
        private service:CartaAutRetiroService,
        private eventEmiiterSrv: EventEmitterService
        ) {
    }

    ngOnInit(): void {

       let entidadDirigida=this.courier.toLowerCase()===this.model.couriers.CHILE_EXPRESS.sigla?this.model.couriers.CHILE_EXPRESS:this.model.couriers.CORREOS_CHILE;
        this.model.carta=
            {
                fecha:new Date(),
                direccionLab : 'Camino costero 1111, secotr el Bato, Loncura, Quintero',
                nombreUsuario: this.authService.currentUserValue.nombre + ' ' + this.authService.currentUserValue.apellido,
                rutUsuario: this.authService.currentUserValue.rut,
                dirigidaA:entidadDirigida
            };
        this.model.label=entidadDirigida.labelLink;

    }



    public descargarCarta(solicitudAnalisisId:string,numeroOT?:string):boolean {
        let DATA = document.getElementById(this.courier);
        const doc = new jsPDF('p', 'pt', 'a4');
        const options = {
          background: 'white',
          scale: 3,
          allowTaint: true,
          scrollX:0, scrollY: -window.scrollY
        };
        if(DATA){
            DATA.style.display = 'block';
            this.spinner.startLoading();
            html2canvas(DATA, options).then((canvas) => {
                const img = canvas.toDataURL('image/PNG');
                const bufferX = 15;
                const bufferY = 15;
                const imgProps = (doc as any).getImageProperties(img);
                const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
                return doc;
            })
            .then((docResult) => {
                    docResult.save(`c_autorizacion_${this.model.carta?.dirigidaA.sigla}_.pdf`);
                    this.informarSeleccion(solicitudAnalisisId,numeroOT);
            }).catch(e=>{
            }).finally(()=>{
                this.spinner.stopLoading();
            });
            DATA.style.display = 'none';
        }
        return false;

    }

    private informarSeleccion(solicitudAnalisisId:string,numeroOT?:string){
        let request:CartaAutRetiroModels.SetSolicitudDeAnalisisEstadoRequest={
            clienteId:this.authService.currentClientValue.clienteId,
            faenaId:this.authService.currentClientValue.faena.faenaId,
            userId:this.authService.usuario.userId,
            solicitudAnalisisId:solicitudAnalisisId,
            transporte:{
                courier:this.model.carta?this.model.carta?.dirigidaA.sigla:'',
                numeroOT:numeroOT || ''
            }
        }
        this.spinner.off();
        this.service.setSolicitudAnalisisEstado(request).toPromise().then(r=>{
            if(r.success){
                this.eventEmiiterSrv.sendResult(this.eventEmiiterSrv.enumEvents.DESCARGA_CARTA,{success:true,data:request});
            }
       });
    }

}
