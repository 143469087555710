<div class="row mb-3">
    <div class="col-12">
        <h3>Muestras y solicitudes de análisis</h3>
    </div>
</div>

<mat-tab-group animationDuration="0ms">
    <mat-tab label="Muestras">
        <app-mant-muestras #mantMuestras></app-mant-muestras>
    </mat-tab>
    <mat-tab label="Solicitudes">
        <div class="p-1 mt-3">
            <div class="row justify-content-between align-items-center mb-4 g-0">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                    <input type="search" class="form-control" placeholder="Buscar..." [formControl]="myControl" [(ngModel)]="model.filtroDeBusqueda" (focus)="onFocusInputSearch()" (input)="keyupInputSearch()">
                </div>
                <div class="col d-flex justify-content-end align-items-center gap-3">
                    <button class="btn btn-primary d-flex align-items-center" (click)="irABusquedaComponente()">
                        <em class="bi bi-plus-lg align-middle me-md-2"></em>
                        <span class="d-none d-md-block">Agregar muestra</span>
                    </button>
                    <caja-muestas-icon></caja-muestas-icon>
                </div>
            </div>
            <div class="mb-3 mt-5" id="alerta-sin-resultado" *ngIf="model.alertaSinResultado">
                <div class="alert alert-info text-start" role="alert">
                    <div class="alert-message">
                        <h4 class="alert-heading">Nada por aquí!</h4>
                        <p>No hemos encontrado ninguna solicitud que contenga el texto que buscas, por favor ajusta tu
                            búsqueda.</p>
                        <hr>
                    </div>
                </div>
            </div>
            <div id="mant-solicitudes">
                <div class="card" *ngIf="model.solicitudesEncontradas && !model.alertaSinResultado">
                    <div class="table-responsive">
                        <div *ngIf="model.filtroDeBusqueda" class="card-header">
                            <h5 class="card-title mb-0"><strong> Solicitudes registradas que contienen
                                    <span>{{model.filtroDeBusqueda}}</span></strong></h5>
                        </div>
                        <table mat-table [dataSource]="dataSource" matSort class="w-100">
                            <ng-container matColumnDef="solicitud.solicitudAnalisisId">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Solicitud</th>
                                <td mat-cell *matCellDef="let e">
                                    <a href="#" (click)="verSolicitud(e);false">{{e.solicitud.solicitudAnalisisId}}</a>
                                    <span *ngIf="e.updating"><mat-spinner matSuffix [diameter]="18" style="margin-left: 8px;display: inherit;"></mat-spinner></span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="transportista">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Transportista </th>
                                <td mat-cell *matCellDef="let e">
                                    {{e.transporte?model.courierDescripcion[e.transporte.courier]:'' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="ordenTransporte">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Orden de transporte </th>
                                <td mat-cell *matCellDef="let e">
                                    {{e.transporte?.numeroOT}}
                                    <span *ngIf="!isReceived(e.estado) &&  !e.transporte?.numeroOT && esEnvioCourier()">
                                        <ng-template #popContent>
                                            <popover-editar-transporte [transporte]="e.transporte" (resultado)="popoverAgregarTransportistaYOrden(e,$event);pEditarOrden.close()"></popover-editar-transporte>
                                        </ng-template>

                                        <a class="link-primary" placement="start" (click)="changeAutoClose(e,pEditarOrden)" [ngbPopover]="popContent" [autoClose]="false" #pEditarOrden="ngbPopover">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                            </svg> Agregar
                                        </a>
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="solicitante">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Solicitante </th>
                                <td mat-cell *matCellDef="let e">
                                    {{e.solicitud.cliente.faena?.nombre}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fecha">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha </th>
                                <td mat-cell *matCellDef="let e">

                                    {{e.solicitud.fechaCreacion|date:'dd-MM-yyyy'}}


                                </td>
                            </ng-container>
                            <ng-container matColumnDef="muestras">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Muestras </th>
                                <td mat-cell *matCellDef="let e">
                                    {{e.solicitud.muestras.length}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="estado">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado </th>
                                <td mat-cell *matCellDef="let e">
                                    {{model.estadosDeSolicitud[e.estado]}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="resultados">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                <td mat-cell *matCellDef="let e" class="text-center">

                                    <ng-template #popContentDetail>
                                        <span class="my-2"><a href="#" (click)="descargaSolicitud.descargarOrdenAnalisis(e.solicitud.solicitudAnalisisId);false; descargaSolicitud.mostrarSolicitud();false;"><i class="bi bi-arrow-bar-down"></i>&nbsp; Descargar
                                                solicitud</a></span>
                                        <span *ngIf="e.solicitud.cliente.faena?.transporte?.toUpperCase()==='ENVIOCOURIER'  && e.solicitud.cliente.faena?.transporte?.toUpperCase() !== 'LABORATORIO'">
                                            <p></p>
                                            <span class="py-2"><a href="#" (click)="editarOrden.click();false"><i class="bi bi-pencil-fill"></i>&nbsp; Editar orden de
                                                    transporte</a></span>
                                            <p></p>
                                            <span class="py-2"><a href="#" (click)="CARTACHILEXPRESS.descargarCarta(e.solicitud.solicitudAnalisisId,e.transporte?.numeroOT)"><i class="bi bi-file-text"></i>&nbsp; Carta para
                                                    Chilexpress</a></span>
                                            <p></p>
                                            <span class="py-2"><a href="#" (click)="CARTACORREOS.descargarCarta(e.solicitud.solicitudAnalisisId,e.transporte?.numeroOT)"><i class="bi bi-file-text"></i>&nbsp; Carta para Correos de
                                                    Chile</a></span>
                                        </span>
                                    </ng-template>
                                    <ng-template #popContentEditOrden>
                                        <popover-editar-transporte [transporte]="e.transporte" (resultado)="popoverAgregarTransportistaYOrden(e,$event);pEditarOrden.close()"></popover-editar-transporte>
                                    </ng-template>
                                    <span class="text-dark" placement="start" [ngbPopover]="popContentDetail" [autoClose]="true" #pDetail="ngbPopover" (click)="changeAutoClose(e,pDetail);false">
                                        <i class="bi bi-three-dots-vertical"></i>
                                    </span>
                                    <a href="#" (click)="changeAutoClose(e,pEditarOrden);false" placement="start" [ngbPopover]="popContentEditOrden" [autoClose]="false" #pEditarOrden="ngbPopover" #editarOrden></a>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;">


                            </tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell text-center" colspan="8">
                                    <span *ngIf="model.cargando">Cargando solicitudes<mat-spinner matSuffix [diameter]="18" style="margin-left: 8px;display: inherit;"></mat-spinner></span>
                                    <span *ngIf="!model.cargando">No se han encontrado elementos...</span>
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" (page)="handlePageEvent($event)" [length]="model.pagination.totalElements" [pageSize]="model.pagination.pageSize" [pageIndex]="model.pagination.page" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
                <p class="text-muted" *ngIf="!model.cargando">
                    <a type="button" class="link-secondary" (click)="recargarTabla()" [routerLink]="" data-toggle="tooltip" data-placement="top" title="{{model.fechaCargaSolicitudes| date :'HH:mm:ss'}}">
                        <i class="bi bi-arrow-clockwise"></i>
                        <span *ngIf="model.fechaCargaSolicitudes !==null">
                            Actualizado {{model.fechaCargaSolicitudes | amLocale:'es' | amTimeAgo}}
                        </span>
                    </a>

                </p>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
<div class="row justify-content-center">
    <div class="col-12 col-md-5 text-center">
        <p>Puedes descargar un reporte de resultados de análisis en formato Excel desde la <a type="button" [routerLink]="'/reportes/historico'">Sección de reportes.</a></p>
    </div>
</div>

<muestra-solicitud-de-analisis [esModal]="false" #descargaSolicitud></muestra-solicitud-de-analisis>
<descarga-carta-autorizacion-retiro courier="CHILEEXPRESS" #CARTACHILEXPRESS></descarga-carta-autorizacion-retiro>
<descarga-carta-autorizacion-retiro courier="CORREOSCHILE" #CARTACORREOS></descarga-carta-autorizacion-retiro>
