<form #registroNuevoComponente="ngForm">
<div class="fade show" id="modal-componente-equipo" tabindex="-1" aria-labelledby="modal-componente-equipo-label" style="display: block;" aria-modal="true" role="dialog">
    <div class="modal-content">
        <div class="modal-header">
                <h5 class="modal-title">Agregar componente a equipo</h5>
                <button type="button" class="btn-close"  aria-label="Close" (click)="cancelar()"></button>
            </div>
            <div class="modal-body">
                <div *ngIf="!model.esNuevoComponente">
                    <label for="codigoComponente" class="form-label">Código del componente</label>
                    <mat-spinner *ngIf="model.loadingEquipos" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                    <input type="text" class="form-control form-control-lg mt10" 
                    placeholder="Ingresa el código del componente" 
                    #autoCompleteInput matInput [formControl]="myControl" 
                    [matAutocomplete]="auto" [(ngModel)]="model.componenteFiltro" name="codigoComponente"
                    (keyup.enter)="enterInputSearch()" (focusout)="enterInputSearch()"
                    [readonly]="model.loadingEquipos" maxlength="30" blockSpecialCharacters>
                    <mat-autocomplete  #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChanged($event)">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            <strong>{{ option.componenteId }}</strong> 
                            - {{ option.tipoComponente.nombre }} {{ option.marca }} {{ option.modelo}}
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <div *ngIf="model.componenteInexistente" class="text-danger">
                    El código no existe en el sistema.
                </div>

                <div class="form-text" *ngIf="!model.esNuevoComponente">Si el componente no ha sido ingresado en el sistema, puedes 
                    <a [routerLink]="" class="link-primary" (click)="crearComponente()">crear uno nuevo</a>
                </div>
                <br>

                <div *ngIf="model.tieneComponente">
                    <div class="card" id="datos-componente">
                        <div class="card-body">
                            <img src="../../../../../../assets/images/icono-sin.svg" alt="" id="icono-componente">
                            <h5 class="card-title">{{ model.componente.tipoComponente.nombre }} {{model.componente.componenteId}}</h5>
                            <table id="datos-componente">
                                <tbody><tr>
                                    <td class="align-top">Marca</td>
                                    <td class="align-top"><strong>{{model.componente.marca}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Modelo</td>
                                    <td class="align-top"><strong>{{model.componente.modelo}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Descripción</td>
                                    <td class="align-top"><strong>{{model.componente.descriptor}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Posición</td>
                                    <td class="align-top"><strong>{{model.componente.ubicacion}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Lubricante</td>
                                    <td class="align-top"><strong>{{model.componente.lubricante?.nombre}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Plan de análisis</td>
                                    <td class="align-top"><strong>{{model.componente.planAnalisis?.nombre}}</strong></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div *ngIf="model.componenteEstaAsignado">
                        <div class="alert alert-danger mt-3">
                            <div class="alert-message">
                                <i class="bi bi-exclamation-triangle-fill float-end"></i>
                                <p class="mb-0">Este componente se encuentra actualmente en el equipo 
                                    <strong>{{ model.componente.equipo?.equipoId }} {{ model.componente.equipo?.tipoEquipo?.nombre }} {{ model.componente.equipo?.marca }} {{ model.componente.equipo?.modelo }}</strong> 
                                    ¿Deseas cambiarlo al equipo 
                                    <strong>{{ model.equipo.equipoId }} {{ model.equipo.tipoEquipo.nombre }} {{ model.equipo.marca }} {{ model.equipo.modelo }}</strong>?
                                </p>
                            </div>
                            <br>
                        </div>
                        <input type="checkbox" [checked]="model.confirmoCambioEquipo" 
                            (change)="aceptarCambioEquipo()">
                        <label>&nbsp;Si, quiero cambiar el componente de equipo</label>

                        <div *ngIf="model.alertaCambioEquipo" class="text-danger">
                            Debe aceptar el cambio de equipo.
                        </div>
                    </div>

                    <div *ngIf="model.equipoContieneComponente">
                        <div class="alert alert-danger mt-3">
                            <div class="alert-message">
                                <i class="bi bi-exclamation-triangle-fill float-end"></i>
                                <p class="mb-0">Este componente ya se encuentra actualmente en el equipo 
                                    <strong>{{ model.componente.equipo?.equipoId }} {{ model.componente.equipo?.tipoEquipo?.nombre }} {{ model.componente.equipo?.marca }} {{ model.componente.equipo?.modelo }}</strong> 
                                </p>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>

                <div *ngIf="model.esNuevoComponente">
                        <div class="form-group">
                            <label for="componenteId" class="form-label">Código del componente</label>
                            <mat-spinner *ngIf="model.loadingIdComponente" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                            <input type="text" name="componenteId" id="componenteId" class="form-control" 
                            [(ngModel)]="model.componente.componenteId"
                            #codigoComponente="ngModel"
                            value="{{ model.componente.componenteId }}"
                            [ngClass]="{'is-invalid':codigoComponente.invalid && (codigoComponente.dirty || codigoComponente.touched)}"
                            required
                            (focusout)="validaId()" 
                            [readonly]="model.loadingIdComponente"
                            maxlength="30" idComponenteDirective>
                            <div class="form-text">Código de componente debe ir en mayúsculas</div>
                            <div *ngIf="codigoComponente.invalid && (codigoComponente.dirty || codigoComponente.touched)" class="text-danger"> 
                                <div *ngIf="codigoComponente.errors?.required">
                                    Debe ingresar código del componente.
                                </div>
                            </div>
        
                            <div *ngIf="model.idError" class="text-danger">
                                El código ya existe dentro del sistema.
                            </div>

                            <div *ngIf="model.idComponenteVacio" class="text-danger">
                                Debe ingresar código del componente.
                            </div>
                        
                        </div>
                        <br>
                        <div class="form-group">
                            <label for="tipo" class="form-label">Tipo de componente</label>
                            <mat-spinner *ngIf="model.loadingMarca" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                            <ng-select [(ngModel)]="model.componente.tipoComponente"
                                [disabled]="model.loadingMarca" blockSpecialCharacters
                                [clearable]="false" name="tipo" (change)="model.tieneErrorSetComponente = !model.tieneErrorSetComponente">
                                <ng-option *ngFor="let item of model.tipoComponentes" [value]="item">{{item.nombre}}</ng-option>
                            </ng-select>
        
                            <div *ngIf="model.tieneErrorSetComponente" class="text-danger">
                                {{ model.errorSetComponente }}
                            </div>
                        </div>
                        <br>
                        <div class="form-group">
                            <label for="marca" class="form-label">Marca</label>
                            <mat-spinner *ngIf="model.loadingMarca" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                            <ng-select [(ngModel)]="model.componente.marca" (ngModelChange)="cambiaMarca()"
                                [disabled]="model.loadingMarca"
                                [clearable]="false" name="marca" required>
                                <ng-option *ngFor="let marca of model.marcas" [value]="marca.nombre">{{marca.nombre}}</ng-option>
                                <ng-option value="otro">Otro</ng-option>
                            </ng-select>
                        </div>
                        <br>
                
                        <div *ngIf="model.verOtraMarca">
                            <div class="form-group">
                                <label for="otraMarca" class="form-label">Otra marca</label>
                                <input type="text" id="otraMarca" blockSpecialCharacters class="form-control" value="{{ model.otraMarca }}" required name="otraMarca"
                                #otraMarca="ngModel"
                                [(ngModel)]="model.otraMarca" required maxlength="50">
                                <div *ngIf="otraMarca.invalid && (otraMarca.dirty || otraMarca.touched)" class="text-danger"> 
                                    <div *ngIf="otraMarca.errors?.required">
                                        Debe ingresar marca del componente.
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                
                        <div class="form-group">
                            <label for="modelo" class="form-label">Modelo <em>(opcional)</em></label>
                            <ng-select [(ngModel)]="model.componente.modelo" (ngModelChange)="cambiaModelo()"
                                [disabled]="model.loadingMarca"
                                [clearable]="false" name="modelo">
                                <ng-option *ngFor="let modelo of model.modelos" [value]="modelo">{{modelo}}</ng-option>
                                <ng-option value="otro">Otro</ng-option>
                            </ng-select>
                        </div>
                        <br>
                
                        <div *ngIf="model.verOtroModelo">
                            <div class="form-group">
                                <label for="otroModelo" class="form-label">Otro modelo</label>
                                <input type="text" id="otroModelo" blockSpecialCharacters class="form-control" value="{{ model.otroModelo }}" name="otroModelo"
                                #otroModelo="ngModel"
                                [(ngModel)]="model.otroModelo" required maxlength="50">
                                <div *ngIf="otroModelo.invalid && (otroModelo.dirty || otroModelo.touched)" class="text-danger"> 
                                    <div *ngIf="otroModelo.errors?.required">
                                        Debe ingresar modelo del componente.
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                
                        <div class="form-group">
                            <label for="descriptor" class="form-label">Descripción <em>(opcional)</em></label>
                            <input type="text" id="descriptor" class="form-control" value="" name="descriptor"
                                value="" maxlength="50" blockSpecialCharacters>
                        </div>
                        <br>
                
                        <div class="form-group">
                            <label for="poscicion" class="form-label">Posición <em>(opcional)</em></label>
                            <input type="text" id="poscicion" class="form-control" value="" name="posicion"
                                value="{{ model.componente.ubicacion }}" [(ngModel)]="model.componente.ubicacion"
                                maxlength="50" blockSpecialCharacters>
                        </div>
                        <br>
                
                        <div class="form-group">
                            <label for="lubricante" class="form-label">Lubricante</label>
                            <mat-spinner *ngIf="model.loadingLubricante" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                            <ng-select [(ngModel)]="model.componente.lubricante" blockSpecialCharacters
                                #selectLubricante="ngModel" [disabled]="model.loadingLubricante" [clearable]="false"
                                name="lubricante"
                                [ngClass]="{'is-invalid':selectLubricante.invalid && (selectLubricante.dirty || selectLubricante.touched)}"
                                (change)="model.tieneErrorSetComponente = !model.tieneErrorSetComponente" required>                                
                                <ng-option *ngFor="let item of model.lubricantes" [value]="item">{{item.nombre}}</ng-option>
                                <div *ngIf="selectLubricante.invalid && (selectLubricante.dirty || selectLubricante.touched)"
                                    class="text-danger">
                                    <div *ngIf="selectLubricante.errors?.required">
                                        Debe seleccionar lubricante.
                                    </div>
                                </div>
                            </ng-select>
                        </div>
                        <br>
                        <a [routerLink]="" (click)="model.esNuevoComponente = false">Volver a buscar</a>
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="cancelar()">Cancelar</button>
                    <button type="button" class="btn btn-primary" 
                        [disabled]="registroNuevoComponente.invalid || model.idError || model.equipoContieneComponente" 
                        data-bs-dismiss="modal" (click)="asignarEquipo()">
                            Agregar al equipo
                        </button>
                </div>
            </div>
        </div>
</form>
