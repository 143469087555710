import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authenticationService: AuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentUser = this.authenticationService.currentUserValue;

        if (currentUser) {
            if (route.data.roles) {
                let isValid = false;
                if (currentUser.roles) {
                    currentUser.roles.forEach((s) => {
                        if (route.data.roles.indexOf(s) !== -1) {
                            isValid = true;
                        }
                    });
                }

                if (!isValid) {
                    this.router.navigate(['/home']);
                    return false;
                }
            }
            return true;
        }

        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
@Injectable({ providedIn: 'root' })
export class ClientGuard implements CanActivate {
    constructor(private router: Router, private authenticationService: AuthenticationService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentClient = this.authenticationService.currentClientValue;
        if (currentClient.clienteId == '') {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return true;
    }
}

@Injectable({ providedIn: 'root' })
export class proformaGuard implements CanActivate {
    constructor(private router: Router, private authenticationService: AuthenticationService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let proforma = this.authenticationService.currentUserValue.proforma_enabled;
        !proforma && this.router.navigate(['/condiciones-comerciales']);
        return proforma;
    }
}
