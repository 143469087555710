import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSolicitudDeInsumosComponent } from '../modal-solicitud-view/modal-solicitud-component';
import { MatTableDataSource } from '@angular/material/table';
import { SolicitudDeInsumosModels } from '../../solicitud-de-insumos-models/solicitud-de-insumos.models';
import { SolicitudDeInsumosViewModels } from '../../solicitud-de-insumos-models/solicitud-de-insumos.view.models';
import { CondicionesComercialesService } from '../../solicitud-de-insumos-services/solicitud-de-insumos.service';
import { AuthenticationService } from '../../../shared/auth/authentication.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { MatSort } from '@angular/material/sort';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { ToastService } from '../../../shared/components/ng-toast/toast-service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'solicitud-de-insumos',
    templateUrl: './solicitud-de-insumos.component.html',
    styleUrls: ['./solicitud-de-insumos.component.css']
})
export class SolicitudDeInsumosComponent implements OnInit, AfterViewInit {

    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    displayedColumns: string[] = ['solicitudInsumoId', 'fecha', 'solicitadoPor', 'totalInsumos'];
    dataSource = new MatTableDataSource<SolicitudDeInsumosModels.Solicitud>([]);
    subscriberGetInsumos: Subscription | undefined;
    model: SolicitudDeInsumosViewModels.ViewModel = {
        pagination: { page: 0, pageSize: 10, totalElements: 0 },
        cargando: false,
        solicitudes: [],
        fechaCargaSolicitudes: new Date()
    };

    constructor(private authService: AuthenticationService, private modalService: NgbModal,
        private service: CondicionesComercialesService,
        private spinner: SpinnerService,
        public toastService: ToastService
    ) {

    }

    ngOnInit(): void {
        this.getInsumos();
    }
    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

    }

    solicitarInsumo() {
        const modalRef = this.modalService.open(ModalSolicitudDeInsumosComponent, {
            centered: true
        });
        modalRef.result.then(r => {
            if (r) {
                let solicitud = <SolicitudDeInsumosModels.Solicitud>r;
                let req: SolicitudDeInsumosModels.SetInsumoRequest = {
                    clienteId: this.authService.currentClientValue.clienteId,
                    faenaId: this.authService.currentClientValue.faena.faenaId,
                    solicitadoPor: this.authService.current_user,
                    bombaExtraccion: solicitud.bombaExtraccion,
                    kitTomaMuestra12: solicitud.kitTomaMuestra12,
                    kitTomaMuestra48: solicitud.kitTomaMuestra48,
                    kitTomaMuestra12SP: solicitud.kitTomaMuestra12SP,
                    kitTomaMuestra48SP: solicitud.kitTomaMuestra48SP,
                    kitTomaMuestraTurbina: solicitud.kitTomaMuestraTurbina
                }
                this.service.setInsumos(req).then(r => {

                    if (r.success) {
                        this.toastService.show(`La solicitud fue ingresada con id ${r.data.solicitudInsumoId}`, { classname: 'bg-primary text-light', delay: 2000 });
                    }

                }).finally(() => {
                    this.model.solicitudes = [];
                    this.LoadPaginate();
                    this.getInsumos(true);
                });
            }
        });
    }
    getInsumos(force: boolean = false) {
        let req: SolicitudDeInsumosModels.GetInsumosRequest = {
            clienteId: this.authService.currentClientValue.clienteId
        }
        this.spinner.off();
        this.model.cargando = true;
        this.subscriberGetInsumos?.unsubscribe();
        this.subscriberGetInsumos = this.service.getInsumos(req, force).subscribe(r => {
            if (r.success) {
                this.model.solicitudes = r.data;
                this.model.solicitudes.forEach(s => {
                    s.fecha = this.corrigeFecha(s.fecha)
                    s.totalInsumos = this.sumarInsumos(s);
                })
                this.model.fechaCargaSolicitudes = new Date();
                this.model.cargando = false;
            } else {
                this.model.solicitudes = [];
            }
            this.LoadPaginate();
        });
    }

    LoadPaginate(): void {
        const records = this.model.solicitudes;
        this.dataSource = new MatTableDataSource<SolicitudDeInsumosModels.Solicitud>(records);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

    }

    handlePageEvent(event: PageEvent): void {
        this.model.pagination.totalElements = event.length;
        this.model.pagination.pageSize = event.pageSize;
        this.model.pagination.page = event.pageIndex;
        this.LoadPaginate();
    }




    corrigeFecha(fecha: string) {
        let d = fecha.split(" ")[0].split("-");
        return d.join("/")
    }

    date(fecha: string) {
        let d = fecha.split(" ")[0].split("-")
        return new Date(d[2] + "-" + d[1] + "-" + d[0] + 'T' + fecha.split(" ")[1]);
    }

    sumarInsumos(sol: SolicitudDeInsumosModels.Solicitud): number {
        return parseInt(sol.kitTomaMuestra12) + parseInt(sol.kitTomaMuestra48) + parseInt(sol.kitTomaMuestra12SP ?? "0") + parseInt(sol.kitTomaMuestra48SP ?? "0") + parseInt(sol.kitTomaMuestraTurbina) + parseInt(sol.bombaExtraccion);
    }
}
