import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { ModalReportesComponent } from '../modal-reportes/modal-reportes.component';
import { ReportesModels } from '../models/reportes.models';
import { ReportesService } from '../services/reportes.service';
import { AnimationOptions } from 'ngx-lottie';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportesViewModels } from '../models/reportes.view.models';
import { Objects } from 'src/app/shared';

@Component({
    selector: 'app-reporte-historico',
    templateUrl: './reporte-historico.component.html',
    styleUrls: ['./reporte-historico.component.css']
})
export class ReporteHistoricoComponent implements OnInit {
    listAuxComponentes: ReportesModels.Componente[] = [];
    generation: boolean = false;
    timerId: any;
    printed: boolean = false;
    printedLoading: boolean = true;
    options: AnimationOptions = {
        path: '/assets/images/cloud-print.json',
    };
    printOptions: AnimationOptions = {
        path: '/assets/images/cloud-print.json',
    };
    printCheckOptions: AnimationOptions = {
        path: '/assets/images/black-check.json',
    };
    subscribers: { name: string, sub: Subscription, active?: boolean }[] = [];
    todaysdate = new Date();
    stateOptions: any[] = [
        { value: 'NORMAL', name: 'Normal' },
        { value: 'ALERTA', name: 'Alerta' },
        { value: 'PRECAUCION', name: 'Precaucion' }
    ]
    components: ReportesModels.Componente[] = [];
    nroFrasco: string = ""
    selectedCid: any | undefined;
    tipoFecha: any = 1;
    state: string = "";
    selectedCliente: ReportesModels.Usuario | any;
    clientes: ReportesModels.Usuario[] = []
    optionsCliente: ReportesModels.Usuario[] = []
    loadingFaenas = false;
    selectedFaena?: Objects.Faena[] = [];
    optionsFaena: Objects.Faena[] = [];
    fechaUna: any = "";
    faenaName = "";
    range = new FormGroup({
        start: new FormControl(""),
        end: new FormControl("")
    });

    informe: ReportesModels.historicoResponseUrl = {
        trx_complete: false,
        file_name: "",
        url: ""
    }

    constructor(
        public dialog: MatDialog,
        private authService: AuthenticationService,
        private service: ReportesService,
        private spinner: SpinnerService,
        private _snackBar: MatSnackBar,


    ) { }

    ngOnInit(): void {

        this.loadFaenas();

    }

    loadFaenas() {
        this.optionsFaena = this.authService.currentUserValue.clientes.filter(
            (cf) => cf.clienteId === this.authService.currentClientValue.clienteId
        )[0].faenas;
        this.selectAllChores(true);
        this.load_components();

        this.spinner.off();
    }




    calculo() {
        var fechaInicio = new Date(this.range.get('start')?.value).getTime();
        var fechaFin = new Date(this.range.get('end')?.value).getTime();
        var diff = fechaFin - fechaInicio;
        diff = diff / (1000 * 60 * 60 * 24)
        if (diff > 366) {
            return false
        }

        return true
    }
    validarFechas() {
        var fechaInicio = new Date(this.range.get('start')?.value).getTime();
        var fechaFin = new Date(this.range.get('end')?.value).getTime();
        if (!fechaFin || !fechaInicio) {
            return false
        }
        var diff = fechaFin - fechaInicio;
        diff = diff / (1000 * 60 * 60 * 24)
        if (diff < 0) {
            return false
        }
        return true
    }

    validarFechasAmbos() {
        if (this.tipoFecha == 1) {
            if (!this.calculo() || !this.validarFechas()) {
                return false
            }
        }
        if (this.tipoFecha > 1) {
            if (!this.fechaUna) {
                return false
            }
        }

        return true
    }


    filtradoComponente() {
        this.selectedCid = undefined
        let componentesFiltrados: ReportesModels.Componente[] = [];

        this.selectedFaena?.forEach((faena) => {
            const coincidencias = this.listAuxComponentes.filter((c) => c.faenaId === faena.faenaId);
            componentesFiltrados = [...componentesFiltrados, ...coincidencias];
        });

        this.components = componentesFiltrados;
    }

    load_components(): void {
        this.optionsFaena
            ?.reduce((promise, f) => {
                return promise.then(() => {
                    const req: ReportesModels.GetComponentesRequest = {
                        clienteId: this.authService.currentClientValue.clienteId,
                        faenaId: f.faenaId,
                    };
                    return this.service.GetComponentesP(req).then((resp) => {
                        if (resp.code == 200 && resp.data) {
                            resp.data.forEach((d) => {
                                this.listAuxComponentes.push(d);
                            });
                        }
                    });
                });
            }, Promise.resolve())
            .finally(() => {
                this.filtradoComponente();
            });


    }





    getReport() {
        var mesI = new Date(this.range.get('start')?.value).toLocaleDateString('en-us', { month: "2-digit" })
        var diaI = new Date(this.range.get('start')?.value).toLocaleDateString('en-us', { day: "2-digit" })
        var yearI = new Date(this.range.get('start')?.value).toLocaleDateString('en-us', { year: "numeric" })
        let fechaI = yearI + "-" + mesI + "-" + diaI
        var mesF = new Date(this.range.get('end')?.value).toLocaleDateString('en-us', { month: "2-digit" })
        var diaF = new Date(this.range.get('end')?.value).toLocaleDateString('en-us', { day: "2-digit" })
        var yearF = new Date(this.range.get('end')?.value).toLocaleDateString('en-us', { year: "numeric" })
        let fechaF = yearF + "-" + mesF + "-" + diaF
        var mes = new Date(this.fechaUna).toLocaleDateString('en-us', { month: "2-digit" })
        var dia = new Date(this.fechaUna).toLocaleDateString('en-us', { day: "2-digit" })
        var year = new Date(this.fechaUna).toLocaleDateString('en-us', { year: "numeric" })
        let fecha = year + "-" + mes + "-" + dia
        const request: ReportesModels.reportHistoricoRequest = {
            id_cliente: this.authService.currentClientValue.clienteId,
            fecha_f: this.tipoFecha == 1 ? fechaF : "",
            fecha_i: this.tipoFecha == 1 ? fechaI : "",
            componente: this.selectedCid ? this.selectedCid : "",
            estado: this.state,
            fecha_informe: this.tipoFecha == 3 ? "" + fecha : "",
            fecha_ingreso: "",
            fecha_solicitud: this.tipoFecha == 2 ? "" + fecha : "",
            id_faena: this.selectedFaena?.map(f => f.numeroSap).join(","),
            nmuestra: this.nroFrasco,
            is_wc: true
        }
        this.service.SetReportHitorico(request).then(response => {

            if (response.success) {
                this.printed = true

                this.timerId = setInterval(() => {
                    this.revisarUrl(response.data.clienteId, response.data.trx_report);
                    this.validaResult();
                }, 10000);

                setTimeout(() => {
                    clearInterval(this.timerId);
                    this._snackBar.open(`Hubo un error al generar el informe, realizar nuevamente.`, '', {
                        duration: 5000,
                        panelClass: [`success-snackBar`],
                        verticalPosition: 'bottom',
                        horizontalPosition: 'right'
                    });
                }, 600000)
            }
        }
        )
    }



    private destroySubs(name?: string) {
        this.subscribers.filter(s => !name || s.name === name).forEach(s => {
            s.sub.unsubscribe();
            s.active = false;
        })
    }

    private addSubscribe(name: string, sub: Subscription) {
        this.subscribers.push({ name: name, sub: sub });
    }


    private revisarUrl(cliente: any, trx: any) {
        let rq: ReportesModels.HistoricoUrlRequest = {
            cliente_id: cliente,
            trx: trx,
        }
        this.service.GetReportHitorico(rq).then(rsp => {
            if (rsp.success) {

                this.informe = rsp.data;
            }
        })



    }

    validaResult() {
        console.log("validando...");
        console.log("this.informe", this.informe);
        if (this.informe.trx_complete && this.informe.url) {
            clearInterval(this.timerId);
            this._snackBar.open(`Reporte generado.`, '', {
                duration: 5000,
                panelClass: [`success-snackBar`],
                verticalPosition: 'bottom',
                horizontalPosition: 'right'
            });
            this.printedLoading = false;
        } else if (this.informe.trx_complete && this.informe.is_empty) {
            this.printed = false;
            this.printedLoading = true;

            clearInterval(this.timerId);
            this.printed = false
            this._snackBar.open(`No se han encontrado coincidencias al generar el informe, intente nuevamente.`, '', {
                duration: 5000,
                panelClass: [`success-snackBar`],
                verticalPosition: 'bottom',
                horizontalPosition: 'right'
            });
            this.informe = {
                trx_complete: false,
                file_name: "",
                url: "",
            }

        } else if (this.informe.trx_complete && this.informe.url == "") {
            this.printed = false;
            this.printedLoading = true;

            clearInterval(this.timerId);
            this.printed = false
            this._snackBar.open(`Hubo un error al generar el informe, realizar nuevamente.`, '', {
                duration: 5000,
                panelClass: [`success-snackBar`],
                verticalPosition: 'bottom',
                horizontalPosition: 'right'
            });
            this.informe = {
                trx_complete: false,
                file_name: "",
                url: "",
            }
        }
    }

    clear() {
        this.informe = {
            trx_complete: false,
            file_name: "",
            url: "",
        }
        this.selectedFaena = []
        this.selectedCid = undefined
        this.nroFrasco = ""
        this.fechaUna = ""
        this.selectAllChores(true);

    }

    download_file() {
        window.open(this.informe.url, "_blank");
        this._snackBar.open(`Descarga reporte exitosa.`, '', {
            duration: 5000,
            panelClass: [`success-snackBar`],
            verticalPosition: 'bottom',
            horizontalPosition: 'right'
        });

        this.clear();
        this.generation = false;
        this.printed = false;
        this.printedLoading = true;
    }

    validaAdmin(): boolean {
        if (this.authService.currentUserValue.roles) {
            let roles = this.authService.currentUserValue.roles!;

            if (roles.includes("Admin")) {
                return true
            }
        }
        return false;
    }
    public selectAllChores(checked: boolean): void {
        this.selectedFaena = checked ? this.optionsFaena : [];
        this.filtradoComponente();

    }
}
