import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ChartType, Row } from 'angular-google-charts';
import { ReportsModels } from '../../../models/reports.models';

@Component({
  selector: 'pqFeCu-chart',
  templateUrl: './pqFeCu.component.html',
  styleUrls: ['./pqFeCu.component.css']
})
export class PqFeCuChartComponent implements OnInit {
  chart:any;
  imgSrc:string='';
  isLoading:boolean=true;

   @Input() isPrinting:boolean=false;
   @Input() data:Row[]=[
    ['1', 15.0, 14.40, 13.06],
    ['2', 14.4,  14.00, 13.06],
    ['3', 14.5, 13.80, 13.06],
    ['4', 14.6, 14.10, 13.06],
    ['5', 15.5, 15.20, 13.06]
   ];
   @Output() event = new EventEmitter<boolean>();

  configChart:ReportsModels.GoogleChart={
   title : 'PQ VS HIERRO Y COBRE',
    type:ChartType.ComboChart,
    data:this.data,
     columnNames :['PQ', 'Hierro','Cobre'],
     options : {

          pointSize: 5,
          bar: {groupWidth: '95%',groupHeight:'80%'},
          hAxis: {
            gridlines: {
              count: 5
            }
          },
          vAxis:{
            gridlines: {
              count: 7
            }
          },
          seriesType: 'bars',
          series: {
            0:{
              labelInLegend:'PQ'
            },
            1: {type: 'line',
                labelInLegend:'Hierro'
            },
            2: {type: 'line',
                annotations: {
                  textStyle: {fontSize: 12, color: 'red' }
                },
                labelInLegend:'Cobre'
            }
          },
          legend: { position: 'bottom',textStyle: {fontSize: 5}},
          chartArea: {
            left: '20%', width: '70%', height: '50%',right :'10%'
          },

        },
        width : 150,
        height : 150,

  };
  constructor() { }

  ngOnInit(): void {
  }
  onReadypqFeCuChart(event:any){
    this.isLoading=false;
    this.chart= event.chart;

    this.imgSrc=event.chart.getImageURI();
    let bars = document.querySelector("pqfecu-chart > google-chart > div > div:nth-child(1) > div > svg > g:nth-child(5) > g:nth-child(2) > g:nth-child(2");
    if(bars){

      for (var i = 0; i < bars.children.length; i++) {

       let bar = bars.children[i];
       let h= bar.getAttribute('height');
       let hNumber=h?Number(h)-700:0;
       if(hNumber>0){
         bar.setAttribute('height',String(hNumber))
       }

      }
    }
  }

  onReadyImage(){
    this.event.emit(true);
  }
  ngDoCheck(){
    this.configChart.data=this.data;
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['isPrinting'] && this.chart){
      this.chart.setSelection([])
    }
  }

}
