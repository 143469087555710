import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EquipoVerComponent } from "src/app/mantenedores/mant-equipos/mant-equipos-views/equipo-ver/equipo-ver-views/equipo-ver.component";
import { Objects } from "src/app/shared";
import { AuthenticationService } from "src/app/shared/auth/authentication.service";
import { EventEmitterService } from "src/app/shared/services/event-emitter.service";
import { LogService } from "src/app/shared/services/log.service";
import { ModalConfirmService } from "src/app/shared/services/modalConfirm.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { ComponenteNuevoComponent } from "../../componente-nuevo/componente-nuevo-views/componente-nuevo.component";
import { ComponenteVerModels } from "../componente-ver-models/componente-ver.models";
import { ComponenteVerViewsModels } from "../componente-ver-models/componente-ver.view.models";
import { ComponenteVerService } from "../componente-ver-services/componente-ver.service";

@Component({
    selector: 'app-componente-ver',
    templateUrl: './componente-ver.component.html',
    styleUrls: ['./componente-ver.component.css']
})
export class ComponenteVerComponent implements OnInit {

    enumStatus: { [key: string]: string } = {
        analisis: 'En análisis',
        normal: 'Normal',
        precaucion: 'Precaución',
        alerta: 'En alerta'
    };

    model: ComponenteVerViewsModels.ViewModel = {
        pagination: { page: 0, pageSize: 10, totalElements: 0 },
        componente: {
            equipo: {
                equipoId: '',
                tipoEquipo: {
                    tipoEquipoId: '',
                    nombre: ''
                }
            },
            marca: '',
            ubicacion: '',
            clienteId: '',
            faenaId: '',
            tipoComponente: {
                tipoComponenteId: '',
                nombre: ''
            },
            componenteId: '',
            modelo: '',
            lubricante: {
                lubricanteId: '0',
                nombre: '',
                code: '',
                tipo: undefined
            },
            planAnalisis: {
                nombre: '',
                planAnalisisId: 0
            },
            descriptor: '',
            removable: false,
            ultimaMuestra: {
                muestraId: '',
                fechaMuestreo: '',
                estado: ''
            }
        },
        equipo: {
            clienteId: '',
            faenaId: '',
            equipoId: '',
            tipoEquipo: {
                tipoEquipoId: '',
                nombre: ''
            },
            marca: '',
            modelo: '',
            descriptor: '',
            alias: [],
            tags: []
        },
        alertaSinResultado: false,
        estaEditando: false,
        equipoCargado: false,
        loading: false,
    }


    @Input() componenteInput: ComponenteVerModels.ComponenteInput = {
        componente: {
            equipo: {
                equipoId: '',
                tipoEquipo: {
                    tipoEquipoId: '',
                    nombre: ''
                }
            },
            marca: '',
            ubicacion: '',
            clienteId: '',
            faenaId: '',
            tipoComponente: {
                tipoComponenteId: '',
                nombre: ''
            },
            componenteId: '',
            modelo: '',
            descriptor: '',
            removable: false,
            ultimaMuestra: {
                muestraId: '',
                fechaMuestreo: '',
                estado: ''
            }
        },
        tieneEquipoCargado: false,
        vuelveNuevoComponente: false,
        vieneDesdeEquipo: false,
        vieneDesdeVerComponente: false,
        sinEquipo: false
    }

    constructor(
        public dialogRef: NgbActiveModal,
        private authService: AuthenticationService,
        private service: ComponenteVerService,
        private spinner: SpinnerService,
        private modalService: NgbModal,
        private router: Router,
        private eventEmiiterSrv: EventEmitterService,
        private modalConfirmService: ModalConfirmService,
        private _logService: LogService,
    ) {
    }

    ngOnInit() {
        const componenteId = Object.assign(this.componenteInput.componente.componenteId, {});
        this.model.componente = Object.assign(this.componenteInput.componente, {});
        if (componenteId) {
            this.getComponenteInfo(componenteId);
        }
    }

    getComponenteInfo(componenteId: string): void {
        this.model.loading = true;
        if (this.componenteInput.vieneDesdeEquipo) {
            this.spinner.on();
        } else {
            this.spinner.off(true);
        }
        if (this.componenteInput.equipo) {
            this.model.equipo = this.componenteInput.equipo!;
        }
        const request: ComponenteVerModels.GetComponenteVerRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            componenteId: componenteId,
            faenaId: this.authService.currentClientValue.faena.faenaId
        };
        this.service.GetComponenteInfo(request)
            .then(response => {
                if (response.success) {
                    this.model.componente = response.data;
                    const equipoId = this.model.componente.equipo.equipoId;
                    if (equipoId) {
                        this.getEquipoInfo(equipoId);
                    } else {
                        this.model.equipoCargado = true;
                        this.model.loading = false;
                        this.spinner.off();
                    }
                }
            })
    }

    getEquipoInfo(equipoId: string): void {
        const requestEquipo: ComponenteVerModels.GetEquipoComponenteVerRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId,
            equipoId: equipoId
        }
        this.service.GetEquipoComponente(requestEquipo)
            .then(respEquipo => {
                if (respEquipo.success) {
                    this.model.equipo = respEquipo.data[0];
                }
            })            
            .finally(() => {
                this.model.equipoCargado = true;
                this.model.loading = false;
                this.spinner.off();
            })
    }

    cancelar(): void {
        if (this.componenteInput.vieneDesdeEquipo) {
            this.dialogRef.close({ result: 'cancelar' });

            const modalRef = this.modalService.open(EquipoVerComponent, {
                centered: true,
                size: 'xl'
            });

            modalRef.componentInstance.equipoInput.equipo = Object.assign({}, this.model.equipo);
        }
        if (this.componenteInput.estaEditado) {
            this.eventEmiiterSrv.sendResult("FLOW_MANTENEDOR", { accion: 'EDITAR', id: this.model.componente.componenteId })
        }
        this.dialogRef.close({ result: 'cancelar' });
    }

    editar(): void {
        this.componenteInput.componente = this.model.componente;
        this.componenteInput.equipo = this.model.equipo;
        this.componenteInput.equipoAntiguo = this.model.equipo;
        this.componenteInput.tieneEquipoCargado = true;
        this.componenteInput.vieneDesdeVerComponente = true;
        this.componenteInput.estaEditado = true;
        this.dialogRef.close();
        const modalRef = this.modalService.open(ComponenteNuevoComponent, {
            centered: true,
            size: 'lg'
        });

        modalRef.componentInstance.compDesdeAsignarEquipo = Object.assign({}, this.componenteInput);
    }

    agregarMuestra(): void {
        this.dialogRef.close();
        let data = JSON.stringify(this.model.componente);
        this.router.navigate(['/agregar-muestra'], { queryParams: { data: data }, skipLocationChange: true });
    }

    eliminarComponente(): void {
        if (this.model.componente.removable) {
            const modalRef = this.modalConfirmService.openStatic({ title: 'Eliminar componente', msg: `¿Desea eliminar el componente ${this.model.componente.componenteId}?`, buttonClassname: 'btn-danger', titleButtonOK: 'Si, eliminar' });
            modalRef.result.then(isOK => {
                if (isOK) {
                    this.confirmarEliminar();
                }
            });
        } else {
            this.modalConfirmService.openStatic(
                {
                    title: 'Eliminar componente',
                    msg: `El componente ${this.model.componente.componenteId},
                        no se puede eliminar porque ya tiene muestras ingresadas`, onlyClose: true
                }
            );
        }
    }

    private confirmarEliminar(): void {
        this.spinner.on();
        const request: ComponenteVerModels.DeleteComponenteRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId,
            componenteId: this.model.componente.componenteId
        };
        this.service.DeleteComponente(request).subscribe(data => {
            if (data.success && data.data.status === "200") {

                let rq: Objects.LogInput = {
                    accion: 'Delete',
                    elemento: this.model.componente.componenteId,
                    tipoElemento: 'Componente',
                    usuario: this.authService.usuario.nombre,
                    usuarioId: this.authService.usuario.userId,
                    ambiente: 'Web Cliente'
                }
                this._logService.createLog(rq, true);

                this.eventEmiiterSrv.sendResult("FLOW_MANTENEDOR", { accion: 'ELIMINAR', id: this.model.componente.componenteId });
                this.spinner.off();
                this.dialogRef.close();
            } else if (data.success && data.data.status === "405") {
                this.modalConfirmService.openStatic(
                    {
                        title: 'Eliminar componente',
                        msg: `El componente ${this.model.componente.componenteId},
                            no se puede eliminar porque ya tiene muestras ingresadas`, onlyClose: true
                    }
                );
            }
        });
    }
}
