import { HttpContextToken } from "@angular/common/http";

export namespace Enums {

    export const SPINNER_ENABLED = new HttpContextToken<boolean>(() => true);

    export const Muestra={
        estado:{
            pending_approval:'pending_approval',
            reported:'reported'
        }
    }
}
