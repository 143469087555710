import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { SampleBoxDataProvider } from '../../../shared/services/sampleBoxDataProvider.service';
import {  ComprobanteCajaMuestrasViewModels } from '../../caja-muestras-models/caja-muestras.view.models';
import { AuthenticationService } from '../../../shared/auth/authentication.service';


@Component({
    selector: 'comprobante',
    templateUrl: './caja-comprobante.component.html',
    styleUrls: ['./caja-comprobante.component.css']
})
export class ComprobanteCajaMuestraComponent {

    model: ComprobanteCajaMuestrasViewModels.ViewModel = {
            data: {
             numberOS:'0',
             muestras:[]
            },
            transporte:''
        };
    constructor(
        public appData:SampleBoxDataProvider,
        private route: ActivatedRoute,
        private authService: AuthenticationService

    ) {
        let comprobanteCajaMuestra = this.route.snapshot.queryParams.comprobanteCajaMuestra;
        this.model.data= JSON.parse(comprobanteCajaMuestra);
        this.model.transporte= authService.currentClientValue.faena["transporte"] ||  "SINTRANSPORTE";
    }    
}
