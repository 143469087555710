import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProformaRoutingModule } from './proforma-routing.module';
import { DetailProformaComponent } from './views/detail-proforma/detail-proforma.component';
import { ReplacePipe } from './replace.pipe';


@NgModule({
  declarations: [
    DetailProformaComponent,
    ReplacePipe
  ],
  imports: [
    CommonModule,
    ProformaRoutingModule
  ],
  exports:[DetailProformaComponent]
})
export class ProformaModule { }
