import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-edit-sample',
  templateUrl: './modal-edit-sample.component.html',
  styleUrls: ['./modal-edit-sample.component.css']
})
export class ModalEditSampleComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    public dialogRef: MatDialogRef<ModalEditSampleComponent>,
    @ Inject(MAT_DIALOG_DATA) public data:{muestraId:string,cliente:string,faena:string}
  ) { }

  ngOnInit(): void {
  }

  confirmUpdateSample(){

    
    this.dialogRef.close('si');
  }

}
