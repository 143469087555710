<div class="modal-content" *ngIf="model">
  <div class="modal-header">
    <h5 class="modal-title">Solicitar insumos</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cancelar()"></button>
  </div>
  <div class="modal-body">
    <form action="">
      <div class="row mb-3">
        <label class="col-sm-9 col-form-label">Kit de toma de muestra 12 unidades. Con polytube</label>
        <div class="col-sm-3">
          <input type="number" class="form-control" #kitTomaMuestra12 name="kitTomaMuestra12" [(ngModel)]="model.kitTomaMuestra12" min="0" max="99" maxlength="2" numeric (keypress)="keyMaxLengthCheck($event,kitTomaMuestra12)" />
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-sm-9 col-form-label">Kit de toma de muestra 48 unidades. Con polytube</label>
        <div class="col-sm-3">
          <input type="number" class="form-control" #kitTomaMuestra48 name="kitTomaMuestra48" [(ngModel)]="model.kitTomaMuestra48" min="0" max="99" maxlength="2" numeric (keypress)="keyMaxLengthCheck($event,kitTomaMuestra48)" />
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-sm-9 col-form-label">Kit de toma de muestra 12 unidades. Sin polytube</label>
        <div class="col-sm-3">
          <input type="number" class="form-control" #kitTomaMuestra12SP name="kitTomaMuestra12SP" [(ngModel)]="model.kitTomaMuestra12SP" min="0" max="99" maxlength="2" numeric (keypress)="keyMaxLengthCheck($event,kitTomaMuestra12SP)" />
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-sm-9 col-form-label">Kit de toma de muestra 48 unidades. Sin polytube</label>
        <div class="col-sm-3">
          <input type="number" class="form-control" #kitTomaMuestra48SP name="kitTomaMuestra48SP" [(ngModel)]="model.kitTomaMuestra48SP" min="0" max="99" maxlength="2" numeric (keypress)="keyMaxLengthCheck($event,kitTomaMuestra48SP)" />
        </div>
      </div>

      <div class="row mb-3">
        <label class="col-sm-9 col-form-label">Bomba de extración (vampiro)</label>
        <div class="col-sm-3">
          <input type="number" class="form-control" #bombaExtraccion name="bombaExtraccion" [(ngModel)]="model.bombaExtraccion" min="0" max="99" maxlength="2" numeric (keypress)="keyMaxLengthCheck($event,bombaExtraccion)" />
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-sm-9 col-form-label">Kit de toma de muestras Turbina</label>
        <div class="col-sm-3">
          <input type="number" class="form-control" #kitTomaMuestraTurbina name="kitTomaMuestraTurbina" [(ngModel)]="model.kitTomaMuestraTurbina" min="0" max="99" maxlength="2" numeric (keypress)="keyMaxLengthCheck($event,kitTomaMuestraTurbina)" />
        </div>
      </div>
    </form>

  </div>
  <div class="modal-footer">
    <div>
      <button type="button" class="btn btn-link" data-bs-dismiss="modal" (click)="cancelar()">Cancelar</button>

    </div>
    <button type="button" class="btn btn-success" [disabled]="isInvalid()" (click)="solicitar()">
      Solicitar</button>
  </div>
</div>