<div class="modal__contanier" [class]="{'bloqueado': is_request_call }" >
  <mat-spinner  matSuffix [diameter]="18" class="center" *ngIf="is_request_call" ></mat-spinner>
  <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Documento de venta</h2>
      <a mat-dialog-close href="#" [routerLink]="" (click)="close()">
          <em class="bi bi-x-lg"></em>
      </a>
  </div>
  <div class="modal__content">
      <div class="d-flex justify-content-between gap-4 flex-wrap mb-4">
          <dl class="description flex-fill">
              <dt class="description__term txt--xs c-gray">CLIENTE</dt>
              <dd class="description__definition">{{data.document.cliente.nombre}}<br><small>Nº
                      {{data.document.cliente.numeroSap}}</small></dd>
          </dl>
          <dl class="description flex-fill">
              <dt class="description__term txt--xs c-gray">FAENA</dt>
              <dd class="description__definition">{{data.document.cliente.faena.nombre}}<br><small>Nº
                      {{data.document.cliente.faena.numeroSap}}</small></dd>
          </dl>
          <dl class="description flex-fill">
              <dt class="description__term txt--xs c-gray">FECHA</dt>
              <dd class="description__definition">{{data.document.fecha|date:'dd/MM/yyyy' }}<br><small>por
                      {{data.document.usuario.nombre}}</small></dd>
          </dl>
          <dl class="description flex-fill">
              <dt class="description__term txt--xs c-gray">PERIODO</dt>
              <dd class="description__definition">Desde {{data.document.desde_periodo}}<br>Hasta
                  {{data.document.hasta_periodo}}</dd>
          </dl>
          <dl class="description flex-fill">
              <dt class="description__term txt--xs c-gray text-lg-end">Nº DOCUMENTO DE VENTA</dt>
              <dd class="description__definition txt--lg text-lg-end mt-md-2">{{data.document.numero}}</dd>
          </dl>
      </div>

      <div class="bg-gray-light p-3 rounded mb-3" *ngIf="data.document.pedido_cost.planes_analisis.length>0">


          <div class="table-responsive p-1">
              <table class="table w-100">
                  <thead>
                      <tr>
                          <th colspan="3">&nbsp;</th>
                          <th colspan="3">Precios unitarios</th>
                          <th>&nbsp;</th>
                      </tr>
                      <tr class="bg-gray">
                          <th>Plan de análisis</th>
                          <th>Nº del plan</th>
                          <th>Muestras</th>
                          <th>Precio lista</th>
                          <th>Descuento</th>
                          <th></th>
                          <th>Total a cobrar</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let plan of data.document.pedido_cost.planes_analisis">
                          <td>{{plan.nombre}}</td>
                          <td>{{plan.code}}</td>
                          <td>{{plan.muestras.length}}</td>
                          <td>
                              <div *ngIf="plan.currency && plan.currency !== ''">
                                  <span [ngbTooltip]="tipContent_pl" class="tipContent currency-info">
                                      {{ format_currency(plan.currency) +(plan.precio_lista_tm | number: '1.0-2') }}
                                  </span>
                                  <ng-template #tipContent_pl>
                                      <div class="d-flex flex-column align-items-start text-start p-1">
                                          <div class="d-flex align-items-center gap-2"><span class="dot waiting"></span>{{ '$ '+(plan.precio_lista| number: '1.0-0') }}</div>
                                      </div>
                                  </ng-template>
                              </div>
                              <div *ngIf="!plan.currency || plan.currency == ''">
                                  {{ '$ '+(plan.precio_lista| number: '1.0-0') }}
                              </div>
                          </td>
                          <td>
                              <div *ngIf="plan.currency && plan.currency !== ''">
                                  <span [ngbTooltip]="tipContent_d" class="tipContent currency-info">
                                      {{ format_currency(plan.currency) +(plan.descuento_tm | number: '1.0-2') }}
                                  </span>
                                  <ng-template #tipContent_d>
                                      <div class="d-flex flex-column align-items-start text-start p-1">
                                          <div class="d-flex align-items-center gap-2"><span class="dot waiting"></span>{{ '$ '+(plan.descuento| number: '1.0-0') }}</div>
                                      </div>
                                  </ng-template>
                              </div>
                              <div *ngIf="!plan.currency || plan.currency == ''">
                                  {{ '$ '+(plan.descuento| number: '1.0-0') }}
                              </div>
                          </td>
                          <td></td>
                          <td>
                              <div *ngIf="plan.currency && plan.currency !== ''">
                                  <span [ngbTooltip]="tipContent_tc" class="tipContent currency-info">
                                      {{ '$ ' +(plan.total_cobro | number: '1.0-0') }}
                                  </span>
                                  <ng-template #tipContent_tc>
                                      <div class="d-flex flex-column align-items-start text-start p-1">
                                          <div class="d-flex align-items-center gap-2"><span class="dot waiting"></span>{{ format_currency(plan.currency)+(plan.total_cobro_tm | number: '1.0-2') }}</div>
                                      </div>
                                  </ng-template>
                              </div>
                              <div *ngIf="!plan.currency || plan.currency == ''">
                                  {{ format_currency(plan.currency)+(plan.total_cobro | number: '1.0-0') }}
                              </div>
                          </td>
                      </tr>

                      <tr *ngIf="data.document.pedido_cost.planes_analisis.length===0">
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                      </tr>
                  </tbody>
                  <tfoot>
                      <tr>
                          <td colspan="6" class="text-end"><b>Total</b></td>
                          <td>
                              <input type="text" class="input" *ngIf="false">
                              <b>${{total | number}}</b>
                          </td>
                      </tr>
                      <tr *ngIf="canShowManual() && data.document.pedido_cost.total_pedido">
                          <td colspan="6" class="text-end">
                              <div class="d-flex gap-1 justify-content-end">
                                  <b>Total</b>
                                  <small class="txt--normal">manual</small>
                              </div>
                          </td>
                          <td>
                              <input type="text" class="input" *ngIf="false">
                              <b>${{ data.document.pedido_cost.total_pedido| number}}</b>
                          </td>
                      </tr>
                  </tfoot>
              </table>
          </div>
      </div>
  </div>
  <div class="modal__foot flex-column flex-md-row justify-content-md-between gap-3 align-items-center">
      <a class="link-primary link--icon order-0 order-md-1" href={{data.document.url_proforma}}>
          <em class="bi bi-file-earmark-pdf ico--small me-2"></em>
          <span>DESCARGAR PROFORMA</span>
      </a>
  </div>

</div>

<app-detail-proforma [docVenta]="data.document" #docDetailProforma></app-detail-proforma>
