import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Enums, Objects } from 'src/app/shared';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { LogService } from 'src/app/shared/services/log.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { SampleModels } from '../models/sample.models';
import { SampleViewModels } from '../models/sample.view.models';
import { SampleService } from '../services/sample.services';
import { Location } from '@angular/common';
import { ModalEditSampleComponent } from '../modal-edit-sample/modal-edit-sample.component';

@Component({
  selector: 'app-edit-sample',
  templateUrl: './edit-sample.component.html',
  styleUrls: ['./edit-sample.component.css']
})
export class EditSampleComponent implements OnInit {
    estadosMuestra = Enums.EstadosMuestra;
    is_request_call: boolean = false;
    sampleId = "";
    planes: SampleModels.PlanAnalisis[] = []
    todaysdate = new Date();
    model: SampleViewModels.ViewResultEditModel = {
        muestra: {
            rellenoDesdeUltimoCambio: '',
            unidadUsoCambioLubricante: '',
            unidadUsoTotalComponente: '',
            usoCambioLubricante: '',
            usoTotalComponente: '',
            estado: '',
            correlativo: '',
            cliente: {
                clienteId: '',
                nombre: '',
                numeroSap: '',
                rut: '',
                faena: {
                    faenaId: '',
                    direccion: '',
                    nombre: '',
                    rubro: '',
                    numeroSap: ''
                }
            },
            clienteId: '',
            componente: {
                clienteId: '',
                componenteId: '',
                descriptor: '',
                marca: '',
                planAnalisis: {
                    nombre: '',
                    planAnalisisId: '',
                    code: ''
                },
                lubricante: {
                    lubricanteId: '',
                    nombre: '',
                    code: '',
                    tipo: {
                        categoriaLubricanteId: '',
                        nombre: ''
                    }
                },
                solicitante: '',
                tipoComponente: {
                    nombre: '',
                    tipoComponenteId: ''
                },
            },
            fechaMuestreo: new Date() + "",
            muestraId: '',
            planAnalisis: { nombre: '', planAnalisisId: '' },
            otroLubricante: '',
            muestraIdExt: '',
            componenteId: '',
            solicitudAnalisis: '',
            imagenes: [],
            comentarios: { comentario: '', comentarioId: '', nombre: '', status: '' }
        }
    }


    constructor(
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private service: SampleService,
        private auth: AuthenticationService,
        private _snackBar: MatSnackBar,
        public _logService: LogService,
        private location: Location,
        private spinner: SpinnerService,
        private router: Router,
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    private getPlanes() {
        return new Promise((resolve) => {
        this.service.GetPlanesAnalisis({},true ).subscribe(response => {
            if (response.code == 200) {
                this.planes = response.data;
                resolve(this.planes)

            }
        });
         });
    }

    opcionesRellenos: any[] = [{ value: '1', name: '1' }];
    loadingRellenos = false;
    selectedRelleno: string | undefined;

    comentarios: { comentario: string, comentarioId: string, nombre: string, status: string } = {
        comentario: '', comentarioId: '', nombre: '', status: ''
    }


    opcionesPlanAnalisis: SampleModels.PlanAnalisis[] = [];
    loadingPlanAnalisis = false;
    selectedPlanAnalisis: SampleModels.PlanAnalisis | undefined;

    opcionesLubricantes: SampleModels.Lubricante[] = [];
    loadingLubricante = false;
    selectedLubricante: SampleModels.Lubricante | undefined;

    opcionesComponentes: SampleModels.Componente[] = [];
    loadingComponente = false;
    selectedComponente: SampleModels.Componente | undefined ;

    estadosTodos: { value: string, name: string, posi: number }[] = [{ value: 'registered', name: 'Registrada', posi: 0 },
    { value: 'received' || 'received_observations', name: 'Recepcionada', posi: 1 },
    { value: 'not_received', name: 'No recepcionada', posi: 2 },
    { value: 'assigned_batch', name: 'Asignada a lote', posi: 3 },
    { value: 'in_analysis', name: 'En análisis', posi: 4 },
    { value: 'pending_approval', name: 'Por aprobar', posi: 5 },
    { value: 'reported', name: 'Reportada', posi: 6 }];
    loadingEstado = false;
    selectedEstado = { value: 'registered', name: 'Registrada', posi: 0 }
    opcionesEstados: { value: string, name: string, posi: number }[] = []

    ngOnInit(): void {
        this.loadData();
    }


    loadData() {
        this.route.queryParams.subscribe(params => {
            this.sampleId = params['id'];
            this.model.muestra = JSON.parse(params['muestra']);
        });

        if (!this.sampleId || !this.model.muestra.muestraId) {
            this.goBack();
        } else {
            this.load_sample_data();
        }
    }

    load_sample_data() {
        if (this.model.muestra.comentarios) {
            this.comentarios = this.model.muestra.comentarios ? this.model.muestra.comentarios : this.comentarios
        }
        if (this.model.muestra.estado != 'pending_approval') {
            this.getPlanes().then(() => {
                this.getCondicionesComerciales();
            });
            this.load_components();
            this.cargarLubricantes();
        }
        this.opcionesEstados = this.estadosTodos
        this.loadState();
    }



    getCondicionesComerciales() {
        let request: SampleModels.GetCondicionComercialRequest = {
            clienteId: this.auth.currentClientValue.clienteId,
            faenaId: this.auth.currentClientValue.faena.faenaId,
        }
        this.service.GetCondicionComercial(request,true).subscribe(response => {
            let planActual = this.planes.filter(plan => plan.planAnalisisId === this.model.muestra.planAnalisis.planAnalisisId)[0]||undefined;
            if(response.success && response.code===200){
               this.planes = this.planes.filter((plan) => response.data.planes.some((p) => p.planAnalisisId === plan.planAnalisisId));
            }
            if(!this.planes.includes(planActual!)){
                if(!planActual){
                    planActual = this.model.muestra.planAnalisis;
                }
                this.planes.push(planActual!);
            }
        })
    }


    loadState() {
        let list: { value: string, name: string, posi: number }[] = []
        this.opcionesEstados.forEach(es => {
            if (es.value == this.model.muestra.estado) {
                this.selectedEstado = es
                list.push(this.selectedEstado)
                if (this.selectedEstado.posi > 0) {
                    list.push(this.opcionesEstados[this.selectedEstado.posi - 1])

                }

            }
        })
        this.opcionesEstados = list
    }


    load_components(): void {
        this.loadingComponente = true

        const req: SampleModels.GetComponentesRequest = {
            clienteId: this.model.muestra.cliente.clienteId,
            faenaId: this.model.muestra.cliente.faena.faenaId,
        }

        this.service.GetComponentes(req)
            .subscribe(response => {
                if (response.code == 200 && response.data) {
                    response.data.forEach(data => {
                        this.opcionesComponentes.push(data)
                        this.opcionesComponentes.forEach(comp => {
                            if (comp.componenteId == this.model.muestra.componenteId) {
                                this.selectedComponente = comp;

                            }
                        })

                    })
                    this.loadingComponente = false
                    this.getMuestraInfo();
                }
            });

    }

    goBack() {
        this.router.navigate(['/solicitudes-de-analisis']);
    }

    cargarLubricantes() {
        this.loadingLubricante = true
        this.service.GetLubricants({}, true).subscribe(response => {
            if (response.data && response.code === 200) {
                this.opcionesLubricantes = response.data;
                if (this.model.muestra.lubricante?.lubricanteId != '0') {
                    const id = this.opcionesLubricantes.findIndex(lub => lub.lubricanteId === this.model.muestra.lubricante?.lubricanteId)
                    if (id > -1) {
                        this.selectedLubricante = this.opcionesLubricantes[id]
                    }
                } else {
                    this.selectedLubricante = this.model.muestra.lubricante;
                }
                this.loadingLubricante = false
            }
        });
    }

    change_lubricante() {
        if (!this.selectedLubricante) {
            this.selectedLubricante = {
                lubricanteId: "0",
                nombre: "Otro"
            }
        }
    }


    getMuestraInfo() {

        const request: SampleModels.GetMuestraInfoRequest = {
            clienteId: this.model.muestra.cliente.clienteId,
            faenaId: this.model.muestra.cliente.faena.faenaId,
            componenteId: this.selectedComponente ? this.selectedComponente.componenteId : ""
        };
        this.spinner.off();
        //this.model.cargando.lubricantes=true;
        this.loadingPlanAnalisis = true;
        this.service.GetMuestraInfo(request)
            .then(response => {
                if (response.code == 200 && response.data) {
                    this.opcionesPlanAnalisis = [];
                    this.selectedPlanAnalisis = undefined
                    this.opcionesPlanAnalisis = response.data.planesAnalisis;
                    this.loadingPlanAnalisis = false;
                    this.opcionesPlanAnalisis.forEach(planA => {
                        if (planA.planAnalisisId === this.model.muestra.planAnalisis.planAnalisisId) {
                            this.selectedPlanAnalisis = planA;
                            this.loadingPlanAnalisis = false;

                        }
                    })
                }
            })
            .catch(error => {
            });
    }

    changes() {
        this.getMuestraInfo()
    }


    childsTabReport: { [ref: string]: { bottleNumber: string, tab: Window, sample?: SampleModels.RegistroMuestra } } = {};

    public getPdf(): void {
        const ref = new Date().getTime();
        const url = '/reports2?ref=' + ref;
        const muestra: SampleModels.RegistroMuestra = this.model.muestra;
        let tab = window.open(url, '_blank');
        if (tab) {
            this.childsTabReport[ref] = { tab: tab, bottleNumber: muestra.muestraId, sample: muestra }
        }
        window.focus();
    }

    @HostListener('window:message', ['$event'])
    onMessage(e: any) {
        if (e.data.action === "showReport" && e.origin === window.location.origin) {
            switch (e.data.event) {
                case 'init':
                    let ref = this.childsTabReport[e.data.ref]
                    // console.log("ref", ref)
                    if (ref) {
                        ref.tab.window.postMessage({ action: "loadReport", bottleNumber: ref.bottleNumber, sample: ref.sample }, window.location.origin);
                    }
                    break;
                default:
                    break;
            }

        }
    }

    getComponente() {
        return {
            clienteId: this.selectedComponente?.clienteId ?? this.model.muestra.componente?.clienteId,
            componenteId: this.selectedComponente?.componenteId ?? this.model.muestra.componente?.componenteId,
            descriptor: this.selectedComponente?.descriptor ?? this.model.muestra.componente?.descriptor,
            equipo: {
                tipoEquipo: {
                    nombre: this.selectedComponente?.equipo?.tipoEquipo?.nombre ?? this.model.muestra.componente?.equipo?.tipoEquipo?.nombre,
                    tipoEquipoId: this.selectedComponente?.equipo?.tipoEquipo?.tipoEquipoId ?? this.model.muestra.componente?.equipo?.tipoEquipo?.tipoEquipoId,
                }
            }
        };
    };

    getPlanAnalisis() {
        return {
            code: this.selectedPlanAnalisis?.code ?? this.model.muestra.planAnalisis?.code,
            nombre: this.selectedPlanAnalisis?.nombre ?? this.model.muestra.planAnalisis?.nombre,
            planAnalisisId: this.selectedPlanAnalisis?.planAnalisisId ?? this.model.muestra.planAnalisis?.planAnalisisId
        };
    };

    getLubricante() {
        return {
            lubricanteId: this.selectedLubricante?.lubricanteId ?? this.model.muestra.lubricante?.lubricanteId,
            nombre: this.selectedLubricante?.nombre ?? this.model.muestra.lubricante?.nombre,
            tipo: {
                categoriaLubricanteId: this.selectedLubricante?.tipo?.categoriaLubricanteId ?? this.model.muestra.lubricante?.tipo?.categoriaLubricanteId,
                nombre: this.selectedLubricante?.tipo?.nombre ?? this.model.muestra.lubricante?.tipo?.nombre
            }
        };
    };

    procesarMuestra(): { componente: any, planAnalisis: any, lubricante: any } {
        return {
            componente: this.getComponente(),
            planAnalisis: this.getPlanAnalisis(),
            lubricante: this.getLubricante()
        }
    }



    private obtenerDatosMuestra() {
        return {
            clienteId: this.model.muestra.cliente.clienteId,
            faenaId: this.model.muestra.cliente.faena.faenaId,
            fechaMuestreo: this.model.muestra.fechaMuestreo,
            rellenoDesdeUltimoCambio: this.model.muestra.rellenoDesdeUltimoCambio || null,
            unidadUsoCambioLubricante: this.model.muestra.unidadUsoCambioLubricante || null,
            unidadUsoTotalComponente: this.model.muestra.unidadUsoTotalComponente || null,
            usoCambioLubricante: this.model.muestra.usoCambioLubricante || null,
            usoTotalComponente: this.model.muestra.usoTotalComponente || null,
            comentarioCliente: this.model.muestra.comentarioCliente || null,
            muestraId: this.model.muestra.muestraId,
            componente:this.procesarMuestra().componente,
            comentarios: this.comentarios.comentario,
            estado: this.selectedEstado.value,
            lubricante: this.procesarMuestra().lubricante,
            planAnalisis: this.procesarMuestra().planAnalisis,
        }
    }

    sendLog(muestraId: string, usuarioNombre: string, usuarioUserId: string): void {
        let req: Objects.LogInput = {
            accion: "Edit",
            elemento: muestraId,
            tipoElemento: 'Muestra',
            usuario: usuarioNombre,
            usuarioId: usuarioUserId,
            ambiente: 'Web-Cliente'
        }
        this._logService.createLog(req, true);
    }


    updateSample() {
        const dialogRef = this.dialog.open(ModalEditSampleComponent, {
            panelClass: 'ds-modal',
            data: {
                muestraId: this.model.muestra.muestraId,
                cliente: this.model.muestra.cliente.nombre,
                faena: this.model.muestra.cliente.faena.faenaId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'si') {
                const planAnalisis: SampleModels.PlanAnalisis[] = this.planes.filter(plans => plans.planAnalisisId === this.selectedPlanAnalisis?.planAnalisisId)
                const rq: SampleModels.SetMuestraRequest = {
                    action: "update_sample",
                    muestra: this.obtenerDatosMuestra(),
                    usuario: {
                        apellido: this.auth.currentUserValue.apellido,
                        email: this.auth.currentUserValue.email,
                        nombre: this.auth.currentUserValue.nombre,
                        phone: this.auth.currentUserValue.phone,
                        rut: this.auth.currentUserValue.rut,
                        userId: this.auth.currentUserValue.userId
                    }
                }
                this.is_request_call = true;
                this.service.SetModificarMuestra(rq).subscribe(response => {
                    this.is_request_call = false;
                    if (response.code == 200) {

                        this.sendLog(this.model.muestra.muestraId, this.auth.usuario.nombre, this.auth.usuario.userId);

                        this._snackBar.open(`La muestra "${this.model.muestra.muestraId}" se ha actualizado.`, 'Cerrar', {
                            duration: 5000,
                            panelClass: [`success-snackBar`],
                            verticalPosition: 'bottom',
                            horizontalPosition: 'right'
                        });
                        this.spinner.off();
                        this.goBack();
                    } else {
                        this.spinner.off();
                        this._snackBar.open(`No se pudo actualizar la muestra.`, 'Cerrar', {
                            duration: 5000,
                            panelClass: [`success-snackBar`],
                            verticalPosition: 'bottom',
                            horizontalPosition: 'right'
                        });
                        this.goBack();
                    }
                }, error =>{
                    this.is_request_call = false;
                });
            }
        });

    }
    getEstadoMuestra = (estado: string): string => estado in Enums.EstadosMuestra ? Enums.EstadosMuestra[estado as keyof typeof Enums.EstadosMuestra] : '';
}
