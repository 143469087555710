<ng-container *ngIf="!confirmarAbierto">
  <div class="modal-header">
    <h5 class="modal-title">
      Seleccionar ensayos
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancelar()"></button>
  </div>
  <div class="modal-body">
        <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-md-between w-100">
          <div class="mx-2">
            <input
              type="search"
              class="form-control"
              [placeholder]="'Buscar'"
              [(ngModel)]="buscar"
              (ngModelChange)="busqueda()"
            />
          </div>
          <div class="alert alert--info mx-2 mt-2">
            <div class="alert-message d-flex justify-content-center align-items-center p-1">
              <em class="bi bi-info-circle-fill ico--small mx-1"></em>
              <span class="d-flex justify-content-center align-items-center">Debes seleccionar al menos un ensayo para continuar</span>
            </div>
          </div>
        </div>
    <div class="d-block mt-3">
      <mat-accordion [multi]="true" class="ds-accordion">
        <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title class="subtitle">ANÁLISIS INCLUIDO</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="grid grid--checkboxes pt-2">
            <ng-container *ngFor="let check of ensayos; let i = index">
              <mat-checkbox
                [color]="'primary'"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="check.check"
                (change)="ensayoChange(check)"
              >
                <label class="label-checkbox"> {{ check.ensayoId }} - {{ check.nombre | uppercase }}</label>
              </mat-checkbox>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="modal-footer border-top">
    <button type="button" class="btn btn-light mx-4" data-bs-dismiss="modal" (click)="cancelar()">
      Cancelar </button>
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="!validarCheck()" (click)="confirmation()">
      Confirmar
    </button>
  </div>

</ng-container>
