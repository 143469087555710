import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Tools } from 'src/app/shared';
import { SolicitudDeAnalisisModels } from '../solicitud-models/solicitud.models';

@Injectable({
    providedIn: 'root'
})
export class SolicitudDeAnalisisService {

    constructor(
        private http: HttpClient,
        private tools: Tools) {
    }
    GetSolicitudAnalisis(request: SolicitudDeAnalisisModels.GetSolicitudDeAnalisisRequest): Promise<SolicitudDeAnalisisModels.GetSolicitudDeAnalisisResponse> {
        return this.http.get<SolicitudDeAnalisisModels.GetSolicitudDeAnalisisResponse>(`${environment.apiUrl}${environment.routes.getSolicitudAnalisis}`, { params: this.tools.toHttpParams(request) }).toPromise();
    }
}
