import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-replay-modal',
  templateUrl: './replay-modal.component.html',
  styleUrls: ['./replay-modal.component.css']
})
export class ReplayModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
