import { Component, OnInit } from '@angular/core';
import { CondicionesComercialesService } from '../condiciones-comerciales-services/condiciones-comerciales.service';
import { AuthenticationService } from '../../shared/auth/authentication.service';
import { CondicionesComercialesModels } from '../condiciones-comerciales-models/condiciones-comerciales.models';
import { CondicionesComercialesViewModels } from '../condiciones-comerciales-models/condiciones-comerciales.view.models';
import { PendienteFacturacionService } from '../condiciones-comerciales-components/pendientes-facturacion/pendientes-facturacion-services/pendientes-facturacion-service';
import { PendienteFacturacionModels } from '../condiciones-comerciales-components/pendientes-facturacion/pendientes-facturacion-models/pendientes-facturacion-models';

@Component({
    selector: 'condiciones-comerciales',
    templateUrl: './condiciones-comerciales.component.html',
    styleUrls: ['./condiciones-comerciales.component.css'],

})
export class CondicionesComercialesComponent implements OnInit {
    model: CondicionesComercialesViewModels.ViewModel | undefined;
    facturacionNumbers: PendienteFacturacionModels.TotalPendientesFacturacion = {
        total: '0',
        cebe: '0',
        pendiente: '0'
    }
    showItems = 3;
    showAll = false;
    tooltipContent = '<div class="text-center d-flex justify-content-center flex-column"><span class="mb-0">CEBE: <b>$ 0</b></span><span class="mb-0">Pendiente facturación: <b>$ 0</b></span></div>';

    constructor(private service: CondicionesComercialesService,
        private authService: AuthenticationService,
        private pendienteFacturacionService: PendienteFacturacionService) {

    }

    toggleShowAll() {
        this.showAll = !this.showAll;
    }

    ngOnInit(): void {
        let req: CondicionesComercialesModels.GetCondicionComercialRequest;
        req = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId
        }
        this.service.getCondicionComercial(req).subscribe(response => {
            if (response.success) {
                this.model = {
                    condicionComercial: response.data
                }
                if (this.validarAdmin()) this.loadPendientesFacturacion();

            }

        })
    }

    loadPendientesFacturacion() {
        let req: PendienteFacturacionModels.GetTotalPendientesFacturacionRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId
        };
        this.pendienteFacturacionService.GetTotalPendientesFacturacion(req).then(response => {
            if (response.code == 200) {
                this.facturacionNumbers = response.data;
                this.tooltipContent = '<div class="text-center d-flex justify-content-center flex-column"><span class="mb-0">CEBE: <b>$ ' + this.formatearNumero(this.facturacionNumbers.cebe) + '</b></span><span class="mb-0">Pendiente facturación: <b>$ ' + this.formatearNumero(this.facturacionNumbers.pendiente) + '</b></span></div>';

            }
            if (response.code == 500) {
                this.facturacionNumbers = { total: '0', cebe: '0', pendiente: '0' };
            }


        }).catch(error => {
            this.facturacionNumbers = { total: '0', cebe: '0', pendiente: '0' };
        })
    }

    esEnvioCourier(): boolean {
        return this.model?.condicionComercial.transporte.toUpperCase().toUpperCase() === 'ENVIOCOURIER'
    }
    esLaboratorio(): boolean {
        return this.model?.condicionComercial.transporte.toUpperCase() === 'LABORATORIO'
    }

    esSinTransporte(): boolean {
        return this.model?.condicionComercial.transporte.toUpperCase() === 'SINTRANSPORTE'
    }

    formatearNumero(numero: string) {
        return numero.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    validarAdmin(): boolean {
        return this.authService.currentUserValue.roles.includes("Admin") && this.authService.currentUserValue.proforma_enabled;    }

}
