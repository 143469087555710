<div class="content__head">
  <div class="content__title mb-5">
    <h2 class="mb-0">Descargar reportes de componentes</h2>
    <p>Selecciona las variables para obtener una planilla con los reportes de componentes.</p>
  </div>
</div>
<section class="content__container">
  <form action="">
    <div class="row">      
      <div class="col-12 col-md mb-3">
        <div class="form__field">
          <label class="label mb-1 d-block">Faena</label>
          <ng-select
              id="reporte_componente_faena"
              blockSpecialCharacters
              #selectedFaena="ngModel"
              class="ng-select"
              name="faena"
              placeholder="Seleccionar"
              [items]="optionsFaena"
              required
              [(ngModel)]="selectedChore"
              [appendTo]="'body'"
              [clearable]="false"
              [clearSearchOnAdd]="true"
              [closeOnSelect]="false"
              [multiple]="true"
              clearAllText="Clear"
              bindLabel="nombre"
          >
            <ng-template ng-header-tmp>
              <div class="py-2 d-flex align-items-center gap-1">
                <input id="reporte_componente_all_faena" type="checkbox"id="selectAllHeader" #selectAllHeader (change)="selectAllChores(selectAllHeader.checked)" checked/>
                <label for="selectAllHeader">Seleccionar todos</label>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="d-flex align-items-center gap-1">
                  <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                  {{item['faenaId']}} - {{item['nombre']}}
              </div>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <div *ngIf="items.length === 1">
                <div class="ng-value" *ngFor="let item of items | slice:0:1">
                  {{$any(item)['nombre']}}
                </div>
              </div>
              <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{items.length}} Seleccionados</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-12 col-md d-flex align-self-end mb-3">
        <button id="reporte_componente_descargar" class="btn btn-primary btn--small btn--first mb-1" [disabled]="!selectedChore" (click)="getReport()">
          Descargar reporte
        </button>
      </div>
    </div>
  </form>
</section>

<div class="printed" *ngIf="printed">
  <ng-container *ngIf="printedLoading">
    <div class="printed-icon">
      <ng-lottie [options]="printOptions"></ng-lottie>
    </div>
    <h2>Generando reporte</h2>
    <p><b>No cierres esta ventana</b>, Una vez terminado el reporte, aparecerá un botón para que lo descargues.</p>
  </ng-container>
  <ng-container *ngIf="!printedLoading">
    <div class="printed-icon lottie-check">
      <ng-lottie [options]="printCheckOptions"></ng-lottie>
    </div>
    <h2>Reporte Generado</h2>
    <button id="reporte_componente_download_file" class="btn btn--small btn--success" (click)="download_file()">Descargar reporte</button>
  </ng-container>
</div>
