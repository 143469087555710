import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LimitesModels } from '../../mant-limites-models/limites.models';
import { ConfirmarPersonalizacionComponentLimites } from '../confirmar-personalizacion/confirmar-personalizacion.component';


@Component({
  selector: 'app-select-ensayos',
  templateUrl: './select-ensayos.component.html',
  styleUrls: ['./select-ensayos.component.css']
})
export class SelectEnsayosComponent implements OnInit {
    confirmarAbierto = false;
    buscar: string = '';
    @Input() data: LimitesModels.EnsayosListCheck | undefined;
    ensayos: LimitesModels.EnsayoCheck[] = [];
    ensayosAUX: LimitesModels.EnsayoCheck[] = [];
    constructor(public dialogRef: NgbActiveModal, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.ensayos = JSON.parse(JSON.stringify(this.data?.ensayosCheck)) ?? [];
        this.ensayosAUX = JSON.parse(JSON.stringify(this.data?.ensayosCheck)) ?? [];
        this.ensayos.sort((a, b) => {
            let aId = parseInt(a.ensayoId.split(' - ')[0]);
            let bId = parseInt(b.ensayoId.split(' - ')[0]);

            return aId - bId;
        });
        this.ensayosAUX.sort((a, b) => {
            let aId = parseInt(a.ensayoId.split(' - ')[0]);
            let bId = parseInt(b.ensayoId.split(' - ')[0]);

            return aId - bId;
        });
    }

    busqueda() {
        this.ensayos = this.ensayosAUX.filter((item) => {
            const filtroTexto = this.buscar.toLowerCase();
            return (
                item.ensayoId.toLowerCase().includes(filtroTexto) ||
                item.nombre.toLowerCase().includes(filtroTexto) ||
                item.code.toLowerCase().includes(filtroTexto)
            );
        });
    }

    cancelar() {
        this.dialogRef.close();
    }

    ensayoChange(ensayo: LimitesModels.EnsayoCheck): void {

            let ep = this.ensayos.find((e) => e.ensayoId === ensayo.ensayoId);
            if (ep) {
                this.ensayos.splice(
                    this.ensayos.findIndex((e) => e.ensayoId === ensayo.ensayoId),
                    1,
                    ensayo
                );
                this.ensayosAUX.splice(
                    this.ensayosAUX.findIndex((e) => e.ensayoId === ensayo.ensayoId),
                    1,
                    ensayo
                );
            }

    }

    validarCheck() {
        if (this.data?.ensayosCheck && this.ensayosAUX.filter((en) => en.check === true).length > 0) {
            return true;
        }
        return false;
    }

    confirmation() {
        this.confirmarAbierto = true;

        const modalRef = this.modalService.open(ConfirmarPersonalizacionComponentLimites, {
            centered: true,
            size: 'ml',
            backdrop: 'static',
            keyboard: false,
        });
        modalRef.componentInstance.ensayos = this.ensayosAUX.filter((en) => en.check).length;

        modalRef.result
            .then((confirma) => {
                if (confirma) {
                    this.dialogRef.close(this.ensayosAUX.filter((en) => en.check));
                }
            })
            .catch(() => (this.confirmarAbierto = false))
            .finally(() => (this.confirmarAbierto = false));
    }
}
