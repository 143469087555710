import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/shared/auth/authentication.service";
import { ToastService } from "src/app/shared/components/ng-toast/toast-service";
import { EventEmitterService } from "src/app/shared/services/event-emitter.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { MantUsuariosModels } from "../../mant-usuarios-models/mant-usuarios.models";
import { MantUsuariosViewModels } from "../../mant-usuarios-models/mant-usuarios.view.models";
import { MantUsuariosService } from "../../mant-usuarios-services/mant-usuarios.service";
import { UsuarioNuevoComponent } from "../usuario-nuevo/usuario-nuevo-views/usuario-nuevo.component";
import { ModalConfirmService } from "src/app/shared/services/modalConfirm.service";
import { Objects } from "src/app/shared/models/objects";
import { LogService } from "src/app/shared/services/log.service";

@Component({
    selector: 'app-mant-usuarios',
    templateUrl: './mant-usuarios.component.html',
    styleUrls: ['./mant-usuarios.component.css']
})
export class MantUsuariosComponent implements OnInit, OnDestroy{

    displayedColumns: string[] = ['nombre', 'apellido', 'phone','email','tipo', 'faena', 'accion'];

    dataSource = new MatTableDataSource<MantUsuariosModels.Usuario>([]);

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    myControl = new FormControl();
    subscribers: Subscription[] = [];

    model: MantUsuariosViewModels.ViewModel = {
        pagination: { page: 0, pageSize: 10, totalElements: 0 },
        usuarios: [],
        usuariosEncontrados: [],
        usuarioFiltro: '',
        alertaSinResultado:false,
        cargandoUsuarios:false,
        forceRefresh: false,
        currentClientId: '',
    }
    usuariosTipo:{[usuarioId:string]:{rol:string,elimina:boolean}}={}

    constructor(
        private authService: AuthenticationService,
        private spinner: SpinnerService,
        private modalService: NgbModal,
        private service: MantUsuariosService,
        private eventEmiiterSrv: EventEmitterService,
        private toastService: ToastService,
        private modalConfirmService: ModalConfirmService,
        private _logService:LogService
    ){}

    ngOnInit(){
        this.spinner.off(true);
        this.getUsuarios();
        this.model.currentClientId = this.authService.currentClientValue.clienteId;

        this.eventEmiiterSrv.event("FLOW_MANTENEDOR").subscribe(result=>{
            this.model.usuarioFiltro = '';
            this.model.forceRefresh = true;
            this.getUsuarios();

            switch(result.accion){
                case 'NUEVO':
                    this.toastService.show(`El usuario fue creado`, { classname: 'bg-success text-light', delay: 2000 });
                    break;
                case 'EDITAR':
                    this.toastService.show(`El usuario fue actualizado`, { classname: 'bg-primary text-light', delay: 2000 });
                    break;
                case 'ELIMINAR':
                    this.toastService.show(`El usuario fue eliminado`, { classname: 'bg-danger text-light', delay: 2000 });
                    break;
                default:
                    break;
            }
        });
    }

    ngOnDestroy() {
        this.spinner.off(true);
        this.destroySubs();
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    keyUpSearch(): void{
        this.model.pagination.page = 0;
        this.filtrarUsuarios();
    }

    filtrarUsuarios(): void{

        if(this.model.usuarioFiltro){
            if (typeof(this.model.usuarioFiltro) === 'string') {
                this.model.usuariosEncontrados = !this.model.usuarioFiltro && this.model.usuarioFiltro === '' ? [] :
                    this.model.usuarios.filter(option =>
                        option.nombre.toLowerCase().includes(this.model.usuarioFiltro.toLowerCase()) ||
                        option.apellido.toLowerCase().includes(this.model.usuarioFiltro.toLowerCase()) ||
                        option.email.toLowerCase().includes(this.model.usuarioFiltro.toLowerCase()) ||
                        option.phone.toLowerCase().includes(this.model.usuarioFiltro.toLowerCase())
                    );
                if (this.model.usuariosEncontrados.length === 0){
                    this.model.alertaSinResultado=true;
                } else {
                    this.model.alertaSinResultado=false;
                    this.dataSource = new MatTableDataSource<MantUsuariosModels.Usuario>(this.model.usuariosEncontrados);
                    this.LoadPaginate();
                }
            } else {
                this.model.usuarioFiltro = '';
                this.LoadPaginate();
            }
        }else{
            this.model.usuariosEncontrados = this.model.usuarios;
            this.model.alertaSinResultado=false;
            this.LoadPaginate();
        }
    }

    getUsuarios(): void{
        this.LoadPaginate();
        this.destroySubs();

        this.model.cargandoUsuarios = true;
        const request: MantUsuariosModels.GetUsuariosRequest = {
            clienteId: this.authService.currentClientValue.clienteId
        };

        let sub = this.service.GetUsuarios(request, this.model.forceRefresh).subscribe(response => {
            if (response.success && response.code === 200) {
                this.model.usuarios = response.data;
                this.model.usuariosEncontrados = this.model.usuarios;
                this.dataSource = new MatTableDataSource<MantUsuariosModels.Usuario>(this.model.usuariosEncontrados);
                this.LoadPaginate();
                this.model.cargandoUsuarios = false;
                this.tipoUser();
            } else {
            }
        });
        this.subscribers.push(sub);
    }

    LoadPaginate(): void {
        this.dataSource.sortingDataAccessor = (usuario: MantUsuariosModels.Usuario, sortHeaderId: string) => {
            switch(sortHeaderId) {
                case 'nombre': return usuario.nombre.toLowerCase();
                case 'apellido': return usuario.apellido.toLowerCase();
                case 'phone': return usuario.phone;
                case 'email': return usuario.email;
                default: return 1;
            }
        }
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    handlePageEvent(event: PageEvent): void {
        this.model.pagination.totalElements = event.length;
        this.model.pagination.pageSize = event.pageSize;
        this.model.pagination.page = event.pageIndex;

        this.LoadPaginate();
    }

    private destroySubs() {
        this.subscribers.forEach(s=>{
            s.unsubscribe();
        })
    }

    nuevoUsuario(): void{
        const modalRef=this.modalService.open(UsuarioNuevoComponent, {
            centered:true,
            size: 'md'
        });
    }

    editarUsuario(usuario: MantUsuariosModels.Usuario): void{
        let usuarioInput: MantUsuariosModels.UsuarioInput = {
            usuario: usuario,
            editar: true
        }

        const modalRef=this.modalService.open(UsuarioNuevoComponent, {
            centered:true,
            size: 'md'
        });

        modalRef.componentInstance.usuarioInput = Object.assign({}, usuarioInput) ;
    }

    eliminarUsuario(usuario: MantUsuariosModels.Usuario): void{
        this.spinner.on();
        const req: MantUsuariosModels.DeleteUsuarioRequest = {
            delete: true,
            userId: usuario.userId,
            usuario: usuario
        }
        this.service.DeleteUsuario(req).subscribe(response => {
            if(response.success){

                let rq: Objects.LogInput = {
                    accion: 'Delete',
                    elemento: usuario.rut.trim(),
                    tipoElemento: 'Usuario',
                    usuario: this.authService.usuario.nombre,
                    usuarioId: this.authService.usuario.userId,
                    ambiente: 'Web Cliente'
                  }
                this._logService.createLog(rq, true);
                this.eventEmiiterSrv.sendResult("FLOW_MANTENEDOR", {accion: 'ELIMINAR'});
            }else{
                this.toastService.show(`Erro al eliminar usuario`, { classname: 'bg-danger text-light', delay: 2000 });
                this.spinner.off();
            }

        });
    }

    tipoUser() {
        const rolesMap = {
            'Super Administrador': {rol:'Super Administrador', elimina:false},
            'Admin': {rol:'Administrador', elimina:true},
            'default': {rol:'Usuario Faena', elimina:true}
        };
        this.model.usuarios.forEach(usuario => {
            const rol = this.determinarRol(usuario);
            this.usuariosTipo[usuario.userId] = rolesMap[rol];
        });

        const mi_rol=this.usuariosTipo[this.authService.currentUserValue.userId].rol;
        this.usuariosTipo[this.authService.currentUserValue.userId]={rol:mi_rol,elimina:false};

    }

    determinarRol(usuario: MantUsuariosModels.Usuario) {
        if(usuario.is_super_admin) return 'Super Administrador';
        if(usuario.roles.includes('Admin')) return 'Admin';
        return 'default';
    }


    eliminarUsuarioModal(usuario: MantUsuariosModels.Usuario){
        const msgContent = `Al eliminar este usuario se eliminara de todas las faenas asociadas y no podrá ingresar a la plataforma ni recibirá más correos.<br>
            <div class="alertModal alert--info">
                <figure class="alert__figure d-flex align-items-center justify-content-center">
                    <em class="bi bi-info-circle-fill ico--small"></em>
                </figure>
                <div class="alert__message white-wrap">
                    Si se requiere solo quitar una faena se debe editar y no eliminar el usuario
                </div>
            </div>`;

        const modalRef = this.modalConfirmService.open({
            title: '¿Estás seguro?',
            msg: msgContent,
            titleButtonOK: 'Confirmar',
            buttonClassname: 'btn-primary'
        }, {
            backdrop: 'static',
            backdropClass: 'customBackdrop',
            centered: true,
        });

        modalRef.result.then(isOK => {
            if (isOK) {
                this.eliminarUsuario(usuario);
            }
        });
    }
}
