import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { LogService } from 'src/app/shared/services/log.service';
import { ReportesModels } from '../models/reportes.models';
import { ReportesService } from '../services/reportes.service';

@Component({
  selector: 'app-modal-reportes',
  templateUrl: './modal-reportes.component.html',
  styleUrls: ['./modal-reportes.component.css']
})


export class ModalReportesComponent implements OnInit {
  subscribers: { name: string, sub: Subscription, active?: boolean }[] = [];
  
  
  constructor(
    public dialogRef: MatDialogRef<ModalReportesComponent>,
    private service:ReportesService,
    private authService: AuthenticationService,
    private _snackBar: MatSnackBar,
    private _logService: LogService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) { }
 

 

  ngOnInit(): void {

  }
  
  goTolink(){
    const url=this.data;
    window.open(url,"_blank");
    this._snackBar.open(`Descarga reporte exitosa.`, '', {
      duration: 5000,
      panelClass: [`success-snackBar`],
      verticalPosition: 'bottom',
      horizontalPosition: 'right'
    });
    this.dialogRef.close();
  }

 
}