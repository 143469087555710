import { Component, OnInit, Input, Output, EventEmitter, ElementRef, SimpleChanges, DoCheck, OnChanges, ViewChild } from '@angular/core';
import { Enums } from 'src/app/shared';
import { DecimalPipe } from '@angular/common';
import { ReportsModels } from '../../models/reports.models';
import { Constants } from '../../models/constants';

@Component({
    selector: 'td[cell-report]',
    templateUrl: './cell-report.component.html',
    styleUrls: ['./cell-report.component.css'],
    providers: [DecimalPipe]
})
export class CellReportComponent implements OnInit, DoCheck, OnChanges {
    @ViewChild('analysisForm') analysisForm: any;
    visible: boolean = true;

    enumResultadoEnsayo: { [key: string]: string } = Enums.ResultadoGeneralEnsayo;
    tryToSave: boolean = false;
    statusKey: string = '';
    enumTdStatusClass: { [key: string]: string } = {
        alerta: 'danger',
        normal: '',
        precaucion: 'caution'
    }
    condicion = {
        esPorIngreso: false,
        debeAct: false,
        debeIngresar: false,
        checkboxSeleccionR: false,
        resultadoRepeticion: false,
        permiteSeleccionar: false
    }
    esSeleccionado: boolean = false;
    valorResultado: string = '';
    public valorNuevo: string = '';
    public estadoAnalisis: string = '';
    private oldMuestra?: ReportsModels.Muestra;


    @Input() ensayo?: ReportsModels.Ensayo;
    @Input() muestra?: ReportsModels.Muestra;
    @Input() esMuestraBase: boolean = false;
    @Input() tdClass: string = '';
    @Output() inputEmitter = new EventEmitter<{ muestra?: ReportsModels.Muestra, ensayo?: ReportsModels.Ensayo, valor: string }>();
    @Output() hideEmitter = new EventEmitter<{ ensayo: string, oculto: boolean }>();

    constructor(
        private _decimalPipe: DecimalPipe
    ) {

    }

    ngOnInit(): void {
    }

    ngAfterContentInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.cargarInformacion();
        this.stored();
    }

    ngDoCheck() {
        if (this.oldMuestra && this.muestra) {
            let restultadosOld = JSON.stringify(this.oldMuestra.resultados);
            let restultadosNEW = JSON.stringify(this.muestra.resultados);
            let nuevoResultado = restultadosOld !== restultadosNEW;
            if (nuevoResultado) {
                this.stored();
                this.cargarInformacion();
            }
        }
    }

    cargarInformacion() {
        this.tryToSave = false;
        this.estadoAnalisis = this.estadoUltimoAnalisis;
        this.valorResultado = this.valorUltimoAnalisis;

        if (this.ensayo?.hide) {
            this.visible = !this.ensayo?.hide;
        }

        if (this.valorResultado === '') {
            this.condicion.esPorIngreso = true;
            this.condicion.checkboxSeleccionR = false;
        } else {
            this.condicion.checkboxSeleccionR = true;
            this.condicion.esPorIngreso = false;
        }

        if (this.esMuestraBase) {
            this.tdClass = 'col-highlight ' + this.enumTdStatusClass[this.estadoAnalisis.toLowerCase()];
        } else {
            this.tdClass = '';
        }
    }

    get esPorRepetir() {
        let es = this.resultados.length > 1 && this.ultimoResultado?.stamp != 'cargado';
        return es || this.ultimoResultado?.stamp! === Constants.Ensayo.estado.porRepetir
    }

    get esFinal() {        
        return this.muestra && this.muestra.resultados &&
            this.ultimoResultado?.stamp === 'cargado'
            && this.resultados.filter(r => r?.stamp === 'cargado').length === 1
    }

    get limites(): { min: number, max: number } {
        let min = 0;
        let max = 0;
        if (this.ensayo) {
            min = Number(this.ensayo.limites?.inputMin)
            max = Number(this.ensayo.limites?.inputMax)
        }
        return { min: min, max: max }
    }


    get estadoUltimoAnalisis() {
        return this.ultimoResultado?.estado?.status || '';
    }

    get valorUltimoAnalisis(): string {        
        return this.ultimoResultado?.stamp === 'no_realizable' ? "N/R" : this.ultimoResultado?.value || '';
    }

    get ultimoResultado(): ReportsModels.Resultado | undefined {
        let resultados = this.muestra?.resultados || [];
        resultados = resultados.filter(r => r.ensayo.ensayoId === this.ensayo?.ensayoId);
        let ultimoResultado = resultados?.sort((a, b) => {
            if (a.ensayo.ensayoId < b.ensayo.ensayoId) { return -1; }
            if (a.ensayo.ensayoId > b.ensayo.ensayoId) { return 1; }
            return 0;
        }).pop();
        return ultimoResultado;
    }

    get resultados(): ReportsModels.Resultado[] {
        let resultados: ReportsModels.Resultado[] | undefined = [];
        if (this.ensayo && this.muestra) {
            resultados = this.muestra.resultados?.filter(r => r.ensayo.ensayoId === this.ensayo?.ensayoId);;
        }
        return resultados || []
    }

    get noRealizable(): boolean {
        let stamp = this.ultimoResultado?.stamp ? this.ultimoResultado?.stamp : '';
        return stamp === 'no_realizable';
    }

    get faltaIniciar(): boolean {
        let stamp = this.ultimoResultado?.stamp ? this.ultimoResultado?.stamp : '';
        return stamp === 'falta_iniciar';
    }


    get valorResultadoFormateado() {
        let valor = this.valorUltimoAnalisis;
        return valor != "N/R" ? this.formatearValor(valor) : valor;
    }

    stopPropagation(event: any) {
        event.stopPropagation();
    }

    formatearValor(valor: string) {

        switch (this.ensayo?.tipoDato?.tipo.toUpperCase()) {
            case 'BOOLEAN':
                valor = this.enumResultadoEnsayo[valor];
                break;
            case 'FLOAT':
            case 'INTEGER':
            default:
                let decimal = this.ensayo?.tipoDato?.decimals || 0
                if (valor && !isNaN(Number(valor))) {
                    valor = this._decimalPipe.transform(parseFloat(valor).toFixed(Number(decimal))) || ''
                }
                break
            case 'INTEGER':
                if (valor && !isNaN(Number(valor))) {
                    valor = this._decimalPipe.transform(valor) || ''
                }
                break
        }
        return valor;
    }

    click_guardar() {
        this.tryToSave = true;
        if (!this.analysisForm.invalid) {
            this.esSeleccionado = false;
            this.valorResultado = this.valorNuevo;
            this.inputEmitter.emit({ muestra: this.muestra, ensayo: this.ensayo, valor: this.valorResultado })
        }
    }

    click_visible() {
        this.visible = !this.visible;
        if (this.ensayo) {
            this.hideEmitter.emit({ ensayo: this.ensayo.ensayoId, oculto: this.visible });
        }
    }

    stored() {
        this.oldMuestra = Object.assign({}, this.muestra);
        this.oldMuestra.resultados = Object.assign([], this.muestra?.resultados);
    }

    getResultValue(result: ReportsModels.Resultado) {
        let value = '';
        let status = result.stamp === 'no_realizable' ? 'noRealizable' : result.estado?.status;
        switch (status) {
            case 'porRepetir':
                value = '---'
                break;
            case 'noRealizable':
                value = 'N/R'
                break;
            default:
                value = this.formatearValor(result.value)
                break;
        }
        return value;
    }

}

