import { AuthenticationService } from './../../shared/auth/authentication.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Component, OnInit } from '@angular/core';
import { LimitesViewModels } from './mant-limites-models/limites.view.models';
import { LimitesModels } from './mant-limites-models/limites.models';
import { LimitesServices } from './mant-limites-services/limites.services';
import { AnimationOptions } from 'ngx-lottie';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NewEditLimitesComponent } from './componentes/new-edit-limites/new-edit-limites.component';
import { SnackBarService } from 'src/app/shared/services/snackBar.service';

@Component({
    selector: 'app-mant-limites',
    templateUrl: './mant-limites.component.html',
    styleUrls: ['./mant-limites.component.css']
})
export class MantLimitesComponent implements OnInit {
    generation: boolean = false;
    printed: boolean = false;
    printedLoading: boolean = true;
    printOptions: AnimationOptions = {
        path: '/assets/images/cloud-print.json',
    };
    printCheckOptions: AnimationOptions = {
        path: '/assets/images/black-check.json',
    };

    timerId: any;
    timeout_exist_error: any;
    timeout=0;
    informe: LimitesModels.Reporte = {
        trx_complete: false,
        file_name: '',
        url: '',
    };
    displayedColumns: string[] = ['fechaModificacion', 'tipoProtocolo', 'tipoComponente', 'marcaComponente', 'modeloComponente', 'idComponentes', 'tipoLubricante', 'Responsable'];
    dataSource = new MatTableDataSource<LimitesModels.ProtocolSpecific>([]);
    model: LimitesViewModels.ViewModelEspecifico = {
        ensayos: [],
        clientes: [],
        components: [],
        filteredEnsayos: [],
        isComponent: false,
        isLubricant: false,
        loadingClientes: false,
        loadingComponents: false,
        loadingLubricants: false,
        loadingProtocols: false,
        pagination: { page: 1, pageSize: 10, totalElements: 0 },
        protocolos: { lista: [], auxLista: [] },
        selectedAssays: undefined,
        selectedType: undefined,
        faenas: { lista: [], auxLista: [] },
        idComponentes: { lista: [], auxLista: [] },
        lubricante: { lista: [], auxLista: [] },
        marcaComponente: { lista: [], auxLista: [] },
        marcaEquipo: { lista: [], auxLista: [] },
        modeloComponente: { lista: [], auxLista: [] },
        modeloEquipo: { lista: [], auxLista: [] },
        selectFaena: [],
        selectIdComponentes: [],
        selectLubricante: undefined,
        selectMarcaComponente: undefined,
        selectMarcaEquipo: undefined,
        selectModeloComponente: undefined,
        selecModeloEquipo: undefined,
        selectTipoComponente: undefined,
        selectTipoEquipo: undefined,
        selectTipoProtocolo: { value: 'componente', tag: 'Componente' },
        tipoComponente: { lista: [], auxLista: [] },
        tipoEquipo: { lista: [], auxLista: [] },
        tipoProtocolo: { lista: [], auxLista: [] }
    }

    constructor(private services: LimitesServices, private authenticationService: AuthenticationService, private storageService: StorageService, private dialog: MatDialog, private snackBarSV: SnackBarService) { }

    ngOnInit(): void {
        this.model.tipoProtocolo.lista = [{ value: 'componente', tag: 'Componente' }, { value: 'lubricante', tag: 'Lubricante' }];
        this.model.selectTipoProtocolo = this.model.tipoProtocolo.lista[0];
        this.model.faenas.lista = this.authenticationService.currentUserValue.clientes.find(x => x.clienteId === this.authenticationService.currentClientValue.clienteId)?.faenas || [];
        this.model.selectFaena = this.model.faenas.lista.filter(x => x.faenaId === this.authenticationService.currentClientValue.faena.faenaId);
        this.cargarDatos();
        this.cargarComponentesId();
        this.cargarProtocolos();
        this.cargarEnsayos();
    }

    cargarDatos() {
        this.model.loadingClientes = true;
        this.model.loadingComponents = true;
        this.model.loadingLubricants = true;
        this.model.loadingProtocols = true;

        Promise.all([
            this.cargarLubricantes(),
            this.cargarTipoComponentes(),
            this.cargarTipoEquipos(),

        ]).then(() => {
            this.model.loadingClientes = false;
            this.model.loadingComponents = false;
            this.model.loadingLubricants = false;
            this.model.loadingProtocols = false;
        }).catch((error) => {
        });
    }

    cargarEnsayos() {
        this.services.GetEnsayos2({}).then((response) => {
            if (response.success && response.code === 200) {
                this.storageService.setData('ensayos', response.data);
            }
        }).finally(() => {
            return;
        });
    }

    cargarTipoComponentes(): Promise<void> {
        this.model.loadingComponents = true;
        return new Promise((resolve, reject) => {
            let dataLocalStorageTipo = this.storageService.getData('tiposComponentes');
            let dataLocalStorageMarca = this.storageService.getData('marcasComponentes');
            if (dataLocalStorageTipo && dataLocalStorageMarca) {
                this.model.tipoComponente.lista = dataLocalStorageTipo;
                this.model.marcaComponente.lista = dataLocalStorageMarca;
                resolve();
                return;
            }
            this.model.loadingLubricants = true;
            this.services.GetTiposComponentes({}).subscribe(
                (response) => {
                    if (response.success && response.code === 200) {
                        this.model.tipoComponente.lista = response.data.tiposComponentes;
                        this.model.marcaComponente.lista = response.data.marcas;
                        this.storageService.setData('tiposComponentes', response.data.tiposComponentes);
                        this.storageService.setData('marcasComponentes', response.data.marcas);
                        resolve();
                    } else if (response.code === 500) {
                        reject(new Error('Error en la respuesta del servicio'));
                    }
                },
                (error) => {
                    reject(error);
                },
                () => {
                    this.model.loadingComponents = false;
                    resolve();
                }

            );
        });
    }

    cargarTipoEquipos(): Promise<void> {
        return new Promise((resolve, reject) => {
            let dataLocalStorageTipo = this.storageService.getData('tiposEquipos');
            let dataLocalStorageMarca = this.storageService.getData('marcasEquipos');

            if (dataLocalStorageTipo && dataLocalStorageMarca) {
                this.model.tipoEquipo.lista = dataLocalStorageTipo;
                this.model.marcaEquipo.lista = dataLocalStorageMarca;
                resolve();
                return;
            }
            this.model.loadingComponents = true;
            this.services.GetTiposEquipos({}).subscribe(
                (response) => {
                    if (response.success && response.code === 200) {
                        this.model.tipoEquipo.lista = response.data.tiposEquipos;
                        this.model.marcaEquipo.lista = response.data.marcas;
                        this.storageService.setData('tiposEquipos', response.data.tiposEquipos);
                        this.storageService.setData('marcasEquipos', response.data.marcas);
                        resolve();
                    } else if (response.code === 500) {
                        reject(new Error('Error en la respuesta del servicio'));
                    }
                },
                (error) => {
                    reject(error);
                },
                () => {
                    this.model.loadingComponents = false;
                    resolve();
                }
            )
        });
    }

    cargarLubricantes(): Promise<void> {
        return new Promise((resolve, reject) => {
            let dataLocalStorage = this.storageService.getData('categoriasLubricantes');
            if (dataLocalStorage) {
                this.model.lubricante.lista = dataLocalStorage;
                resolve();
                return;
            }

            this.model.loadingLubricants = true;
            this.services.GetCategoryLubricants({}).subscribe(
                (response) => {
                    if (response.success && response.code === 200) {
                        this.model.lubricante.lista = response.data;
                        this.storageService.setData('categoriasLubricantes', response.data);
                        resolve();
                    } else if (response.code === 500) {
                        reject(new Error('Error en la respuesta del servicio'));
                    }
                },
                (error) => {
                    reject(error);
                },
                () => {
                    // Esta función se ejecuta cuando el observable ha terminado de emitir valores
                    this.model.loadingLubricants = false;
                    resolve();
                }
            );
        });
    }


    async cargarProtocolos() {
        await this.getProtocolos();
        this.filtroProtocolos();

    }

    async getProtocolos() {
        const request: LimitesModels.GetProtocolsSpecificRequest = {
            clienteId: this.authenticationService.currentClientValue.clienteId,
            faenaId: '0',
        };
        const response = await this.services.GetProtocolsSpecific(request).toPromise();
        if (response.success && response.code === 200) {
            this.model.protocolos.lista = response.data.filter(data => this.model.faenas.lista.some(f => data.faenaId.includes(f.faenaId)));
            this.dataSource.data = this.model.protocolos.lista;

        }

    }


    async cargarComponentesId() {
        for (const faena of this.model.faenas.lista) {
            await this.getComponenteFaena(faena.faenaId);
        }
        this.filtrarComponentes();

    }
    async getComponenteFaena(faenaId: string) {
        const request: LimitesModels.GetComponentesRequest = {
            clienteId: this.authenticationService.currentClientValue.clienteId,
            faenaId: faenaId
        };
        const response = await this.services.GetComponentes(request).toPromise();
        if (response.success && response.code === 200) {
            this.model.idComponentes.lista.push(...response.data);
        }

    }

    filtrarComponentes() {
        if (this.model.selectFaena.length === 0) {
            this.model.idComponentes.auxLista = this.model.idComponentes.lista;
            return;
        }
        this.model.idComponentes.auxLista = this.model.idComponentes.lista.filter(x => this.model.selectFaena.some(f => f.faenaId === x.faenaId));
        let selectAux: LimitesModels.Componente[] | undefined = JSON.parse(JSON.stringify(this.model.selectIdComponentes));
        selectAux?.forEach(x => {
            if (!this.model.idComponentes.auxLista.some(f => f.componenteId === x.componenteId)) {
                this.model.selectIdComponentes = this.model.selectIdComponentes?.filter(f => f.componenteId !== x.componenteId);
            }
        });
    }


    validaString(valor: string | string[] | undefined): string[] {
        if (!valor) return [];
        return typeof valor === 'string' ? [valor] : valor;
    }

    nombrarC(compo: string) {
        let components = this.model.tipoComponente.lista.filter(c => compo === c.tipoComponenteId)
        if (components.length > 0) {
            return components[0].nombre
        }
        return compo
    }
    nombrarE(equ: string) {

        let equipos = this.model.tipoEquipo.lista.filter(e => equ === e.tipoEquipoId)
        if (equipos.length > 0) { return equipos[0].nombre }

        return equ


    }
    nombrarF(faena: string) {

        let faenas = this.model.faenas.lista.filter(f => faena === f.faenaId)
        if (faenas.length > 0) { return faenas[0].nombre }

        return faena


    }
    nombreCatLub(categoria: string) {
        const cat_paso = this.model.lubricante.lista.find(x => x.categoriaLubricanteId === categoria);
        return cat_paso?.nombre ?? '';
    }

    protocoloModal(protocolo?: LimitesModels.ProtocolSpecific) {
        const dialogRef = this.dialog.open(NewEditLimitesComponent, {
            width: '80%',
            hasBackdrop: true,
            disableClose: true,
            data: {
                protocol: protocolo,
                idComponentes: this.model.idComponentes.lista,
            }
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result && result != 'error') {
                this.cargarProtocolos();
            }
        });

    }

    generarReporte() {
        this.printedLoading = true;
        this.generation = true;
        this.printed = true;
        let reporte: LimitesModels.GetReporteRequest = {
            trx_report: ''
        }
        const request: LimitesModels.SetReporteRequest = {
            clienteId: this.authenticationService.currentClientValue.clienteId,
            tag: 'webCliente'
        }

        this.services.SetReporte(request).then(response => {
            if (response.success && response.code === 200) {
                reporte = response.data;
                this.timeout=0;
                this.verificarEstadoReporte(reporte);

            }

            if (response.success && response.code === 500) {
                this.printedLoading = false;
                this.generation = false;
                this.printed = false;
                this.snackBarSV.danger('Error al generar reporte');
            }
        }).catch(error => {
            this.printedLoading = false;
            this.generation = false;
            this.printed = false;
            this.snackBarSV.danger('Error al generar reporte');
        })

    }

    verificarEstadoReporte(reporte: LimitesModels.GetReporteRequest) {
        this.services.GetReporte(reporte).then(response => {
            if (response.code === 200) {
                if (response.data.trx_complete) {
                    this.informe = response.data;
                    if (this.informe.is_empty) {
                        this.snackBarSV.warning('No se encontraron datos para generar el reporte');
                            this.generation = false;
                            this.printed = false;
                            this.informe = {
                                trx_complete: false,
                                file_name: '',
                                url: '',
                            };

                    }
                    if (this.informe.url) {
                        this.snackBarSV.success('Reporte generado con éxito');
                        this.printedLoading = false;
                    }
                } else {
                    if (this.timeout < 6) {
                        this.timeout++
                        setTimeout(() => {
                            this.verificarEstadoReporte(reporte);
                        }, 9000);
                    }
                    else {
                        this.snackBarSV.danger('Error al generar reporte');
                        this.printedLoading = false;
                        this.generation = false;
                        this.printed = false;
                        this.informe = {
                            trx_complete: false,
                            file_name: '',
                            url: '',
                        };
                    }
                }
            }
        }).catch(error => {
            this.snackBarSV.danger('Error al generar reporte');
        })
    }

    descargarReporte() {
        window.open(this.informe.url, '_blank');
        this.printedLoading = false;
        this.generation = false;
        this.printed = false;
        this.informe = {
            trx_complete: false,
            file_name: '',
            url: '',
        };
    }

    filtroProtocolos() {
        this.model.protocolos.auxLista = [...this.model.protocolos.lista];
        const filtros = [
            { condition: this.model.selectFaena.length > 0, filterFn: (x: LimitesModels.ProtocolSpecific) => this.model.selectFaena.some(f => x.faenaId.includes(f.faenaId)) },
            { condition: this.model.selectTipoProtocolo, filterFn: (x: LimitesModels.ProtocolSpecific) => x.tipo === this.model.selectTipoProtocolo.value },
            { condition: this.model.selectLubricante, filterFn: (x: LimitesModels.ProtocolSpecific) => x.categoriaLubricante != null && x.categoriaLubricante === this.model.selectLubricante?.categoriaLubricanteId },
            { condition: this.model.selectTipoEquipo, filterFn: (x: LimitesModels.ProtocolSpecific) => x.custom.tipoEquipo === this.model.selectTipoEquipo?.tipoEquipoId },
            { condition: this.model.selectMarcaEquipo, filterFn: (x: LimitesModels.ProtocolSpecific) => x.custom.marcaEquipo === this.model.selectMarcaEquipo?.nombre },
            { condition: this.model.selecModeloEquipo, filterFn: (x: LimitesModels.ProtocolSpecific) => x.custom.modeloEquipo === this.model.selecModeloEquipo },
            { condition: this.model.selectTipoComponente, filterFn: (x: LimitesModels.ProtocolSpecific) => x.custom.tipoComponente === this.model.selectTipoComponente?.tipoComponenteId },
            { condition: this.model.selectMarcaComponente, filterFn: (x: LimitesModels.ProtocolSpecific) => x.custom.marcaComponente === this.model.selectMarcaComponente?.nombre },
            { condition: this.model.selectModeloComponente, filterFn: (x: LimitesModels.ProtocolSpecific) => x.custom.modeloComponente === this.model.selectModeloComponente },
            { condition: this.model.selectIdComponentes.length > 0, filterFn: (x: LimitesModels.ProtocolSpecific) => this.model.selectIdComponentes.some(f => x.custom.componenteId?.length !== 0 && x.custom.componenteId?.includes(f.componenteId)) }
        ];

        filtros.forEach(({ condition, filterFn }) => {
            if (condition) this.model.protocolos.auxLista = this.model.protocolos.auxLista.filter(filterFn);
        });

        this.dataSource.data = this.model.protocolos.auxLista;
    }


}
