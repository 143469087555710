import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Tools } from "src/app/shared";
import { environment } from "src/environments/environment";
import { ServiceSubjectTime } from '../../shared/tools/service-subject-time.tools';
import { CondicionesComercialesModels } from "../condiciones-comerciales-models/condiciones-comerciales.models";
import { Observable } from 'rxjs';
import { ServiceSubjectTimeProvide } from "src/app/shared/tools/service-subject-provider.tools";

@Injectable({
    providedIn: 'root'
})
export class CondicionesComercialesService {

    subjetGetCondicionComercial: ServiceSubjectTime<
        CondicionesComercialesModels.GetCondicionComercialRequest,
        CondicionesComercialesModels.GetCondicionComercialResponse
    >;
    subjetGetPlans: ServiceSubjectTime<
        CondicionesComercialesModels.GetPlanesAnalisisRequest,
        CondicionesComercialesModels.GetPlanesAnalisisResponse
    >;

    constructor(private http: HttpClient, private tools: Tools, serviceSubject: ServiceSubjectTimeProvide) {
        this.subjetGetCondicionComercial = new ServiceSubjectTime(
            `${environment.apiUrl}${environment.routes.getCondicionComercial}`,
            http,
            tools
        );
        this.subjetGetPlans = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.plananalisis}`);
    }

    getCondicionComercial(
        request: CondicionesComercialesModels.GetCondicionComercialRequest,
        force: boolean = false
    ): Observable<CondicionesComercialesModels.GetCondicionComercialResponse> {
        return this.subjetGetCondicionComercial.httpGet(request, force);
    }

    getPlanesAnalisis(
        request: CondicionesComercialesModels.GetPlanesAnalisisRequest,
        opts: { force: boolean; fnCallback?: () => {}; spinner: boolean } = { force: false, spinner: false }
    ): Observable<CondicionesComercialesModels.GetPlanesAnalisisResponse> {
        return this.subjetGetPlans._httpGet(request, opts);
    }


}
