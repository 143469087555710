import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ChartType, Row } from 'angular-google-charts';
import { ReportsModels } from '../../../models/reports.models';

@Component({
  selector: 'alloyWear-chart',
  templateUrl: './alloyWear.component.html',
  styleUrls: ['./alloyWear.component.css']
})
export class AlloyWearChartComponent implements OnInit {
   chart:any;
   imgSrc:string='';
   isLoading:boolean=true;
   @Input() isPrinting:boolean=false;
   @Input() data:Row[]=[
        ['1', 15.0, 16.40],
        ['2', 14.4,15.50],
        ['3', 14.5, 15.80],
        ['4', 14.6, 14.10],
        ['5', 15.5, 13.20]
     ];
     @Output() event = new EventEmitter<boolean>();


     configChart:ReportsModels.GoogleChart={
      title : 'Desgaste de aleación',
      type:ChartType.LineChart,
      data:[
        ['1', 0.2, 0.2, 0.2, 0.2],
        ['2', 1.2, 0.2, 0.2, 0.2],
        ['3', 0.2, 0.2, 0.2, 0.2],
        ['4', 0.5, 0.2, 0.2, 0.2],
        ['5', 0.9, 0.2, 0.2, 0.2]
       ],
       columnNames :['Pb', 'Al','Cr','Ni'],
       options : {

            pointSize: 5,
            hAxis: {
             gridlines: {
                count: 5
              }
            },
            vAxis:{
              gridlines: {
                count: 7
              }
            },
            series: {
              0:{
                labelInLegend:'Pb'
              },
              1:{
                labelInLegend:'Al'
              },
              2:{
                labelInLegend:'Cr'
              },
              3: {
                labelInLegend:'Ni'
              }
            },
            legend: { position: 'bottom',textStyle: {fontSize: 5}},
            chartArea: {
              left: '20%', width: '70%', height: '50%',right :'10%'
            },
          },
          width : 150,
          height : 150,
    };
  constructor() { }

  ngOnInit(): void {
  }
  onReadypqFeCuChart(event:any){
    this.imgSrc=event.chart.getImageURI();
    this.isLoading=false;
    this.chart= event.chart;
  }
  ngDoCheck(){
    this.configChart.data=this.data;
  }
  onReadyImage(){
    this.event.emit(true);
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['isPrinting'] && this.chart){
      this.chart.setSelection([])
    }
  }
}
