import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { Objects } from 'src/app/shared/models/objects';

@Component({
    selector: 'app-modal-select-faena',
    templateUrl: './modal-select-faena.component.html',
    styleUrls: ['./modal-select-faena.component.css'],
})
export class ModalSelectFaenaComponent implements OnInit {
    clienteSeleccionado: Objects.Cliente = {} as Objects.Cliente;
    clientes: Objects.Cliente[] = [];
    faenaSeleccionada: Objects.Faena = {} as Objects.Faena;
    faenas: Objects.Faena[] = [];

    constructor(
        private authService: AuthenticationService,
        public dialogRef: MatDialogRef<ModalSelectFaenaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        console.log(this.data);
    }

    ngOnInit(): void {
        this.getClientes();

        this.selectClienteFaena();
    }

    getClientes() {
        this.clientes = this.authService.currentUserValue.clientes;
        if (this.clientes.length === 1) {
            this.clienteSeleccionado = this.clientes[0];
            this.getFaenas();
        }
    }

    getFaenas() {
        this.faenas = this.clienteSeleccionado.faenas;
        if (this.faenas.length === 1) {
            this.faenaSeleccionada = this.faenas[0];
        }
    }

    selectClienteFaena() {        
        if (this.data?.param.clienteId && this.data?.param.faenaId) {         
            this.clienteSeleccionado = this.authService.currentUserValue.clientes.find(cl => cl.clienteId === this.data?.param.clienteId)!;
            this.getFaenas();
            this.faenaSeleccionada = this.clienteSeleccionado?.faenas.find(f => f.faenaId === this.data?.param.faenaId)!;
            this.ingresar();
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    ingresar() {
        const { faenas, ...cliente } = this.clienteSeleccionado;
        this.authService.currentClientValue = { ...cliente, faena: this.faenaSeleccionada };
        location.reload();
    }
}
