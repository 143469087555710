<div [ngClass]="{'bloqueado': is_request_call }" class="container">

  <div class="content__head">
    <mat-spinner matSuffix [diameter]="18" class="center" *ngIf="is_request_call"></mat-spinner>
    <div class="content__title">
      <div class="d-flex justify-content-start align-items-center">
      <h3><strong>Editar muestra N° {{model.muestra.muestraId}}</strong></h3>
      <mat-chip-list class="chippill" *ngIf="model.muestra.estado"><mat-chip class="bg-primary chippill ">{{getEstadoMuestra(model.muestra.estado)}}</mat-chip></mat-chip-list>
      </div>

    </div>
  </div>
  <section class="content__container mt-5">
    <form action="" #muestraForm="ngForm">
      <div class="grid grid--3col mb-3">
        <div class="form__field">
          <label class="label mb-1 d-block">Fecha toma de muestra</label>
          <div class="form__range">
            <input type="date" class="form-control" onkeydown="return false" id="fechaMuestreo" name="fechaMuestreo" max="{{  todaysdate | date :'yyyy-MM-dd' }}" validarFecha [(ngModel)]="model.muestra.fechaMuestreo" #fechaMuestreo="ngModel" [ngClass]="{'is-invalid':fechaMuestreo.invalid
                    && (fechaMuestreo.dirty || fechaMuestreo.touched)}" required>
            <div *ngIf="fechaMuestreo.invalid && (fechaMuestreo.dirty || fechaMuestreo.touched)" class="text-danger">
              <div *ngIf="fechaMuestreo.errors?.required">
                Debe seleccionar la fecha de toma de muestra.
              </div>
            </div>
          </div>
        </div>
        <div class="form__field">
          <label class="form-label mb-1">Relleno <em>(Litros)</em></label>
          <div class="row">
              <div class="col">
                  <input type="text" id="rellenoDesdeUltimoCambio" name="rellenoDesdeUltimoCambio" maxlength="10" class="form-control" numeric decimals="0" [(ngModel)]="model.muestra.rellenoDesdeUltimoCambio">
              </div>
          </div>
        </div>
      </div>
      <div class="grid grid--2col mb-3">
        <div class="form__field d-flex gap-2 align-items-center">
          <div class="flex-fill">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <label class="label" for="name">Uso de componentes (Hrs/KM)</label>
            </div>
            <input maxlength="10" class="form-control" numeric decimals="0" id="usoTotalComponente" name="usoTotalComponente" [(ngModel)]="model.muestra.usoTotalComponente" #usoTotalComponente="ngModel">

          </div>
          <div>

            <div class="form__field">
              <mat-radio-group class="d-flex gap-2 mt-md-3 flex-column flex-md-row" id="unidadUsoTotalComponente" name="unidadUsoTotalComponente" [(ngModel)]="model.muestra.unidadUsoTotalComponente">
                <mat-radio-button color="primary" value="horas">Horas</mat-radio-button>
                <mat-radio-button color="primary" value="km">KM</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="form__field d-flex gap-2 align-items-center">
          <div class="flex-fill">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <label class="label">Uso de aceite (Hrs/KM)</label>
            </div>
            <input maxlength="10" class="form-control" numeric decimals="0" id="usoCambioLubricante" name="usoCambioLubricante" [(ngModel)]="model.muestra.usoCambioLubricante" #usoCambioLubricante="ngModel">
          </div>
          <div>
            <div class="form__field">
              <mat-radio-group class="d-flex gap-2 mt-md-3 flex-column flex-md-row" id="unidadUsoCambioLubricante" name="unidadUsoCambioLubricante" [(ngModel)]="model.muestra.unidadUsoCambioLubricante" #unidadUsoCambioLubricante="ngModel">
                <mat-radio-button color="primary" value="horas">Horas</mat-radio-button>
                <mat-radio-button color="primary" value="km">KM</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>

      </div>

      <div class="grid grid--3col mb-3" *ngIf="model.muestra.estado!='pending_approval'">
        <div class="form__field">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <label class="label">Plan de analisis</label>
            <mat-spinner *ngIf="loadingPlanAnalisis" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
          </div>
          <ng-select class="ng-select" [appendTo]="'body'" [clearable]="false" [disabled]="loadingPlanAnalisis" name="plan" #plan="ngModel" placeholder="Seleccionar" [(ngModel)]="selectedPlanAnalisis" required>
            <ng-option *ngFor="let or of opcionesPlanAnalisis" [value]="or">{{or.nombre}}</ng-option>
          </ng-select>
        </div>
        <div class="form__field">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <label class="label">Componente</label>
            <mat-spinner *ngIf="loadingComponente" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
          </div>
          <ng-select class="ng-select" [appendTo]="'body'" [clearable]="false" [disabled]="loadingComponente" (change)="changes()" name="componente" #componente="ngModel" placeholder="Seleccionar" [(ngModel)]="selectedComponente" required>
            <ng-option *ngFor="let or of opcionesComponentes" [value]="or">{{or.componenteId}}</ng-option>
          </ng-select>
        </div>

        <div class="form__field">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <label class="label">Lubricante</label>
            <mat-spinner *ngIf="loadingLubricante" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
          </div>
          <ng-select class="ng-select" [appendTo]="'body'" [clearable]="(model.muestra.lubricante?.lubricanteId==='0')" [disabled]="loadingLubricante" (change)="change_lubricante()" name="lubricante" #lubricante="ngModel" placeholder="Seleccionar" [(ngModel)]="selectedLubricante" required>
            <ng-option *ngFor="let or of opcionesLubricantes" [value]="or">{{or.nombre}}</ng-option>
          </ng-select>
        </div>

        <div class="form__field" *ngIf="selectedLubricante?.lubricanteId==='0' || !selectedLubricante ||false">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <label class="label">Otro lubricante</label>
            <mat-spinner *ngIf="loadingLubricante" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
          </div>
          <input type="text" id="otroLubricante" name="otroLubricante" class="form-control" maxlength="100" [(ngModel)]="model.muestra.otroLubricante" #otroLubricante="ngModel" [ngClass]="{'is-invalid':otroLubricante.invalid && (otroLubricante.dirty || otroLubricante.touched)}" readonly>
          <div *ngIf="otroLubricante.invalid && (otroLubricante.dirty || otroLubricante.touched)" class="text-danger">
            <div *ngIf="otroLubricante.errors?.required">
              Debe ingresar otro lubricante.
            </div>
          </div>
        </div>

      </div>

      <div class="alert alert-info text-start justify-content-start mb-3" role="alert" *ngIf="false">
        <div class="alert-message d-flex justify-content-start align-items-center gap-3">
          <em class="bi bi-exclamation-triangle-fill"></em>
          <p class="mb-0">Al editar el plan de análisis, o tipo de lubricante y/o componente la muestra volverá al estado anterior del actual.</p>
        </div>
      </div>
      <div class="d-flex">
        <div class="form__field w-100 mb-3">
          <label>Comentario (opcional)</label>
          <textarea name="comment" class="form-control" id="comment" autocomplete="off" [(ngModel)]="model.muestra.comentarioCliente"></textarea>
        </div>
      </div>
      <div class="alert alert-info text-start justify-content-start" role="alert" *ngIf="false">
        <div class="alert-message d-flex justify-content-start align-items-center gap-3">
          <em class="bi bi-exclamation-triangle-fill"></em>
          <p class="mb-0">Este comentario solo se podrá editar si la muestra esta en un estado reportado o pendiente de aprobación.</p>
        </div>
      </div>

      <div class="d-flex justify-content-end pt-5">
        <button class="btn btn-small btn-primary" (click)="updateSample()" [disabled]="muestraForm.invalid|| loadingLubricante|| loadingPlanAnalisis|| loadingComponente">
          Actualizar muestra
        </button>
      </div>
    </form>
  </section>
</div>
