import { Injectable } from '@angular/core';
import { Subject,  Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventEmitterService {

    listObserv: Array<{key:string,sub:Subject<any>}>=[];
    listSubscriptions: Array<{key:string,sub:Subscription}>=[];
    enumEvents={DESCARGA_CARTA:"DESCARGA_CARTA",ENVIO_CAJA_MUESTRA:"ENVIO_CAJA_MUESTRAS"};
    eventNames={
        UPDATED_SAMPLE:"UPDATED_SAMPLE",
        APROVE_REPORT:"APROVE_REPORT"
    };

    event(eventName:string):Subject<any>{
        let subjet = this.getEvent(eventName)
       if(!this.getEvent(eventName)){
           subjet = new Subject();
          this.listObserv.push({key:eventName,sub:subjet})
       }
       return subjet;
    }

    /**
     * @param eventName Nombre del evento
     * @param keySubcription llave unica del subscriptor
     * @param fn funcion del subscriptor
     * @returns retorna el subscritor creado
     */
    createSubcriberUnique(eventName:string,keySubcription:string,fn:any):Subscription{
       let subItem = this.listSubscriptions.find(s=>{s.key===keySubcription});
        if(!subItem){

            let subjet = this.getEvent(eventName);
            if(!subjet){
                subjet = new Subject();
               this.listObserv.push({key:eventName,sub:subjet})
            }
            let s=subjet.subscribe(fn);
            subItem={key:keySubcription,sub:s};
            this.listSubscriptions.push(subItem);
        }
       return subItem.sub;
    }

    getEvent(eventName:string):Subject<any>{
       return this.listObserv.filter(e=>e.key===eventName).map(e=>e.sub)[0];
    }

    sendResult(eventName:string,result:any){
        if(this.getEvent(eventName)){
            this.getEvent(eventName).next(result);
        }
    }

    completeEvent(eventName:string){
        let sel=this.listObserv.filter(e=>e.key===eventName);
        if(sel.length>0){
            sel[0].sub.complete();
            this.listObserv = this.listObserv.filter(e=>e.key!==eventName);
        }
    }

}
