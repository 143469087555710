export namespace Objects {
    export enum Role {
        Admin = 'Admin',
        Usuario = 'Usuario',
    }

    export interface Pagination {
        page: number;
        pageSize: number;
        totalElements: number;
    }

    export interface Faena {
        direccion: string;
        faenaId: string;
        nombre: string;
        numeroSap: string;
        rubro: string;
        transporte: string;
        dashboard_enabled?:boolean;
        dashboard_url?:string;
        responsable?:{
            nombre:string;
            phone:string;
            email:string;
        }
    }


    export interface UsuarioAws {
        apellido: string;
        email: string;
        nombre: string;
        phone: string;
        rut: string;
        userId: string;
    }

    export interface Cliente {
        clienteId: string;
        numeroSap: string;
        razonSocial: string;
        faenas: Faena[];
    }

    export interface CurrentClient {
        clienteId: string;
        numeroSap: string;
        razonSocial: string;
        faena: Faena;
    }

    export interface UserProfile {
        applicationId: string;
        birthdate: Date;
        email: string;
        email_verified: boolean;
        family_name: string;
        given_name: string;
        middle_name: string;
        name: string;
        phone_number: string;
        preferred_username: string;
        roles: string[];
        sub: string;
        access_token: string;
        rut: string;
        nombre: string;
        apellido: string;
        userId: string;
        phone: string;
        is_super_admin: boolean;
        proforma_enabled: boolean;
        clientes: Cliente[];
    }

    export interface CurrentUser {
        rut: string;
        phone: string;
        apellido: string;
        nombre: string;
        userId: string;
        email: string;
    }

    export interface Select {
        value: number;
        displayText: string;
    }

    export interface Request {
        version?: string;
    }

    export interface Response<T> {
        message: string;
        code: number;
        success: boolean;
        data: T;
        date?: Date;
    }

    export interface ItemTable {
        index: number;
        name: string;
        value: string;
    }
    export interface RowTable {
        columns: ItemTable[];
    }
    export interface Filter {
        name: string;
        visible: boolean;
    }

    export interface FilterDefinition {
        pageName: string;
        filters: Filter[];
    }

    export interface SampleBoxData {
        clienteId: string;
        faenaId: string;
        usuario?: Usuario;
        fechaCreacion: Date;
        fechaActualizacion: Date;
        muestras: RegistroMuestra[];
    }

    export interface RegistroMuestra {
        componente: Objects.Componente;
        fechaMuestreo: string;
        usoTotalComponente?: string;
        unidadUsoTotalComponente?: string;
        usoCambioLubricante?: string;
        unidadUsoCambioLubricante?: string;
        rellenoDesdeUltimoCambio?: string;
        muestraId: string;
        lubricante?: Lubricante;
        planAnalisis?: PlanAnalisis;
        otroLubricante: string;
        usuario?: Usuario;
        fechaIngreso?: Date;
        resultados?: Resultado[];
        comentarioCliente?: string;
    }
    export interface Resultado {
        ensayo: {
            code: string;
            ensayoId: string;
            nombre: string;
        };
        estado?: { status: string; color: string };
        fecha: string;
        hora: string;
        muestraId: string;
        resultadoId: string;
        usuario: Objects.Usuario;
        value: string;
        stamp?: string;
    }
    export interface Componente {
        equipo?: Equipo;
        componenteId: string;
        marca: string;
        modelo?: string;
        ubicacion?: string;
        clienteId: string;
        solicitante: string;
        tipoComponente: TipoComponente;
        planAnalisis: PlanAnalisis;
        lubricante: Lubricante;
        descriptor?: string;
    }
    export interface TipoComponente {
        tipoComponenteId: string;
        nombre: string;
    }
    export interface Equipo {
        equipoId: string;
        tipoEquipo: {
            tipoEquipoId: string;
            nombre: string;
        };
        marca?: string;
        modelo?: string;
        descriptor?: string;
        alias?: [string];
        tags?: [string];
    }

    export interface TipoComponente {
        tipoComponenteId: string;
        nombre: string;
    }
    export interface TipoLubricante {
        categoriaLubricanteId: string;
        nombre: string | undefined;
    }

    export interface Lubricante {
        lubricanteId: string;
        nombre: string;
        code: string;
        tipo: TipoLubricante | undefined;
    }
    export interface PlanAnalisis {
        nombre: string;
        planAnalisisId: string;
        code?: string;
        ensayos?:{
            code: string;
            ensayoId: string;
            nombre: string;
        }[];
        tiposComponentes?: TipoComponente[];
    }

    export interface Usuario {
        userId: string;
        nombre: string;
        apellido: string;
        rut: string;
        email: string;
        phone: string;
    }

    export interface TipoEquipo {
        tipoEquipoId: string;
        nombre: string;
    }

    export interface LogInput {
        elemento: string;
        tipoElemento: string;
        subElemento?: string;
        tipoSubElemento?: string;
        valor?: any;
        accion: string;
        fecha?: string;
        usuario: string;
        usuarioId: string;
        comentario?: string;
        ambiente: string;
    }

    export interface LogRequest {
        log: LogInput;
    }

    export interface LogResponse extends Response<LogInput[]> {}
}
