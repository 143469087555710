<div id="carta-autorizacion">
    <section class="carta" [id]="courier" [style.display]="'none'">
        <img src="../assets/images/logo-copec-110.png" alt="" id="logo-copec">
        <p class="izquierda">Santiago de Chile, {{model?.carta?.fecha | date : 'EEEE' }}, {{model?.carta?.fecha | date :'dd'}} de {{model?.carta?.fecha | date :'MMMM'}} de {{model?.carta?.fecha | date :'yyyy'}}</p>
        <p class="izquierda"><strong>Ref:</strong>Retiro / entrega de muestras de aceite usado</p>
        <p><strong>SEÑORES {{model.carta?.dirigidaA?.nombre}}</strong><br>
            At: Móvil de reparto y oficinas regionales </p>

        <p> Estimados Señores,</p>
        <p>Yo RAÚL PONCE VIVACETA, Jefe de laboratorio de Lubricantes COPEC, autorizo a {{model?.carta?.nombreUsuario | titlecase}}, Rut {{model?.carta?.rutUsuario}},. a realizar el envío de sus muestras de aceite usado a nuestras dependencias ubicadas en Camino Costero 1111, sector el Bato, Loncura,
            Quintero, con cargo a la empresa COMPAÑÍA DE PETRÓLEOS DE CHILE COPEC S.A., Rut 99.520.000-7, dirección principal (casa matriz) ubicada en Isidora Goyenechea 2915, Las Condes, Región Metropolitana.
        </p>
        <img src="../assets/images/carta-autorizacion-codigo.gif" alt="" id="codigo-barra">

        <p class="sin-otro-particular">Sin otro particular, le saluda atentamente,</p>
        
        <div id="datos-firma"> <img src="../assets/images/carta-autorizacion-firma.png" alt="" id="firma">
            <p><strong>Raúl Ponce Vivaceta</strong><br>
                Jefe de Laboratorios Lubricantes<br>
                Cía. de Petróleos de Chile COPEC S.A. </p>
            </div>
        </section>
    </div>
