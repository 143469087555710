<div class="fade show" id="modal-componente" tabindex="-1" aria-labelledby="modal-componente-label" style="display: block;" aria-modal="true" role="dialog">
  <form #registroUsuarioForm="ngForm">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" *ngIf="!model.estaEditando">Nuevo usuario</h5>
          <h5 class="modal-title" *ngIf="model.estaEditando">Editar usuario</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="cerrar()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <fieldset>
              <div class="mb-3">
                <label for="rut" class="form-label">Rut</label>
                <input type="text" id="rut" class="form-control" value="{{usuarioInput.usuario.rut}}" validacionRut
                  [(ngModel)]="model.usuario.rut" name="rut" required (keyup)="formatRut()" maxlength="10"
                  #rutUsuario="ngModel"
                  [ngClass]="{'is-invalid':rutUsuario.invalid && (rutUsuario.dirty || rutUsuario.touched)}">
                <div *ngIf="rutUsuario.invalid && (rutUsuario.dirty || rutUsuario.touched)" class="text-danger">
                    <div *ngIf="rutUsuario.errors?.required">
                        Debe ingresar rut del usuario.
                    </div>
                </div>
                <div *ngIf="model.errorRut" class="text-danger">
                    {{model.mensajeErrorRut}}
                </div>
                <div *ngIf="rutUsuario.value !=='' && rutUsuario.errors?.validacionRut" class="text-danger">
                  Rut inválido.
              </div>
              </div>
              <div class="mb-3">
                <label for="nombre" class="form-label">Nombre</label>
                <input type="text" id="nombre" class="form-control" value="{{usuarioInput.usuario.nombre}}" nameDirective
                  [(ngModel)]="model.usuario.nombre" name="nombre" required
                  #nombreUsuario="ngModel"  maxlength="100"
                  [ngClass]="{'is-invalid':nombreUsuario.invalid && (nombreUsuario.dirty || nombreUsuario.touched)}"
                  >
                <div *ngIf="nombreUsuario.invalid && (nombreUsuario.dirty || nombreUsuario.touched)" class="text-danger">
                    <div *ngIf="nombreUsuario.errors?.required">
                        Debe ingresar nombre del usuario.
                    </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="apellido" class="form-label">Apellido</label>
                <input type="text" id="apellido" class="form-control" value="{{usuarioInput.usuario.apellido}}" nameDirective
                  [(ngModel)]="model.usuario.apellido" name="apellido" required
                    #apellidoUsuario="ngModel" maxlength="100"
                    [ngClass]="{'is-invalid':apellidoUsuario.invalid && (apellidoUsuario.dirty || apellidoUsuario.touched)}"
                    >
                  <div *ngIf="apellidoUsuario.invalid && (apellidoUsuario.dirty || apellidoUsuario.touched)" class="text-danger">
                      <div *ngIf="apellidoUsuario.errors?.required">
                          Debe ingresar apellido del usuario.
                      </div>
                  </div>
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">Teléfono</label>
                <input type="text" id="phone" class="form-control" value="{{usuarioInput.usuario.phone}}"
                  [(ngModel)]="model.usuario.phone" name="phone" required phoneDirective
                  #telefonoUsuario="ngModel" maxlength="12" minlength="9"
                    [ngClass]="{'is-invalid':telefonoUsuario.invalid && (telefonoUsuario.dirty || telefonoUsuario.touched)}"
                    >
                  <div *ngIf="telefonoUsuario.invalid && (telefonoUsuario.dirty || telefonoUsuario.touched)" class="text-danger">
                      <div *ngIf="telefonoUsuario.errors?.required">
                          Debe ingresar teléfono del usuario.
                      </div>
                  </div>
              </div>

              <div class="mb-3" *ngIf="!model.desdeVerPerfil">
                <div class="mb-3" *ngIf="!model.estaEditando">
                  <label for="email" class="form-label">Email</label>
                  <mat-spinner *ngIf="model.loadingEmail" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                  <input type="text" id="email" class="form-control" value="{{usuarioInput.usuario.email}}" blockEmailSpecialCharacters
                    [(ngModel)]="model.usuario.email" name="email" required (keyup)="limpiaEmail()" (focusout)="validaEmail()"
                    #emailUsuario="ngModel" [readonly]="model.loadingEmail"
                    [ngClass]="{'is-invalid':emailUsuario.invalid && (emailUsuario.dirty || emailUsuario.touched)}"
                    >
                  <div *ngIf="emailUsuario.invalid && (emailUsuario.dirty || emailUsuario.touched)" class="text-danger">
                      <div *ngIf="emailUsuario.errors?.required">
                          Debe ingresar email del usuario.
                      </div>
                  </div>

                    <div *ngIf="model.errorEmail" class="text-danger">
                      {{model.mensajeErrorEmail}}
                  </div>
                </div>

                <div class="mb-3" *ngIf="model.estaEditando">
                  <label for="email" class="form-label">Email</label>
                  <br>
                  <label><strong>{{usuarioInput.usuario.email}}</strong></label>
                </div>

                <div class="form__field flex-50">
                  <label for="faena" class="form-label">Faena</label>
                  <mat-spinner *ngIf="model.loadingFaenas" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                  <ng-select
                      blockSpecialCharacters
                      #selectedFaena="ngModel"
                      class="ng-select"
                      name="faena"
                      placeholder="Seleccionar"
                      required
                      [ngClass]="{'is-invalid':selectedFaena.invalid && (selectedFaena.dirty || selectedFaena.touched)}"
                      [items]="model.faenas"
                      [disabled]="model.loadingFaenas"
                      [(ngModel)]="model.faenaSeleccionada"
                      [clearable]="false"
                      [clearSearchOnAdd]="true"
                      [closeOnSelect]="false"
                      [multiple]="true"
                      clearAllText="Clear"
                      bindLabel="nombre"
                  >
                    <ng-template ng-header-tmp>
                      <div class="py-2 d-flex align-items-center gap-1">
                        <input type="checkbox"id="selectAllHeader" #selectAllHeader (change)="selectAllChores(selectAllHeader.checked)" />
                        <label for="selectAllHeader">Seleccionar todos</label>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-flex align-items-center gap-1">
                          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                          {{item['faenaId']}} - {{item['nombre']}}
                      </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items">
                      <div *ngIf="items.length === 1">
                        <div class="ng-value" *ngFor="let item of items | slice:0:1">
                          {{$any(item)['nombre']}}
                        </div>
                      </div>
                      <div class="ng-value" *ngIf="items.length > 1">
                        <span class="ng-value-label">{{items.length}} Seleccionados</span>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>

              <div class="mb-3" *ngIf="model.desdeVerPerfil">
                <div class="mb-3">
                  <label class="form-label">Email</label>
                  <br>
                  <label><strong>{{model.usuario.email}}</strong></label>
                </div>

                <div class="mb-3">
                  <label class="form-label">Faena</label>
                  <br>
                  <div *ngFor="let faena of model.faenaSeleccionada">
                    <label><strong>{{faena.nombre}}</strong></label>
                  </div>
                </div>
              </div>

            </fieldset>
        </div>
        <div class="modal-footer">
          <div>
            <button type="button" class="btn btn-link" (click)="cerrar()" data-bs-dismiss="modal">Cancelar</button>

          </div>
          <button *ngIf="!model.estaEditando" type="button" (click)="crearUsuario()" class="btn btn-success" data-bs-dismiss="modal"
            [disabled]="registroUsuarioForm.invalid || model.errorEmail || model.errorRut">
            Crear usuario</button>
          <button *ngIf="model.estaEditando" type="button" (click)="editarUsuario()" class="btn btn-primary" data-bs-dismiss="modal"
            [disabled]="registroUsuarioForm.invalid || model.errorEmail || model.errorRut">
            Guardar cambios</button>
        </div>
    </div>
  </form>
</div>

