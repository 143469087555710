import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LimitesModels } from '../../mant-limites-models/limites.models';
import { LimitesViewModels } from '../../mant-limites-models/limites.view.models';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectEnsayosComponent } from '../select-ensayos/select-ensayos.component';
import { LimitesServices } from '../../mant-limites-services/limites.services';
import { MatModalConfirmComponent } from 'src/app/shared/components/mat-dialog-content/mat-dialog-content.component';
import { SnackBarService } from 'src/app/shared/services/snackBar.service';
import { NgForm } from '@angular/forms';
import { LogService } from 'src/app/shared/services/log.service';
import { Objects } from 'src/app/shared';


@Component({
    selector: 'app-new-edit-limites',
    templateUrl: './new-edit-limites.component.html',
    styleUrls: ['./new-edit-limites.component.css'],
})
export class NewEditLimitesComponent implements OnInit {
    @ViewChild('formLimites') formLimites!: NgForm;
    model: LimitesViewModels.ViewModelEspecificoNew = {
        ensayos: [],
        clientes: [],
        components: [],
        faenas: { lista: [], auxLista: [] },
        idComponentes: { lista: [], auxLista: [] },
        isComponent: false,
        isEdit: false,
        isLubricant: false,
        loadingClientes: false,
        loadingComponents: false,
        loadingLubricants: false,
        loadingProtocols: false,
        lubricante: { lista: [], auxLista: [] },
        marcaComponente: { lista: [], auxLista: [] },
        marcaEquipo: { lista: [], auxLista: [] },
        modeloComponente: { lista: [], auxLista: [] },
        modeloEquipo: { lista: [], auxLista: [] },
        pagination: { page: 1, pageSize: 10, totalElements: 0 },
        protocolo: {protocoloPersonalizadoId:'',fecha:'',hora:'',usuario:{userId:'',nombre:'',correo:''},tipo:'',tipoComponente:'',categoriaLubricante:'',limites:[],custom:{},clienteId:'',faenaId:[]},
        selectFaena: [],
        selectIdComponentes: [],
        selectLubricante: undefined,
        selectMarcaComponente: undefined,
        selectMarcaEquipo: undefined,
        selectModeloComponente: undefined,
        selecModeloEquipo: undefined,
        selectTipoComponente: undefined,
        selectTipoEquipo: undefined,
        selectTipoProtocolo: undefined,
        selectedAssays: undefined,
        selectedType: undefined,
        tipoComponente: { lista: [], auxLista: [] },
        tipoEquipo: { lista: [], auxLista: [] },
        tipoProtocolo: { lista: [], auxLista: [] },
        filteredEnsayo: [],
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { protocol?: LimitesModels.ProtocolSpecific; idComponentes: LimitesModels.Componente[] },
        private authenticationService: AuthenticationService,
        private services: LimitesServices,
        private storageService: StorageService,
        public dialogRef: MatDialogRef<NewEditLimitesComponent>,
        public modalService: NgbModal,
        public dialog:MatDialog,
        private snackbarService: SnackBarService,
        private _logService:LogService
    ) {
        if (data.protocol) {
            this.model.protocolo = JSON.parse(JSON.stringify(data.protocol));
            this.model.isEdit = true;
        }
        this.model.idComponentes.lista = this.model.idComponentes.auxLista = data.idComponentes;
        this.model.tipoEquipo.lista = this.model.tipoEquipo.auxLista = this.storageService.getData('tiposEquipos');
        this.model.marcaEquipo.lista = this.model.marcaEquipo.auxLista = this.storageService.getData('marcasEquipos');
        this.model.tipoComponente.lista = this.model.tipoComponente.auxLista = this.storageService.getData('tiposComponentes');
        this.model.marcaComponente.lista = this.model.marcaComponente.auxLista = this.storageService.getData('marcasComponentes');
        this.model.lubricante.lista = this.model.lubricante.auxLista = this.storageService.getData('categoriasLubricantes');
        this.model.tipoProtocolo.lista = [
            { value: 'componente', tag: 'Componente' },
            { value: 'lubricante', tag: 'Lubricante' },
        ];
        this.model.selectTipoProtocolo=this.model.tipoProtocolo.lista[0];
        this.model.faenas.lista = this.authenticationService.currentUserValue.clientes.find(
            (c) => c.clienteId == this.authenticationService.currentClientValue.clienteId
        )!.faenas;
        this.model.ensayos = this.storageService.getData('ensayos');

    }

    ngOnInit(): void {
        if (this.model.isEdit) {
            this.model.selectTipoProtocolo = this.model.tipoProtocolo.lista.find((t) => t.value == this.model.protocolo?.tipo);
            this.model.selectTipoEquipo = this.model.tipoEquipo.lista.find((t) => t.tipoEquipoId == this.model.protocolo?.custom.tipoEquipo);
            this.model.selectMarcaEquipo = this.model.marcaEquipo.lista.find((m) => m.marcaId == this.model.protocolo?.custom.marcaEquipo);
            this.model.selecModeloEquipo = this.model.selectMarcaEquipo?.modelo.find((m) => m == this.model.protocolo?.custom.modeloEquipo);
            this.model.selectTipoComponente = this.model.tipoComponente.lista.find(
                (t) => t.tipoComponenteId == this.model.protocolo?.custom.tipoComponente
            );
            this.model.selectMarcaComponente = this.model.marcaComponente.lista.find(
                (m) => m.marcaId == this.model.protocolo?.custom.marcaComponente
            );
            this.model.selectModeloComponente = this.model.selectMarcaComponente?.modelo.find(
                (m) => m == this.model.protocolo?.custom.modeloComponente
            );
            this.model.selectLubricante = this.model.lubricante.lista.find(
                (l) => l.categoriaLubricanteId == (this.model.protocolo?.categoriaLubricante ?? '')
            );
            this.model.selectIdComponentes = this.model.idComponentes.lista.filter((c) =>
                this.model.protocolo?.custom?.componenteId?.includes(c.componenteId)
            );
            this.model.selectFaena = this.model.faenas.lista.filter((f) => this.model.protocolo?.faenaId?.includes(f.faenaId));
        }
        this.quitarTipoNoValido();
        this.filtrarLimitesTipo();
    }

    filtrarComponentes() {
        if (this.model.selectFaena.length === 0) {
            this.model.idComponentes.auxLista = this.model.idComponentes.lista;
            return;
        }
        this.model.idComponentes.auxLista = this.model.idComponentes.lista.filter((x) =>
            this.model.selectFaena.some((f) => f.faenaId === x.faenaId)
        );
        let selectAux: LimitesModels.Componente[] | undefined = JSON.parse(JSON.stringify(this.model.selectIdComponentes));
        selectAux?.forEach((x) => {
            if (!this.model.idComponentes.auxLista.some((f) => f.componenteId === x.componenteId)) {
                this.model.selectIdComponentes = this.model.selectIdComponentes?.filter((f) => f.componenteId !== x.componenteId);
            }
        });
    }

    validarAlertas(id: string) {
        const limite = this.model.protocolo?.limites.find((x) => x.ensayoId == id);
        if (limite) {
            return limite.min !== '' && limite.max !== '' && parseFloat(limite.min) <= parseFloat(limite.max);
        }
        return false;
    }

    validarPrecauciones(id: string) {
        const limite = this.model.protocolo?.limites.find((x) => x.ensayoId == id);
        if (limite) {
            if (limite.allowMin != null && limite.allowMax != null) {
                return parseFloat(limite.allowMin) <= parseFloat(limite.allowMax);
            } else {
                return true;
            }
        }
        return false;
    }

    validarAlertasPrecauciones(id: string) {
        const limite = this.model.protocolo?.limites.find((x) => x.ensayoId == id);
        if (limite) {
            if ((limite.allowMin == '0' || limite.allowMin == '') && (limite.allowMax == '0' || limite.allowMax == '')) {
                return true;
            }

            let valid = true;
            if (limite.allowMin != null && limite.allowMin != '0' && limite.allowMin != '') {
                valid =
                    valid && parseFloat(limite.min) <= parseFloat(limite.allowMin) && parseFloat(limite.allowMin) <= parseFloat(limite.max);
            }
            if (limite.allowMax != null && limite.allowMax != '0' && limite.allowMax != '') {
                valid =
                    valid && parseFloat(limite.min) <= parseFloat(limite.allowMax) && parseFloat(limite.allowMax) <= parseFloat(limite.max);
            }
            return valid;
        }
        return false;
    }

    quitarTipoNoValido() {
        const tiposValidos = ['integer', 'float'];
        this.model.ensayos = this.model.ensayos.filter((x) => tiposValidos.includes(x.tipoDato?.tipo ?? ''));
        const ensayosValidosIds = new Set(this.model.ensayos.map((x) => x.ensayoId));
        this.model.protocolo!.limites = this.model.protocolo?.limites.filter((x) => ensayosValidosIds.has(x.ensayoId)) ?? [];
    }

    tipoDato(ensayoId: string) {
        return this.model.ensayos.find((x) => x.ensayoId == ensayoId)?.tipoDato?.tipo
            ? this.model.ensayos.find((x) => x.ensayoId == ensayoId)?.tipoDato?.tipo
            : false;
    }

    quitarCeros(ensayoId: string, tipo: 'integer' | 'float', string: 'min' | 'max' | 'allowMin' | 'allowMax') {
        const limite = this.model.protocolo?.limites.find((x) => x.ensayoId == ensayoId);
        if (limite) {
            if (tipo == 'integer') {
                limite[string] = parseInt(limite[string]).toString();
            }
            if (tipo == 'float') {
                limite[string] = parseFloat(limite[string]).toString();
            }
        }
    }

    quitarEnsayo(id: string) {
        this.model.protocolo!.limites = this.model.protocolo?.limites.filter((x) => x.ensayoId != id) ?? [];
    }

    nombrarEnsayo(id: string) {
        return this.model.ensayos.find((x) => x.ensayoId == id)
    }

    selecionaIdComponente() {
        this.model.selecModeloEquipo = undefined;
        this.model.selectMarcaEquipo = undefined;
        this.model.selectMarcaComponente = undefined;
        this.model.selectModeloComponente = undefined;
        this.model.selectTipoComponente = undefined;
        this.model.selectTipoEquipo = undefined;
    }


    get filtrarEnsayosTipo() {
        return this.model.ensayos.filter((x) => x.categoria == this.model.selectTipoProtocolo?.value);
    }
    filtrarLimitesTipo() {
        this.model.protocolo!.limites = this.model.protocolo?.limites.filter((x) => this.filtrarEnsayosTipo.map((y) => y.ensayoId).includes(x.ensayoId)) ?? []
    }

    selectEnsayos() {
        this.model.filteredEnsayo = this.filtrarEnsayosTipo
        this.updateEnsayosCheck();
        const data: LimitesModels.EnsayosListCheck = {
            ensayosCheck: this.model.filteredEnsayo,
            ensayosSeleccionados: [],
        };
        if (data) {
            this.openModal(data);
        }
    }

    updateEnsayosCheck() {
        this.model.filteredEnsayo.forEach((x) => {
            x.check = !!this.model.protocolo?.limites.find((y) => y.ensayoId == x.ensayoId);
        });
    }

    openModal(data: LimitesModels.EnsayosListCheck) {
        const modalRef = this.modalService.open(SelectEnsayosComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            container: '#modal__new-limit',
        });

        modalRef.componentInstance.data = data;
        modalRef.result.then((resul) => {
            if (resul) {
                this.updateLimites(resul);
            }
        });
    }

    updateLimites(resultados: LimitesModels.EnsayoCheck[]) {
        const limitesOld: LimitesModels.LimitesProtocol[] = JSON.parse(JSON.stringify(this.model.protocolo?.limites));
        const limitesActual: LimitesModels.LimitesProtocol[] = [];

        resultados.forEach((x) => {
            const limiteOld = limitesOld.find((y) => y.ensayoId == x.ensayoId);
            limitesActual.push(limiteOld ?? this.createNewLimite(x.ensayoId));
        });

        this.addNewLimites(limitesActual);
        this.removeOldLimites(limitesActual);
    }

    createNewLimite(ensayoId: string): LimitesModels.LimitesProtocol {
        return {
            ensayoId,
            max: '0',
            min: '0',
            allowMax: '0',
            allowMin: '0',
        };
    }

    addNewLimites(limitesActual: LimitesModels.LimitesProtocol[]) {
        limitesActual.forEach((x) => {
            if (!this.model.protocolo?.limites.some((y) => y.ensayoId == x.ensayoId)) {
                this.model.protocolo?.limites.push(x);
            }
        });
    }

    removeOldLimites(limitesActual: LimitesModels.LimitesProtocol[]) {
        this.model.protocolo!.limites = this.model.protocolo?.limites.filter((x) =>
            limitesActual.some((y) => y.ensayoId == x.ensayoId)
        ) ?? [];
    }

    deleteModal() {
        let title = 'Eliminar protocolo';
        let pregunta = '¿Está seguro de eliminar los limites?';
        let titleButtonOK = 'Eliminar';
        let titleButtonCancel = 'Cancelar';


        const dialogRef = this.dialog.open(MatModalConfirmComponent, {
            data: {
                title: title,
                msg: pregunta,
                titleButtonOK: titleButtonOK,
                titleButtonCancel: titleButtonCancel,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.delete();
            }
        });
    }

    delete() {
        const req: LimitesModels.DelPersoRequest = {
            protocoloPersonalizadoId: this.model.protocolo?.protocoloPersonalizadoId ?? '',
            clienteId: this.authenticationService.currentClientValue.clienteId,
            faenaId: this.model.protocolo?.faenaId ?? [],
            nombre: this.authenticationService.currentUserValue.nombre + ' ' + this.authenticationService.currentUserValue.apellido,
            userId: this.authenticationService.currentUserValue.userId,
            correo: this.authenticationService.currentUserValue.email,
            ambiente: 'Web Cliente',
        };

        this.services.DeletePerso(req).then((response) => {
            if (response.success && response.code == 200) {
                this.snackbarService.success('Límites eliminados');
                this.dialogRef.close('delete');
            }
            if (response.success && response.code == 500) {
                this.snackbarService.warning('Error al eliminar limites');

                this.dialogRef.close('error');
            }
        }).finally(() => {
            let rq: Objects.LogInput = {
                accion: 'Eliminar',
                elemento: this.model.protocolo?.protocoloPersonalizadoId!,
                tipoElemento: 'Protocolo especifico',
                usuario: this.authenticationService.usuario.nombre+' '+this.authenticationService.usuario.apellido,
                usuarioId: this.authenticationService.usuario.userId,
                ambiente: 'Web Cliente'
            }
            this._logService.createLog(rq, true);
        });
    }

    guardar() {
        const { nombre, apellido } = this.authenticationService.currentUserValue;
        const req: LimitesModels.SetProtocolSpecificRequest = {
            protocol: {
                categoriaLubricante: this.model.selectLubricante?.categoriaLubricanteId??'',
                clienteId: this.authenticationService.currentClientValue.clienteId,
                custom: {
                    componenteId: this.model.selectIdComponentes.map((x) => x.componenteId),
                    marcaComponente: this.model.selectMarcaComponente?.nombre ,
                    marcaEquipo: this.model.selectMarcaEquipo?.nombre ,
                    modeloComponente: this.model.selectModeloComponente ,
                    modeloEquipo: this.model.selecModeloEquipo ,
                    tipoComponente: this.model.selectTipoComponente?.tipoComponenteId ,
                    tipoEquipo: this.model.selectTipoEquipo?.tipoEquipoId,
                },
                faenaId: this.model.selectFaena.map((x) => x.faenaId),
                fecha: '',
                hora: '',
                limites: this.model.protocolo?.limites ?? [],
                tipo: this.model.selectTipoProtocolo?.value ?? '',
                tipoComponente: this.model.selectTipoComponente?.tipoComponenteId??'',
                usuario: {
                    nombre: `${nombre ?? ''} ${apellido ?? ''}`,
                    userId: this.authenticationService.currentUserValue.userId,
                    correo: this.authenticationService.currentUserValue.email,
                },
                protocoloPersonalizadoId: this.model.protocolo?.protocoloPersonalizadoId ?? '',
                ambiente: 'Web Cliente',
            },

        }

        this.services.SetProtocolSpecific(req).then((response) => {
            if (response.success && response.code == 200) {
                this.snackbarService.success('Límites guardados');
                this.dialogRef.close('save');
            }
            if (response.success && response.code == 500) {
                this.snackbarService.warning('Error al guardar');
                this.dialogRef.close('error');
            }
        }
        ).catch((error) => {
            this.snackbarService.warning('Error al guardar');
            this.dialogRef.close('error');
        }
        ).finally(() => {
            let rq: Objects.LogInput = {
                accion: this.model.isEdit ? 'Editar' : 'Crear',
                elemento: this.model.protocolo?.protocoloPersonalizadoId??'nuevo protocolo',
                tipoElemento: 'Protocolo especifico',
                usuario: `${nombre ?? ''} ${apellido ?? ''}`,
                usuarioId: this.authenticationService.usuario.userId,
                ambiente: 'Web Cliente'
            }
            this._logService.createLog(rq, true);
        });
    }

    canSave(): boolean {
        if (!this.formLimites) {
            return false;
        }

        const isLubricanteSelected = this.model.selectTipoProtocolo?.value === 'lubricante';
        const isLubricanteValid = isLubricanteSelected ? Boolean(this.model.selectLubricante) : true;

        return (
            this.formLimites.form.valid &&
            Boolean(this.model.selectFaena.length>0) &&
            Boolean(this.model.protocolo &&
            this.model.protocolo?.limites?.length > 0) &&
            Boolean(this.model.selectIdComponentes.length > 0 || this.model.selectTipoComponente) &&
            Boolean(this.model.selectTipoProtocolo) &&
            isLubricanteValid
            && Boolean(this.validarLimites())
        );
    }

    validarLimites() {
        let valor = true;
        this.model.protocolo?.limites.forEach((x) => {
            if (!this.validarAlertas(x.ensayoId) || !this.validarPrecauciones(x.ensayoId) || !this.validarAlertasPrecauciones(x.ensayoId)) {
                valor = false;
            }
        });
        return valor
    }

}
