import { HttpClient, HttpContext, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Tools } from "src/app/shared";
import { Enums } from 'src/app/shared/models/constants';
import { environment } from "src/environments/environment";
import { ComponenteVerModels } from "../componente-ver-models/componente-ver.models";

@Injectable({
    providedIn: 'root'
})
export class ComponenteVerService {

    constructor(
        private http: HttpClient,
        private tools: Tools
    ){}

    GetComponenteInfo(request: ComponenteVerModels.GetComponenteVerRequest): Promise<ComponenteVerModels.GetComponenteVerResponse> {
        return this.http.get<ComponenteVerModels.GetComponenteVerResponse>(`${environment.apiUrl}${environment.routes.component}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise(); 
    }

    GetEquipoComponente(request: ComponenteVerModels.GetEquipoComponenteVerRequest): Promise<ComponenteVerModels.GetEquipoComponenteVerResponse> {
        return this.http.get<ComponenteVerModels.GetEquipoComponenteVerResponse>(`${environment.apiUrl}${environment.routes.equipo}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    }

    DeleteComponente(request:ComponenteVerModels.DeleteComponenteRequest): Observable<ComponenteVerModels.DeleteComponeteResponse> {
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            }),
            body: {
                clienteId: request.clienteId,
                faenaId: request.faenaId,
                componenteId: request.componenteId
            }
        }
        return this.http.delete<ComponenteVerModels.DeleteComponeteResponse>(`${environment.apiUrl}${environment.routes.component}`, options);
    }
}