<div class="row mb-2 mb-xl-3" id="caja-de-muestras">
    <div class="col-md-6">
        <h3 id="caja-muestras-comprobante-title"><i class="bi bi-box-seam"></i> Caja de muestras</h3>
    </div>
</div>
<div id="caja-comprobante">
    <div class="row row-cols-1 justify-content-center" id="aviso">
        <div class="col-sm-12 col-md-10 col-lg-7 col-xl-5 col-xxl-4 mt-3 mt-sm-7 text-center">
            <i class="bi bi-box-seam"></i><i class="bi bi-check-circle-fill"></i>
            <h2>La caja de muestras está lista</h2>
            <p>La solicitud de an&aacute;lisis asignada es la n&uacute;mero <strong>{{model.data.numberOS}}</strong></p>
        </div>
        <div class="col text-center">
            <muestra-solicitud-de-analisis [solicitudAnalisisId]="model.data.numberOS" #descargaSolicitud></muestra-solicitud-de-analisis>
            <button id="caja-muestras-comprobante-descargar" class="btn btn-lg btn-primary" (click)="descargaSolicitud.descargarOrdenAnalisis()">Descargar solicitud</button>
        </div>
    </div>
</div>
<div class="row justify-content-center mt-4">
    <comprobante-tarjeta-envio [transporte]="model.transporte" [solicitudAnalisisId]="model.data.numberOS" ></comprobante-tarjeta-envio>
</div>