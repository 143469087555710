import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Objects } from "src/app/shared";
import { AuthenticationService } from "src/app/shared/auth/authentication.service";
import { EventEmitterService } from "src/app/shared/services/event-emitter.service";
import { LogService } from "src/app/shared/services/log.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { UsuarioNuevoModels } from "../usuario-nuevo-models/usuario-nuevo.models";
import { UsuarioNuevoViewModels } from "../usuario-nuevo-models/usuario-nuevo.view.models";
import { UsuarioNuevoService } from "../usuario-nuevo-services/usuario-nuevo.service";

@Component({
    selector: 'app-usuario-nuevo',
    templateUrl: './usuario-nuevo.component.html',
    styleUrls: ['./usuario-nuevo.component.css']
})
export class UsuarioNuevoComponent implements OnInit{

    @Input() usuarioInput: UsuarioNuevoModels.UsuarioInput = {
        usuario: {
            rut: '',
            phone: '',
            apellido: '',
            nombre: '',
            userId: '',
            email: '',
            clientes: [],
            roles: []
        },
        faena: '',
        desdeVerPerfil: false,
        editar: false
    }

    model: UsuarioNuevoViewModels.ViewModel = {
        usuario: {
            rut: '',
            phone: '',
            apellido: '',
            nombre: '',
            userId: '',
            email: '',
            clientes: [],
            roles: []
        },
        faenaSeleccionada: undefined,
        errorEmail: false,
        mensajeErrorEmail: '',
        errorRut: false,
        mensajeErrorRut: '',
        estaEditando: false,
        desdeVerPerfil: false,
        esAdmin: false,
        faenas: [],
        loadingFaenas: false,
        loadingEmail: false,
    }

    constructor(
        private authService: AuthenticationService,
        private spinner: SpinnerService,
        private modalService: NgbModal,
        public dialogRef: NgbActiveModal,
        public service: UsuarioNuevoService,
        private eventEmiiterSrv: EventEmitterService,
        private _logService: LogService,
    ){}

    ngOnInit(): void{
        this.getFaenas();

        if(this.usuarioInput.desdeVerPerfil || this.usuarioInput.editar){
            this.model.estaEditando = true;
            this.model.desdeVerPerfil = this.usuarioInput.desdeVerPerfil;
            this.generaUsuario(this.model.desdeVerPerfil);
        }

    }

    crearUsuario(): void{
        if(this.model.faenaSeleccionada){
            this.spinner.on();

            const faenasRequest: UsuarioNuevoModels.FaenaRequest[] = [];
            this.model.faenaSeleccionada!.forEach((faena) => faenasRequest.push({ faenaId: faena.faenaId }));

            const clientesRequest: UsuarioNuevoModels.ClienteRequest[] = [{
                clienteId: this.authService.currentClientValue.clienteId,
                faenas: faenasRequest
            }];

            const request: UsuarioNuevoModels.SetUsuarioRequest = {
                userId: this.authService.currentUserValue.userId,
                usuario: {
                    rut: this.model.usuario.rut.trim(),
                    phone: this.model.usuario.phone.trim(),
                    apellido: this.model.usuario.apellido.trim(),
                    nombre: this.model.usuario.nombre.trim(),
                    userId: "",
                    email: this.model.usuario.email.trim(),
                    clientes: clientesRequest,
                    roles: ['User']
                }
            };

            this.service.SetUsuario(request).subscribe(data => {
                if(data.success){

                    let rq: Objects.LogInput = {
                        accion: 'New',
                        elemento: this.model.usuario.rut.trim(),
                        tipoElemento: 'Usuario',
                        usuario: this.authService.usuario.nombre,
                        usuarioId: this.authService.usuario.userId,
                        ambiente: 'Web Cliente'
                      }
                    this._logService.createLog(rq, true);

                    this.spinner.off();
                    this.eventEmiiterSrv.sendResult("FLOW_MANTENEDOR", {accion: 'NUEVO'});
                    this.dialogRef.close();
                }else{
                    this.spinner.off();
                }
            });
        }
    }

    editarUsuario(): void{
        if(this.model.faenaSeleccionada){
            this.spinner.on();

            const clientesRequest: UsuarioNuevoModels.ClienteRequest[] = [];
            this.model.usuario.clientes.forEach((cliente) => {
                const faenasRequest: UsuarioNuevoModels.FaenaRequest[] = [];
                const faenas = cliente.clienteId === this.authService.currentClientValue.clienteId ? this.model.faenaSeleccionada : cliente.faenas;

                faenas!.forEach((faena) => faenasRequest.push({ faenaId: faena.faenaId }));

                clientesRequest.push({ clienteId: cliente.clienteId, faenas: faenasRequest });
            });

            const request: UsuarioNuevoModels.SetUsuarioRequest = {
                userId: this.authService.currentUserValue.userId,
                usuario: {
                    rut: this.model.usuario.rut.trim(),
                    phone: this.model.usuario.phone.trim(),
                    apellido: this.model.usuario.apellido.trim(),
                    nombre: this.model.usuario.nombre.trim(),
                    userId: this.model.usuario.userId.trim(),
                    email: this.model.usuario.email.trim(),
                    clientes: clientesRequest,
                    roles: this.model.usuario.roles
                }
            };

            this.service.SetUsuario(request).subscribe(data => {
                if(data.success){

                    let rq: Objects.LogInput = {
                        accion: 'Update',
                        elemento: this.model.usuario.rut.trim(),
                        tipoElemento: 'Usuario',
                        usuario: this.authService.usuario.nombre,
                        usuarioId: this.authService.usuario.userId,
                        ambiente: 'Web Cliente'
                      }
                    this._logService.createLog(rq, true);

                    if(this.model.desdeVerPerfil){
                        this.spinner.off();
                        this.authService.reloadClient(this.authService.currentUserValue.userId);
                        this.dialogRef.close();
                    }else{
                        this.spinner.off();
                        this.eventEmiiterSrv.sendResult("FLOW_MANTENEDOR", {accion: 'EDITAR'});
                        this.dialogRef.close();
                    }
                }else{
                    this.spinner.off();
                }
            });
        }
    }

    cerrar(): void{
        this.dialogRef.close();
    }

    formatRut(): void{
        this.model.errorRut = false;
        this.model.mensajeErrorRut = ''
        let rutF = this.model.usuario.rut.replace(/\./gi, '').replace(/\-/gi, '');
        if(rutF.length > 1){
            this.model.usuario.rut = `${rutF.substring(0, rutF.length - 1)}-${rutF.substring(rutF.length - 1, rutF.length)}`;
        }else{
            this.model.usuario.rut = rutF;
        }
    }

    limpiaEmail(): void {
        this.model.errorEmail = false;
        this.model.mensajeErrorEmail = '';
    }

    validaEmail(): void{
        let email: string = this.model.usuario.email;
        this.model.errorEmail = false;
        this.model.mensajeErrorEmail = '';
        if(email != this.usuarioInput.usuario.email){
            if(this.validaFormato(email)){
                this.validaEmailExistente(email);
            }else{
                this.model.errorEmail = true;
                this.model.mensajeErrorEmail = 'Formato de email inválido.';
            }
        }
    }

    private getFaenas(): void{
        this.model.loadingFaenas = true;
        const request: UsuarioNuevoModels.GetFaenasRequest = {
            clienteId: this.authService.currentClientValue.clienteId
        };
        this.service.GetFaenas(request)
            .then(response =>{
                if(response.success){
                    const faenas: Objects.Faena[] = [];
                    response.data.forEach((faena) =>
                        faenas.push({
                            faenaId: faena.solicitanteId,
                            direccion: faena.direccion,
                            nombre: faena.nombre,
                            numeroSap: faena.numeroSap,
                            rubro: faena.rubro,
                            transporte: '',
                        })
                    );
                    this.model.faenas = faenas;
                }
            })
            .finally(() => {
                this.model.loadingFaenas = false;
            })
    }

    private generaUsuario(perfil: boolean): void{
        const currentClient: Objects.CurrentClient = this.authService.currentClientValue;
        const userProfile: Objects.UserProfile = this.authService.currentUserValue;
        let faenasUsuario = [];

        if(perfil){
            faenasUsuario = userProfile.clientes.find(c => c.clienteId === currentClient.clienteId)!.faenas;
            this.model.usuario = {
                rut: userProfile.rut,
                phone: userProfile.phone,
                apellido: userProfile.apellido,
                nombre: userProfile.nombre,
                userId: userProfile.userId,
                email: userProfile.email,
                clientes: userProfile.clientes,
                roles: userProfile.roles
            }
        }else{
            faenasUsuario = this.usuarioInput.usuario.clientes.find(c => c.clienteId === currentClient.clienteId)!.faenas;
            this.model.usuario = {
                rut: this.usuarioInput.usuario.rut,
                phone: this.usuarioInput.usuario.phone,
                apellido: this.usuarioInput.usuario.apellido,
                nombre: this.usuarioInput.usuario.nombre,
                userId: this.usuarioInput.usuario.userId,
                email: this.usuarioInput.usuario.email,
                clientes: this.usuarioInput.usuario.clientes,
                roles: this.usuarioInput.usuario.roles
            }
        }
        this.model.faenaSeleccionada = faenasUsuario;
    }

    private validaEmailExistente(email: string): void {
        this.model.loadingEmail = true;
        let existeEmail: boolean = false;
        if(email.trim() !== ''){
            const request: UsuarioNuevoModels.GetUsuarioRequest = {
                clienteId: this.authService.currentClientValue.clienteId,
                email: email.trim()
            };
            this.service.GetUsuarioInfo(request)
                .then(response => {
                    if (response.success) {
                        if(response.data.length != 0){
                            existeEmail = true;
                        }
                    }
                })
                .finally(() =>{
                    this.model.errorEmail = existeEmail;
                    this.model.mensajeErrorEmail = 'Email ya existe dentro del sistema.';
                    this.model.loadingEmail = false;
                })
        }else{
            this.model.errorEmail = false;
            this.model.loadingEmail = false;
        }
    }

    private validaAdmin(): boolean{
        if( this.authService.currentUserValue.roles!){
            let roles =  this.authService.currentUserValue.roles!;
            return roles.includes("Admin");
        }
        return false;
    }

    private validaFormato(email: string): boolean {
        const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regularExpression.test(String(email).toLowerCase());
    }

    public selectAllChores(checked: boolean): void {
        this.model.faenaSeleccionada = checked ? this.model.faenas : [];
    }
}
