<form #registroNuevoComponente="ngForm">
    <div class="modal-header">
        <h5 class="modal-title" *ngIf="!model.estaEditando">Nuevo Componente</h5>
        <h5 class="modal-title" *ngIf="model.estaEditando">Editar Componente</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="cancelar()"></button>
    </div>
    <div class="modal-body text-start">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <div *ngIf="!model.crearEquipo">
                            <div *ngIf="!model.vieneDesdeBuscarMuestra">
                                <input type="checkbox" [checked]="model.equipoAsociado" (change)="cambioAsociado()">
                                <label>&nbsp;El equipo asociado tiene un código único</label>
                                <br>
                                <div *ngIf="model.errorSinEquipo && this.model.equipo.tipoEquipo.tipoEquipoId == ''" class="text-danger mt10">
                                    Debe ingresar un equipo.
                                </div>
                                <br>
                            </div>

                            <div *ngIf="!model.tieneEquipo && !model.equipoAsociado">
                                <label for="tipo" class="form-label">Tipo de equipo</label>
                                <mat-spinner *ngIf="model.loadingTipoEquipo" matSuffix [diameter]="18"
                                    style="float: right; margin-left: 8px"></mat-spinner>
                                <ng-select [(ngModel)]="model.equipo.tipoEquipo" blockSpecialCharacters #selectedTipo
                                    required [disabled]="model.loadingTipoEquipo" [clearable]="false" name="tipo">
                                    <ng-option *ngFor="let tipo of model.tipoEquipos" select [value]="tipo">
                                        {{tipo.nombre}}</ng-option>
                                </ng-select>
                            </div>

                            <div *ngIf="!model.tieneEquipo && model.equipoAsociado">
                                <label for="codigoEquipo" class="form-label">Código del equipo</label>
                                <mat-spinner *ngIf="model.loadingEquipos" matSuffix [diameter]="18"
                                    style="float: right; margin-left: 8px"></mat-spinner>
                                <input type="text" class="form-control form-control-lg mt10"
                                    placeholder="Ingresa el código del equipo" #autoCompleteInput matInput
                                    [formControl]="myControl" blockSpecialCharacters [matAutocomplete]="auto"
                                    [(ngModel)]="model.equipoFiltro" name="codigoEquipo"
                                    (keyup.enter)="enterInputSearch()" (focusout)="enterInputSearch();"
                                    [readonly]="model.loadingEquipos" maxlength="30">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                    (optionSelected)="onSelectionChanged($event)">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                        <strong>{{ option.equipoId }}</strong> - {{ option.tipoEquipo.nombre }} {{
                                        option.marca }} {{ option.modelo }}
                                    </mat-option>
                                </mat-autocomplete>

                                <div *ngIf="model.equipoInvalido" class="text-danger">
                                    Debe ingresar un código de equipo válido.
                                </div>
                                <div *ngIf="model.equipoInexistente" class="text-danger">
                                    El código no existe en el sistema.
                                </div>

                                <div class="form-text">Si el equipo no ha sido ingresado en el sistema, puedes
                                    <a [routerLink]="" class="link-primary"
                                        (click)="model.crearEquipo = !model.crearEquipo">crear uno nuevo</a>
                                </div>
                            </div>

                            <div *ngIf="model.tieneEquipo && model.equipoAsociado">
                                <div *ngIf="model.equipo?.tipoEquipo?.nombre == 'CAMION'">
                                    <img src="../../../../../../assets/images/icono-camion.svg" alt=""
                                        class="icono-equipo">
                                </div>

                                <div *ngIf="model.equipo?.tipoEquipo?.nombre != 'CAMION'">
                                    <img src="../../../../../../assets/images/icono-sin.svg" alt=""
                                        class="icono-equipo">
                                </div>
                                <h5 class="card-title">{{model.equipo?.tipoEquipo?.nombre}} {{model.equipo?.equipoId}}
                                </h5>

                                <div class="mb-3">
                                    <label class="form-label mb-0">Marca</label>
                                    <p><strong>{{ model.equipo?.marca }}</strong></p>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label mb-0">Modelo</label>
                                    <p><strong>{{ model.equipo?.modelo }}</strong></p>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label mb-0">Alias</label>
                                    <p>
                                        <span *ngFor="let alia of model.equipo?.alias"><strong>{{alia}} </strong></span>
                                    </p>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label mb-0">Descripción</label>
                                    <p><strong>{{ model.equipo?.descriptor }}</strong></p>
                                </div>
                                <div class="mb-0">
                                    <label class="form-label mb-0">Etiquetas</label>
                                    <p>
                                        <span class="badge bg-secondary me-1" *ngFor="let tag of model.equipo?.tags">
                                            {{tag}}
                                        </span>
                                    </p>
                                </div>
                                <div class="text-end">
                                    <button type="button" (click)="cambiarEquipo()" class="btn btn-link"><i
                                            class="bi bi-pencil"></i> Cambiar equipo</button>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="model.crearEquipo">
                            <div class="form-group">
                                <label for="equipoId" class="form-label">Código del equipo</label>
                                <mat-spinner *ngIf="model.loadingComponenteId" matSuffix [diameter]="18"
                                    style="float: right; margin-left: 8px"></mat-spinner>
                                <input type="text" name="equipoId" id="equipoId" class="form-control" idDirective
                                    [(ngModel)]="model.equipo.equipoId" #codigoEquipo="ngModel"
                                    value="{{ model.equipo.equipoId }}"
                                    [ngClass]="{'is-invalid':codigoEquipo.invalid && (codigoEquipo.dirty || codigoEquipo.touched)}"
                                    required [readonly]="model.loadingEquipos" (keyup)="validaIdEquipo()"
                                    maxlength="30">
                                <div class="form-text">Código de equipo debe ir en mayúsculas</div>
                                <div *ngIf="codigoEquipo.invalid && (codigoEquipo.dirty || codigoEquipo.touched)"
                                    class="text-danger">
                                    <div *ngIf="codigoEquipo.errors?.required">
                                        Debe ingresar código del equipo.
                                    </div>
                                </div>

                                <div *ngIf="model.idEquipoError" class="text-danger">
                                    El código ya existe dentro del sistema.
                                </div>

                                <div *ngIf="model.idEquipoVacio" class="text-danger">
                                    Debe ingresar código del equipo.
                                </div>
                            </div>
                            <br>
                            <div class="form-group">
                                <label for="tipo" class="form-label">Tipo de equipo</label>
                                <mat-spinner *ngIf="model.loadingTipoEquipo" matSuffix [diameter]="18"
                                    style="float: right; margin-left: 8px"></mat-spinner>
                                <ng-select [(ngModel)]="model.equipo.tipoEquipo" blockSpecialCharacters #selectedTipo
                                    required [disabled]="model.loadingTipoEquipo" [clearable]="false" name="tipo">
                                    <ng-option *ngFor="let tipo of model.tipoEquipos" select [value]="tipo">
                                        {{tipo.nombre}}</ng-option>
                                </ng-select>
                            </div>
                            <br>
                            <div class="form-group">
                                <label for="marcaEquipo" class="form-label">Marca</label>
                                <mat-spinner *ngIf="model.loadingTipoEquipo" matSuffix [diameter]="18"
                                    style="float: right; margin-left: 8px"></mat-spinner>
                                <ng-select [(ngModel)]="model.equipo.marca" blockSpecialCharacters #selectedMarca
                                    required (ngModelChange)="cambiaMarcaEquipo()" [disabled]="model.loadingTipoEquipo"
                                    [clearable]="false" name="marcaEquipo">
                                    <ng-option *ngFor="let marca of model.marcasEquipo" [value]="marca.nombre">
                                        {{marca.nombre}}</ng-option>
                                    <ng-option value="otro">Otro</ng-option>
                                </ng-select>
                            </div>
                            <br>

                            <div *ngIf="model.verOtraMarcaEquipo">
                                <div class="form-group">
                                    <label for="otraMarcaEquipo" class="form-label">Otra marca</label>
                                    <input type="text" id="otraMarcaEquipo" blockSpecialCharacters class="form-control"
                                        value="{{ model.otraMarcaEquipo }}" name="otraMarcaEquipo"
                                        #otraMarcaEquipo="ngModel" [(ngModel)]="model.otraMarcaEquipo" required
                                        maxlength="50">
                                    <div *ngIf="otraMarcaEquipo.invalid && (otraMarcaEquipo.dirty || otraMarcaEquipo.touched)"
                                        class="text-danger">
                                        <div *ngIf="otraMarcaEquipo.errors?.required">
                                            Debe ingresar marca del equipo.
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>

                            <div class="form-group">
                                <label for="modeloEquipo" class="form-label">Modelo <em>(opcional)</em></label>
                                <mat-spinner *ngIf="model.loadingTipoEquipo" matSuffix [diameter]="18"
                                    style="float: right; margin-left: 8px"></mat-spinner>
                                <ng-select [(ngModel)]="model.equipo.modelo" blockSpecialCharacters #selectedModelo
                                    (ngModelChange)="cambiaModeloEquipo()" [disabled]="model.loadingTipoEquipo"
                                    [clearable]="false" name="modeloEquipo">
                                    <ng-option *ngFor="let modelo of model.modelosEquipo" [value]="modelo">{{modelo}}
                                    </ng-option>
                                    <ng-option value="otro">Otro</ng-option>
                                </ng-select>
                            </div>
                            <br>

                            <div *ngIf="model.verOtroModeloEquipo">
                                <div class="form-group">
                                    <label for="otroModeloEquipo" class="form-label">Otro modelo</label>
                                    <input type="text" id="otroModelo" blockSpecialCharacters class="form-control"
                                        value="{{ model.otroModeloEquipo }}" name="otroModeloEquipo"
                                        #otroModeloEquipo="ngModel" [(ngModel)]="model.otroModeloEquipo" required
                                        maxlength="50">
                                    <div *ngIf="otroModeloEquipo.invalid && (otroModeloEquipo.dirty || otroModeloEquipo.touched)"
                                        class="text-danger">
                                        <div *ngIf="otroModeloEquipo.errors?.required">
                                            Debe ingresar modelo del equipo.
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>

                            <a [routerLink]="" (click)="model.verMasCamposEquipo = !model.verMasCamposEquipo"
                                *ngIf="!model.verMasCamposEquipo">Más campos opcionales</a>

                            <div *ngIf="model.verMasCamposEquipo">
                                <div class="form-group">
                                    <label for="descriptor" class="form-label">Descripción <em>(opcional)</em></label>
                                    <input type="text" id="descriptor" blockSpecialCharacters class="form-control"
                                        value="" [(ngModel)]="model.equipo.descriptor" name="descriptor" maxlength="50">
                                </div>
                                <br>

                                <div class="form-group">
                                    <label for="alias" class="form-label">Alias <em>(opcional)</em></label>
                                    <mat-form-field class="example-chip-list">
                                        <mat-chip-list #listAlias aria-label="alias selection">
                                            <mat-chip *ngFor="let alia of model.equipo.alias"
                                                [selectable]="model.selectable" [removable]="model.removable"
                                                (removed)="removeAlia(alia)">
                                                {{ alia }}
                                                <span matChipRemove *ngIf="model.removable">x</span>
                                            </mat-chip>
                                            <input [matChipInputFor]="listAlias"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="addAlia($event)" blockSpecialCharacters
                                                maxlength="20">
                                        </mat-chip-list>
                                    </mat-form-field>
                                </div>
                                <br>

                                <div class="form-group">
                                    <label for="tags" class="form-label">Agrupadores <em>(opcional)</em></label>
                                    <mat-form-field class="example-chip-list">
                                        <mat-chip-list #listTags aria-label="tag selection">
                                            <mat-chip *ngFor="let tag of model.equipo.tags"
                                                [selectable]="model.selectable" [removable]="model.removable"
                                                (removed)="removeTag(tag)">
                                                {{ tag }}
                                                <span matChipRemove *ngIf="model.removable">x</span>
                                            </mat-chip>
                                            <input [matChipInputFor]="listTags"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="addTag($event)" blockSpecialCharacters
                                                maxlength="20">
                                        </mat-chip-list>
                                    </mat-form-field>
                                </div>

                                <br>
                            </div>
                            <br>
                            <button type="button" class="btn btn-light mt10"
                                (click)="cancelarCrearEquipo()">cancelar</button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group" *ngIf="!model.estaEditando">
                    <label for="componenteId" class="form-label">Código del componente</label>
                    <mat-spinner *ngIf="model.loadingComponenteId" matSuffix [diameter]="18"
                        style="float: right; margin-left: 8px"></mat-spinner>
                    <input type="text" name="componenteId" id="componenteId" class="form-control"
                        [(ngModel)]="model.componente.componenteId" idComponenteDirective #codigoComponente="ngModel"
                        [readonly]="model.loadingComponenteId" value="{{ model.componente.componenteId }}"
                        [ngClass]="{'is-invalid':codigoComponente.invalid && (codigoComponente.dirty || codigoComponente.touched)}"
                        required (focusout)="validaId()" (keyup)="limipiaId()" maxlength="30">
                    <div class="form-text">Código de componente debe ir en mayúsculas</div>
                    <div *ngIf="codigoComponente.invalid && (codigoComponente.dirty || codigoComponente.touched)"
                        class="text-danger">
                        <div *ngIf="codigoComponente.errors?.required">
                            Debe ingresar código del componente.
                        </div>
                    </div>

                    <div *ngIf="model.idError" class="text-danger">
                        El código ya existe dentro del sistema.
                    </div>

                </div>
                <div class="form-group" *ngIf="model.estaEditando">
                    <label for="componenteId" class="form-label">Código del componente</label>
                    <br>
                    <label><strong>{{ model.componente.componenteId }}</strong></label>
                </div>
                <br>
                <div class="form-group">
                    <label for="tipo" class="form-label">Tipo de componente</label>
                    <mat-spinner *ngIf="model.loadingMarca" matSuffix [diameter]="18"
                        style="float: right; margin-left: 8px"></mat-spinner>
                    <ng-select [(ngModel)]="model.componente.tipoComponente" blockSpecialCharacters
                        [disabled]="model.loadingMarca" [clearable]="false" name="tipo"
                        (change)="model.tieneErrorSetComponente = !model.tieneErrorSetComponente">


                        <ng-option *ngFor="let item of model.tipoComponentes" [value]="item">{{item.nombre}}</ng-option>
                    </ng-select>

                    <div *ngIf="model.tieneErrorSetComponente" class="text-danger">
                        {{ model.errorSetComponente }}
                    </div>
                </div>
                <br>
                <div class="form-group">
                    <label for="marca" class="form-label">Marca</label>
                    <mat-spinner *ngIf="model.loadingMarca" matSuffix [diameter]="18"
                        style="float: right; margin-left: 8px"></mat-spinner>
                    <ng-select [(ngModel)]="model.componente.marca" blockSpecialCharacters
                        (ngModelChange)="cambiaMarca()" [disabled]="model.loadingMarca" [clearable]="false" name="marca"
                        required>
                        <ng-option *ngFor="let marca of model.marcas" [value]="marca.nombre">{{marca.nombre}}
                        </ng-option>
                        <ng-option value="otro">Otro</ng-option>
                    </ng-select>
                </div>
                <br>

                <div *ngIf="model.verOtraMarca">
                    <div class="form-group">
                        <label for="otraMarca" class="form-label">Otra marca</label>
                        <input type="text" id="otraMarca" blockSpecialCharacters class="form-control"
                            value="{{ model.otraMarca }}" required name="otraMarca" #otraMarca="ngModel"
                            [(ngModel)]="model.otraMarca" required maxlength="50">
                        <div *ngIf="otraMarca.invalid && (otraMarca.dirty || otraMarca.touched)" class="text-danger">
                            <div *ngIf="otraMarca.errors?.required">
                                Debe ingresar marca del componente.
                            </div>
                        </div>
                    </div>
                    <br>
                </div>

                <div class="form-group">
                    <label for="modelo" class="form-label">Modelo <em>(opcional)</em></label>
                    <ng-select [(ngModel)]="model.componente.modelo" blockSpecialCharacters
                        (ngModelChange)="cambiaModelo()" [disabled]="model.loadingMarca" [clearable]="false"
                        name="modelo">
                        <ng-option *ngFor="let modelo of model.modelos" [value]="modelo">{{modelo}}</ng-option>
                        <ng-option value="otro">Otro</ng-option>
                    </ng-select>
                </div>
                <br>

                <div *ngIf="model.verOtroModelo">
                    <div class="form-group">
                        <label for="otroModelo" class="form-label">Otro modelo</label>
                        <input type="text" id="otroModelo" class="form-control" blockSpecialCharacters
                            value="{{ model.otroModelo }}" name="otroModelo" [(ngModel)]="model.otroModelo" required
                            #otroModelo="ngModel" maxlength="50">
                        <div *ngIf="otroModelo.invalid && (otroModelo.dirty || otroModelo.touched)" class="text-danger">
                            <div *ngIf="otroModelo.errors?.required">
                                Debe ingresar modelo del componente.
                            </div>
                        </div>
                    </div>
                    <br>
                </div>

                <div class="form-group">
                    <label for="descriptor" class="form-label">Descripción <em>(opcional)</em></label>
                    <input type="text" id="descriptor" class="form-control" value="" blockSpecialCharacters
                        name="descriptor" value="{{ model.componente.descriptor }}"
                        [(ngModel)]="model.componente.descriptor" maxlength="50">
                </div>
                <br>

                <div class="form-group">
                    <label for="posicion" class="form-label">Posición <em>(opcional)</em></label>
                    <input type="text" name="posicion" id="posicion" class="form-control" value=""
                        blockSpecialCharacters value="{{ model.componente.ubicacion }}"
                        [(ngModel)]="model.componente.ubicacion" maxlength="50">
                </div>
                <br>

                <div class="form-group">
                    <label for="lubricante" class="form-label">Lubricante</label>
                    <mat-spinner *ngIf="model.loadingLubricante" matSuffix [diameter]="18"
                        style="float: right; margin-left: 8px"></mat-spinner>

                    <ng-select [(ngModel)]="model.componente.lubricante" blockSpecialCharacters
                        #selectLubricante="ngModel" [disabled]="model.loadingLubricante" [clearable]="false"
                        name="lubricante"
                        [ngClass]="{'is-invalid':selectLubricante.invalid && (selectLubricante.dirty || selectLubricante.touched)}"
                        (change)="model.tieneErrorSetComponente = !model.tieneErrorSetComponente" required>


                        <ng-option *ngFor="let item of model.lubricantes" [value]="item">{{item.nombre}}</ng-option>
                        <div *ngIf="selectLubricante.invalid && (selectLubricante.dirty || selectLubricante.touched)"
                            class="text-danger">
                            <div *ngIf="selectLubricante.errors?.required">
                                Debe seleccionar lubricante.
                            </div>
                        </div>
                    </ng-select>                    
                </div>
                <br>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="cancelar()">Cancelar</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal"
            [disabled]="registroNuevoComponente.invalid" (click)="guardarComponente()">
            <span *ngIf="!model.estaEditando">
                Crear el componente
            </span>
            <span *ngIf="model.estaEditando">
                Guardar el componente
            </span>
        </button>
    </div>
</form>
