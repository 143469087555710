import { NG_VALIDATORS, AbstractControl, Validator, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { Objects } from 'src/app/shared';
import { SampleBoxDataProvider } from '../services/sampleBoxDataProvider.service';
@Directive({
    selector: '[unicoNumeroEnvase]',
    providers: [{provide: NG_VALIDATORS, useExisting: UnicoNumeroEnvaseValidatorDirective, multi: true}]
  })
  export class UnicoNumeroEnvaseValidatorDirective implements Validator {
  @Input('unicoNumeroEnvase') muestrasEnFormulario: Objects.RegistroMuestra[]=[];  
  @Input('index') index: number=0;  

    validate(control: AbstractControl): ValidationErrors | null {
      return  this.existeNumero(control.value)(control);
    }  
    constructor(public appData:SampleBoxDataProvider){

    }
    existeNumero(number:string):ValidatorFn{
      return (control: AbstractControl): {[key: string]: any} | null => {
        const forbidden = number && number.trim() && ( this.appData.sampleBoxData.muestras.filter(e=>e.muestraId===number).length>0 || this.muestrasEnFormulario.filter((e,index)=>e.muestraId===number && this.index!==index).length>0);
        return forbidden ? {unicoNumeroEnvase: {value: control.value}} : null;
      };
    }
  }