<div class="tools" *ngIf="downloadLink && model.muestra">
  <div class="tools__acctions">
    <div class="d-flex align-items-center justify-content-end gap-3">
      <button class="btn btn-primary d-flex align-items-center" (click)="generatePDF()" download>DESCARGAR INFORME</button>
      <button
        class="btn btn--small btn--first btn--outline"
        (click)="showImages()"
        *ngIf="model.muestra.imagenes && model.muestra.imagenes.length > 0"
      >
        VER IMÁGENES ({{ model.muestra.imagenes.length }})
      </button>
    </div>
    <button class="close-page" (click)="windowClose()">
      <em class="bi bi-x"></em>
    </button>
  </div>
</div>
<div *ngIf="model.muestra" id="report" class="report">
  <ng-container *ngFor="let pagina of model.paginas; let index = index">
    <div id="page_{{ index }}" [ngClass]="{ 'p-2': isVisible, 'p-2 none ': !isVisible }" [hidden]="loading.inProgress">
      <ng-container *ngFor="let tipo of pagina.tipos_ensayo">
        <ng-container [ngSwitch]="tipo.tipo">
          <ng-container *ngSwitchCase="'generic_'">
            <div id="p_generic_{{ index }}">
              <ng-container [ngTemplateOutlet]="generic" [ngTemplateOutletContext]="{ data: tipo.data }"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'ferro'">
            <br />
            <div id="p_ferro_{{ index }}">
              <div id="ferro" class="pb-2">
                <br />

                <ng-container [ngTemplateOutlet]="ferro" [ngTemplateOutletContext]="{ data: tipo.data }"></ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'turb'">
            <div id="p_turb_{{ index }}">
              <div id="turb" class="pb-2">
                <br />

                <ng-container [ngTemplateOutlet]="turb" [ngTemplateOutletContext]="{ data: tipo.data }"></ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'memb'">
            <br />
            <div id="p_memb_{{ index }}">
              <div id="memb" class="pb-2">
                <br />

                <ng-container [ngTemplateOutlet]="memb" [ngTemplateOutletContext]="{ data: tipo.data }"></ng-container>
              </div>
            </div>
          </ng-container>


          <ng-container *ngSwitchCase="'one'">
            <div id="p_one_{{ index }}">
              <ng-container [ngTemplateOutlet]="one" [ngTemplateOutletContext]="{ data: tipo.data }"></ng-container>
            </div>
            <div *ngIf="model.muestra.diagnostico_ia?.diagnostico_ia && mostrar_recomendacionIA">
                <ng-container  [ngTemplateOutlet]="page_recomendation_ia"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault> </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #one let-data="data">
    <div #reportNoisOne>
      <div class="report__top mt-3" #reportTop>
        <div class="report__title">
          <h1 class="mb-0">
            <b>Frasco {{ model.muestra.muestraId }} del {{ model.muestra.fechaIngreso }}</b>
          </h1>
          <h3>Informe de Resultados</h3>
        </div>
        <div class="report__brand">
          <img class="color" src="assets/images/logo-mobil-assistance_400_61.png" alt="" /><img
            class="black"
            src="assets/images/logo-mobil-assistance-negro.svg"
            alt=""
          />
        </div>
      </div>
      <div class="report__header mb-3" [hidden]="loading.inProgress" *ngIf="model.cliente" #reportHeader>
        <div class="card">
          <dl class="description mb-3">
            <dd class="description__definition">Cliente</dd>
            <dt class="description__term">
              {{ model.cliente.nombre }},<br />{{ model.cliente.faena.nombre }}<br />{{ model.cliente.faena.direccion }}<br />Contacto:
              {{ model.cliente.faena.contacto }}
            </dt>
          </dl>
        </div>
        <div class="card">
          <dl class="description mb-3">
            <dd class="description__definition">Responsable de la cuenta</dd>
            <dt class="description__term">
              {{ model.cliente.faena.responsable.nombre }}<br />{{ model.cliente.faena.responsable.email }}<br />{{
                model.cliente.faena.responsable.phone
              }}
            </dt>
          </dl>
        </div>
        <div class="card d-flex justify-content-between gap-1">
          <div>
            <dl class="description mb-4">
              <dd class="description__definition">Equipo</dd>
              <dt class="description__term">
                {{ model.muestra.componente.equipo?.tipoEquipo?.nombre }} {{ model.muestra.componente.equipo?.marca }}<br />
                {{ model.muestra.componente.equipo?.modelo }} {{ model.muestra.componente.equipo?.descriptor }}
              </dt>
            </dl>
          </div>
          <div>
            <dl class="description mb-3">
              <dd class="description__definition">Componente</dd>
              <dt class="description__term">
                {{ model.muestra.componente.tipoComponente.nombre | uppercase }}<br />
                {{ model.muestra.componente.marca }} {{ model.muestra.componente.modelo }}
                <br />
                Código {{ model.muestra.componente.componenteId }}
              </dt>
            </dl>
          </div>
        </div>
        <div class="card report__state report__state--{{ enumClass.reportState[estadoMuestra] }}">
          <dl class="description mb-3">
            <dd class="description__definition">Estado de la muestra</dd>
            <dt class="description__term">{{ enumResultado[estadoMuestra] | titlecase }}</dt>
          </dl>
          <ng-container [ngSwitch]="estadoMuestra">
            <em *ngSwitchCase="'normal'" class="bi bi-check-circle-fill"></em>
            <em *ngSwitchCase="'alerta'" class="bi bi-exclamation-triangle-fill"></em>
            <em *ngSwitchCase="'precaucion'" class="bi bi-exclamation-circle-fill"></em>
            <em *ngSwitchDefault class=""></em>
          </ng-container>
        </div>
      </div>
      <div class="report__detail" [hidden]="loading.inProgress" #reportDetail>
        <div class="report__analysis flex-fill">
          <table class="table table--detail" style="border: 0">
            <thead #reportDetail_TableHead>
              <tr>
                <th class="bg-gray text-start" style="border-right: solid 1px #d8d8d8; padding-left: 3px" colspan="3">
                  Datos de la Muestra
                </th>
                <th class="bg-gray" [attr.colspan]="this.model.limite.muestras.previas">Muestras anteriores</th>
                <th class="bg-gray col-highlight" style="white-space: nowrap">Muestra actual</th>
                <th></th>
              </tr>
            </thead>
            <tbody #reportDetail_tableBody>
              <ng-container *ngFor="let dato of model.datos.muestra; let index_dato = index">
                <tr style="border: none">
                  <td style="border-bottom: solid 1px #efefef" class="txt--dark" colspan="3">{{ dato.etiqueta | titlecase }}</td>

                  <ng-container *ngFor="let nro of createArrayFromNumberOfSamples(); let index_muestra = index; let last = last">
                    <ng-container *ngIf="!last">
                      <ng-container [ngSwitch]="dato.key">
                        <ng-container *ngSwitchCase="'estadoMuestra'">
                          <ng-container [ngSwitch]="dato.valor(model.muestras[nro])">
                            <td
                              style="border-bottom: solid 1px #efefef; white-space: normal"
                              *ngSwitchCase="'normal'"
                              [ngClass]="{
                                'col-highlight txt--success': model.muestras[nro].correlativo === model.muestra?.correlativo,
                                'txt--success': true
                              }"
                            >
                              <em class="bi bi-check-circle-fill me-2"></em> Normal
                            </td>
                            <td
                              style="border-bottom: solid 1px #efefef; white-space: normal"
                              *ngSwitchCase="'alerta'"
                              [ngClass]="{
                                'col-highlight txt--danger': model.muestras[nro].correlativo === model.muestra?.correlativo,
                                'txt--danger': true
                              }"
                            >
                              <em class="bi bi-exclamation-triangle-fill me-2"></em> {{ enumResultado[dato.valor(model.muestras[nro])] }}
                            </td>
                            <td
                              style="border-bottom: solid 1px #efefef; white-space: normal"
                              *ngSwitchCase="'precaucion'"
                              [ngClass]="{
                                'col-highlight txt--warning': model.muestras[nro].correlativo === model.muestra?.correlativo,
                                'txt--warning': true
                              }"
                            >
                              <em class="bi bi-exclamation-circle-fill me-2"></em> {{ enumResultado[dato.valor(model.muestras[nro])] }}
                            </td>
                            <td
                              style="border-bottom: solid 1px #efefef; white-space: normal"
                              *ngSwitchDefault
                              [ngClass]="{
                                'col-highlight txt--gray':
                                  model.muestras[nro] && model.muestras[nro].correlativo === model.muestra?.correlativo,
                                'txt--gray': true
                              }"
                            >
                              {{ enumResultado[dato.valor(model.muestras[nro])] }}
                            </td>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <td
                            style="border-bottom: solid 1px #efefef; white-space: normal"
                            [ngClass]="{
                              'col-highlight': model.muestras[nro] && model.muestras[nro].correlativo === model.muestra?.correlativo
                            }"
                          >
                            {{ dato.valor(model.muestras[nro]) }}
                          </td>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <td style="border: 0" *ngIf="last"></td>
                  </ng-container>
                </tr>
              </ng-container>
              <ng-container *ngFor="let tipo of orderTypes(getOwnPropertyNames(data)); let indexType = index" #reportTableAnalisysSamples>
                <tr class="bg-gray" *ngIf="tipo && indexType === 0">
                  <td>{{ tipo }}</td>
                  <td>Unidad</td>
                  <td>Norma</td>
                  <ng-container *ngFor="let nro of createArrayFromNumberOfSamples(); let last = last">
                    <td *ngIf="!model.muestras[nro] && !last"></td>
                    <td
                      *ngIf="model.muestras[nro] && !last"
                      [ngClass]="{ 'col-highlight': model.muestras[nro].correlativo === model.muestra?.correlativo }"
                    >
                      <div
                        class="d-flex justify-content-start"
                        *ngIf="model.muestras[nro].correlativo === model.muestra?.correlativo"
                      ></div>
                    </td>
                    <td *ngIf="last">L&iacute;mites</td>
                  </ng-container>
                </tr>
                <tr class="bg-gray" *ngIf="indexType > 0">
                  <td>{{ cambiarNombreEnsayo(tipo) }}</td>
                  <td></td>
                  <td></td>
                  <ng-container *ngFor="let nro of createArrayFromNumberOfSamples(); let last = last">
                    <td *ngIf="!model.muestras[nro] && !last"></td>
                    <td
                      *ngIf="model.muestras[nro] && !last"
                      [ngClass]="{ 'col-highlight': model.muestras[nro].correlativo === model.muestra?.correlativo }"
                    >
                      <div
                        class="d-flex justify-content-start"
                        *ngIf="model.muestras[nro].correlativo === model.muestra?.correlativo"
                      ></div>
                    </td>
                    <td *ngIf="last">Límites Máx.</td>
                  </ng-container>
                </tr>

                <ng-container *ngFor="let subtipo of orderSubTypes(getOwnPropertyNames(data[tipo]), tipo); let indexSubType = index">
                  <tr
                    style="border: none"
                    *ngFor="let ensayo of orderAnalysis(data[tipo][subtipo].ensayos, tipo); let indexAnalysis = index"
                    class="item"
                  >
                    <ng-container>
                      <td
                        style="border-bottom: solid 1px #efefef"
                        [attr.colspan]="subtipo === '' ? 2 : 0"
                        class="txt--dark"
                        style="width: 230px"
                      >
                        {{ ensayo.nombre | titlecase }}
                      </td>
                      <td style="border-bottom: solid 1px #efefef">{{ ensayo.unidad | lowercase }}</td>

                      <td
                        style="border-bottom: solid 1px #efefef"
                        *ngIf="columnsMatrix[indexType + '' + indexSubType + '' + indexAnalysis]"
                        [attr.rowspan]="columnsMatrix[indexType + '' + indexSubType + '' + indexAnalysis].rowspan"
                      >
                        {{ ensayo.norma | uppercase }}
                      </td>

                      <ng-container *ngFor="let nro of createArrayFromNumberOfSamples(); let last = last">
                        <td
                          style="width: 130px"
                          *ngIf="!last"
                          cell-report
                          [muestra]="model.muestras[nro]"
                          #cell
                          [ensayo]="ensayo"
                          [esMuestraBase]="model.muestras[nro]?.correlativo === model.muestra?.correlativo"
                          [class]="
                            model.muestras[nro]?.correlativo === model.muestra?.correlativo ? getClass(ensayo) : 'simple-line-bottom'
                          "
                        ></td>
                        <td style="border-bottom: solid 1px #efefef; text-align: center" *ngIf="last">
                          <ng-container [ngSwitch]="ensayo.tipoDato?.tipo">
                            <ng-container *ngSwitchCase="'text'"> </ng-container>
                            <ng-container *ngSwitchCase="'boolean'"> </ng-container>
                            <ng-container *ngSwitchDefault>
                              {{ { ensayo: ensayo, muestra: model.muestra } | limit_report }}
                            </ng-container>
                          </ng-container>
                        </td>
                      </ng-container>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div
          class="report__visual"
          *ngIf="
            model.chartConfig['viscosidad'].value.length > 0 ||
            model.chartConfig['pq_hierro_cobre'].value.length > 0 ||
            model.chartConfig['desgaste_aleacion'].value.length > 0 ||
            model.chartConfig['contaminacion'].value.length > 0
          "
        >
          <viscosity-chart
            *ngIf="model.chartConfig['viscosidad'].value.length > 0"
            [data]="model.chartConfig['viscosidad'].value"
            [isPrinting]="isGeneratingPDF"
            (event)="recursoCargado('viscosidad')"
          ></viscosity-chart>
          <br />
          <pqFeCu-chart
            *ngIf="model.chartConfig['pq_hierro_cobre'].value.length > 0"
            [data]="model.chartConfig['pq_hierro_cobre'].value"
            [isPrinting]="isGeneratingPDF"
            (event)="recursoCargado('pq_hierro_cobre')"
          ></pqFeCu-chart>
          <br />
          <alloyWear-chart
            *ngIf="model.chartConfig['desgaste_aleacion'].value.length > 0"
            [data]="model.chartConfig['desgaste_aleacion'].value"
            [isPrinting]="isGeneratingPDF"
            (event)="recursoCargado('desgaste_aleacion')"
          >
          </alloyWear-chart>
          <br />
          <pollution-chart
            *ngIf="model.chartConfig['contaminacion'].value.length > 0"
            [data]="model.chartConfig['contaminacion'].value"
            [isPrinting]="isGeneratingPDF"
            (event)="recursoCargado('contaminacion')"
          ></pollution-chart>
        </div>
      </div>
    </div>
    <div class="report__result" #reportEvalOne>
      <div class="card report__evaluation">
        <div class="d-flex flex-column gap-4">
          <dl class="description mb-3">
            <dd class="description__definition">Evaluación de la muestra</dd>
            <dt class="description__term" *ngIf="!model.inputTextComentario.visible">
              <ng-container *ngIf="model.muestra?.comentarios && model.muestra?.comentarios?.comentario">
                {{ model.muestra?.comentarios?.comentario }}<br />
              </ng-container>
              <ng-container *ngIf="!model.muestra?.comentarios || !model.muestra?.comentarios?.comentario">
                Las características determinadas a la muestra se encuentran entre los límites aceptados para el servicio.
              </ng-container>
            </dt>
          </dl>
          <dl class="description mb-3" *ngIf="model.muestra?.comentarioCliente && model.muestra?.comentarioCliente !== ''">
            <dd class="description__definition">Comentario del cliente</dd>
            <dt class="description__term">
              {{ model.muestra!.comentarioCliente }}
            </dt>
          </dl>
          <dl class="description mb-3" *ngIf="historialCambiosCliente() != ''">
            <dd class="description__definition">Historial de cambios del cliente</dd>
            <dt class="description__term">
              <div [innerHTML]="historialCambiosCliente()">
              </div>
            </dt>
          </dl>
        </div>
        <ng-container *ngIf="!model.muestra?.approverUser?.firma?.url">
          <div class="firm gap-3" *ngIf="model.laboratorio">
            <img class="convert_img_pdf" [src]="model.laboratorio.encargado.firma.url" alt="" crossorigin="anonymous" />

            <div class="d-flex flex-column">
              <span>{{ model.laboratorio.encargado.nombre }}</span
              ><b>Encargado Laboratorio</b><span>{{ model.laboratorio.encargado.phone }}</span>
            </div>
          </div>
          </ng-container>
          <ng-container *ngIf="model.muestra?.approverUser?.firma?.url">
            <div class="firm gap-3">
              <img class="convert_img_pdf" [src]="model.muestra.approverUser?.firma?.url" alt="" crossorigin="anonymous" />

              <div class="d-flex flex-column">
                <span>{{ model.muestra.approverUser?.nombre }}</span
                ><b>Encargado Laboratorio</b><span>{{ model.muestra.approverUser?.phone }}</span>
              </div>
            </div>
            </ng-container>
      </div>
    </div>
    <!-- <div class="col-7">
      <ul class="text-muted">
      <li><small><strong>N/R:</strong> No realizable</small></li>
      </ul>
    </div> -->
    <div #reportEval>

      <div class="disclaimer">
        <small
          >Los resultados se relacionan sólo con la información y la muestra entregada por el cliente, quien asume la completa
          responsabilidad del correcto muestreo, aplicación de los resultados y/o recomendaciones entregadas por Copec.</small
        >
        <span>
          <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.5083 0C18.5083 0 16.04 2.39471 13.3705 3.36017C-6.39764 10.5104 1.93943 24.4442 2.21142 24.4901C2.21142 24.4901 3.31992 22.5597 4.81734 21.4587C14.3193 14.4733 16.2506 6.4328 16.2506 6.4328C16.2506 6.4328 14.1184 16.1081 5.6649 22.1283C3.79784 23.4573 2.53362 26.7295 1.99847 30C1.99847 30 3.32194 29.4653 3.89104 29.3204C4.11297 27.8722 4.57696 26.4855 5.36142 25.2467C17.1761 26.6533 21.0457 17.1332 21.5512 13.8136C22.7443 5.97344 18.5083 0 18.5083 0Z"
              fill="#61D15C"
            />
          </svg>
          Por favor, imprima este informe sólo si es estrictamente necesario, todos somos responsables del medio ambiente.
        </span>
      </div>
    </div>
  </ng-template>

 <ng-template #page_recomendation_ia>
  <div>
    <div #reportNoisOne>
      <div class="report__top mt-3" #reportTop>
        <div class="report__title">
          <h1 class="mb-0">
            <b>Frasco {{ model.muestra.muestraId }} del {{ model.muestra.fechaIngreso }}</b>
          </h1>
          <h3>Informe de Resultados</h3>
        </div>
        <div class="report__brand">
          <img class="color" src="assets/images/logo-mobil-assistance_400_61.png" alt="" /><img
            class="black"
            src="assets/images/logo-mobil-assistance-negro.svg"
            alt=""
          />
        </div>
      </div>
      <div class="report__header mb-3" [hidden]="loading.inProgress" *ngIf="model.cliente" #reportHeader>
        <div class="card">
          <dl class="description mb-3">
            <dd class="description__definition">Cliente</dd>
            <dt class="description__term">
              {{ model.cliente.nombre }},<br />{{ model.cliente.faena.nombre }}<br />{{ model.cliente.faena.direccion }}<br />Contacto:
              {{ model.cliente.faena.contacto }}
            </dt>
          </dl>
        </div>
        <div class="card">
          <dl class="description mb-3">
            <dd class="description__definition">Responsable de la cuenta</dd>
            <dt class="description__term">
              {{ model.cliente.faena.responsable.nombre }}<br />{{ model.cliente.faena.responsable.email }}<br />{{
                model.cliente.faena.responsable.phone
              }}
            </dt>
          </dl>
        </div>
        <div class="card d-flex justify-content-between gap-1">
          <div>
            <dl class="description mb-4">
              <dd class="description__definition">Equipo</dd>
              <dt class="description__term">
                {{ model.muestra.componente.equipo?.tipoEquipo?.nombre }} {{ model.muestra.componente.equipo?.marca }}<br />
                {{ model.muestra.componente.equipo?.modelo }} {{ model.muestra.componente.equipo?.descriptor }}
              </dt>
            </dl>
          </div>
          <div>
            <dl class="description mb-3">
              <dd class="description__definition">Componente</dd>
              <dt class="description__term">
                {{ model.muestra.componente.tipoComponente.nombre | uppercase }}<br />
                {{ model.muestra.componente.marca }} {{ model.muestra.componente.modelo }}
                <br />
                Código {{ model.muestra.componente.componenteId }}
              </dt>
            </dl>
          </div>
        </div>
        <div class="card report__state report__state--{{ enumClass.reportState[estadoMuestra] }}">
          <dl class="description mb-3">
            <dd class="description__definition">Estado de la muestra</dd>
            <dt class="description__term">{{ enumResultado[estadoMuestra] | titlecase }}</dt>
          </dl>
          <ng-container [ngSwitch]="estadoMuestra">
            <em *ngSwitchCase="'normal'" class="bi bi-check-circle-fill"></em>
            <em *ngSwitchCase="'alerta'" class="bi bi-exclamation-triangle-fill"></em>
            <em *ngSwitchCase="'precaucion'" class="bi bi-exclamation-circle-fill"></em>
            <em *ngSwitchDefault class=""></em>
          </ng-container>
        </div>
      </div>
      <div class="report__detail" [hidden]="loading.inProgress" #reportDetail>
        <div class="report__analysis flex-fill">
            <table class="table table--detail table-horizontal" aria-describedby="Datos de la muestra">
              <thead>
                <tr>
                  <th class="text-start" colspan="4">
                    Datos de la Muestra
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of [0, 1, 2, 3, 4, 5]; let lastIndex = last">
                  <td *ngIf="model.datos.muestra[i]">
                    {{ model.datos.muestra[i].etiqueta }}
                  </td>
                  <td *ngIf="model.datos.muestra[i]">
                    {{ model.datos.muestra[i].valor(model.muestra) }}
                  </td>
                  <td  *ngIf="model.datos.muestra[i + 6]">
                    {{ model.datos.muestra[i + 6].etiqueta }}
                  </td>
                  <td *ngIf="model.datos.muestra[i + 6] && !lastIndex">
                    {{ model.datos.muestra[i + 6].valor(model.muestra) }}
                  </td>
                    <ng-container *ngIf="model.datos.muestra[i + 6] && lastIndex">
                    <td [ngClass]="getStateAttributes(model.datos.muestra[i + 6].valor(model.muestra)).stateClass">
                      <em [ngClass]="getStateAttributes(model.datos.muestra[i + 6].valor(model.muestra)).icon" class="me-2"></em>
                      {{ enumResultado[model.datos.muestra[i + 6].valor(model.muestra)] || model.datos.muestra[i + 6].valor(model.muestra) }}
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
    <div class="report__result mt-4 mb-2">
      <div class="card report__evaluation">
        <div class="d-flex flex-column gap-4">
          <dl class="description">
            <dd class="description__definition d-flex justify-content-start align-items-center">
                <em class="mb-2">
                  <img src="assets/images/IA-COPEC.svg" alt="IA" />
                </em>
               COMENTARIOS Y RECOMENDACIONES POR COPEC IA
            </dd>
            <dt class="description__definition mt-2">RECOMENDACIONES
            </dt>
            <dt class="description__term">
                <div [innerText]="model.muestra.diagnostico_ia?.diagnostico_ia??''"></div>
            </dt>
          </dl>
        </div>
      </div>

    </div>
    <ng-container *ngIf="!model.muestra?.approverUser?.firma?.url">
      <div class="firm flex-column" *ngIf="model.laboratorio">
        <div class="d-flex flex-column align-items-center">
          <img class="convert_img_pdf" [src]="model.laboratorio.encargado.firma.url" alt="" crossorigin="anonymous" />
          <p>{{ model.laboratorio.encargado.nombre }}</p>
          <b>RESPONSABLE APROBACIÓN</b>
          <p>{{ model.laboratorio.encargado.phone }}</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="model.muestra?.approverUser?.firma?.url">
      <div class="firm flex-column">
        <div class="d-flex flex-column align-items-center">
          <img class="convert_img_pdf" [src]="model.muestra.approverUser?.firma?.url" alt="" crossorigin="anonymous" />
          <p>{{ model.muestra.approverUser?.nombre }}</p>
          <b>RESPONSABLE APROBACIÓN</b>
          <p>{{ model.muestra.approverUser?.phone }}</p>
        </div>
      </div>
    </ng-container>
    <div >
      <div class="disclaimer">
        <small
          >Los resultados se relacionan sólo con la información y la muestra entregada por el cliente, quien asume la completa
          responsabilidad del correcto muestreo, aplicación de los resultados y/o recomendaciones entregadas por Copec.</small
        >
        <span>
          <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.5083 0C18.5083 0 16.04 2.39471 13.3705 3.36017C-6.39764 10.5104 1.93943 24.4442 2.21142 24.4901C2.21142 24.4901 3.31992 22.5597 4.81734 21.4587C14.3193 14.4733 16.2506 6.4328 16.2506 6.4328C16.2506 6.4328 14.1184 16.1081 5.6649 22.1283C3.79784 23.4573 2.53362 26.7295 1.99847 30C1.99847 30 3.32194 29.4653 3.89104 29.3204C4.11297 27.8722 4.57696 26.4855 5.36142 25.2467C17.1761 26.6533 21.0457 17.1332 21.5512 13.8136C22.7443 5.97344 18.5083 0 18.5083 0Z"
              fill="#61D15C"
            />
          </svg>
          Por favor, imprima este informe sólo si es estrictamente necesario, todos somos responsables del medio ambiente.
        </span>
      </div>
    </div>
  </div>
</ng-template>

  <ng-template #generic let-data="data">
    <ng-container>
      <div>
        <div class="report__detail" [hidden]="loading.inProgress">
          <div class="report__analysis flex-fill">
            <table class="table table--detail mt-3" style="border: none">
              <tbody #reportDetail_tableBody_generic>
                <ng-container *ngFor="let tipo of getOwnPropertyNames(data); let i = index">
                  <tr class="bg-gray" *ngIf="tipo && i === 0 && !data[tipo]">
                    <td colspan="2">{{ tipo }}</td>
                    <td>Unidad</td>
                    <td>Norma</td>
                    <ng-container *ngFor="let nro of createArrayFromNumberOfSamples(); let last = last">
                      <td *ngIf="!model.muestras[nro] && !last"></td>
                      <td
                        *ngIf="model.muestras[nro] && !last"
                        [ngClass]="{ 'col-highlight': model.muestras[nro].correlativo === model.muestra?.correlativo }"
                      >
                        <div
                          class="d-flex justify-content-start"
                          *ngIf="model.muestras[nro].correlativo === model.muestra?.correlativo"
                        ></div>
                      </td>
                      <td *ngIf="last">
                        <span> L&iacute;mites </span>
                      </td>
                    </ng-container>
                  </tr>

                  <tr class="bg-gray" *ngIf="i === 0">
                    <td colspan="2">{{ tipo }}</td>
                    <td></td>
                    <td></td>
                    <ng-container *ngFor="let nro of createArrayFromNumberOfSamples(); let last = last">
                      <td *ngIf="!model.muestras[nro] && !last"></td>
                      <td
                        *ngIf="model.muestras[nro] && !last"
                        [ngClass]="{ 'col-highlight': model.muestras[nro].correlativo === model.muestra?.correlativo }"
                      >
                        <div
                          class="d-flex justify-content-start"
                          *ngIf="model.muestras[nro].correlativo === model.muestra?.correlativo"
                        ></div>
                      </td>
                      <td *ngIf="last">L&iacute;mites</td>
                    </ng-container>
                  </tr>

                  <ng-container *ngFor="let subtipo of getOwnPropertyNames(data[tipo]); let tipo_index = index">
                    <tr style="border: none" *ngFor="let ensayo of orderAnalysis(data[tipo][subtipo].ensayos, tipo); let sub_index = index">
                      <ng-container *ngIf="!ensayo.hide">
                        <td
                          style="border-bottom: solid #efefef 1px; width: 70px; position: relative; overflow: hidden; height: 120px"
                          [attr.rowspan]="data[tipo][subtipo].ensayos.length"
                          *ngIf="sub_index == 0 && subtipo !== ''"
                        >
                          <div
                            style="
                              transform: rotate(-90deg);
                              position: absolute;
                              left: -54px;
                              text-align: center;
                              white-space: nowrap;
                              width: 120px;
                              text-align: center;
                            "
                            class="subtype"
                          >
                            {{ subtipo }}
                          </div>
                        </td>
                        <td
                          style="border-bottom: solid 1px #efefef; width: 230px"
                          [attr.colspan]="subtipo === '' ? 2 : 0"
                          class="txt--dark"
                        >
                          {{ ensayo.nombre | titlecase }}
                        </td>
                        <td style="border-bottom: solid 1px #efefef">{{ ensayo.unidad }}</td>
                        <td style="border-bottom: solid 1px #efefef">{{ ensayo.norma }}</td>
                        <ng-container *ngFor="let nro of createArrayFromNumberOfSamples(); let last = last">
                          <td
                            style="width: 130px"
                            *ngIf="!last"
                            cell-report
                            [muestra]="model.muestras[nro]"
                            #cell
                            [ensayo]="ensayo"
                            [esMuestraBase]="model.muestras[nro]?.correlativo === model.muestra?.correlativo"
                            [class]="
                              model.muestras[nro]?.correlativo === model.muestra?.correlativo ? getClass(ensayo) : 'simple-line-bottom'
                            "
                          ></td>
                          <td style="border-bottom: solid 1px #efefef; width: 75px" *ngIf="last">
                            <ng-container [ngSwitch]="ensayo.tipoDato?.tipo">
                              <ng-container *ngSwitchCase="'text'"> </ng-container>
                              <ng-container *ngSwitchCase="'boolean'"> </ng-container>
                              <ng-container *ngSwitchDefault>
                                {{ { ensayo: ensayo, muestra: model.muestra } | limit_report }}
                              </ng-container>
                            </ng-container>
                          </td>
                        </ng-container>
                      </ng-container>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #ferro let-data="data">
    <div>
      <div class="report__top mt-0" #reportTop>
        <div class="report__title">
          <h1 class="mb-0">
            <b>Frasco {{ model.muestra.muestraId }} del {{ model.muestra.fechaIngreso | date : "dd-MM-yyyy" }}</b>
          </h1>
          <h3>Informe de Resultados</h3>
        </div>
        <div class="report__brand">
          <img class="color" src="assets/images/logo-mobil-assistance_400_61.png" alt="" /><img
            class="black"
            src="assets/images/logo-mobil-assistance-negro.svg"
            alt=""
          />
        </div>
      </div>
      <div class="report__header mb-3" [hidden]="loading.inProgress" *ngIf="model.cliente" #reportHeader>
        <div class="card">
          <dl class="description mb-3">
            <dd class="description__definition">Cliente</dd>
            <dt class="description__term">
              {{ model.cliente.nombre }},<br />{{ model.cliente.faena.nombre }}<br />{{ model.cliente.faena.direccion }}<br />Contacto:
              {{ model.cliente.faena.contacto }}
            </dt>
          </dl>
        </div>
        <div class="card">
          <dl class="description mb-3">
            <dd class="description__definition">Responsable de la cuenta</dd>
            <dt class="description__term">
              {{ model.cliente.faena.responsable.nombre }}<br />{{ model.cliente.faena.responsable.email }}<br />{{
                model.cliente.faena.responsable.phone
              }}
            </dt>
          </dl>
        </div>
        <div class="card d-flex justify-content-between gap-1">
          <div>
            <dl class="description mb-4">
              <dd class="description__definition">Equipo</dd>
              <dt class="description__term">
                {{ model.muestra.componente.equipo?.tipoEquipo?.nombre }} {{ model.muestra.componente.equipo?.marca }}<br />
                {{ model.muestra.componente.equipo?.modelo }} {{ model.muestra.componente.equipo?.descriptor }}
              </dt>
            </dl>
          </div>
          <div>
            <dl class="description mb-3">
              <dd class="description__definition">Componente</dd>
              <dt class="description__term">
                {{ model.muestra.componente.tipoComponente.nombre | uppercase }}<br />
                {{ model.muestra.componente.marca }} {{ model.muestra.componente.modelo }}
                <br />
                Código {{ model.muestra.componente.componenteId }}
              </dt>
            </dl>
          </div>
        </div>
        <div class="card report__state report__state--{{ enumClass.reportState[estadoMuestra] }}">
          <dl class="description mb-3">
            <dd class="description__definition">Estado de la muestra</dd>
            <dt class="description__term">{{ enumResultado[estadoMuestra] | titlecase }}</dt>
          </dl>
          <ng-container [ngSwitch]="estadoMuestra">
            <em *ngSwitchCase="'normal'" class="bi bi-check-circle-fill"></em>
            <em *ngSwitchCase="'alerta'" class="bi bi-exclamation-triangle-fill"></em>
            <em *ngSwitchCase="'precaucion'" class="bi bi-exclamation-circle-fill"></em>
            <em *ngSwitchDefault class=""></em>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="report__top mt-3">
      <div class="report__title">
        <h1 class="mb-0"><b>ANÁLISIS DE FERROGRAFÍA</b></h1>
      </div>
    </div>
    <div class="report__gallery">
      <ng-container *ngFor="let ensayo of model.datos.analisis.ferrografia; let index = index">
        <div class="card d-flex flex-column" *ngIf="ensayo.image !== ''">
          <b>{{ ferroImgNombre(ensayo) }}</b>
          <div class="picture square">
            <img class="convert_img_pdf img-ferro" crossorigin="anonymous" [src]="ensayo.image" *ngIf="ensayo.image" />
          </div>
        </div>
      </ng-container>
      <div
        class="card d-flex flex-column"
        *ngIf="model.datos.analisis.ferro_comentario_general && model.datos.analisis.ferro_comentario_general.image !== ''"
      >
        <b>Imagen Comentario General</b>
        <div class="picture square">
          <img
            class="convert_img_pdf img-ferro"
            crossorigin="anonymous"
            [src]="model.datos.analisis.ferro_comentario_general.image"
            *ngIf="model.datos.analisis.ferro_comentario_general.image"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between gap-3 mt-3 report__column">
      <div>
        <div class="card d-flex flex-column mb-3">
          <h5><b class="mb-2">COMENTARIO DE LAS IMÁGENES OBTENIDAS</b></h5>
          <ng-container *ngFor="let ensayo of model.datos.analisis.ferrografia; let index = index">
            <dl class="description" *ngIf="ensayo.image">
              <dd class="description__definition">{{ ferroImgNombre(ensayo) }}</dd>
              <dt class="description__term">{{ ensayo.value }}</dt>
            </dl>
          </ng-container>
          <dl
            class="description"
            *ngIf="
              model.datos.analisis.ferrografia_tabla &&
              model.datos.analisis.ferrografia_tabla.value &&
              model.datos.analisis.ferrografia_tabla.image
            "
          >
            <dd class="description__definition">Tabla de ferrografía</dd>
            <dt class="description__term">{{ model.datos.analisis.ferrografia_tabla.value }}</dt>
          </dl>
          <dl class="description" *ngIf="ensayosSinImagen('ferro')">
            <dd class="description__definition">Otros comentarios</dd>
            <ng-container *ngFor="let ensayo of model.datos.analisis.ferrografia; let index = index">
              <dt class="description__term" *ngIf="!ensayo.image">
                {{ ensayo.value }}
              </dt>
            </ng-container>
          </dl>
        </div>
        <div
          class="card d-flex flex-column"
          *ngIf="model.datos.analisis.ferro_comentario_general && model.datos.analisis.ferro_comentario_general!.value"
        >
          <h5><b class="mb-3">COMENTARIO GENERAL </b></h5>
          <p>
            {{ model.datos.analisis.ferro_comentario_general!.value }}
          </p>
        </div>
      </div>
      <div>
        <div
          class="card d-flex flex-column card-analisis h-100"
          *ngIf="model.datos.analisis.ferrografia_tabla && model.datos.analisis.ferrografia_tabla.image"
        >
          <h5 class="mb-0">
            <b class="text-uppercase"> Tabla de Ferrografía</b>
          </h5>
          <img class="img-fluid" crossorigin="anonymous" [src]="model.datos.analisis.ferrografia_tabla.image" alt="" />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #turb let-data="data">
    <ng-container *ngIf="data.length > 0">
      <div #reportNoisOne>
        <div class="report__top mt-0" #reportTop>
          <div class="report__title">
            <h1 class="mb-0">
              <b>Frasco {{ model.muestra.muestraId }} del {{ model.muestra.fechaIngreso | date : "dd-MM-yyyy" }}</b>
            </h1>
            <h3>Informe de Resultados</h3>
          </div>
          <div class="report__brand">
            <img class="color" src="assets/images/logo-mobil-assistance_400_61.png" alt="" /><img
              class="black"
              src="assets/images/logo-mobil-assistance-negro.svg"
              alt=""
            />
          </div>
        </div>
        <div class="report__header mb-3" [hidden]="loading.inProgress" *ngIf="model.cliente" #reportHeader>
          <div class="card">
            <dl class="description mb-3">
              <dd class="description__definition">Cliente</dd>
              <dt class="description__term">
                {{ model.cliente.nombre }},<br />{{ model.cliente.faena.nombre }}<br />{{ model.cliente.faena.direccion }}<br />Contacto:
                {{ model.cliente.faena.contacto }}
              </dt>
            </dl>
          </div>
          <div class="card">
            <dl class="description mb-3">
              <dd class="description__definition">Responsable de la cuenta</dd>
              <dt class="description__term">
                {{ model.cliente.faena.responsable.nombre }}<br />{{ model.cliente.faena.responsable.email }}<br />{{
                  model.cliente.faena.responsable.phone
                }}
              </dt>
            </dl>
          </div>
          <div class="card d-flex justify-content-between gap-1">
            <div>
              <dl class="description mb-4">
                <dd class="description__definition">Equipo</dd>
                <dt class="description__term">
                  {{ model.muestra.componente.equipo?.tipoEquipo?.nombre }} {{ model.muestra.componente.equipo?.marca }}<br />
                  {{ model.muestra.componente.equipo?.modelo }} {{ model.muestra.componente.equipo?.descriptor }}
                </dt>
              </dl>
            </div>
            <div>
              <dl class="description mb-3">
                <dd class="description__definition">Componente</dd>
                <dt class="description__term">
                  {{ model.muestra.componente.tipoComponente.nombre | uppercase }}<br />
                  {{ model.muestra.componente.marca }} {{ model.muestra.componente.modelo }}
                  <br />
                  Código {{ model.muestra.componente.componenteId }}
                </dt>
              </dl>
            </div>
          </div>
          <div class="card report__state report__state--{{ enumClass.reportState[estadoMuestra] }}">
            <dl class="description mb-3">
              <dd class="description__definition">Estado de la muestra</dd>
              <dt class="description__term">{{ enumResultado[estadoMuestra] | titlecase }}</dt>
            </dl>
            <ng-container [ngSwitch]="estadoMuestra">
              <em *ngSwitchCase="'normal'" class="bi bi-check-circle-fill"></em>
              <em *ngSwitchCase="'alerta'" class="bi bi-exclamation-triangle-fill"></em>
              <em *ngSwitchCase="'precaucion'" class="bi bi-exclamation-circle-fill"></em>
              <em *ngSwitchDefault class=""></em>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="report__top mt-3">
        <div class="report__title">
          <h1 class="mb-0"><b>ANÁLISIS DE TURBINA</b></h1>
        </div>
      </div>
      <ng-container *ngFor="let ensayo of model.datos.analisis.turbina">
        <div class="d-flex flex-column my-3" *ngIf="!model.turbina_foot[ensayo.code]">
          <div class="d-flex">
            <h4 class="mb-0">
              <b>Respaldo {{ ensayo.nombre }}</b>
            </h4>
          </div>
          <div class="d-flex justify-content-start gap-3">
            <ng-container *ngFor="let nro of createArrayFromNumberOfSamples(0); let index = index; let last = last">
              <div class="card card--turb d-flex flex-column" *ngIf="model.muestras[nro]">
                <b>Nº LABORATORIO {{ model.muestras[nro].correlativo }}</b>
                <span class="d-flex justify-content-center align-items-center">
                  <div class="picture rectangle">
                    <img
                      *ngIf="buscarImagen(ensayo, model.muestras[nro])?.url"
                      class="convert_img_pdf"
                      crossorigin="anonymous"
                      [src]="buscarImagen(ensayo, model.muestras[nro])?.url"
                      alt=""
                    />
                  </div>
                </span>
                <small
                  >Resultado:
                  {{ ultimoResultado(ensayo, model.muestras[nro])?.value }}
                </small>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div class="d-flex justify-content-between gap-3 my-3 report__column pb-3">
          <ng-container *ngFor="let ensayo of model.datos.analisis.turbina">
            <div *ngIf="model.turbina_foot[ensayo.code]">
              <div class="card d-flex flex-column h-100 card-analisis">
                <h5 class="mb-0">
                  <b>Imagen del {{ ensayo.nombre }}</b>
                </h5>
                <small>
                  {{ ultimoResultado(ensayo, model.muestra)?.value ? "Resultado: " + ultimoResultado(ensayo, model.muestra)?.value : "" }}
                </small>
                <img
                  *ngIf="buscarImagen(ensayo, model.muestra)?.url"
                  class="convert_img_pdf img-fluid"
                  crossorigin="anonymous"
                  [src]="buscarImagen(ensayo, model.muestra)?.url"
                  alt=""
                />
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #memb let-data="data">
    <div #reportNoisOne>
      <div class="report__top mt-0" #reportTop>
        <div class="report__title">
          <h1 class="mb-0">
            <b>Frasco {{ model.muestra.muestraId }} del {{ model.muestra.fechaIngreso | date : "dd-MM-yyyy" }}</b>
          </h1>
          <h3>Informe de Resultados</h3>
        </div>
        <div class="report__brand">
          <img class="color" src="assets/images/logo-mobil-assistance_400_61.png" alt="" /><img
            class="black"
            src="assets/images/logo-mobil-assistance-negro.svg"
            alt=""
          />
        </div>
      </div>
      <div class="report__header mb-3" [hidden]="loading.inProgress" *ngIf="model.cliente" #reportHeader>
        <div class="card">
          <dl class="description mb-3">
            <dd class="description__definition">Cliente</dd>
            <dt class="description__term">
              {{ model.cliente.nombre }},<br />{{ model.cliente.faena.nombre }}<br />{{ model.cliente.faena.direccion }}<br />Contacto:
              {{ model.cliente.faena.contacto }}
            </dt>
          </dl>
        </div>
        <div class="card">
          <dl class="description mb-3">
            <dd class="description__definition">Responsable de la cuenta</dd>
            <dt class="description__term">
              {{ model.cliente.faena.responsable.nombre }}<br />{{ model.cliente.faena.responsable.email }}<br />{{
                model.cliente.faena.responsable.phone
              }}
            </dt>
          </dl>
        </div>
        <div class="card d-flex justify-content-between gap-1">
          <div>
            <dl class="description mb-4">
              <dd class="description__definition">Equipo</dd>
              <dt class="description__term">
                {{ model.muestra.componente.equipo?.tipoEquipo?.nombre }} {{ model.muestra.componente.equipo?.marca }}<br />
                {{ model.muestra.componente.equipo?.modelo }} {{ model.muestra.componente.equipo?.descriptor }}
              </dt>
            </dl>
          </div>
          <div>
            <dl class="description mb-3">
              <dd class="description__definition">Componente</dd>
              <dt class="description__term">
                {{ model.muestra.componente.tipoComponente.nombre | uppercase }}<br />
                {{ model.muestra.componente.marca }} {{ model.muestra.componente.modelo }}
                <br />
                Código {{ model.muestra.componente.componenteId }}
              </dt>
            </dl>
          </div>
        </div>
        <div class="card report__state report__state--{{ enumClass.reportState[estadoMuestra] }}">
          <dl class="description mb-3">
            <dd class="description__definition">Estado de la muestra</dd>
            <dt class="description__term">{{ enumResultado[estadoMuestra] | titlecase }}</dt>
          </dl>
          <ng-container [ngSwitch]="estadoMuestra">
            <em *ngSwitchCase="'normal'" class="bi bi-check-circle-fill"></em>
            <em *ngSwitchCase="'alerta'" class="bi bi-exclamation-triangle-fill"></em>
            <em *ngSwitchCase="'precaucion'" class="bi bi-exclamation-circle-fill"></em>
            <em *ngSwitchDefault class=""></em>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="report__top mt-3">
      <div class="report__title">
        <h1 class="mb-0"><b>ANÁLISIS DE MEMBRANA</b></h1>
      </div>
    </div>
    <div class="d-flex justify-content-between my-3 report__column align-items-stretch">
      <div class="d-flex align-items-stretch">
        <div class="report__gallery flex-nowrap">
          <div class="card d-flex flex-column h-100" *ngIf="model.datos.analisis.membrana_img_frasco?.image">
            <b>FOTO DEL FRASCO</b>
            <div class="picture">
              <img class="convert_img_pdf" [src]="model.datos.analisis.membrana_img_frasco!.image" alt="" crossorigin="anonymous" />
            </div>
          </div>
          <div class="card d-flex flex-column h-100" *ngIf="model.datos.analisis.membrana_img_filtro?.image">
            <b>FOTO DEL FILTRO</b>
            <div class="picture">
              <img
                *ngIf="model.datos.analisis.membrana_img_filtro!.image"
                class="convert_img_pdf"
                [src]="model.datos.analisis.membrana_img_filtro!.image"
                alt=""
                crossorigin="anonymous"
              />
            </div>
          </div>
        </div>

        <div
          class="card d-flex flex-column card-analisis"
          *ngIf="
            model.datos.analisis.membrana_tabla_1!.image &&
            !model.datos.analisis.membrana_img_filtro?.image &&
            !model.datos.analisis.membrana_img_frasco?.image
          "
        >
          <h5 class="mb-0">
            <b>{{ model.datos.analisis.membrana_tabla_1 ? model.datos.analisis.membrana_tabla_1.nombre : "" }}</b>
          </h5>
          <img class="mx-100" src="{{ model.datos.analisis.membrana_tabla_1!.image }}" alt="" crossorigin="anonymous" />
        </div>
      </div>

      <div
        class="card d-flex flex-column card-analisis"
        *ngIf="
          model.datos.analisis.membrana_tabla_1!.image &&
          (model.datos.analisis.membrana_img_filtro?.image || model.datos.analisis.membrana_img_frasco?.image)
        "
      >
        <h5 class="mb-0">
          <b class="text-uppercase">Tabla de Membrana</b>
        </h5>
        <img class="mx-100" src="{{ model.datos.analisis.membrana_tabla_1!.image }}" alt="" crossorigin="anonymous" />
      </div>
    </div>
    <div class="report__gallery">
      <ng-container *ngFor="let ensayo of model.datos.analisis.membrana; let index = index">
        <div class="card d-flex flex-column" *ngIf="ensayo.image">
          <b>{{ membranaImgNombre(ensayo) }}</b>
          <div class="picture square">
            <img class="convert_img_pdf" crossorigin="anonymous" [src]="ensayo.image" />
          </div>
        </div>
      </ng-container>
      <div
        class="card d-flex flex-column"
        *ngIf="model.datos.analisis.memb_comentario_general && model.datos.analisis.memb_comentario_general.image !== ''"
      >
        <b>Imagen Comentario General</b>
        <div class="picture square">
          <img
            class="convert_img_pdf img-ferro"
            crossorigin="anonymous"
            [src]="model.datos.analisis.memb_comentario_general.image"
            *ngIf="model.datos.analisis.memb_comentario_general.image"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between my-3 report__column align-items-start">
      <div>
        <div class="card d-flex flex-column mb-1">
          <h5><b class="mb-2">COMENTARIO DE LAS IMÁGENES OBTENIDAS</b></h5>
          <ng-container *ngFor="let ensayo of model.datos.analisis.membrana; let index = index">
            <dl class="description" *ngIf="ensayo.image">
              <dd class="description__definition">{{ membranaImgNombre(ensayo) }}</dd>
              <dt class="description__term">{{ ensayo.value }}</dt>
            </dl>
          </ng-container>
          <dl class="description mb-3" *ngIf="model.datos.analisis.membrana_tabla_1?.value">
            <dd class="description__definition">Tabla de membrana(imagen superior)</dd>
            <dt class="description__term">
              {{ model.datos.analisis.membrana_tabla_1!.value }}
            </dt>
          </dl>
          <dl class="description mb-3" *ngIf="model.datos.analisis.membrana_tabla_2?.value">
            <dd class="description__definition">Tabla de membrana(imagen inferior)</dd>
            <dt class="description__term">
              {{ model.datos.analisis.membrana_tabla_2!.value }}
            </dt>
          </dl>
          <dl class="description" *ngIf="ensayosSinImagen('memb')">
            <dd class="description__definition">Otros comentarios</dd>
            <ng-container *ngFor="let ensayo of model.datos.analisis.membrana; let index = index">
              <dt class="description__term" *ngIf="!ensayo.image">
                {{ ensayo.value }}
              </dt>
            </ng-container>
          </dl>
        </div>
        <div
          class="card d-flex flex-column"
          *ngIf="model.datos.analisis.memb_comentario_general && model.datos.analisis.memb_comentario_general!.value"
        >
          <h5><b class="mb-3">COMENTARIO GENERAL </b></h5>
          <p>
            {{ model.datos.analisis.memb_comentario_general!.value }}
          </p>
        </div>
      </div>
      <div class="card d-flex flex-column card-analisis" *ngIf="model.datos.analisis.membrana_tabla_2!.image">
        <h5 class="mb-0">
          <b class="text-uppercase">Tabla de Membrana</b>
        </h5>
        <img class="mx-100" src="{{ model.datos.analisis.membrana_tabla_2!.image }}" alt="" crossorigin="anonymous" />
      </div>
    </div>
  </ng-template>
</div>

<div class="printed" *ngIf="printed">
  <div class="printed-icon">
    <ng-lottie [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
  </div>
  <h2>Generando PDF de Informe</h2>
  <p>Por ser la primera vez que generemos este informe nos tomará un tiempo, la próxima vez será mas rápido.</p>
</div>
