<div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3">
        <div class="col-auto d-none d-sm-block">
            <h3>Agregar muestra</h3>
        </div>

        <div class="col-auto ms-auto text-end mt-n1">
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-10">
            <ul class="stepper stepper-horizontal">
                <li class="completed">
                    <a [routerLink]="" class="px-2">
                        <span class="circle">1</span><span class="label">Datos del componente</span>
                    </a>
                </li>

                <li class="completed">
                    <a [routerLink]="" class="px-2">
                        <span class="circle">2</span><span class="label">Datos de la muestra</span>
                    </a>
                </li>

                <li  class="active">
                    <a [routerLink]="" class="px-2">
                        <span class="circle">3</span><span class="label">Comprobante</span>
                    </a>
                </li>

            </ul>
        </div>
    </div>


    <div class="row justify-content-center" *ngIf="!model.registroMultple">
        <div class="col-sm-12 col-md-10 col-lg-7 col-xl-5 col-xxl-4">
              <h2 class="tit-comprobante success">La muestra {{model.muestras[0].muestraId}} quedó ingresada en la caja de muestras</h2>
              <div class="alert alert-warning" role="alert" *ngIf="model.muestras[0].lubricante?.lubricanteId==='0'">
                        <div class="alert-message">
                            Deberá incluir adicionalmente una muestra del lubricante Nombre Lubricante <strong>NUEVO SIN USO</strong> para que el laboratorio pueda analizarlo.
                        </div>
                </div>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="model.registroMultple">
        <div class="col-sm-12 col-md-10 col-lg-7 col-xl-5 col-xxl-4">
          <h2 class="tit-comprobante success">Las muestras quedaron ingresadas en la caja de muestras:</h2>
            <div class="row justify-content-center mb-5">
                <div class="col-6 text-center" *ngFor="let muestra of model.muestras"><h4> {{muestra.componente.componenteId}}</h4></div>
            </div>
          </div>
      </div>
    <div class="row justify-content-center">
        <div class="col text-center">
              <button id="comprobante-muestra-otra-muestra" class="btn btn-lg btn-light btn-labeled" (click)="irAbusquedaComponente()">Agregar otra muestra</button><button id="agregar-muestra-ir-caja-muestra" class="btn btn-lg btn-light" (click)="irACajaDeMuestras()">Ir a la caja de muestras <span class="badge rounded-pill bg-danger">{{ model.sampleBoxData?.muestras?.length || 0}}</span></button>
        </div>
    </div>

</div>
