import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { GoogleChartsModule } from 'angular-google-charts';
import { MatDialogModule } from '@angular/material/dialog';
import { ReplayModalComponent } from './components/replay-modal/replay-modal.component';
import { MatMenuModule } from '@angular/material/menu';
import { ViscosityChartComponent } from './components/chart/viscosity/viscosity.component';
import { PqFeCuChartComponent } from './components/chart/pqFeCu/pqFeCu.component';
import { AlloyWearChartComponent } from './components/chart/alloyWear/alloyWear.component';
import { PollutionChartComponent } from './components/chart/pollution/pollution.component';
import { CellReportComponent } from './components/report-cell/cell-report.component';
import { ReportExportPDF } from './views/report-result-component/report-result-component.component';
import { ReportResultPivotComponent } from './views/report-result-pivot-component/report-result-pivot.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { FormatLimitDirective } from './directives/report-format-limit/formatLimitReport.directive';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { ModalGalleryComponent } from './components/modal-gallery/modal-gallery.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        ReplayModalComponent,
        ViscosityChartComponent,
        PqFeCuChartComponent,
        AlloyWearChartComponent,
        PollutionChartComponent,
        CellReportComponent,
        ModalGalleryComponent,
        ReportExportPDF,
        ReportResultPivotComponent,
        FormatLimitDirective,
    ],
    imports: [
        CommonModule,
        ReportsRoutingModule,
        GoogleChartsModule,
        MatDialogModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        LottieModule.forRoot({ player: playerFactory }),
    ],
    exports: [ReportExportPDF],
})
export class ReportsModule {}
