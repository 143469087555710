<div class="fade show" id="modal-equipo" tabindex="-1" aria-labelledby="modal-equipo-label" style="display: block;" aria-modal="true" role="dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Equipo</h5>
            <button type="button" class="btn-close"  aria-label="Close" (click)="cerrar()"></button>
        </div>
        <div class="modal-body text-start">
            <div class="datos-equipo">
                <div class="row">
                <div class="col-12 col-xl-3 mb-2">
                    <div class="tipo-codigo-equipo {{ model.equipo.tipoEquipo.nombre | lowercase }}">
                    <h3>{{ model.equipo.tipoEquipo.nombre }}</h3>
                    <h4>{{ model.equipo.equipoId }}</h4>
                    </div>
                </div>
                <div class="col-12 col-xl-9">
                    <div class="row">
                    <div class="col">
                        <table>
                        <tbody><tr>
                            <td>Marca</td>
                            <td><strong>{{ model.equipo.marca }}</strong></td>
                        </tr>
                        <tr>
                            <td>Modelo</td>
                            <td><strong>{{ model.equipo.modelo }}</strong></td>
                        </tr>
                        <tr>
                            <td>Alias</td>
                            <td>
                                <span *ngFor="let alia of model.equipo.alias"><strong>{{ alia }}</strong>&nbsp;</span>
                            </td>
                        </tr>
                        </tbody></table>
                    </div>
                    <div class="col">
                        <table>
                        <tbody><tr>
                            <td>Descripción</td>
                        </tr>
                        <tr>
                            <td><strong>{{ model.equipo.descriptor }}</strong></td>
                        </tr>
                        </tbody></table>
                    </div>
                    <div class="col">
                        <table>
                        <tbody><tr>
                            <td>Etiquetas</td>
                        </tr>
                        <tr>
                            <td>
                                <span class="badge bg-secondary me-1" *ngFor="let tag of model.equipo.tags">{{ tag }}&nbsp;</span>
                            </td>
                        </tr>
                        </tbody></table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <h4>Componentes del equipo</h4>
            <div class="col-md-12" *ngIf="model.componentesEquipo.length > 0 && !model.cargandoComponentes">
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1">
                  <ng-container matColumnDef="componenteId">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                      <td mat-cell *matCellDef="let e">
                          <a (click)="verComponente(e)"><strong>{{ e.componenteId }}</strong></a>
                      </td>
                  </ng-container>

                  <ng-container matColumnDef="tipo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                      <td mat-cell *matCellDef="let e">
                          <span class="icono-componente cargador">{{ e.tipoComponente.nombre }}</span>
                      </td>
                  </ng-container>

                  <ng-container matColumnDef="marca">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Marca </th>
                      <td mat-cell *matCellDef="let e"> {{ e.marca }}</td>
                  </ng-container>

                  <ng-container matColumnDef="modelo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Modelo </th>
                      <td mat-cell *matCellDef="let e">{{ e.modelo }}</td>
                  </ng-container>

                  <ng-container matColumnDef="poscicion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Posición </th>
                      <td mat-cell *matCellDef="let e"> {{e.ubicacion}} </td>
                  </ng-container>

                  <ng-container matColumnDef="ultimaMuestra">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Última muestra</th>
                    <td mat-cell *matCellDef="let c">
                      <strong>{{c.ultimaMuestra.muestraId}}</strong><br>
                      {{c.ultimaMuestra.fechaMuestreo | date: 'dd-MM-yyyy'}}
                    </td>
                </ng-container>

                  <ng-container matColumnDef="estado">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                      <td mat-cell *matCellDef="let c">
                        <div class="d-flex align-items-center gap-1">
                            <span *ngIf="c.ultimaMuestra.estado" class="indicator" [ngClass]="{
                                'normal': c.ultimaMuestra.estado == 'normal',
                                'danger': c.ultimaMuestra.estado === 'alerta',
                                'warning': c.ultimaMuestra.estado == 'precaucion'
                            }"></span>
                            <strong>{{enumStatus[c.ultimaMuestra.estado]}}</strong>
                        </div>

                      </td>
                  </ng-container>

                  <ng-container matColumnDef="accion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                      <td mat-cell *matCellDef="let e">  </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="6">No data matching the filter</td>
                  </tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" (page)="handlePageEvent($event)" [length]="model.pagination.totalElements" [pageSize]="model.pagination.pageSize" [pageIndex]="model.pagination.page" showFirstLastButtons></mat-paginator>
            </div>

            <div class="col-md-12" *ngIf="model.cargandoComponentes">
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1">
                <ng-container matColumnDef="componenteId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let e">
                    </td>
                </ng-container>

                <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                    <td mat-cell *matCellDef="let e">
                    </td>
                </ng-container>

                <ng-container matColumnDef="marca">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Marca </th>
                    <td mat-cell *matCellDef="let e"></td>
                </ng-container>

                <ng-container matColumnDef="modelo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Modelo </th>
                    <td mat-cell *matCellDef="let e"></td>
                </ng-container>

                <ng-container matColumnDef="poscicion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Posición </th>
                    <td mat-cell *matCellDef="let e"></td>
                </ng-container>

                <ng-container matColumnDef="ultimaMuestra">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Última muestra </th>
                    <td mat-cell *matCellDef="let e">  </td>
                </ng-container>

                <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                    <td mat-cell *matCellDef="let e">  </td>
                </ng-container>

                <ng-container matColumnDef="accion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let e">  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="8">
                        <div class="row">
                            <div class="col-md-6" style="text-align: right;">
                                Cargando componentes
                            </div>
                            <div class="col-md-6">
                                <mat-spinner *ngIf="model.cargandoComponentes" matSuffix [diameter]="18" style="margin-left: 8px"></mat-spinner>
                            </div>
                        </div>
                    </td>
                </tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" (page)="handlePageEvent($event)" [length]="model.pagination.totalElements" [pageSize]="model.pagination.pageSize" [pageIndex]="model.pagination.page" showFirstLastButtons></mat-paginator>
            </div>

            <div *ngIf="model.componentesEquipo.length == 0 && !model.cargandoComponentes">
                <div class="col-md-12">
                    <div class="mb-3 mt-5" id="alerta-sin-resultado">
                        <div class="alert alert-info text-start" role="alert">
                            <div class="alert-message text-center">
                                <h4 class="alert-heading">Nada por aquí!</h4>
                                <p class="mb-0">Este equipo no tiene componentes.</p>
                                <br>
                                <button class="btn btn-primary" (click)="agregarComponente()"><i class="bi bi-plus-lg"></i> Agregar componente</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer justify-content-between">
            <div>
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="editar()"><i class="bi bi-pencil"></i> Editar</button>
                <button type="button" class="btn btn-light danger" data-bs-dismiss="modal"
                (click)="eliminarEquipo()"><i class="bi bi-trash"></i> Eliminar</button>
            </div>
            <button type="button" class="btn btn-light" (click)="agregarComponente()" data-bs-dismiss="modal"><i class="bi bi-plus-lg"></i> Agregar componente</button>
        </div>
    </div>
</div>

