import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { Enums } from "../models/enums";
import { Objects } from "../models/objects";

@Injectable({ providedIn: 'root' })
export class LogService{

    constructor(
        private http: HttpClient
    ) {}

    public createLog(logInput: Objects.LogInput, spinner: boolean = false) {
        let request: Objects.LogRequest = {
            log: logInput,
        }
        request.log.fecha = moment().format("DD/MM/YYYY h:mm:ss a");
        this.SetLog(request, {spinner}).then(r => {
            if(r.code == 200) {
            }
        })
    }

    private SetLog(request: Objects.LogRequest, opts: { spinner: boolean } = { spinner: false }): Promise<Objects.LogResponse> {
        return this.http.post<Objects.LogResponse>(`${environment.apiUrl}${environment.routes.log}`, request.log).toPromise();
    }
}