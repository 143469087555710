<h4>Equipos y Componentes</h4>
<br>
<ul class="nav nav-tabs">
    <li class="nav-item">
        <a class="nav-link" [routerLink]="['/mantenedor-equipos']">Equipos</a>
    </li>
    <li class="nav-item">
        <a class="nav-link active" [routerLink]="['/mantenedor-componentes']"><strong>Componentes</strong>
            <mat-spinner matSuffix *ngIf="model.cargandoComponentes" [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner></a>
    </li>
</ul>
<br>
<div class="row">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
        <input type="search" class="form-control mb-4" placeholder="Buscar..." [formControl]="myControl" [(ngModel)]="model.componenteFiltro" (keyup)="enterInputSearch()">
    </div>
    <div class="col text-end">
      <button class="btn bt-secondary text-white  mb-4 mx-4" [disabled]="this.selection.selected.length==0" (click)="modalMoverComponentes()">Mover<span class="badge mx-1 bg-opacity-10">{{ this.selection.selected.length}}</span></button>
        <a class="btn btn-primary mb-4" (click)="nuevoComponente()"><i class="bi bi-plus-lg mx-2"></i>Agregar Componente</a>
    </div>
</div>

<div class="mb-3 mt-5" id="alerta-sin-resultado" *ngIf="model.alertaSinResultado">
    <div class="alert alert-info text-start" role="alert">
        <div class="alert-message">
            <h4 class="alert-heading">Nada por aquí!</h4>
            <p>No hemos encontrado ningún componente que contenga el texto que buscas, por favor ajusta tu búsqueda.</p>
            <hr>
        </div>
    </div>
</div>

<div id="mant-componentes">

    <div class="card" *ngIf="!model.alertaSinResultado">

        <div class="table-responsive">
            <div *ngIf="model.componenteFiltro" class="card-header">
                <h5 class="card-title mb-0"><strong> Componentes registrados que contienen <span>{{model.componenteFiltro}}</span></strong></h5>
            </div>
            <table mat-table [dataSource]="dataSource" matSort class="w-100">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : masterUntoggle()"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()"
                                [color]="'primary'"
                                >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : unchecked(row)"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)"
                                [color]="'primary'"
                                >
                  </mat-checkbox>



                </td>
              </ng-container>
                <ng-container matColumnDef="componenteId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Código de componente </th>
                    <td mat-cell *matCellDef="let c">
                        <a [routerLink]="" (click)="verComponente(c)"><strong>{{ c.componenteId }}</strong></a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="componente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Componente </th>
                    <td class="pTopDown10" mat-cell *matCellDef="let c">
                        <span class="icono-componente mandofinal">
                            <strong>{{ c.tipoComponente.nombre }}</strong><br>
                            {{ c.marca }} {{ c.modelo }}<br>
                            {{ c.ubicacion }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="equipo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Equipo </th>
                    <td class="pTopDown10" mat-cell *matCellDef="let c">
                        <span class="icono-equipo {{ c.equipo?.tipoEquipo.nombre | lowercase }}">
                            <strong>{{c.equipo?.tipoEquipo.nombre}}</strong><br>
                            <span *ngIf="c.equipo.marca">{{c.equipo?.marca}} {{c.equipo?.modelo}}</span>
                            <span *ngIf="c.equipo?.equipoId">Código </span> <a [routerLink]="" (click)="verEquipo(c)">{{c.equipo?.equipoId}}</a>
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="muestra">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Última muestra</th>
                    <td mat-cell *matCellDef="let c">
                        <strong>{{c.ultimaMuestra.muestraId}}</strong><br>
                        {{c.ultimaMuestra.fechaMuestreo | date: 'dd-MM-yyyy'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                    <td mat-cell *matCellDef="let c">
                        <div class="d-flex align-items-center gap-1">
                            <span *ngIf="c.ultimaMuestra.estado" class="indicator" [ngClass]="{
                              'normal': c.ultimaMuestra.estado == 'normal',
                              'danger': c.ultimaMuestra.estado === 'alerta',
                              'warning': c.ultimaMuestra.estado == 'precaucion'
                          }"></span>
                            <strong>{{enumStatus[c.ultimaMuestra.estado]}}</strong>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="7">
                        <div class="d-flex justify-content-center" *ngIf="model.cargandoComponentes">
                            Cargando componentes <mat-spinner matSuffix [diameter]="18" style="margin-left: 8px"></mat-spinner>
                        </div>
                        <div class="d-flex justify-content-center" *ngIf="!model.cargandoComponentes">
                            No se han encontrado elementos...
                        </div>
                    </td>
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" (page)="handlePageEvent($event)" [length]="model.pagination.totalElements" [pageSize]="model.pagination.pageSize" [pageIndex]="model.pagination.page" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <p class="text-muted" *ngIf="!model.cargandoComponentes">
        <a type="button" class="link-secondary" (click)="recargarTabla()" [routerLink]="" data-toggle="tooltip" data-placement="top" title="{{model.fechaCargaSolicitudes| date :'HH:mm:ss'}}">
            <i class="bi bi-arrow-clockwise"></i>
            <span *ngIf="model.fechaCargaSolicitudes !==null">
                Actualizado {{model.fechaCargaSolicitudes | amLocale:'es' | amTimeAgo}}
            </span>
        </a>
    </p>
</div>
