<footer class="footer">
    <div class="container-fluid">
        <div class="row text-muted">
            <div class="col-6 text-start">
                <p class="mb-0">
                    <a href="https://www.copec.cl" class="text-muted" target="_blank"><strong>Copec {{year}}</strong></a> &copy;
                </p>
            </div>
            <div class="col-6 text-end">
                <div class="copyright float-right">
                    
                </div>
            </div>
        </div>
    </div>
</footer>