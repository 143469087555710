import { Subject, Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { Enums, Objects } from 'src/app/shared';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Tools } from './objects.tools';
import { environment } from '../../../environments/environment';
import { deprecate } from 'util';
export  class ServiceSubjectTime<REQ,RESP extends Objects.Response<any>>  {

    private _value:RESP|undefined;
    private _sub:Subject<RESP>;
    private _maxInterval:number=0;
    private _lastTime:number;
    private defaultValue={"code":-1,"data":[],"message":'',"success":false,date:new Date()};
    private isCalled = false;
    private listRequests:REQ[]=[];



    constructor(private _apiUrl:string,private http: HttpClient,private tools: Tools,_maxInterval?: number){
        this._lastTime=new Date().getTime();
        this._value=<RESP>this.defaultValue;
        this._sub=new BehaviorSubject(this._value);
        this._maxInterval= _maxInterval || environment.config.api.tiempoGeneralEspera;

    }

    setHttpAndTools(http: HttpClient,tools: Tools){
        this.http=http;
        this.tools=tools;
    }

    /**
     * @deprecated
     * @param request
     * @param force
     * @param fnCallback
     * @returns
     */
    httpGet(request: REQ,force:boolean,fnCallback?:()=>{}): Observable<RESP> {
        if(!this.isCalled && (force || (this._value?.code===-1 || this.isMaxTimeStore()))
        ){
            this.isCalled = true;
            if(force){
                this._value=<RESP>this.defaultValue;
                this._sub.next(this._value);
            }
            this.http.get<RESP>(this._apiUrl, { params: this.tools.toHttpParams(request) }).toPromise().then(r=>{
                this._value=r;
                this._value.date=new Date();
                this._lastTime=new Date().getTime();
                this._sub.next(this._value);

            }).finally(()=>{
                if(fnCallback){
                    fnCallback();
                }
                this.isCalled = false;
            });
        }else if(fnCallback){
            fnCallback();
        }
        return this._sub;
    }

    _httpGet(request: REQ, opts: {force:boolean, fnCallback?:()=>void, spinner: boolean, ignoreStore?:boolean,fnContinue?:(requests:REQ[])=>boolean} = {force: false, spinner: false}): Observable<RESP> {

        if((!this.isCalled || opts.fnContinue && opts.fnContinue(this.listRequests) ) && (opts.force || (this._value?.code===-1 || this.isMaxTimeStore()))
        ){

            this.isCalled = true;
            this.listRequests.push(request);
            if(opts.ignoreStore){
                this._value=<RESP>this.defaultValue;
                this._sub.next(this._value);
            }
            this.http.get<RESP>(this._apiUrl, {params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).subscribe(r=>{
                this._value=r;
                this._value.date=new Date();
                this._lastTime=new Date().getTime();
                this._sub.next(this._value);
                this.listRequests=this.listRequests.filter(req=>request!==req);
                this.isCalled = this.listRequests.length > 0;
                if(opts.fnCallback){
                    opts.fnCallback();
                }
                return this._sub;

            });


        }else if(opts.fnCallback){
            opts.fnCallback();
        }

        return this._sub;
    }

    _httpGetToPromise(request: REQ, opts: {force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean } = {force: false, spinner: false}):Promise<RESP>{

        return new Promise((resolve,reject)=>{
            this._httpGet(request,opts).subscribe(response=>{
                  if(response.code!==-1){
                     resolve(response)
                  }
            },error=>{
                reject(error)
            })
        })


    }


    private isMaxTimeStore():boolean{
        let rest =(new Date()).getTime()-this._lastTime;
        return this._maxInterval <rest;
    }

    httpPost(request: REQ, opts: { spinner: boolean } = {spinner: false}): Observable<RESP> {
       return  this.http.post<RESP>(this._apiUrl, request ,  { context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) })

    }
}
