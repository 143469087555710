<nav id="sidebar" class="sidebar sticky-top">
    <div class="sidebar-content js-simplebar bg-menu">
      <div class="navHeaderBg w-100">
        <a class="sidebar-brand" [routerLink]="['/home']">
            <img src="../assets/images/mobil.png" class="img-fluid" alt="">
        </a>
        <div class="mx-3 d-flex">
        <div class="searchbox">
          <input type="text" class="text-black-50"
          maxlength="10"
          placeholder="Buscar muestra"
          [(ngModel)]="muestraId" (keyup.enter)="getSample()" aria-label="Show an example snack-bar" [disabled]="searching"
          >
          <mat-spinner  matSuffix [diameter]="18" class="center" *ngIf="searching"></mat-spinner>
          <button type="button" class="btnSearchimg" (click)="getSample()" *ngIf="!searching">
          </button>
      </div>
    </div>


    <small class="sidebar__info mt-1 d-block mx-auto w-100 text-center">Busca por N° de frasco / laboratorio</small>
  </div>
        <ul class="sidebar-nav mt-0">
            <li class="sidebar-item" [ngClass]="{ active: isActive('/home')}">
                <a id="home" class="sidebar-link" [routerLink]="['/home']">
                    <em class="align-middle" data-feather="home"></em> <span class="align-middle">Inicio</span>
                </a>
            </li>

            <li class="sidebar-item" [ngClass]="{ active: isActiveMulti(['/busqueda-componente', '/agregar-muestra'])}">
                <a id="busqueda_componente" class="sidebar-link" [routerLink]="['/busqueda-componente']">
                    <em class="align-middle" data-feather="thermometer"></em> <span class="align-middle">Muestras</span>
                </a>
            </li>

            <li class="sidebar-item" [ngClass]="{ active: isActive('/mantenedor-equipos')}">
                <a id="mantenedor_equipos" class="sidebar-link" [routerLink]="['/mantenedor-equipos']">
                    <em class="align-middle" data-feather="command"></em> <span class="align-middle">Equipos y Componentes</span>
                </a>
            </li>

            <li class="sidebar-item" [ngClass]="{ active: isActive('/solicitudes-de-analisis')}">
                <a id="solicitudes_de_analisis" class="sidebar-link" [routerLink]="['/solicitudes-de-analisis']">
                    <em class="align-middle" data-feather="archive"></em> <span class="align-middle">Muestras y Solicitudes</span>
                </a>
            </li>

            <li class="sidebar-item" [ngClass]="{ active: isActive('/reportes')}">
                <a id="reportes" class="sidebar-link" [routerLink]="['/reportes']">
                    <em class="align-middle" data-feather="clipboard"></em> <span class="align-middle">Reportes</span>
                </a>
            </li>
            <li class="sidebar-item" [ngClass]="{ active: isActive('/solicitud-de-insumos')}">
                <a id="solicitud_de_insumos" class="sidebar-link" [routerLink]="['/solicitud-de-insumos']">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell align-middle"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg> <span class="align-middle">Insumos</span>
                </a>
            </li>
            <li class="sidebar-item" [ngClass]="{ active: isActive('/limites')}">
              <a id="limites" class="sidebar-link" [routerLink]="['/limites']">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-steps" viewBox="0 0 16 16"><path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z"/></svg><span class="align-middle">Personalizar limites</span>
              </a>
          </li>
          <li class="sidebar-item" [ngClass]="{ active: isActive('/external-content')}" *ngIf="isVisibleTableau()">
            <a id="dashboard" class="sidebar-link" (click)="openTableau()">
                <em class="align-middle" data-feather="link-2"></em> <span class="align-middle">Dashboard</span>
            </a>
        </li>

        </ul>

        <div class="logo-copec imagen-logo-rojo" style="z-index: -1;">
            <h4>COPEC</h4>
        </div>
    </div>
</nav>
