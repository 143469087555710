<h4>Administración de clientes</h4>
<br>
<div class="row">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
        <input type="search" class="form-control mb-4" placeholder="Buscar..."
        [formControl]="myControl" [(ngModel)]="model.usuarioFiltro" blockSpecialCharacters (keyup)="keyUpSearch()">
    </div>
    <div class="col text-end">
            <a class="btn btn-outline-secondary mb-4" (click)="nuevoUsuario()"><i class="bi bi-plus-lg"></i> Crear usuario</a>
    </div>
</div>

<div class="mb-3 mt-5" id="alerta-sin-resultado" *ngIf="model.alertaSinResultado">
  <div class="alert alert-info text-start" role="alert">
      <div class="alert-message">
          <h4 class="alert-heading">Nada por aquí!</h4>
          <p>No hemos encontrado ningún usuario que contenga el texto que buscas, por favor ajusta tu búsqueda.</p>
          <hr>
      </div>
  </div>
</div>

<div id="mant-usuarios">
    <div class="card" *ngIf="!model.alertaSinResultado">

      <div class="table-responsive">
          <div *ngIf="model.usuarioFiltro" class="card-header">
              <h5 class="card-title mb-0"><strong> Usuarios registrados que contienen <span>{{model.usuarioFiltro}}</span></strong></h5>
          </div>
              <table mat-table [dataSource]="dataSource" matSort class="w-100">
                <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                    <td mat-cell *matCellDef="let c">
                        {{c.nombre | titlecase}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="apellido">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido </th>
                    <td mat-cell *matCellDef="let c">
                        {{c.apellido | titlecase}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Télefono </th>
                    <td mat-cell *matCellDef="let c">
                        {{c.phone}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                    <td mat-cell *matCellDef="let c">
                        {{c.email}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="tipo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de usuario </th>
                  <td mat-cell *matCellDef="let c">
                      {{usuariosTipo[c.userId].rol}}
                  </td>
              </ng-container>

                <ng-container matColumnDef="faena">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Faena </th>
                    <td mat-cell *matCellDef="let c">
                      <div class="d-flex gap-1">
                        <ng-container *ngFor="let faena of c.clientes | getFaenasByClientId: model.currentClientId">
                          <span class="chip">{{faena.nombre}}</span>
                        </ng-container>
                      </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="accion">
                    <th mat-header-cell *matHeaderCellDef class="with-header-cell"></th>
                    <td mat-cell *matCellDef="let c">
                      <div class="d-flex justify-content-between">
                        <a [routerLink]="" (click)="editarUsuario(c)"><i class="bi bi-pencil mx-1"></i></a>
                        <a [routerLink]="" *ngIf="usuariosTipo[c.userId].elimina" (click)="eliminarUsuarioModal(c)"><i class="bi bi-trash text-danger mx-1"></i></a>
                      </div>
                    </td>
                </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="7">
                        <div class="row">
                            <div class="col-md-6" style="text-align: right;">
                                Cargando usuarios
                            </div>
                            <div class="col-md-6">
                                <mat-spinner *ngIf="model.usuariosEncontrados.length == 0 && !model.usuarioFiltro" matSuffix [diameter]="18" style="margin-left: 8px"></mat-spinner>
                            </div>
                        </div>
                    </td>
                </tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" (page)="handlePageEvent($event)" [length]="model.pagination.totalElements" [pageSize]="model.pagination.pageSize" [pageIndex]="model.pagination.page" showFirstLastButtons></mat-paginator>
      </div>
    </div>
</div>
