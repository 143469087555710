<h4>Equipos y Componentes</h4>
<br>
<ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link active" [routerLink]="['/mantenedor-equipos']"><strong>Equipos</strong>
        <mat-spinner *ngIf="model.cargandoEquipos" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner></a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/mantenedor-componentes']">Componentes</a>
    </li>
</ul>
<br>

<div class="row">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
        <input type="search" class="form-control mb-4" placeholder="Buscar..."
            [formControl]="myControl" [(ngModel)]="model.equipoFiltro" blockSpecialCharacters (keyup)="enterInputSearch()" (input)="onFocusInputSearch()">
    </div>
    <div class="col text-end">
            <a class="btn btn-outline-secondary mb-4" (click)="nuevoEquipo()"><i class="bi bi-plus-lg"></i> Agregar equipo</a>
    </div>
</div>

<div class="mb-3 mt-5" id="alerta-sin-resultado" *ngIf="model.alertaSinResultado">
  <div class="alert alert-info text-start" role="alert">
      <div class="alert-message">
          <h4 class="alert-heading">Nada por aquí!</h4>
          <p>No hemos encontrado ningún componente que contenga el texto que buscas, por favor ajusta tu búsqueda.</p>
          <hr>
      </div>
  </div>
</div>
<div id="mant-equipos">
    <div class="card" *ngIf="!model.alertaSinResultado">
        <div class="table-responsive">
            <div *ngIf="model.equipoFiltro" class="card-header">
                <h5 class="card-title mb-0"><strong> Componentes registrados que contienen <span>{{model.equipoFiltro}}</span></strong></h5>
            </div>
            <table mat-table [dataSource]="dataSource" matSort class="w-100">
                <ng-container matColumnDef="equipoId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Código de equipo </th>
                    <td class="pTopDown10" mat-cell *matCellDef="let e">
                        <a [routerLink]="" (click)="verEquipo(e)"><strong>{{ e.equipoId }}</strong></a>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Equipo </th>
                    <td class="pTopDown10" mat-cell *matCellDef="let e">
                        <span class="icono-equipo {{ e.tipoEquipo.nombre | lowercase }}">
                        <strong>{{e.tipoEquipo.nombre}}</strong><br>
                        {{e.marca}} {{e.modelo}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="alias">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Alias y descripción </th>
                    <td class="pTopDown10" mat-cell *matCellDef="let e">
                        <strong><span *ngFor="let alia of e.alias">{{ alia }} </span></strong><br>
                        {{ e.descriptor }}
                        <br>
                        <span class="badge bg-secondary me-1" *ngFor="let tag of e.tags">{{ tag }}</span>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                        <div class="d-flex justify-content-center" *ngIf="model.cargandoEquipos">
                            Cargando equipos <mat-spinner matSuffix [diameter]="18" style="margin-left: 8px"></mat-spinner>
                        </div>
                        <div class="d-flex justify-content-center" *ngIf="!model.cargandoEquipos">
                            No se han encontrado elementos...
                        </div>
                    </td>
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" (page)="handlePageEvent($event)" [length]="model.pagination.totalElements" [pageSize]="model.pagination.pageSize" [pageIndex]="model.pagination.page" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <p class="text-muted" *ngIf="!model.cargandoEquipos">
        <a type="button" class="link-secondary" (click)="recargarTabla()" [routerLink]=""  data-toggle="tooltip" data-placement="top" title="{{model.fechaCargaSolicitudes| date :'HH:mm:ss'}}" >
            <i class="bi bi-arrow-clockwise"></i>
            <span *ngIf="model.fechaCargaSolicitudes !==null">
                Actualizado {{model.fechaCargaSolicitudes  | amLocale:'es' | amTimeAgo}}
            </span>
        </a>
    </p>
</div>