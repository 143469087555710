import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Tools } from "src/app/shared";
import { Enums } from "src/app/shared/models/constants";
import { environment } from "src/environments/environment";
import { MantEquiposModels } from "../mant-equipos-models/mant-equipos.models";

@Injectable({
    providedIn: 'root'
})
export class MantEquiposService {

    value: MantEquiposModels.GetEquiposResponse|undefined;
    sub: Subject<MantEquiposModels.GetEquiposResponse>;
    lastTime:number=new Date().getTime();
    maxInterval:number=environment.config.api.tiempoGeneralEspera;

    constructor(
        private http: HttpClient,
        private tools: Tools
    ){
        this.value={code:-1,data:[],message:'',success:true};
        this.sub=new BehaviorSubject(this.value);
    }

    GetEquipos(request: MantEquiposModels.GetEquiposRequest, forceRefresh: boolean = false) : Observable<MantEquiposModels.GetEquiposResponse> {
        if(this.value?.code===-1 || this.isMaxTimeStore() || forceRefresh){
            this.http.get<MantEquiposModels.GetEquiposResponse>(`${environment.apiUrl}${environment.routes.equipo}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise().then(r=>{
                this.value=r;
                this.lastTime=new Date().getTime();
                this.sub.next(this.value);
            });
        }

        return this.sub; 
    }

    isMaxTimeStore():boolean{
        let rest =(new Date()).getTime()-this.lastTime;
        return this.maxInterval <rest;
    }
}