import { OAuthStorage } from 'angular-oauth2-oidc';

export class LocalStorageOAuthStorage implements OAuthStorage {

    private needsLocal(key: string) {
        return key === 'nonce' || key === 'PKCE_verifier';
    }

    getItem(key: string): string | null {
        if (this.needsLocal(key)) {
            return localStorage.getItem(key);
        }
        return sessionStorage.getItem(key);
    }

    removeItem(key: string): void {
        if (this.needsLocal(key)) {
        }
        return sessionStorage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        if (this.needsLocal(key)) {
            return localStorage.setItem(key, value);
        }
        return sessionStorage.setItem(key, value);
    }
}
