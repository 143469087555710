import { Pipe, PipeTransform } from '@angular/core';
import { Objects } from '../models/objects';

@Pipe({
    name: 'getFaenasByClientId',
})
export class GetFaenasByClientIdPipe implements PipeTransform {
    transform(clientes: Objects.Cliente[], clientId: string): Objects.Faena[] {
        if (!clientes || !clientId) {
            return [];
        }
        const cliente = clientes.find((cliente) => cliente.clienteId === clientId);
        if (!cliente) {
            return [];
        }
        return cliente.faenas;
    }
}
