import { Component, OnInit, Input } from '@angular/core';
import { SamplesModels } from '../../models/samples.models';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
@Component({
    selector: 'app-detail-proforma',
    templateUrl: './detail-proforma.component.html',
    styleUrls: ['./detail-proforma.component.css']
})
export class DetailProformaComponent implements OnInit {

    @Input() docVenta?: SamplesModels.DocumentoVentas;
    @Input() visible: boolean = false;

    muestras?: SamplesModels.MuestraDV[];
    total_muestras = 0;

    printing: boolean = true;

    nombre_cliente_1: string = "";
    nombre_cliente_2: string = "";

    nombre_faena_1: string = "";
    nombre_faena_2: string = "";

    constructor(
        private spinner: SpinnerService
    ) {

    }

    ngOnInit(): void {

        this.muestras = [];

        if (this.docVenta?.cliente.nombre!.split(" ").length ?? 0 > 3) {
            this.nombre_cliente_1 = this.docVenta?.cliente.nombre!.split(" ").slice(0, 2).join(" ") ?? '';
            this.nombre_cliente_2 = this.docVenta?.cliente.nombre!.split(" ").slice(2, this.docVenta?.cliente.nombre!.split(" ").length).join(" ") ?? '';
        } else {
            this.nombre_cliente_1 = this.docVenta?.cliente.nombre!.split(" ").join(" ") ?? '';
        }

        this.docVenta!.cliente.faena.nombre = this.docVenta?.cliente.faena.nombre.split(" ").join(" ") ?? '';
        if (this.docVenta?.cliente.faena.nombre!.split(" ").length ?? 0 > 3) {
            this.nombre_faena_1 = this.docVenta?.cliente.faena.nombre!.split(" ").slice(0, 2).join(" ") ?? '';
            this.nombre_faena_2 = this.docVenta?.cliente.faena.nombre!.split(" ").slice(2, this.docVenta?.cliente.faena.nombre!.split(" ").length).join(" ") ?? '';
        } else {
            this.nombre_faena_1 = this.docVenta?.cliente.nombre!.split(" ").join(" ") ?? '';
        }


        this.docVenta?.pedido_cost.planes_analisis.sort((x, y) => x.nombre.localeCompare(y.nombre));
        this.docVenta?.pedido_zero_cost.planes_analisis.sort((x, y) => x.nombre.localeCompare(y.nombre));

        this.total_muestras = this.muestras.length;
    }

    public downloadPDF() {
        if (!this.docVenta) {
            return;
        }
        this.spinner.startLoading();

        let report_proforma = document.getElementById('report')?.parentElement!;
        document.getElementById('report')!.style.display = 'block';
        report_proforma.style.display = 'block';

        const doc = new jsPDF('p', 'pt', "legal", true);

        const convertElements = document.getElementsByClassName("convert-on-pdf");
        const elements = Array.from(convertElements) as HTMLElement[];

        if (elements.length > 0) {
            Promise.all(
                elements.map(async (element) => {
                    const canvas = await html2canvas(element);
                    element.replaceWith(canvas);
                }),
            ).then(() => {
                doc.html(report_proforma, {
                    autoPaging: 'text',
                    x: 0,
                    y: 0,
                    margin: [30, 0, 30, 0],
                    html2canvas: {
                        scale: 0.45,
                    },
                    callback: function () {
                        doc.save('proforma.pdf');
                        report_proforma.style.display = 'none';

                    }
                });
            }).finally(() => {
                this.spinner.stopLoading();
                this.printing = false;
            });
        } else {
            doc.html(report_proforma, {
                autoPaging: 'text',
                x: 0,
                y: 0,
                margin: [30, 0, 30, 0],
                html2canvas: {
                    scale: 0.45,
                },
                callback: function () {
                    doc.save('proforma.pdf');
                    report_proforma.style.display = 'none';

                }
            }).finally(() => {
                this.spinner.stopLoading();
                this.printing = false;
            });
        }
    }


    public format_date(date: string) {
        return moment(date, 'YYYY-MM-DDTHH:mm').format("DD-MM-YYYY");
    }

    get number_samples_zero_cost() {
        let count: number = 0;
        this.docVenta?.pedido_zero_cost.planes_analisis.forEach(plan => {
            count += plan.muestras.length;
        })
        return count;
    }

    get number_samples_cost() {
        let count: number = 0;
        this.docVenta?.pedido_cost.planes_analisis.forEach(plan => {
            count += plan.muestras.length;
        })
        return count;
    }

    get total_cost() {
        let sum: number = 0;
        sum = this.docVenta?.pedido_cost.planes_analisis.map(plan => Number(plan.total_cobro)).reduce((previousValue, currentValue) => previousValue + currentValue, 0) || 0
        return sum;
    }

    get total_precio_lista() {

        const suma_zc = this.docVenta?.pedido_zero_cost.planes_analisis.reduce((acumulador, objeto) => acumulador + (Number(objeto.precio_lista) * objeto.muestras.length), 0) || 0;
        const suma = this.docVenta?.pedido_cost.planes_analisis.reduce((acumulador, objeto) => acumulador + (Number(objeto.precio_lista) * objeto.muestras.length), 0) || 0;

        return (suma + suma_zc);
    }

    get total_descuento() {

        const suma_zc = this.docVenta?.pedido_zero_cost.planes_analisis.reduce((acumulador, objeto) => acumulador + (Number(objeto.descuento) * objeto.muestras.length), 0) || 0;
        const suma = this.docVenta?.pedido_cost.planes_analisis.reduce((acumulador, objeto) => acumulador + (Number(objeto.descuento) * objeto.muestras.length), 0) || 0;
        return (suma + suma_zc);
    }

    public format_currency(currency:string): string {
        let response = "$ "

        if (currency === "USD"){
            response = "US$ ";
        } else if (currency === "UF"){
            response = "UF ";
        }

        return response;
    }

}
