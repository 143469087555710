import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

const FECHA_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ValidarFechaDirective),
  multi: true
};

@Directive({
  selector: '[validarFecha]',
  providers:[FECHA_VALIDATOR],
})
export class ValidarFechaDirective implements Validator {

  

  constructor() { }
  validate(control: AbstractControl): ValidationErrors | null {
    const dia=new Date().toLocaleDateString('en-us', { day:"2-digit"});
    const mes=new Date().toLocaleDateString('en-us', { month:"2-digit"});
    const year=new Date().toLocaleDateString('en-us', { year:"numeric"});
    const fechaHoy=year+"-"+mes+"-"+dia;

    if(control.value>fechaHoy){
      return {validarFecha:true}
    }
    return null
  }

}
