import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegistraMuestraModels } from '../registra-models/registra.models';
import { environment } from 'src/environments/environment';
import { Tools } from 'src/app/shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegistraService {

    constructor(
        private http: HttpClient,
        private tools: Tools) {
    }

    GetMuestraInfo(request: RegistraMuestraModels.GetMuestraInfoRequest): Promise<RegistraMuestraModels.GetMuestraInfoResponse> {
        return this.http.get<RegistraMuestraModels.GetMuestraInfoResponse>(`${environment.apiUrl}${environment.routes.getMuestraInfo}`, { params: this.tools.toHttpParams(request) }).toPromise();
    }

    GetLubricantes(): Promise<RegistraMuestraModels.GetLubricanteResponse> {
        return this.http.get<RegistraMuestraModels.GetLubricanteResponse>(`${environment.apiUrl}${environment.routes.getLubricante}`).toPromise();
    }

    setMuestraValid(request:RegistraMuestraModels.SetMuestraValidRequest): Observable<RegistraMuestraModels.SetMuestraValidResponse> {
        return this.http.post<RegistraMuestraModels.SetMuestraValidResponse>(`${environment.apiUrl}${environment.routes.setMuestraValid}`, request);
    }

}
