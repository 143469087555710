import { HttpContextToken } from "@angular/common/http";

export namespace Enums {

  export const SPINNER_ENABLED = new HttpContextToken<boolean>(() => true);

  export enum MaintainerView {
    List = 0,
    New = 1,
    Edit = 2
  };

  export enum EstadosMuestra {
    registered='Registrada',
    received='Recepcionada',
    received_observations='Recepcionada c/ observaciones',
    not_received='No recepcionada',
    in_analysis='En análisis',
    assigned_batch= 'Ingresado al lote',
    pending_approval='Pendiente de aprobación',
    reported='Reportada',
    EnProceso= 'En proceso',
    ConObservacion= 'Con Observación',
    recibido= 'Recibido',
    registrada= 'Registrada',
    programada= 'Programada'
  }

  export enum EstadosDeResultado {
    analisis= 'En análisis',
    normal= 'Normal',
    precaucion= 'Precaución',
    alerta= 'En alerta',
  }

  export enum ResultadoGeneralEnsayo{
    true='Detecta',
    false='No Detecta'
}
export enum EstadosMuestraEnLote {
  pending='Pendiente',
  in_analysis = 'En análisis',
  reported = 'Reportado',
  created='Creado',
  sending='Enviado',
  registered='Registrado',
  assigned_batch= 'Ingresado al lote',
  pending_approval = 'Pendiente de aprobación',
  repeat_analysis = 'En repetición de análisis'

}

export enum EstadosFechaMuestra {
    in_analysis = 'Enviada al laboratorio',
    assigned_batch = 'Frasco ingresado al lote',
    received = 'Frasco recepcionado',
    received_observations = 'Frasco recepcionado',
    pending_approval = 'Análisis finalizado',
    reported = 'Reportado'
  }

}
