<div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3">
        <div class="col-auto d-none d-sm-block">
            <h3>Agregar muestra</h3>
        </div>

        <div class="col-auto ms-auto text-end mt-n1">
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-10">
            <ul class="stepper stepper-horizontal">

                <li class="active">
                    <a [routerLink]="" class="px-2">
                        <span class="circle">1</span><span class="label">Datos del componente</span>
                    </a>
                </li>

                <li>
                    <a [routerLink]="" class="px-2">
                        <span class="circle">2</span><span class="label">Datos de la muestra</span>
                    </a>
                </li>

                <li>
                    <a [routerLink]="" class="px-2">
                        <span class="circle">3</span><span class="label">Comprobante</span>
                    </a>
                </li>

            </ul>
        </div>

        <div class="col-md-10 text-center mt-5">
            <p class="fon-30">¿A qué componente pertenece la <br> muestra a ingresar?</p>
        </div>

        <div id="agregar-muestra-buscador" class="col-sm-12 col-md-11 col-lg-8 col-xl-6 col-xxl-5 text-center">
            <form class="example-form">
                <div class="field">
                  <input id="muestra-text-search" type="text" class="form-control form-control-lg" placeholder="Ingresa el ID, marca o modelo del componente o equipo" #autoCompleteInput matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="model.componenteFiltro" (keyup.enter)="enterInputSearch()" (focus)="onFocusInputSearch()">
                  <button id="muestra-text-search-lupa" (click)="enterInputSearch()"></button>
                </div>
                <mat-autocomplete id="muestra-text-autocomplete" #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChanged($event)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        ID {{option.componenteId}} {{option.tipoComponente.nombre}} - {{option.equipo?.tipoEquipo?.nombre}} {{option.equipo?.marca}} {{option.equipo?.modelo}}
                    </mat-option>
                </mat-autocomplete>
                <div class="help-text text-muted mt-3">
                    Buscamos en todos los datos de tus componentes y equipos, incluyendo
                    &aacute;reas, flotas, etiquetas, descripciones, marcas, modelos, c&oacute;digos,
                     posiciones, lubricantes, etc. Puedes buscar varios c&oacute;digos de
                     componentes separ&aacute;ndolos con un punto y coma ";"
                </div>

                <div class="mb-3 mt-5" id="alerta-sin-resultado" *ngIf="model.alertaSinResultado">
                    <div class="alert alert-info text-start" role="alert">
                        <div class="alert-message">
                            <h4 class="alert-heading">Nada por aquí!</h4>
                            <p>No hemos encontrado ningún componente o equipo que contenga el texto que buscas, por favor ajusta tu búsqueda.</p>
                            <p class="mb-0">También puedes crear un <a [routerLink]="" (click)="crearComponente()">nuevo componente</a> o un <a [routerLink]="" (click)="crearEquipo()">nuevo equipo</a>.</p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-10 mt-5" [hidden]="model.componentesEncontrados.length === 0">
            <div class="card shadow border">
                <div class="card-header">
                    <h5 class="card-title mb-0"><strong>Componentes registrados</strong></h5>
                </div>
                <div class="card-body p-0 border-top">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="seleccion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <input id="muestra-checkbox-checkall" type="checkbox" (change)="checkTodos($event)" [(ngModel)]="model.checkAll" [checked]="model.checkAll"  /> </th>
                            <td mat-cell *matCellDef="let c">
                                <input id="muestra-checkbox-{{c.componenteId}}" type="checkbox" value="true"  [checked]="c.seleccionado" (change)="c.seleccionado = !c.seleccionado;check(c.seleccionado)" [disabled]="c.tipoComponente.nombre=='_noencontrado' || (!c.seleccionado &&  model.limiteSeleccionCompleto)">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="componenteId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                            <td mat-cell *matCellDef="let c">
                                <b [routerLink]="">{{ c.componenteId }}</b>
                                <span *ngIf="c.tipoComponente.nombre=='_noencontrado'">
                                    <ng-template #popContent>
                                        <div class="d-flex justify-content-start mt-2">
                                            <input type="text" class="form-control" value="{{c.componenteId }}" #inputNuevoValor />
                                        </div>
                                        <div class="d-flex justify-content-end mt-2">
                                            <a class="btn btn-link" [routerLink]="" (click)="p.close()">
                                                <b>Cancelar</b>
                                            </a>
                                            <a class="btn btn-primary" [routerLink]="" (click)="popoverCambiarIdDeFila(c.componenteId,inputNuevoValor.value);p.close()">
                                                <b>Listo</b>
                                            </a>
                                        </div>
                                    </ng-template>
                                    <a href="#" placement="top" (click)="false" [ngbPopover]="popContent" [autoClose]="false" #p="ngbPopover">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>
                                    </a>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="componenteName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> COMPONENTE </th>
                            <td mat-cell *matCellDef="let c">
                                <span *ngIf="c.tipoComponente.nombre=='_noencontrado'">
                                    <span class="badge bg-warning">No encontrado</span>
                                </span>
                                {{c.tipoComponente.nombre=='_noencontrado'?'':c.tipoComponente.nombre}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="marca">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> MARCA </th>
                            <td mat-cell *matCellDef="let c"> {{c.marca}} </td>
                        </ng-container>


                        <ng-container matColumnDef="modelo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> MODELO </th>
                            <td mat-cell *matCellDef="let c"> {{c.modelo}} </td>
                        </ng-container>


                        <ng-container matColumnDef="ubicacion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> POSICIÓN </th>
                            <td mat-cell *matCellDef="let c"> {{c.ubicacion}} </td>
                        </ng-container>

                        <ng-container matColumnDef="equipoTipo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> TIPO EQUIPO </th>
                            <td mat-cell *matCellDef="let c"> {{c.equipo.tipoEquipo.nombre}} </td>
                        </ng-container>

                        <ng-container matColumnDef="equipoMarca">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> MARCA EQUIPO </th>
                            <td mat-cell *matCellDef="let c"> {{c.equipo.marca}} </td>
                        </ng-container>

                        <ng-container matColumnDef="equipoModelo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> MODELO EQUIPO </th>
                            <td mat-cell *matCellDef="let c"> {{c.equipo.modelo}} </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                            <td mat-cell *matCellDef="let c">
                                <button id="muestra-button-agregar-muestra-{{c.componenteId}}" class="btn btn-link" (click)="selectedRow(c)" *ngIf="c.componenteName!=='_noencontrado'" [disabled]="!esValidoLinkUnicaMuestra(c)" >Agregar muestra</button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" (page)="handlePageEvent($event)" [length]="model.pagination.totalElements" [pageSize]="model.pagination.pageSize" [pageIndex]="model.pagination.page" showFirstLastButtons></mat-paginator>
                </div>

            </div>
            <div class="text-center" *ngIf="mostrarTablaMultiple()">
                <p>¿No encuentras lo que buscas? Puedes crear un <a [routerLink]="" (click)="crearComponente()">nuevo componente</a> o un <a [routerLink]="" (click)="crearEquipo()">nuevo equipo</a>.</p>
            </div>

        </div>
        <div class="col-md-10 mt-4 mb-5 text-center" *ngIf="esValidoBotonDeIngreso()">
            <button id="muestra-button-ingresar-muestras" class="btn btn-primary btn-lg" (click)="ingresarMuestrasParaComponentes()" [disabled]="model.componentesSeleccionados.length===0">Ingresar muestras a componentes seleccionados <span class="badge rounded-pill bg-light text-dark">{{elementosSeleccionados().length}}</span></button>
        </div>

    </div>

</div>
