import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { SubscribersTools } from "../tools/subscribers.tools";
import { RequestCounterTools } from '../tools/request-counter.tools';
import { PendienteFacturacionService } from "src/app/condiciones-comerciales/condiciones-comerciales-components/pendientes-facturacion/pendientes-facturacion-services/pendientes-facturacion-service";
import { PendienteFacturacionModels } from "src/app/condiciones-comerciales/condiciones-comerciales-components/pendientes-facturacion/pendientes-facturacion-models/pendientes-facturacion-models";

@Injectable({
    providedIn: 'root'
})
export class DocumentosVentasProvider {
    subsTools: SubscribersTools = new SubscribersTools();

    documentos_ventas: {
        data: PendienteFacturacionModels.DocumentoVentas[];
        reqCounter: RequestCounterTools;
        subject: Subject<PendienteFacturacionModels.DocumentoVentas[]>
    };

    doc_ventas: PendienteFacturacionModels.DocumentoVentas[] = [];
    doc_ventas_finish: boolean = false;
    doc_ventas_aux: PendienteFacturacionModels.DocumentoVentas[] = [];

    constructor(
        private service: PendienteFacturacionService,
    ) {
        this.documentos_ventas = {
            data: [],
            reqCounter: new RequestCounterTools(),
            subject: new BehaviorSubject(this.doc_ventas)
        }
    }

    private clear_items() {
        this.doc_ventas_finish = false;
        this.doc_ventas = [];
        this.documentos_ventas = {
            data: [],
            reqCounter: new RequestCounterTools(),
            subject: new BehaviorSubject(this.doc_ventas)
        };
        this.documentos_ventas.subject.next(this.documentos_ventas.data);
    }

    public getDocumentosVentas(period_from: string, period_to: string, estados: string[], clientes: string[], faenas: string[], is_wc: boolean, refresh: boolean = true) {
        this.clear_items();
        let rq: PendienteFacturacionModels.GetDocumentoVentasRequest = {
            period_from: period_from,
            period_to: period_to,
            estados: estados,
            clientes: clientes,
            faenas: faenas,
            is_wc: true,


        }
        if (!this.documentos_ventas.reqCounter.inProgress) {
            this.get_muestras_documento_ventas(rq, refresh);
        }

        return this.documentos_ventas.subject;
    }

    private get_muestras_documento_ventas(rq: PendienteFacturacionModels.GetDocumentoVentasRequest, refresh: boolean = true) {
        this.documentos_ventas.reqCounter.startRequest();
        let key = "";
        this.subsTools.addSubscribe('get_muestras_doc_ventas', this.service.GetDocumentoVentas_paged(rq, {
            force: true, spinner: true, ignoreStore: true
            , fnCallback: () => {
                if (key != "") {
                    rq.page_key = key;
                    this.get_muestras_documento_ventas(rq);
                } else {
                    this.doc_ventas_finish = true;
                    this.setStorage(this.doc_ventas_aux);
                    this.doc_ventas_aux = [];
                }
                this.documentos_ventas.reqCounter.endRequest();
            }
        })
            .subscribe(r => {
                if (r.code == 200) {
                    this.doc_ventas_aux = [...this.doc_ventas_aux, ...r.data.items];

                    if (!this.doc_ventas_finish || this.documentos_ventas.data.length == 0) {
                        this.setStorage(this.documentos_ventas.data.concat(r.data.items));
                    }

                    if (r.data.page_key) {
                        key = r.data.page_key;
                    }
                }
            }));
    }

    private setStorage(sol: PendienteFacturacionModels.DocumentoVentas[]) {
        this.documentos_ventas.data = sol.sort((a, b) => {
            return (Number(b.cliente.clienteId) - Number(a.cliente.clienteId))
        });
        this.documentos_ventas.subject.next(this.documentos_ventas.data);
    }

}
