import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Enums, Tools } from "src/app/shared";
import { environment } from "src/environments/environment";
import { PendienteFacturacionModels } from "../pendientes-facturacion-models/pendientes-facturacion-models";
import { ServiceSubjectTimeProvide } from "src/app/shared/tools/service-subject-provider.tools";
import { ServiceSubjectTime } from "src/app/shared/tools/service-subject-time.tools";

@Injectable({
    providedIn: 'root'
})
export class PendienteFacturacionService {

    subjetGetDocumentoVentas: ServiceSubjectTime<PendienteFacturacionModels.GetDocumentoVentasRequest, PendienteFacturacionModels.GetDocumentoVentasResponse>;
    subjetGetDocumentoVentas_page: ServiceSubjectTime<PendienteFacturacionModels.GetDocumentoVentasRequest, PendienteFacturacionModels.GetDocumentoVentasPagedResponse>;
    subjetGetTotalPendienteFacturacion: ServiceSubjectTime<PendienteFacturacionModels.GetTotalPendientesFacturacionRequest, PendienteFacturacionModels.GetTotalPendientesFacturacionResponse>;

    constructor(
        serviceSubject: ServiceSubjectTimeProvide,
        private http: HttpClient,
        private tools: Tools
    ) {
        this.subjetGetDocumentoVentas = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.facturacion.documento_ventas}`);
        this.subjetGetDocumentoVentas_page = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.facturacion.documento_ventas}`);
        this.subjetGetTotalPendienteFacturacion = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.facturacion.total_pendientes_facturacion}`);
    }


    SetDocumentoVentas(request: PendienteFacturacionModels.SetDocumentoVentasRequest, opts: { spinner: boolean } = { spinner: true }): Promise<PendienteFacturacionModels.SetDocumentoVentasResponse> {
        return this.http.post<PendienteFacturacionModels.SetDocumentoVentasResponse>(`${environment.apiUrl}${environment.routes.facturacion.documento_ventas}`, request).toPromise();
    }

    GetDocumentoVentas(request: PendienteFacturacionModels.GetDocumentoVentasRequest, opts: { force: boolean, fnCallback?: () => {}, spinner: boolean, ignoreStore?: boolean } = { force: true, spinner: false, ignoreStore: true }): Promise<PendienteFacturacionModels.GetDocumentoVentasResponse> {
        return this.subjetGetDocumentoVentas._httpGetToPromise(request, opts);
    }

    GetDocumentoVentas_paged(request: PendienteFacturacionModels.GetDocumentoVentasRequest, opts: { force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = { force: false, spinner: false, ignoreStore: false }): Observable<PendienteFacturacionModels.GetDocumentoVentasPagedResponse> {
        return this.subjetGetDocumentoVentas_page._httpGet(request, opts);
    }

    DelDocumentoVentas(request: PendienteFacturacionModels.DelDocumentoVentasRequest, opts: { spinner: boolean } = { spinner: true }): Promise<PendienteFacturacionModels.DelDocumentoVentasResponse> {
        return this.http.delete<PendienteFacturacionModels.DelDocumentoVentasResponse>(`${environment.apiUrl}${environment.routes.facturacion.documento_ventas}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    GetTotalPendientesFacturacion(request: PendienteFacturacionModels.GetTotalPendientesFacturacionRequest, opts: { force: boolean, fnCallback?: () => {}, spinner: boolean, ignoreStore?: boolean } = { force: true, spinner: false, ignoreStore: true }): Promise<PendienteFacturacionModels.GetTotalPendientesFacturacionResponse> {
        return this.subjetGetTotalPendienteFacturacion._httpGetToPromise(request, opts);
    }

    SetDocumentoVentasId(request: PendienteFacturacionModels.SetDocumentoVentasIdRequest, opts: { spinner: boolean } = { spinner: true }): Promise<PendienteFacturacionModels.SetDocumentoVentasIdResponse> {
        return this.http.post<PendienteFacturacionModels.SetDocumentoVentasIdResponse>(`${environment.apiUrl}${environment.routes.facturacion.send_documento_ventas}`, request).toPromise();
    }

    GetDocumentoVentasById(request: PendienteFacturacionModels.GetDocumentoVentasByIdRequest, opts: { force: boolean, fnCallback?: () => {}, spinner: boolean, ignoreStore?: boolean } = { force: true, spinner: false, ignoreStore: true }): Promise<PendienteFacturacionModels.GetDocumentoVentasByIdResponse> {
        return this.http.get<PendienteFacturacionModels.GetDocumentoVentasByIdResponse>(`${environment.apiUrl}${environment.routes.facturacion.documento_ventas}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }
}
