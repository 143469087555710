import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Tools } from "src/app/shared";
import { environment } from "src/environments/environment";
import { ComponenteAsignarEquipoModels } from "../comp-asignar-equipo-models/comp-asignar-equipo.models";

@Injectable({
    providedIn: 'root'
})
export class CompAsignarEquipoService {

    constructor(
        private http: HttpClient,
        private tools: Tools
    ){}

    GetComponentes(request: ComponenteAsignarEquipoModels.GetComponentesRequest) :Promise<ComponenteAsignarEquipoModels.GetComponentesResponse> {
        return this.http.get<ComponenteAsignarEquipoModels.GetComponentesResponse>(`${environment.apiUrl}${environment.routes.component}`, { params: this.tools.toHttpParams(request) }).toPromise();
    }

    GetEquipoInfo(request: ComponenteAsignarEquipoModels.GetEquipoRequest): Promise<ComponenteAsignarEquipoModels.GetEquipoResponse> {
        return this.http.get<ComponenteAsignarEquipoModels.GetEquipoResponse>(`${environment.apiUrl}${environment.routes.equipo}`, { params: this.tools.toHttpParams(request) }).toPromise(); 
    } 

    setEquipoCambio(request:ComponenteAsignarEquipoModels.SetEquipoNuevoRequest): Observable<ComponenteAsignarEquipoModels.SetEquipoNuevoResponse> {
        return this.http.post<ComponenteAsignarEquipoModels.SetEquipoNuevoResponse>(`${environment.apiUrl}${environment.routes.equipo}`, request.equipo);
    }

    SetComponente(request: ComponenteAsignarEquipoModels.SetComponenteRequest): Observable<ComponenteAsignarEquipoModels.SetComponenteResponse> {
        return this.http.post<ComponenteAsignarEquipoModels.SetComponenteResponse>(`${environment.apiUrl}${environment.routes.component}`, request.componente);
    }

    GetMarcaComponenteInfo(): Promise<ComponenteAsignarEquipoModels.GetMarcaComponenteResponse> {
        return this.http.get<ComponenteAsignarEquipoModels.GetMarcaComponenteResponse>(`${environment.apiUrl}${environment.routes.tipoComponente}`).toPromise(); 
    }

    GetLubricantes(): Promise<ComponenteAsignarEquipoModels.GetLubricanteResponse> {
        return this.http.get<ComponenteAsignarEquipoModels.GetLubricanteResponse>(`${environment.apiUrl}${environment.routes.getLubricante}`).toPromise();
    }

}