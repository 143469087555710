import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Tools } from "src/app/shared";
import { Enums } from "src/app/shared/models/constants";
import { environment } from "src/environments/environment";
import { MantComponentesModels } from "../mant-componentes-models/mant-componentes.models";

@Injectable({
    providedIn: 'root'
})
export class MantComponentesService {

    value: MantComponentesModels.GetComponentesResponse|undefined;
    sub: Subject<MantComponentesModels.GetComponentesResponse>;
    lastTime:number=new Date().getTime();
    maxInterval:number=environment.config.api.tiempoGeneralEspera;

    constructor(
        private http: HttpClient,
        private tools: Tools
    ) {
        this.value = {code:-1,data:[],message:'',success:true};
        this.sub = new BehaviorSubject(this.value);
    }

    GetComponentes(request: MantComponentesModels.GetComponentesRequest, forceRefresh: boolean = false) : Observable<MantComponentesModels.GetComponentesResponse> {
        if(this.value?.code===-1 || this.isMaxTimeStore() || forceRefresh){
            this.http.get<MantComponentesModels.GetComponentesResponse>(`${environment.apiUrl}${environment.routes.component}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise().then(r=>{
                this.value=r;
                this.lastTime=new Date().getTime();
                this.sub.next(this.value);
            });
        }

        return this.sub;
    }

    GetComponents(request: MantComponentesModels.GetComponentesRequest, opts: { spinner: boolean } = { spinner: true }): Promise<MantComponentesModels.GetComponentesResponse> {
        return this.http.get<MantComponentesModels.GetComponentesResponse>(`${environment.apiUrl}${environment.routes.component}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    MoveComponent(request: MantComponentesModels.MoveComponentRequest, opts: { spinner: boolean } = { spinner: true }): Promise<MantComponentesModels.MoveComponentResponse> {
        return this.http.post<MantComponentesModels.MoveComponentResponse>(`${environment.apiUrl}${environment.routes.moveComponentes}`, request).toPromise();}

    isMaxTimeStore():boolean{
        let rest =(new Date()).getTime()-this.lastTime;
        return this.maxInterval <rest;
    }
}
