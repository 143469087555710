import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Tools } from "src/app/shared";
import { environment } from "src/environments/environment";
import { EquipoNuevoModels } from "../equipo-nuevo-models/equipo-nuevo.models";

@Injectable({
    providedIn: 'root'
})
export class EquipoNuevoService {

    constructor(
        private http: HttpClient,
        private tools: Tools) {
    }

    GetMarcaEquipoInfo(request: EquipoNuevoModels.GetMarcaEquipoRequest): Promise<EquipoNuevoModels.GetMarcaEquipoResponse> {
        return this.http.get<EquipoNuevoModels.GetMarcaEquipoResponse>(`${environment.apiUrl}${environment.routes.tipoEquipo}`, { params: this.tools.toHttpParams(request) }).toPromise(); 
    }

    setEquipoNuevo(request:EquipoNuevoModels.SetEquipoNuevoRequest): Observable<EquipoNuevoModels.SetEquipoNuevoResponse> {
        return this.http.post<EquipoNuevoModels.SetEquipoNuevoResponse>(`${environment.apiUrl}${environment.routes.equipo}`, request.equipo);
    }

    GetEquipoInfo(request: EquipoNuevoModels.GetEquipoVerRequest): Promise<EquipoNuevoModels.GetEquipoVerResponse> {
        return this.http.get<EquipoNuevoModels.GetEquipoVerResponse>(`${environment.apiUrl}${environment.routes.equipo}`, { params: this.tools.toHttpParams(request) }).toPromise(); 
    }
    
}