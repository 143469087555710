import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tools, Enums } from 'src/app/shared';
import { ServiceSubjectTime } from 'src/app/shared/tools/service-subject-time.tools';
import { environment } from 'src/environments/environment';
import { ReportesModels } from '../models/reportes.models';

@Injectable({
    providedIn: 'root'
})
export class ReportesService {

    subjetGetFaenas: ServiceSubjectTime<ReportesModels.GetFaenasRequest, ReportesModels.GetFaenasResponse>;
    subjetGetReportH: ServiceSubjectTime<ReportesModels.HistoricoUrlRequest, ReportesModels.HistoricoUrlResponse>;
    subjetGetCompo: ServiceSubjectTime<ReportesModels.GetComponentesRequest, ReportesModels.GetComponentesResponse>;



    constructor(
        private http: HttpClient,
        private tools: Tools) {


        this.subjetGetFaenas = new ServiceSubjectTime(`${environment.apiUrl}${environment.routes.solicitantes}`, http, tools);
        this.subjetGetReportH = new ServiceSubjectTime(`${environment.apiUrl}${environment.routes.reportHistorico}`, http, tools);
        this.subjetGetCompo = new ServiceSubjectTime(`${environment.apiUrl}${environment.routes.component}`, http, tools);


    }




    getFaenas(request: ReportesModels.GetFaenasRequest, force: boolean = false): Observable<ReportesModels.GetFaenasResponse> {
        return this.subjetGetFaenas.httpGet(request, force);
    }


    SetReportHitorico(request: ReportesModels.reportHistoricoRequest, opts: { spinner: boolean } = { spinner: true }): Promise<ReportesModels.HistoricoResponse> {
        return this.http.post<ReportesModels.HistoricoResponse>(`${environment.apiUrl}${environment.routes.reportHistorico}`, request, { context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }
    GetReportHitorico(request: ReportesModels.HistoricoUrlRequest): Promise<ReportesModels.HistoricoUrlResponse> {
        return this.http.get<ReportesModels.HistoricoUrlResponse>(`${environment.apiUrl}${environment.routes.reportHistorico}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    }





    GetComponentes(request: ReportesModels.GetComponentesRequest, forceRefresh: boolean = false): Observable<ReportesModels.GetComponentesResponse> {
        return this.subjetGetCompo._httpGet(request, { force: forceRefresh, spinner: false });
    }

    SetReportComponente(request: ReportesModels.SetReporteComponenteRequest, opts: { spinner: boolean } = { spinner: true }): Promise<ReportesModels.SetReporteComponenteResponse> {
        return this.http.post<ReportesModels.SetReporteComponenteResponse>(`${environment.apiUrl}${environment.routes.componenteReporte}`, request, { context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    GetReporteComponente(request: ReportesModels.GetReporteComponenteRequest, forceRefresh: boolean = false): Promise<ReportesModels.GetReporteComponenteResponse> {
        return this.http.get<ReportesModels.GetReporteComponenteResponse>(`${environment.apiUrl}${environment.routes.componenteReporte}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    }

    //ComponentesPromise
    GetComponentesP(request: ReportesModels.GetComponentesRequest): Promise<ReportesModels.GetComponentesResponse> {
        return this.http
            .get<ReportesModels.GetComponentesResponse>(`${environment.apiUrl}${environment.routes.component}`, {
                params: this.tools.toHttpParams(request),
            })
            .toPromise();
    }


}


