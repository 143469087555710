import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home-view/home.component';
import { LoginComponent } from './login/login-view/login.component';
import { OauthCallbackComponent } from './oauth-callback/oauth-callback-view/oauth.callback.component';
import { AuthGuard, ClientGuard, proformaGuard } from './shared/auth/auth.guard';
import { BusquedaCompomenteComponent } from './ingreso-muestra/busqueda-componente/busqueda-views/busqueda.component';
import { RegistraMuestraComponent } from './ingreso-muestra/registra-muestra/registra-views/registra.component';
import { ComprobanteMuestraComponent } from './ingreso-muestra/comprobante-muestra/comprobante-views/comprobante.component';
import { RegistraMuestraMultipleComponent } from './ingreso-muestra/registro-muestra-multiple/registro-multiple-views/registro-multiple.component';
import { CajaDeMuestrasComponent } from './caja-muestras/caja-muestras-views/caja-view/caja-muestas.component';
import { ComprobanteCajaMuestraComponent } from './caja-muestras/caja-muestras-views/comprobante-view/caja-comprobante.component';
import { MantEquiposComponent } from './mantenedores/mant-equipos/mant-equipos-views/mantenedor/mant-equipos.component';
import { MantComponentesComponent } from './mantenedores/mant-componentes/mant-componentes-views/mantenedor/mant-componentes.component';
import { CartaAutRetiroComponent } from './shared/components/carta-autorizacion-retiro/carta-views/carta.component';
import { TarjetaComprobanteEnvioComponent } from './caja-muestras/caja-muestras-views/tarjeta-envio-view/tarjeta.component';
import { MantSolicitudDeAnalisisComponent } from './mantenedores/mant-solicitud-de-analisis/mant-solicitud-analisis-views/mantenedor-view/mant-solicitud-analisis.component';
import { SolicitudDeAnalisisComponent } from './shared/components/doc-solicitud-de-analisis/solicitud-views/solicitud.component';
import { CondicionesComercialesComponent } from './condiciones-comerciales/condiciones-comerciales-views/condiciones-comerciales.component';
import { SolicitudDeInsumosComponent } from './solicitud-de-insumos/solicitud-de-insumos-views/table-view/solicitud-de-insumos.component';
import { MantUsuariosComponent } from './mantenedores/mant-usuarios/mant-usuarios-views/mantenedor/mant-usuarios.component';
import { UsuarioPerfilComponent } from './mantenedores/mant-usuarios/mant-usuarios-views/usuario-perfil/usuario-perfil-views/usuario-perfil.component';
import { ExternalContentComponent } from './external-content/view/ext.component';
import { BasicLayoutComponent } from './modules/layouts/basic-layout/basic-layout.component';
import { ReporteMenuComponent } from './reportes/reporte-menu/reporte-menu.component';
import { ReporteHistoricoComponent } from './reportes/reporte-historico/reporte-historico.component';
import { ReporteComponentesComponent } from './reportes/reporte-componentes/reporte-componentes.component';
import { PendientesFacturacionComponent } from './condiciones-comerciales/condiciones-comerciales-components/pendientes-facturacion/pendientes-facturacion.component';
import { EditSampleComponent } from './sample/edit-sample/edit-sample.component';
import { MantLimitesComponent } from './mantenedores/mant-limites/mant-limites.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'oauth-callback', component: OauthCallbackComponent },
    { path: 'busqueda-componente', component: BusquedaCompomenteComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'agregar-muestra', component: RegistraMuestraComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'agregar-muestras', component: RegistraMuestraMultipleComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'comprobante-muestra', component: ComprobanteMuestraComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'sample-box', component: CajaDeMuestrasComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'sample-box-comprobante', component: ComprobanteCajaMuestraComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'solicitud-analisis', component: SolicitudDeAnalisisComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'mantenedor-componentes', component: MantComponentesComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'mantenedor-equipos', component: MantEquiposComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'carta-aut-retiro', component: CartaAutRetiroComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'tarjeta-envio', component: TarjetaComprobanteEnvioComponent, canActivate: [AuthGuard, ClientGuard] },
    { path:'limites', component: MantLimitesComponent, canActivate: [AuthGuard, ClientGuard], data: { roles: ['Admin'] }},
    { path: 'solicitudes-de-analisis', component: MantSolicitudDeAnalisisComponent, canActivate: [AuthGuard, ClientGuard] },
    {
        path: 'administracion-usuarios',
        component: MantUsuariosComponent,
        canActivate: [AuthGuard, ClientGuard],
        data: { roles: ['Admin'] },
    },
    {
        path: 'muestra/editar',
        component: EditSampleComponent,canActivate: [AuthGuard, ClientGuard],
      },
    { path: 'mi-perfil', component: UsuarioPerfilComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'pendiente-facturacion', component: PendientesFacturacionComponent, canActivate: [AuthGuard, ClientGuard, proformaGuard] },
    { path: 'condiciones-comerciales', component: CondicionesComercialesComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'solicitud-de-insumos', component: SolicitudDeInsumosComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'external-content', component: ExternalContentComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'reportes', component: ReporteMenuComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'reportes/historico', component: ReporteHistoricoComponent, canActivate: [AuthGuard, ClientGuard] },
    { path: 'reportes/componentes', component: ReporteComponentesComponent, canActivate: [AuthGuard, ClientGuard] },
    {
        path: 'reports2',
        canActivate: [AuthGuard, ClientGuard],
        component: BasicLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/reports2/reports.module').then((m) => m.ReportsModule),
            },
        ],
    },

    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: '**', redirectTo: 'home' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'legacy',
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
