
<div *ngIf="dashboard_enabled" class='tableauPlaceholder' style='width: 100%; height: 100%;'>
  <div id="tableauVizView" style='width: 100%; height: 100%;'></div>
</div>
<div *ngIf="!dashboard_enabled" class="d-flex justify-content-center align-items-center flex-column gap-3" style="height:calc(100vh - 160px)">
<img src="assets/images/no-dashboar_tableau.svg" alt="">
<h1 class="title">
  <i class="bi bi-exclamation-triangle-fill"></i>
  Esta faena no tiene acceso
</h1>
<p class="w-50 text-center">Lo siento, parece que no tiene acceso al dashboard en este momento. Si quieres tener acceso, contacta a tu responsable de faena {{responsable?.nombre | titlecase}} envíe un correo electrónico a {{responsable?.email | lowercase}} y nuestro equipo estará encantado de ayudarlo.</p>
</div>