import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface Imagen {
  imagenes: {
    ensayoId: string;
    ensayoNombre: string;
    nombre: string;
    url: string;
  }[]
}

@Component({
  selector: 'app-modal-gallery',
  templateUrl: './modal-gallery.component.html',
  styleUrls: ['./modal-gallery.component.css']
})
export class ModalGalleryComponent implements OnInit {

  @Input() imagenesInput: Imagen = {
    imagenes: [],
  }
  imagenes: Array<{ensayoId: string;
    ensayoNombre: string;
    nombre: string;
    url: string;}> = []

  constructor(
    public dialogRef: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    if(this.imagenesInput.imagenes){
      this.imagenes = this.imagenesInput.imagenes;
    }
  }

  close(){
    this.dialogRef.close();
  }

}
