import { Injectable } from '@angular/core';
import { Enums, Objects } from 'src/app/shared';

@Injectable({ providedIn: 'root' })
export class Sampletools {  
  
    enumStatus:{[key:string]:string}=Enums.EstadosDeResultado;

    public statusSample(sample:Objects.RegistroMuestra):{label:string,key:string}{
        let state:string= sample.resultados?.length===0?'analisis':'normal';
        let countAlert:number=0;
        let countCaution:number=0;
      
        sample.resultados?.filter(r=>r.stamp ==='cargado').forEach(r=>{
          countAlert=r.estado?.status==='alerta'?countAlert+1:countAlert;
          countCaution=r.estado?.status==='precaucion'?countCaution+1:countCaution;
        })

        state=countAlert>0?'alerta':countCaution>0?'precaucion':state;    
        
        return {key:state,label:this.enumStatus[state]};
      }
  
}
