import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Tools } from "src/app/shared";
import { ServiceSubjectTime } from "src/app/shared/tools/service-subject-time.tools";
import { environment } from "src/environments/environment";
import { MuestrasModels } from "../mant-muestras-models/mant-muestras.models";
import { Enums } from "src/app/shared/models/constants";

@Injectable({
    providedIn: 'root'
})
export class MuestrasService {

    subjetGetMuestras: ServiceSubjectTime<MuestrasModels.GetMuestrasRequest, MuestrasModels.GetMuestrasResponse>;
    subjetGetMuestraId: ServiceSubjectTime<MuestrasModels.GetMuestrasIdRequest, MuestrasModels.GetMuestraByIdResponse>;

    constructor(
        private http: HttpClient,
        private tools: Tools
    ){
        this.subjetGetMuestras = new ServiceSubjectTime(`${environment.apiUrl}${environment.routes.muestras}`,http,tools);
        this.subjetGetMuestraId = new ServiceSubjectTime(`${environment.apiUrl}${environment.routes.muestras}`,http,tools);
    }

    getMuestras(request: MuestrasModels.GetMuestrasRequest,force:boolean=false): Promise<MuestrasModels.GetMuestrasResponse> {
        return this.http.get<MuestrasModels.GetMuestrasResponse>(`${environment.apiUrl}${environment.routes.muestras}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    }

    getMuestraId(request: MuestrasModels.GetMuestrasIdRequest,force:boolean=true): Observable<MuestrasModels.GetMuestraByIdResponse> {
        return this.subjetGetMuestraId.httpGet(request,force);
    }

    getMuestraById(request: MuestrasModels.GetMuestraRequest,opts: {force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean } = {force: false, spinner: false} ): Promise<MuestrasModels.GetMuestraResponse> {
        return this.http.get<MuestrasModels.GetMuestraResponse>(`${environment.apiUrl}${environment.routes.muestras}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    GetMuestraReporteMassive(request: MuestrasModels.GetMuestraReporteMassiveRequest,opts: {force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean } = {force: false, spinner: false} ): Promise<MuestrasModels.GetMuestraReporteMassiveResponse> {
        return this.http.get<MuestrasModels.GetMuestraReporteMassiveResponse>(`${environment.apiUrl}${environment.routes.report_massive}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    GetMuestraReporteMassiveTRX(request: MuestrasModels.GetMuestraReporteMassiveTRXRequest,opts: {force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean } = {force: false, spinner: false} ): Promise<MuestrasModels.GetMuestraReporteMassiveTRXResponse> {
        return this.http.get<MuestrasModels.GetMuestraReporteMassiveTRXResponse>(`${environment.apiUrl}${environment.routes.report_massive}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }
    
}
