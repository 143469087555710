<div id="caja-de-muestras">

   <div *ngIf="model.data.muestras.length>0">
      <div class="row mb-2 mb-xl-3">
         <div class="col-md-6">
            <h3 id="caja-muestras-title"><i class="bi bi-box-seam"></i> Caja de muestras</h3>

         </div>
         <div class="col-md-6 text-end">
            <small class="text-muted">Todas estas muestras han sido agregadas al sistema y están listas para ser
               enviadas al laboratorio, en una <strong>Orden de Transporte</strong>.</small>
         </div>
      </div>
      <div class="row justify-content-center mt-5">
         <div class="col">
            <div class="card">
               <div class="table-responsive">
                  <table class="table table-hover my-0" id="tablaDeMuestras">
                     <tbody>
                        <tr *ngFor="let muestra of model.data.muestras">
                           <td class="align-top col-icono">
                              <img src="assets/images/icono-muestra.svg" alt=""
                                 *ngIf="muestra.lubricante?.lubricanteId !=='0'">
                              <img src="assets/images/icono-muestra-alerta.svg" alt=""
                                 *ngIf="muestra.lubricante?.lubricanteId ==='0'">

                           </td>
                           <td class="align-top">
                              <ng-template #popContent>
                                 <div>
                                    <div>
                                       <div class="row">
                                          <div class="col">
                                             <small>
                                                <p class="card-text mb-1">
                                                   Toma de Muestra
                                                </p>
                                             </small>
                                          </div>
                                          <div class="col ">
                                             <small>
                                                <p class="card-text mb-1">
                                                   <strong>{{muestra.fechaMuestreo}}</strong>
                                                </p>
                                             </small>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col">
                                             <small>
                                                <p class="card-text mb-1">
                                                   Plan de an&aacute;lisis
                                                </p>
                                             </small>
                                          </div>
                                          <div class="col ">
                                             <small>
                                                <p class="card-text mb-1">
                                                   <strong>{{muestra.planAnalisis?.nombre}}</strong>
                                                </p>
                                             </small>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col">
                                             <small>
                                                <p class="card-text mb-1">
                                                   Uso componente
                                                </p>
                                             </small>
                                          </div>
                                          <div class="col ">
                                             <small>
                                                <p class="card-text mb-1">
                                                   <strong>{{muestra.usoTotalComponente?muestra.usoTotalComponente:'0'}}
                                                      {{
                                                      muestra.unidadUsoTotalComponente?muestra.unidadUsoTotalComponente:'-'}}</strong>
                                                </p>
                                             </small>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col">
                                             <small>
                                                <p class="card-text mb-1">
                                                   Uso lubricante
                                                </p>
                                             </small>
                                          </div>
                                          <div class="col">
                                             <small>
                                                <p class="card-text mb-1">
                                                   <strong>{{muestra.usoCambioLubricante?muestra.usoCambioLubricante:'0'}}
                                                      {{muestra.unidadUsoCambioLubricante?muestra.unidadUsoCambioLubricante:'-'}}</strong>
                                                </p>
                                             </small>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col">
                                             <small>
                                                <p class="card-text mb-1">
                                                   Relleno
                                                </p>
                                             </small>
                                          </div>
                                          <div class="col ">
                                             <small>
                                                <p class="card-text mb-1">
                                                   <strong>{{muestra.rellenoDesdeUltimoCambio?muestra.rellenoDesdeUltimoCambio:0}}
                                                      litros</strong>
                                                </p>
                                             </small>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="card-footer py-0 border border-1"
                                       *ngIf="muestra.lubricante?.lubricanteId === '0' ">
                                       <div class="row align-bottom">
                                          <div class="col-2 py-3">
                                             <i class="bi bi-exclamation-circle text-warning"></i>
                                          </div>
                                          <div class="col-10 p-0 text-muted">
                                             <small>

                                                Lubricante nuevo, debe enviarse una muestra sin uso.
                                             </small>
                                          </div>
                                       </div>
                                    </div>

                                 </div>
                              </ng-template>
                              <span [ngbPopover]="popContent" #p="ngbPopover" placement="end">
                                <div class="text-danger" *ngIf="errorPlan[muestra.muestraId]&&!this.loadCondicionComercial"><small>Plan de análisis no contratado <i class="bi bi-exclamation-circle-fill"></i></small></div>
                                 <a [routerLink]="" class="link-primary">
                                    <strong>Envase {{muestra.muestraId}}</strong><br>{{muestra.lubricante?.lubricanteId ==='0' ? muestra.otroLubricante:muestra.lubricante?.nombre}}<br>
                                 </a>
                              </span>
                           </td>
                           <td class="align-top"><strong>Componente ID
                                 {{muestra.componente.componenteId}}</strong><br>{{muestra.componente.tipoComponente.nombre}}<br>{{muestra.componente.marca}}
                              {{muestra.componente.modelo}}</td>
                           <td class="align-top">
                              <strong>Equipo</strong><br>{{muestra.componente.equipo?.tipoEquipo?.nombre}}
                              <br>{{muestra.componente.equipo?.marca}} {{muestra.componente.equipo?.modelo}}</td>
                           <td class="align-top" data-toggle="tooltip" data-placement="top"
                              title="{{muestra.fechaIngreso| amLocale:'es' | amTimeAgo}}"><strong>Ingresada</strong><br>
                              <span> {{ esHoy(muestra.fechaIngreso)? 'Hoy':'El ' + (muestra.fechaIngreso | date:'dd/MM/yyyy') }} a las {{muestra.fechaIngreso | date :'HH:mm'}} hrs.</span><br>por
                              {{muestra.usuario?.nombre}} {{muestra.usuario?.apellido}}</td>
                           <td class="text-end align-top">
                              <button type="button" class="btn btn-outline-secondary mb-1"
                                 (click)="editarMuestra(muestra)">
                                 <i class="bi bi-pencil-fill"></i>
                              </button>
                              <button type="button" class="btn btn-outline-secondary mb-1"
                                 (click)="eliminarMuestra(muestra)">
                                 <i class="bi bi bi-trash-fill"></i>
                              </button>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
            <div class="row mb-5">
               <div class="col text-start">
                  <p class="text-muted">
                     <a type="button" class="link-secondary" (click)="recargarTabla()" [routerLink]=""
                        data-toggle="tooltip" data-placement="top"
                        title="{{sampleBoxData.lastUpdateSampleBoxData| date :'HH:mm:ss'}}">
                        <i class="bi bi-arrow-clockwise"></i>
                        <span *ngIf="sampleBoxData.lastUpdateSampleBoxData">
                           Actualizado {{sampleBoxData.lastUpdateSampleBoxData | amLocale:'es' | amTimeAgo}}
                        </span>
                     </a>
                  </p>
               </div>
               <div class="col text-end">
                  <button id="caja-muestras-button-agregar-muestra-1" class="btn btn-link btn-lg" (click)="irABusquedaComponente()">Agregar muestra</button>
                  <button id="caja-muestras-button-confirmar-envio" [disabled]="!validaPlan() || loadCaja" class="btn btn-success btn-lg" (click)="confirmarEnviarCajaDeMuestras()">Enviar las
                     {{model.data.muestras.length}} muestras</button>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div *ngIf="model.data.muestras.length==0">
      <div class="row mb-2 mb-xl-3">
         <div class="col-md-6">
            <h3><i class="bi bi-box-seam"></i> Caja de muestras</h3>
         </div>
         <div class="col-md-6 text-end">
         </div>
      </div>
      <div class="row row-cols-1 justify-content-center" id="aviso-caja-vacia">
         <div class="col-sm-12 col-md-10 col-lg-7 col-xl-5 col-xxl-4 mt-3 mt-sm-7 text-center">
            <i class="bi bi-cone-striped" style="font-size: 5rem; color: lightgray"></i>
            <h2>Nada por aquí</h2>
            <p>Comienza por agregar una o varias muestras.</p>
         </div>
         <div class="col text-center">
            <button id="caja-muestras-button-agregar-muestra-2" class="btn btn-lg btn-primary" (click)="irABusquedaComponente()">Agregar muestra</button>
         </div>
      </div>
   </div>
</div>
