import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "src/app/shared/auth/authentication.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { UsuarioNuevoComponent } from "../../usuario-nuevo/usuario-nuevo-views/usuario-nuevo.component";
import { UsuarioPerfilModel } from "../usuario-perfil-models/usuario-perfil.models";
import { UsuarioPerfilViewModel } from "../usuario-perfil-models/usuario-perfil.view.models";

@Component({
    selector: 'app-usuario-perfil',
    templateUrl: './usuario-perfil.component.html',
    styleUrls: ['./usuario-perfil.component.css']
})
export class UsuarioPerfilComponent implements OnInit{

    model: UsuarioPerfilViewModel.ViewModel = {
        usuario: {
            applicationId: '',
            birthdate: new Date(),
            email: '',
            email_verified: false,
            family_name: '',
            given_name: '',
            middle_name: '',
            name: '',
            phone_number: '',
            preferred_username: '',
            roles: [],
            sub: '',
            access_token: '',
            rut: '',
            nombre: '',
            apellido: '',
            userId: '',
            phone: '',
            is_super_admin: false,
            proforma_enabled: false,
            clientes: []
        },
        cliente: {
            clienteId: '',
            numeroSap: '',
            razonSocial: '',
            faena: {
                direccion: '',
                faenaId: '',
                nombre: '',
                numeroSap: '',
                rubro: '',
                transporte: ''
            }
        }
    }

    constructor(
        private authService: AuthenticationService,
        private spinner: SpinnerService,
        private modalService: NgbModal,
    ){}

    ngOnInit(): void{
        this.getUsuarioYCliente();
    }

    getUsuarioYCliente(): void{
        this.model.usuario = this.authService.currentUserValue;
        this.model.cliente = this.authService.currentClientValue;
    }

    editarUsuario(): void{
        let usuarioInput: UsuarioPerfilModel.UsuarioInput = {
            usuario: this.model.usuario,
            desdeVerPerfil: true
        }

        const modalRef=this.modalService.open(UsuarioNuevoComponent, {
            centered:true,
            size: 'md'
        });
        modalRef.componentInstance.usuarioInput = Object.assign({}, usuarioInput) ;
    }

}
