import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Tools } from "src/app/shared";
import { Enums } from "src/app/shared/models/constants";
import { environment } from "src/environments/environment";
import { MantUsuariosModels } from "../mant-usuarios-models/mant-usuarios.models";

@Injectable({
    providedIn: 'root'
})
export class MantUsuariosService {

    value: MantUsuariosModels.GetUsuariosResponse|undefined;
    sub: Subject<MantUsuariosModels.GetUsuariosResponse>;
    lastTime:number = new Date().getTime();
    maxInterval:number = environment.config.api.tiempoGeneralEspera;

    constructor(
        private http: HttpClient,
        private tools: Tools
    ){
        this.value={code:-1,data:[],message:'',success:true};
        this.sub=new BehaviorSubject(this.value);
    }

    GetUsuarios(request: MantUsuariosModels.GetUsuariosRequest, forceRefresh: boolean = false){
        if(this.value?.code===-1 || this.isMaxTimeStore() || forceRefresh){
            this.http.get<MantUsuariosModels.GetUsuariosResponse>(`${environment.apiUrl}${environment.routes.usuario}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise().then(r=>{
                this.value=r;
                this.lastTime=new Date().getTime();
                this.sub.next(this.value);
            });
        }

        return this.sub;
    }

    DeleteUsuario(request: MantUsuariosModels.DeleteUsuarioRequest): Observable<MantUsuariosModels.DeleteUsuarioResponse> {
        return this.http.post<MantUsuariosModels.DeleteUsuarioResponse>(`${environment.apiUrl}${environment.routes.usuario}`, request);
    }

    isMaxTimeStore():boolean{
        let rest =(new Date()).getTime()-this.lastTime;
        return this.maxInterval <rest;
    }
}
