import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailProformaComponent } from './views/detail-proforma/detail-proforma.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: DetailProformaComponent
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProformaRoutingModule { }
