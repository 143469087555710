<div class="row justify-content-center">
  <div class="col" [ngClass]="{ 'd-none': esSinTransporte()}">
    <descarga-carta-autorizacion-retiro courier="CHILEEXPRESS" #CARTACHILEXPRESS></descarga-carta-autorizacion-retiro>
    <descarga-carta-autorizacion-retiro courier="CORREOSCHILE" #CARTACORREOS></descarga-carta-autorizacion-retiro>
  </div>
  <div class="col-sm-12 col-md-10 col-lg-7 col-xl-7" [ngClass]="{ 'col-xxl-4': !esSinTransporte()}">
      <div class="card text-dark mb-3 border text-start" *ngIf="esEnvioCourier()">
          <div class="card-header bg-light"><strong>Ahora, env&iacute;anos las muestras</strong></div>
          <div class="card-body">
              <div class="row">
                  <div class="col">
                    <ul>
                        <li>Coloca todas las muestras en una misma caja en forma segura.</li>
                        <li>Entrega la caja en una oficina de Chilexpress o Correos de Chile, junto con la <strong>carta de autorizaci&oacute;n</strong>.</li>
                        <li>Una vez enviada la caja, ingresa el n&uacute;mero de la Orden de Transporte que te van a dar, en la p&aacute;gina de Solicitudes de An&aacute;lisis.</li>
                      </ul>
                  </div>
              </div>
              <a href="#"  (click)="CARTACHILEXPRESS.descargarCarta(solicitudAnalisisId)"> <i class="bi bi-file-text"></i>&nbsp; Carta para Chilexpress</a><br>
              <a href="#"  (click)="CARTACORREOS.descargarCarta(solicitudAnalisisId)"> <i class="bi bi-file-text"></i>&nbsp; Carta para Correos de Chile</a>


        </div>
        <div class="card-footer">
            <div class="row">
              <div class="col-1 text-end text-muted"><i class="bi bi-exclamation-circle-fill"></i></div>
              <div class="col-11 justify-content-center">
                  <p class="text-muted">
                    <small>
                        La Carta para Correos de Chile o Chilexpress debe ser impresa desde la cuenta del usuario que va a llevar la caja de muestras, ya que se requiere que aparezca su nombre y Rut.
                    </small>
                  </p>
              </div>
            </div>
        </div>
      </div>

      <div class="card text-dark mb-3 border text-start" *ngIf="esLaboratorio()">
        <div class="card-header bg-light"><strong>Ahora, env&iacute;aremos a buscar las muestras</strong></div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                  <ul>
                      <li>Coloca todas las muestras en una misma caja en forma segura.</li>
                      <li>Entrega la caja en una oficina de Chilexpress o Correos de Chile, junto con la <strong>carta de autorizaci&oacute;n</strong>.</li>
                    </ul>
                </div>
            </div>
      </div>
    </div>
    <div class="card text-dark mb-3 border text-start" *ngIf="esSinTransporte()">
    <div class="card-header bg-light"><strong>Ahora, tr&aacute;enos las muestras</strong></div>
    <div class="card-body">
        <div class="row">
            <div class="col">
                <ul>
                  <li>Coloca todas las muestras en una misma caja en forma segura</li>
                  <li>Lleva la caja a nuestro laboratorio ubicado en <strong> Camino Costero 1111, sector el Bato, Loncura, Quintero.</strong> </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
  </div>
</div>
