import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendienteFacturacionModels } from '../../pendientes-facturacion-models/pendientes-facturacion-models';


@Component({
    selector: 'app-modal-document-detail',
    templateUrl: './modal-document-detail.component.html',
    styleUrls: ['./modal-document-detail.component.css']
})
export class ModalDocumentDetailComponent implements OnInit {
    is_request_call: boolean = false;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ModalDocumentDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { document: PendienteFacturacionModels.DocumentoVentas },
    ) { }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close({ r: 'close', d: this.data.document })
    }



    get total() {
        let total = 0;
        this.data.document.pedido_cost.planes_analisis.forEach(p => {
            total += Number(p.total_cobro)
        });
        return total;
    }



    canRelease() {
        return this.data.document.pedido_cost.estado.toUpperCase() === 'PENDIENTE' && this.data.document.pedido_cost.is_envio_cebe === false;
    }
    canEdit() {
        return this.data.document.pedido_cost.is_auto === false &&
            this.data.document.pedido_cost.is_created_sap_manual === true
            && ['ENVIADO', 'ELIMINADO'].indexOf(this.data.document.pedido_cost.estado.toUpperCase()) !== -1;
    }

    canReleaseCebe() {
        return this.data.document.pedido_cost.is_auto === false && this.data.document.pedido_cost.is_envio_cebe === true;
    }

    canShowManual() {
        return this.data.document.pedido_cost.is_auto === false && this.data.document.pedido_cost.estado.toUpperCase() !== 'PENDIENTE' && this.data.document.pedido_cost.is_created_sap_manual === true;
    }



    public format_currency(currency:string): string {
        let response = "$ "

        if (currency === "USD"){
            response = "US$ ";
        } else if (currency === "UF"){
            response = "UF ";
        }

        return response;
    }

}
