import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpContextToken, HttpHeaders } from '@angular/common/http';
import { BusquedaModels } from '../busqueda-models/busqueda.models';
import { environment } from 'src/environments/environment';
import { Tools } from 'src/app/shared';
import { Observable,  Subject, BehaviorSubject } from 'rxjs';
import { Enums } from 'src/app/shared/models/constants';

@Injectable({
    providedIn: 'root'
})
export class BusquedaService {

    value:BusquedaModels.GetComponentesResponse|undefined;
    sub:Subject<BusquedaModels.GetComponentesResponse>;
    lastTime:number=new Date().getTime();
    maxInterval:number=environment.config.api.tiempoGeneralEspera;

    constructor(
        private http: HttpClient,
        private tools: Tools) {
            this.value={code:-1,data:[],message:'',success:true};
            this.sub=new BehaviorSubject(this.value);
    }

    GetComponentes(request: BusquedaModels.GetComponentesRequest, forceRefresh: boolean = false): Observable<BusquedaModels.GetComponentesResponse> {
        if(this.value?.code===-1 || this.isMaxTimeStore() || forceRefresh ){
            this.http.get<BusquedaModels.GetComponentesResponse>(`${environment.apiUrl}${environment.routes.getComponentIds}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise().then(r=>{
                this.value=r;
                this.lastTime=new Date().getTime();
                this.sub.next(this.value);
            });
        }
        return this.sub;
    }

    isMaxTimeStore():boolean{
        let rest =(new Date()).getTime()-this.lastTime;
        return this.maxInterval <rest;
    }
}
