import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent {

    year: number = new Date().getFullYear();
    
    constructor(private router: Router) {
    }

}
