import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { ModalGalleryComponent } from 'src/app/shared/components/modal-gallery/modal-gallery.component';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { MuestrasModels } from './mant-muestras-models/mant-muestras.models';
import { MuestrasViewModels } from './mant-muestras-models/mant-muestras.view.models';
import { MuestrasService } from './mant-muestras-service/mant-muestras.service';
import { PaginatorComponent } from '../../shared/components/paginator/paginator.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SnackBarService } from 'src/app/shared/services/snackBar.service';

@Component({
    selector: 'app-mant-muestras',
    templateUrl: './mant-muestras.component.html',
    styleUrls: ['./mant-muestras.component.css']
})
export class MantMuestrasComponent implements OnInit {

    typeSearch = false;
    filterSampleId: string = '';
    displayedColumns: string[] = ['seleccion', 'frascos', 'solucitud', 'lubricante', 'componente', 'equipo', 'toma', 'envio', 'finalizado', 'resultado', 'action'];
    dataSource: MatTableDataSource<MuestrasModels.Muestra>;
    subscribers: { name: string, sub: Subscription, active?: boolean }[] = [];

    myControl = new FormControl();

    model: MuestrasViewModels.ViewModel = {
        pagination: { page: 0, pageSize: 10, totalElements: 0 },
        isLoading: false,
        isDownloading: false,
        muestras: [],
        muestras_encontradas: [],
        muestras_seleccionadas: [],
        alertaSinResultado: false,
        limiteSeleccionCompleto: false,
        limite_seleccion: environment.config.limiteSeleccionDescarga,
        checkAll: false,
        estados_muestras: [],
        estadoMuestra:{            
            registered:'Registrada',
            received:'Recepcionada',
            received_observations:'Recepcionada c/ observaciones',
            not_received:'No recepcionada',
            in_analysis:'En análisis',
            pending_approval:'En análisis',
            reported:'Reportada',
            registrada: 'Registrada',
            assigned_batch: 'Recepcionada',
            recibido: 'Recepcionada',
            sending: 'Recepcionada'
        }
    }

    paginatorParams: {
        refresh?: boolean,
        isLoading?: boolean,
        hasNext: boolean,
    } = {
            hasNext: false,
        }

    enumStatus: { [key: string]: string } = {
        analisis: 'En análisis',
        normal: 'Normal',
        precaucion: 'Precaución',
        alerta: 'En alerta'
    };

    pagination: {
        page_key?: string,
        page_size: number
    } = {
            page_size: 10
        }

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    range: {
        start: string,
        end: string
    } = {
            start: "",
            end: ""
        }
    visualizedFilter: 'all' | 'visualized' | 'not_visualized' = 'all';
    hasNotVisualized: boolean = false; // para pintar el circulo de las no visualizadas

    constructor(
        private modalService: NgbModal,
        private authService: AuthenticationService,
        private service: MuestrasService,
        private spinner: SpinnerService,
        private snackSrv: SnackBarService
    ) {
        this.dataSource = new MatTableDataSource<MuestrasModels.Muestra>([]);
    }

    ngOnInit(): void {
        var today = new Date();

        var firstDay = new Date(new Date().setDate(new Date().getDate() - 30));

        this.range.start = moment(firstDay).format('YYYY-MM-DD');
        this.range.end = moment(today).format('YYYY-MM-DD');

        this.getMuestras();
    }

    openGallery(muestra: MuestrasModels.Muestra) {
        if (muestra.imagenes) {
            const modal = this.modalService.open(ModalGalleryComponent, {
                centered: true
            });
            modal.componentInstance.imagenesInput.imagenes = Object.assign([], muestra.imagenes);
        }
    }

    async getMuestras(force: boolean = true, next: boolean = false, n?: number) {
        this.model.estados_muestras = [];
        this.model.estado_muestras = undefined;

        if (this.calculo()) {
            this.hasNotVisualized = false;

            this.destroySubs('muestras');
            let request: MuestrasModels.GetMuestrasRequest = {
                clienteId: this.authService.currentClientValue.clienteId,
                faenaId: this.authService.currentClientValue.faena.faenaId,
                period_from: moment(this.range.start).format('YYYY-MM-DD'),
                period_to: moment(this.range.end).format('YYYY-MM-DD'),
                page_size: 200
            };

            if (next) {
                request.page_key = this.pagination.page_key;
            }
            if (n) {
                request.page_size = n;
            }
            if (force) {
                this.dataSource = new MatTableDataSource<MuestrasModels.Muestra>([]);
            }

            this.model.isLoading = true;
            this.visualizedFilter = 'all';


            this.model.muestras = [];
            var muestras_lista_paso = await this.service.getMuestras(request, force);

            this.model.muestras = [...muestras_lista_paso.data.muestras];

            while (muestras_lista_paso.data.page_key) {
                request.page_key = muestras_lista_paso.data.page_key;
                muestras_lista_paso = await this.service.getMuestras(request, force);
                this.model.muestras = [...this.model.muestras, ...muestras_lista_paso.data.muestras];
            }

            this.model.muestras_encontradas = this.model.muestras;
            const visualizedDateLimit = environment.config.visualizedSampleDateLimit;

            this.model.muestras.forEach(m => {
                m = this.getDates(m);

                if (m.analisisFinalizado && m.analisisFinalizado < visualizedDateLimit) {
                    m.visualizadoWebcliente = true;
                }

                if (m.estado === 'reported' && !m.visualizadoWebcliente) {
                    this.hasNotVisualized = true;
                }
            });

            this.model.muestras = this.model.muestras.sort((a, b) => {
                const x = Number(a.fechaMuestreo.split("-").join(''));
                const y = Number(b.fechaMuestreo.split("-").join(''));
                return (y - x);
            });

            this.filterVisualizedSamples();

            

            this.model.isLoading = false;
            this.spinner.off()
        }
    }

    private destroySubs(name?: string) {
        this.subscribers.filter(s => !name || s.name === name).forEach(s => {
            s.sub.unsubscribe();
            s.active = false;
        })
    }

    private addSubscribe(name: string, sub: Subscription) {
        this.subscribers.push({ name: name, sub: sub });
    }

    childsTabReport: { [ref: string]: { bottleNumber: string, tab: Window, sample?: MuestrasModels.Muestra } } = {};

    public getPDF(muestra: MuestrasModels.Muestra): void {

        const ref = new Date().getTime();
        const url = '/reports2?ref=' + ref;

        let tab = window.open(url, '_blank');
        if (tab) {
            this.childsTabReport[ref] = {
                tab: tab,
                bottleNumber: muestra.muestraId,
                sample: muestra
            }


            setTimeout(() => {
                this.updateSampleFromService(muestra);
            }, 5000);
        }
    }

    private getDates(muestra: MuestrasModels.Muestra): MuestrasModels.Muestra {

        let finalizado = muestra.traces?.find(t => t.estado == 'reported')
        muestra.analisisFinalizado = finalizado ? moment(finalizado?.fecha + ' ' + finalizado?.hora, 'DD/MM/YYYY HH:mm:ss').format("YYYY-MM-DDTHH:mm") : '';
        muestra.statesample = this.statusSample(muestra);

        muestra.traces?.forEach(t => {
            if (t.fecha.indexOf("T") === -1) {
                t.fecha = moment(t.fecha, 'DD/MM/YYYY HH:mm:ss').format("YYYY-MM-DDTHH:mm");
            }
        });

        muestra.enviadoLaboratorio = muestra.traces?.find(t => t.estado == 'in_analysis')?.fecha;
        muestra.ingresadoLote = muestra.traces?.find(t => t.estado == 'assigned_batch')?.fecha;
        muestra.recibido = muestra.traces?.find(t => t.estado == 'received')?.fecha;

        return muestra;
    }

    public statusSample(muestra: MuestrasModels.Muestra): { label: string, key: string } {
        let state: string = muestra.resultados?.length === 0 ? 'analisis' : 'normal';
        let countAlert: number = 0;
        let countCaution: number = 0;

        muestra.resultados?.filter(r => r.stamp === 'cargado').forEach(r => {
            countAlert = r.estado?.status === 'alerta' ? countAlert + 1 : countAlert;
            countCaution = r.estado?.status === 'precaucion' ? countCaution + 1 : countCaution;
        })

        state = countAlert > 0 ? 'alerta' : countCaution > 0 ? 'precaucion' : state;

        return { key: state, label: this.enumStatus[state] };
    }



    public callBackTableResult(result: any[]): void {
        this.dataSource = new MatTableDataSource<MuestrasModels.Muestra>(result);
    }

    public callBackNextPage(size: number): void {
        this.getMuestras(true, true, size);
    }

    public callBackItemPerPage(n: number): void {
        this.getMuestras(true, false, n);
    }

    formatDate(value: any) {
        return moment(value).format('dd-MM-yyyyY');
    }


    isIsoDate(str: string) {

        if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str) || /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}/.test(str)) {

            var d = new Date(str);
            return d.toISOString() === str;
        }

        return true;
    }

    buscarPorId(): void {
        if (this.filterSampleId != null && this.filterSampleId != "") {
            this.dataSource = new MatTableDataSource<MuestrasModels.Muestra>([]);
            this.destroySubs('muestrasId');
            let request: MuestrasModels.GetMuestrasIdRequest = {
                clienteId: this.authService.currentClientValue.clienteId,
                faenaId: this.authService.currentClientValue.faena.faenaId,
                correlativo: this.filterSampleId.trim()
            };

            this.model.isLoading = true;
            this.spinner.off()
            this.addSubscribe('muestrasId', this.service.getMuestraId(request).subscribe(response => {
                if (response.success) {
                    this.model.muestras = response.data;
                    this.model.muestras_encontradas = this.model.muestras;

                    const visualizedDateLimit = environment.config.visualizedSampleDateLimit;

                    this.model.muestras.forEach(m => {
                        m = this.getDates(m);

                        if (m.analisisFinalizado && m.analisisFinalizado < visualizedDateLimit) {
                            m.visualizadoWebcliente = true;
                        }

                        if (m.estado === 'reported' && !m.visualizadoWebcliente) {
                            this.hasNotVisualized = true;
                        }
                    });

                    this.model.isLoading = false;
                    this.paginatorParams.hasNext = false;

                    this.LoadPaginate();
                } else {
                }
            }));
        } else {
            this.getMuestras();
        }
    }

    private updateSampleArray(array: MuestrasModels.Muestra[], updatedSample: MuestrasModels.Muestra): MuestrasModels.Muestra[] {
        const index = array.findIndex(muestra => muestra.muestraId === updatedSample.muestraId);
        if (index !== -1) {
            return [...array.slice(0, index), updatedSample, ...array.slice(index + 1)];
        }
        return array;
    }

    updateSampleFromService(sample: MuestrasModels.Muestra): void {
        this.hasNotVisualized = false;
        this.destroySubs('muestrasId');
        let request: MuestrasModels.GetMuestrasIdRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId,
            correlativo: sample.correlativo,
        };

        this.model.isLoading = true;
        this.addSubscribe('muestrasId', this.service.getMuestraId(request).subscribe(response => {
            if (response.success) {
                response.data.forEach(m => m = this.getDates(m));
                const updatedSample = response.data[0];

                // actualiza arreglos muestras: muestras, muestras_encontradas y dataSource
                this.model.muestras = this.updateSampleArray(this.model.muestras, updatedSample);
                this.model.muestras_encontradas = this.updateSampleArray(this.model.muestras_encontradas, updatedSample);
                this.dataSource.data = this.updateSampleArray(this.dataSource.data, updatedSample);

                this.hasNotVisualized = this.model.muestras.some(m => m.estado === 'reported' && !m.visualizadoWebcliente);
                this.model.isLoading = false;
            }
        }));
    }

    @HostListener('window:message', ['$event'])
    onMessage(e: any) {
        // console.log('HostListener:', e)
        if (e.data.action === "showReport" && e.origin === window.location.origin) {

            switch (e.data.event) {
                case 'init':
                    let ref = this.childsTabReport[e.data.ref]
                    console.log("ref", ref)
                    if (ref) {
                        ref.tab.window.postMessage({ action: "loadReport", bottleNumber: ref.bottleNumber, sample: ref.sample }, window.location.origin);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    handlePageEvent(event: PageEvent): void {
        this.model.pagination.totalElements = event.length;
        this.model.pagination.pageSize = event.pageSize;
        this.model.pagination.page = event.pageIndex;
        this.LoadPaginate();
    }

    LoadPaginate(): void {
        const records = this.model.muestras_encontradas;
        this.dataSource = new MatTableDataSource<MuestrasModels.Muestra>(records);
        this.dataSource.sortingDataAccessor = (muestra: MuestrasModels.Muestra, sortHeaderId: string) => {
            switch (sortHeaderId) {
                case 'frascos': return muestra.muestraId;
                case 'solucitud': return muestra.solicitudAnalisis;
                case 'lubricante': return muestra.lubricante?.nombre ?? '';
                case 'componente': return muestra.componente.componenteId;
                case 'equipo': return muestra.componente.equipo?.tipoEquipo.tipoEquipoId ?? '';
                case 'toma': return Number(muestra.fechaMuestreo.split("-").join(''));
                case 'finalizado': return muestra.analisisFinalizado ?? '';
                case 'resultado': return muestra.estado ?? '';

                default: return 1;
            }
        }
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

    }

    public keyupInputSearch(filter: string) {
        if (filter || filter !== '') {
            this.model.pagination.page = 0;
            let filtro = filter.toLowerCase();
            this.model.muestras_encontradas = filtro.trim() === '' ? [] :
                this.model.muestras.filter(option =>
                    option.muestraId.toLowerCase().includes(filtro) ||
                    option.solicitudAnalisis.toLowerCase().includes(filtro) ||
                    option.lubricante?.nombre.toLowerCase().includes(filtro) ||
                    option.componenteId.toLowerCase().includes(filtro) ||
                    option.componente.tipoComponente.nombre.toLowerCase().includes(filtro) ||
                    option.componente.equipo?.tipoEquipo.nombre.toLowerCase().includes(filtro) ||
                    option.fechaMuestreo.toLowerCase().includes(filtro) ||
                    option.fechaIngreso?.toLowerCase().includes(filtro) ||
                    option.analisisFinalizado?.toLowerCase().includes(filtro) ||
                    option.estado?.toLowerCase().includes(filtro) ||
                    option.statesample.label.toLowerCase().includes(filtro) ||
                    option.statesample.key.toLowerCase().includes(filtro)
                );
            if (this.model.muestras_encontradas.length === 0) {
                this.model.alertaSinResultado = true;
                this.LoadPaginate();
            } else {
                this.model.alertaSinResultado = false;
                this.LoadPaginate();
            }

        } else {
            this.model.muestras_encontradas = this.model.muestras;
            this.model.alertaSinResultado = false;
            this.LoadPaginate();
        }
    }

    calculo() {
        var fechaInicio = new Date(this.range.start).getTime();
        var fechaFin = new Date(this.range.end).getTime();
        var diff = fechaFin - fechaInicio;
        diff = diff / (1000 * 60 * 60 * 24)
        if (diff > 31) {
            return false
        }

        return true
    }
    validarFechas() {
        var fechaInicio = new Date(this.range.start).getTime();
        var fechaFin = new Date(this.range.end).getTime();
        if (!fechaFin || !fechaInicio) {
            return false
        }
        var diff = fechaFin - fechaInicio;
        diff = diff / (1000 * 60 * 60 * 24)
        if (diff < 0) {
            return false
        }
        return true
    }

    clearSelected() {
        this.model.muestras_encontradas.forEach(m => {
            m.seleccionado = false;
        });

        this.model.muestras_seleccionadas = [];
    }

    filterVisualizedSamples(): void {
        this.model.pagination.page = 0;        

        this.clearSelected();

        switch (this.visualizedFilter) {
            case 'visualized':
                this.model.muestras_encontradas = this.model.muestras.filter(muestra =>
                    muestra.estado === 'reported' && !!muestra.visualizadoWebcliente
                );
                break;
            case 'not_visualized':
                this.model.muestras_encontradas = this.model.muestras.filter(muestra =>
                    muestra.estado === 'reported' && !muestra.visualizadoWebcliente
                );
                break;
            default:
                this.model.muestras_encontradas = this.model.muestras;
        }

        this.model.alertaSinResultado = this.model.muestras_encontradas.length === 0;

        this.model.estados_muestras = [...new Set(this.model.muestras.map(m => this.model.estadoMuestra[m.estado!]))];            

        this.LoadPaginate();
    }

    check(isChecked: boolean) {
        this.filtrarSeleccion();
        if (!isChecked) {
            this.model.checkAll = false;
        }
    }

    filtrarSeleccion() {
        this.model.muestras_seleccionadas = this.elementosSeleccionados();
        this.model.limiteSeleccionCompleto = this.model.muestras_seleccionadas.length >= this.model.limite_seleccion;
    }

    elementosSeleccionados(): MuestrasModels.Muestra[] {
        return this.model.muestras_encontradas.filter(e => {
            return e.seleccionado;
        });
    }

    checkTodos(event: any) {
        if (event.target.checked) {
            let cont: number = 0;
            this.model.muestras_encontradas.filter(m => m.estado === 'reported').forEach(e => {
                if (cont < this.model.limite_seleccion) {
                    e.seleccionado = true;
                    cont++;
                } else {
                    e.seleccionado = false;
                }
            });

        } else {
            this.model.muestras_encontradas.forEach(e => {
                e.seleccionado = false;
            });
        }
        this.filtrarSeleccion();
    }

    descargarSeleccionados() {
        this.spinner.startLoading();   
        this.model.isDownloading = true;     

        let request: MuestrasModels.GetMuestraReporteMassiveRequest = {
            action_type: 'set_report_sample_pdf',
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId,
            usuario: {
                apellido: this.authService.currentUserValue.apellido,
                email: this.authService.currentUserValue.email,
                nombre: this.authService.currentUserValue.nombre,
                phone: this.authService.currentUserValue.phone,
                rut: this.authService.currentUserValue.rut,
                userId: this.authService.currentUserValue.userId
            },
            muestras: this.model.muestras_seleccionadas.map(m => m.muestraId)
        }
        
        this.service.GetMuestraReporteMassive(request).then(r =>{
            if (!(r.success && r.code === 200)) return;

            const rq: MuestrasModels.GetMuestraReporteMassiveTRXRequest = {
                clienteId: this.authService.currentClientValue.clienteId,
                action_type: 'get_report_sample_pdf',
                trx: r.data.trx_report
            };

            const pollData = () => {
                this.service.GetMuestraReporteMassiveTRX(rq).then(rr => {
                    if (!(rr.success && rr.code === 200 && rr.data))  return;
                    console.log(rr.data);
                    if (rr.data.trx_complete) {
                        rr.data.is_empty === true && this.snackSrv.warning('No hay datos para mostrar');
                        rr.data.url && window.open(rr.data.url, '_blank');

                        this.spinner.stopLoading();   
                        this.model.isDownloading = false;                     
                    } else {
                        console.log("otra vez!!!")
                        setTimeout(pollData, 5000);
                    }
                });
            };

            pollData();
        });
    }

    filtrarEstadoMuestra() {        
        this.model.muestras_encontradas = this.model.estado_muestras ? this.model.muestras.filter(m => this.model.estadoMuestra[m.estado!] === this.model.estado_muestras) : this.model.muestras;
        this.LoadPaginate();
    }
}
