import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { Observable, Observer, OperatorFunction, Subscriber, Subscription } from 'rxjs';

import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { BusquedaModels } from '../busqueda-models/busqueda.models';
import { BusquedaViewModels } from '../busqueda-models/busqueda.view.models';
import { BusquedaService } from '../busqueda-services/busqueda.service';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { environment } from '../../../../environments/environment';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponenteNuevoComponent } from 'src/app/mantenedores/mant-componentes/mant-componentes-views/componente-nuevo/componente-nuevo-views/componente-nuevo.component';
import { EquipoNuevoComponent } from 'src/app/mantenedores/mant-equipos/mant-equipos-views/equipo-nuevo/equipo-nuevo-views/equipo-nuevo.component';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';

@Component({
    selector: 'app-busqueda-componente',
    templateUrl: './busqueda.component.html',
    styleUrls: ['./busqueda.component.css']
})
export class BusquedaCompomenteComponent implements OnInit,OnDestroy {
    displayedColumns: string[] = ['seleccion','componenteId', 'componenteName', 'marca', 'modelo','ubicacion', 'actions'];
    subscribers:Subscription[]=[];

    dataSource = new MatTableDataSource<BusquedaModels.Componente>([]);

    @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete!: MatAutocompleteTrigger;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    myControl = new FormControl();
    filteredOptions!: Observable<BusquedaModels.Componente[]>;

    model: BusquedaViewModels.ViewModel = {
        pagination: { page: 0, pageSize: 10, totalElements: 0 },
        checkAll:false,
        componentes: [],
        componentesEncontrados: [],
        idsNoEncontrados:[],
        componenteBuscado: '',
        componenteFiltro: '',
        componentesSeleccionados: [],
        limiteSeleccionCompleto:false,
        limiteSeleccion:environment.config.maxIngresoMuestras,
        alertaSinResultado:false,
        cargandoComponentes:false,
        TIPOS_BUSQUEDA:{multiple:1,simpleRecursiva:2,simple:3},
        tipoDeBusqueda:3,
        forceRefresh: false,
    };

    constructor(
        private authService: AuthenticationService,
        private service: BusquedaService,
        private router: Router,
        private spinner:SpinnerService,
        private modalService: NgbModal,
        private eventEmiiterSrv: EventEmitterService,

    ) { }

    ngOnInit(): void {
        this.getComponents();
        this.configureWatchers();
        this.eventEmiiterSrv.event("FLOW_NUEVO_EQUIPO_MUESTRA").subscribe(result=>{
            if(result == 'FINAL'){
                this.model.componenteFiltro = '';
                this.model.componentesEncontrados = [];
                this.model.forceRefresh = true;
                this.model.alertaSinResultado = false;
                this.getComponents();
            }
        });
    }

    ngOnDestroy() {

        this.eventEmiiterSrv.completeEvent("FLOW_NUEVO_EQUIPO_MUESTRA");
        this.subscribers.forEach(s=>{
            s.unsubscribe();
        })
   }
    private configureWatchers(): void {
        this.filteredOptions = this.myControl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this.filter(name) : [])
            );
    }


    private filter(name: string): BusquedaModels.Componente[] {
        const filterValue = name.toLowerCase();
        this.model.alertaSinResultado=false;
        let compFiltrados:BusquedaModels.Componente[]=[];
        this.model.tipoDeBusqueda= filterValue.indexOf(";")>-1?this.model.TIPOS_BUSQUEDA.multiple:filterValue.indexOf(" ")>-1?this.model.TIPOS_BUSQUEDA.simpleRecursiva:this.model.TIPOS_BUSQUEDA.simple;
        return compFiltrados;

    }

    displayFn(comp: BusquedaModels.Componente): string {
        return comp ? `ID ${comp.componenteId} ${comp.tipoComponente.nombre} - ${comp.equipo?.tipoEquipo} ${comp.equipo?.marca} ${comp.equipo?.modelo}` : '';
    }

    onSelectionChanged(event: MatAutocompleteSelectedEvent): void {
        this.selectedRow(event.option.value);
    }
    onFocusInputSearch(){
        if(this.model.cargandoComponentes){
            this.spinner.on();
            this.spinner.startLoading();
        }
    }
    getComponents(): void {
        const request: BusquedaModels.GetComponentesRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId
        };
        this.spinner.off();
        this.model.cargandoComponentes=true;
       let sub =  this.service.GetComponentes(request, this.model.forceRefresh)
            .subscribe(response => {
                if (response.success) {
                    this.model.componentes = response.data;
                    this.model.componentes.forEach(e=>{
                        e.seleccionado=false;
                    })
                } else {
                }
                this.model.cargandoComponentes=response.code!==-1?false:true;
            });
        this.subscribers.push(sub);
    }

    selectedRow(componente: BusquedaModels.Componente): void {
        let data = JSON.stringify(componente);
        this.router.navigate(['/agregar-muestra'], { queryParams: { data:data } ,skipLocationChange: true} );
    }


    enterInputSearch(): void {
        this.model.alertaSinResultado=false;
        if(this.esSeleccionMultiple()){
            this.filtrarComponentesPorIds('');
            if(this.model.componentesEncontrados.length>0 && this.model.idsNoEncontrados.length==0 ){
                this.filtrarSeleccion();
                this.ingresarMuestrasParaComponentes();
            }
        }else{
            this.filtrarComponentes();
        }
        this.autoComplete.closePanel();
    }


    filtrarComponentesPorIds(filterValue:string): void {
        filterValue=filterValue?filterValue:this.model.componenteFiltro;
        this.model.componentesEncontrados=[];
        this.model.idsNoEncontrados=[];
        let componentes=this.model.componentes;
        let idsEncontrados:string[]=[];
        let idsNoEncontrados:string[]=[];

        if (typeof(filterValue) === 'string') {
           let ids=filterValue.split(";");
            let idEsEncontrado:boolean;
           ids.forEach(id => {
                idEsEncontrado=false;
                componentes=componentes.filter(option =>{
                    let mantieneElemento=true;
                    if(option.componenteId.toLowerCase() === id.trim().toLowerCase() && idsEncontrados.indexOf(id)==-1 ){
                        idEsEncontrado=true;
                        option.seleccionado= idsEncontrados.length<this.model.limiteSeleccion;
                        idsEncontrados.push(id);
                        this.model.componentesEncontrados.push(option);
                        mantieneElemento=false;
                    }
                    return mantieneElemento;
                });

                 if(id.trim() && !idEsEncontrado && idsNoEncontrados.indexOf(id) ==-1){
                    this.model.idsNoEncontrados.push(id);
                    idsNoEncontrados.push(id);
                }
            });
                idsNoEncontrados.forEach(id=>{

                    let compNotFound :BusquedaModels.Componente={
                        seleccionado:false,
                        componenteId:id,
                        tipoComponente:{nombre:'_noencontrado',tipoComponenteId:"0"},
                        modelo: '',
                        ubicacion: '',
                        clienteId:'',
                        lubricante:{
                            nombre:'',
                            lubricanteId:'0',
                            code: '',
                            tipo: undefined
                        },
                        marca:'',
                        planAnalisis:{nombre:'',planAnalisisId:0},
                        solicitante:'',
                    };
                    this.model.componentesEncontrados.push(compNotFound);
                });

                 this.model.limiteSeleccionCompleto=idsEncontrados.length>=this.model.limiteSeleccion;

        }
        this.loadPaginate();
        this.filtrarSeleccion();
    }

    filtrarComponentes(): void {

        if (typeof(this.model.componenteFiltro) === 'string') {
            const filtro=this.model.componenteFiltro;
            this.model.componentesEncontrados = !this.model.componenteFiltro && this.model.componenteFiltro === '' ? [] :this.filtroSimpleRecursivo(filtro);
            if (this.model.componentesEncontrados.length === 0){
                this.model.alertaSinResultado=true;
            } else {
                this.loadPaginate();
            }
        } else {
            let comp = this.model.componentes.filter(c=>c.componenteId===this.model.componenteFiltro.componenteId)[0];
            this.selectedRow(comp);
        }

    }


    check(isChecked:boolean){
        this.filtrarSeleccion();
        if(!isChecked){
            this.model.checkAll=false;
        }
    }
    filtrarSeleccion()
    {  this.model.componentesSeleccionados=this.elementosSeleccionados();
        this.model.limiteSeleccionCompleto=this.model.componentesSeleccionados.length>=this.model.limiteSeleccion;
    }

    ingresarMuestrasParaComponentes(){
        let data = JSON.stringify(this.model.componentesSeleccionados);
        if(this.model.componentesSeleccionados.length===1){
            this.selectedRow(this.model.componentesSeleccionados[0])
        }else{
            this.router.navigate(['/agregar-muestras'], { queryParams: { data:data } ,skipLocationChange: true} );
        }
    }

    checkTodos(event:any){
        if(event.target.checked){
            let cont:number=0;
            this.model.componentesEncontrados.forEach(e=>{
                if(cont < this.model.limiteSeleccion && e.tipoComponente.nombre !== '_noencontrado'){
                    e.seleccionado=true;
                    cont++;
                }else{
                    e.seleccionado=false;
                }
            });

        }else{
            this.model.componentesEncontrados.forEach(e=>{
                e.seleccionado=false;
            });
        }
        this.filtrarSeleccion();
    }

    popoverCambiarIdDeFila(idComponenteActual:string,idComponenteBuscado:string){
        let posicion=-1;
        let componenteEncontrado:BusquedaModels.Componente[]=[];
        let noExiste=this.model.componentesEncontrados.filter(e=>idComponenteBuscado.toLowerCase()===e.componenteId.toLowerCase()).length===0;

            this.model.componentesEncontrados.forEach((elementoActual, index)=>{
                if(elementoActual.componenteId===idComponenteActual){
                    componenteEncontrado = this.model.componentes.filter(c=>{
                        return c.componenteId.toLowerCase()===idComponenteBuscado.toLowerCase();
                    });
                    if(componenteEncontrado.length>0){
                        posicion = index;
                    }else{
                        elementoActual.componenteId=idComponenteBuscado;
                    }
                }
            });
            if(posicion>-1)
            {
                if(noExiste){
                    componenteEncontrado[0].seleccionado= !this.model.limiteSeleccionCompleto;
                    this.model.componentesEncontrados[posicion]=componenteEncontrado[0];
                } else{
                    this.model.componentesEncontrados.splice(posicion,1);
                }
                this.loadPaginate();
            }
            this.filtrarSeleccion();
    }

    /**
     * @returns
     */
    elementosSeleccionados():BusquedaModels.Componente[]{
      return  this.model.componentesEncontrados.filter(e=>{
            return e.seleccionado;
        });
    }

    /**
     * @param filterValue
     * @returns
     */
    private filtroSimpleRecursivo(filterValue: string):BusquedaModels.Componente[]{
        let compFiltrados: BusquedaModels.Componente[]=[];
        const filtros = filterValue.split(" ");
        filtros.forEach((filtro,index) => {
            filtro=filtro.toLowerCase();
            if (index === 0 || (compFiltrados.length === 0 && index < filtros.length-1  )) {
                compFiltrados = this.model.componentes;
            }

            compFiltrados = compFiltrados.filter(option =>
                option.equipo?.tags?.find(e=>e.toLowerCase().includes(filtro)) ||
                (option.equipo?.equipoId && option.equipo?.equipoId?.toLowerCase().includes(filtro)) ||
                option.equipo?.tipoEquipo?.nombre?.toLowerCase().includes(filtro) ||
                option.equipo?.alias?.find(e=>e.toLowerCase().includes(filtro)) ||
                option.equipo?.marca?.toLowerCase().includes(filtro) ||
                option.equipo?.modelo?.toLowerCase().includes(filtro) ||
                option.equipo?.descriptor?.toLowerCase().includes(filtro) ||
                option.componenteId.toLowerCase().includes(filtro) ||
                option.tipoComponente?.nombre?.toLowerCase().includes(filtro) ||
                option.ubicacion?.toLowerCase().includes(filtro)  ||
                option.marca?.toLowerCase().includes(filtro) ||
                option.modelo?.toLowerCase().includes(filtro) ||
                option.lubricante?.nombre?.toLowerCase().includes(filtro)
            );
        });
        return compFiltrados;
    }

    private filtroSimple(filterValue: string):BusquedaModels.Componente[]
    {
        filterValue=filterValue.toLowerCase();
        return  this.model.componentes.filter(option => option.componenteId.toLowerCase().includes(filterValue) ||
                option.tipoComponente.nombre.toLowerCase().includes(filterValue) ||
                option.equipo?.tipoEquipo.nombre.toLowerCase().includes(filterValue) ||
                option.equipo?.marca?.toLowerCase().includes(filterValue) ||
                option.equipo?.modelo?.toLowerCase().includes(filterValue)
    );

    }
    /**
     * @returns
     */
    esSeleccionMultiple():boolean{
        return this.model.tipoDeBusqueda===this.model.TIPOS_BUSQUEDA.multiple;
    }
    /**
     * @returns
     */
    esValidoBotonDeIngreso():boolean{
        return this.model.componentesEncontrados.length>0;

    }

    mostrarTablaMultiple():boolean{
        return this.model.tipoDeBusqueda===this.model.TIPOS_BUSQUEDA.multiple || this.model.tipoDeBusqueda===this.model.TIPOS_BUSQUEDA.simpleRecursiva;
    }

    mostrarTablaSimple():boolean{
        return this.model.tipoDeBusqueda===this.model.TIPOS_BUSQUEDA.simple;
    }

    handlePageEvent(event: PageEvent): void {
        this.model.pagination.totalElements = event.length;
        this.model.pagination.pageSize = event.pageSize;
        this.model.pagination.page = event.pageIndex;

        this.loadPaginate();
    }

    loadPaginate(): void {
        this.dataSource = new MatTableDataSource<BusquedaModels.Componente>(this.model.componentesEncontrados);
        this.dataSource.sortingDataAccessor = (componente: BusquedaModels.Componente, sortHeaderId: string) => {
            switch(sortHeaderId) {
                case 'seleccion': return componente.seleccionado?1:0;
                case 'componenteId': return componente.componenteId;
                case 'componenteName': return componente.tipoComponente.nombre;
                case 'marca': return componente.marca;
                case 'modelo': return componente.modelo?componente.modelo:'';
                case 'ubicacion': return componente.ubicacion?componente.ubicacion:'';
                default: return 1;
              }
        }
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    crearComponente(): void{
        const modalRef=this.modalService.open(ComponenteNuevoComponent, {
            centered:true,
            size: 'lg'
        });

        modalRef.componentInstance.compDesdeAsignarEquipo.vieneDesdeBuscarMuestra = Object.assign({}, true) ;
    }

    crearEquipo(): void{
        const modalRef=this.modalService.open(EquipoNuevoComponent, {
            centered:true,
            size: 'lg'
        });
        modalRef.componentInstance.equipoInput.vieneDesdeMuestra = Object.assign({}, true) ;
    }

    esValidoLinkUnicaMuestra(component:BusquedaModels.Componente){
        let seleccionados = this.elementosSeleccionados();
        let isOk:boolean;
        switch (seleccionados.length) {
            case 0:
                isOk=true;
                break;
            case 1:
                isOk = seleccionados[0].componenteId === component.componenteId;
            break;
            default:
                isOk=false;
                break;
        }
        return isOk;

    }
}
