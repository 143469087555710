import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class Tools {

    toHttpParams(obj: any): HttpParams {        
        return Object.getOwnPropertyNames(obj)
            .reduce((p, key) => p.set(key, obj[key]), new HttpParams());
    }

    getBase64(file: Blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    public descargarArchivo = (nombre: string, archivo: string): void => {
        if (nombre.indexOf('.') === -1) {
            return;
        }

        var extension = nombre.split('.')[1];       
        this.descargarOtros(nombre, archivo, extension);    
    }

    private descargarOtros = (nombre: string, archivo: string, extension: string): void => {
        if (window.navigator.msSaveOrOpenBlob ?? false) {
            var blob = this.b64toBlob(archivo, "data:application/" + extension);
            navigator.msSaveBlob(blob, nombre);
        } else {
            var a = document.createElement('a');
            a.style.cssText = 'display:none;';

            document.body.appendChild(a);
            a["download"] = nombre;
            a.href = 'data:application/' + extension + ';base64,' + archivo;
            a.click();
            document.body.removeChild(a);
        }
    }

    private b64toBlob = (b64Data: string, contentType: string): any => {
        contentType = contentType || '';
        var sliceSize = 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
