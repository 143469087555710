import { Component, OnInit, Inject, ViewEncapsulation  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { LoginViewModel } from '../login-models/login-view-model';

import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Pkce } from 'src/app/shared/tools/pkce.tools';
import { StorageTools } from 'src/app/shared/tools/storage.tools';
import { AuthService } from 'src/app/shared/auth/auth.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    encapsulation: ViewEncapsulation.None,
    host: {'class': 'app-login'}
})
export class LoginComponent implements OnInit {
    exist_user_platform = false;
    faena_user_locked = false;
    faena_user_not_found = false;
    user_error = false;

    constructor(
        private authService: AuthenticationService,
        @Inject(DOCUMENT) private document: Document,
        private pkce: Pkce,
        private router: Router,
        private storage: StorageTools,
        private route: ActivatedRoute
    ) {
        const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl')!;        
        if (returnUrl){            
            localStorage.setItem("returnUrl", returnUrl);
        }

        if (this.authService.currentUserValue && this.authService.currentUserValue.clientes) {
            this.router.navigate(['/home']);
        } else {
            this.faena_user_locked = this.storage.getItem("FAENA_USER_LOCKED") ?? false;
            this.faena_user_not_found = this.storage.getItem("FAENA_USER_NOT_FOUND") ?? false;
            this.exist_user_platform = this.storage.getItem("USER_NOT_FOUND") ?? false;
            this.user_error = this.storage.getItem("USER_ERROR") ?? false;
        }
    }

    ngOnInit(): void {

    }

    logout(): void {

    }

    goToAuth(): void {
        window.location.reload();
    }

}
