<div class="modal__contanier" [class]="{'bloqueado': is_request_call }">

  <div class="modal__header">
      <div class="d-flex flex-column">
        <h2 class="modal__title mb-0 text-nowrap" id="modal-title">Mover a</h2>

      </div>
    <a href="javascript:void(0);" [mat-dialog-close]="false">
      <em class="bi bi-x-lg"></em>
    </a>
  </div>
  <div class=" modal__body">
    <p id="selecciona-cliente-faena">Selecciona el cliente y faena a la cual moverás los componentes seleccionados</p>
    <div class="d-flex flex-column gap-3">
    <div class="form-group">
       <label for="cliente" class="form-label">Cliente</label>
       <ng-select class="ng-select" [appendTo]="'body'" [clearable]="false" name="client" placeholder="Selecciona Cliente" [(ngModel)]="clienteSeleccionado" (change)="faenaSeleccionada=undefined">
        <ng-option *ngFor="let df of clientes" [value]="df">{{ df.razonSocial }}</ng-option>
      </ng-select>
      </div>
      <div class="form-group">
        <label for="faena" class="form-label">Faenas</label>
        <ng-select class="ng-select" [appendTo]="'body'" [clearable]="false" [disabled]="!clienteSeleccionado" name="client" placeholder="Selecciona faena" [(ngModel)]="faenaSeleccionada">
          <ng-option *ngFor="let df of clienteSeleccionado?.faenas||[]" [value]="df">{{df.faenaId}} - {{ df.nombre }}</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center justify-content-md-between flex-md-row flex-nowrap gap-3 pt-3">
    <button id="confirmar-button-cancelar" [mat-dialog-close]="false" type="button" class="btn btn-secondary">Cancelar</button>
    <button id="confirmar-button-confirmar" type="button" class="btn btn-primary" [disabled]="!clienteSeleccionado || !faenaSeleccionada || is_request_call" (click)="confirmar()">
      Confirmar   <mat-spinner  matSuffix [diameter]="18" class="center" *ngIf="is_request_call" ></mat-spinner></button>
  </div>

  </div>
