import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { ChartType, GoogleChartComponent, Row } from 'angular-google-charts';
import { ReportsModels } from '../../../models/reports.models';

@Component({
  selector: 'viscosity-chart',
  templateUrl: './viscosity.component.html',
  styleUrls: ['./viscosity.component.css']
})
export class ViscosityChartComponent implements OnInit {
    chart:any;
    imgSrc:string='';
    isLoading:boolean=true;

    @Input() data:Row[]=[
        ['1', 15.0, 16.40],
        ['3', 14.5, 15.80]
     ];
    @Output() event = new EventEmitter<boolean>();

    @Input() isPrinting:boolean=false;

  viscChart: ReportsModels.GoogleChart = {
    title: 'Viscosidad',
    type: ChartType.LineChart,
    data: this.data,
    columnNames: ['tiempo', '40 C', '100 C'],
    options: {

      series: {
        0: {
          labelInLegend: '40 C'
        },
        1: {
          labelInLegend: '100 C',
        }
      },
      pointSize: 5,
      hAxis: {
        gridlines: {
          count: 5
        },
        scaleType: 'string'

      },
      vAxis: {
        gridlines: {
          count: 7
        },
        title: ''
      },
      legend: { position: 'bottom',textStyle: {fontSize: 5}},
      chartArea: {
        left: '20%', width: '70%', height: '50%',right :'10%'
      },
    },
    width : 150,
    height : 150,
  };
  constructor() { }

  ngOnInit(): void {


  }
  ngDoCheck(changes: SimpleChanges){
    this.viscChart.data=this.data;
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['isPrinting'] && this.chart){
      this.chart.setSelection([])
    }
  }
  onReady(e:any){
    this.chart= e.chart;
    this.isLoading=false;
    this.imgSrc=e.chart.getImageURI();
  }
  onReadyImage(){
    this.event.emit(true);
  }
}
