import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, timer, interval } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

import { Objects } from '../models/objects';
import { LoginModel } from '../../login/login-models/login';
import { StorageTools } from '../tools/storage.tools';
import { promise } from 'protractor';
import { Tools } from '../tools/objects.tools';
import * as moment from 'moment';
import { Enums } from '../models/constants';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<Objects.UserProfile>;
    public currentUser: Observable<Objects.UserProfile>;

    private currentClientSubject = new BehaviorSubject<Objects.CurrentClient>({
        clienteId: '',
        numeroSap: '',
        razonSocial: '',
        faena: {
            faenaId: '',
            nombre: '',
            numeroSap: '',
            direccion: '',
            rubro: '',
            transporte: '',
            dashboard_enabled: false,
            dashboard_url: ''
        }
    });
    public currentClient = this.currentClientSubject.asObservable();

    constructor(
        private http: HttpClient,
        private storage: StorageTools,
        private tools: Tools,
        private oauthService: OAuthService) {

        if (this.storage.containsKey('currentUser')) {
            this.currentUserSubject = new BehaviorSubject<Objects.UserProfile>(JSON.parse(storage.getItem('currentUser') ?? '{}'));
            this.currentUser = this.currentUserSubject.asObservable();
        } else {
            this.currentUserSubject = new BehaviorSubject<Objects.UserProfile>(storage.getItem('currentUser') ?? null);
            this.currentUser = this.currentUserSubject.asObservable();
        }

        const storedClient = this.storage.getItem('currentClient');
        if (storedClient) {
            this.currentClientSubject.next(JSON.parse(storedClient));
        }
    }

    public set currentPkce(value: string) {
        this.storage.setItem('pkce', value);
    }

    public get currentPkce(): string {
        return this.storage.getItem('pkce') ?? '';
    }

    public get currentUserValue(): Objects.UserProfile {
        return this.currentUserSubject.value;
    }

    public set currentUserValue(value: Objects.UserProfile) {
        this.storage.setItem('currentUser', JSON.stringify(value));
    }

    public get currentClientValue(): Objects.CurrentClient {
        return this.currentClientSubject.value;
    }

    public set currentClientValue(client: Objects.CurrentClient) {
        this.storage.setItem('currentClient', client);
        this.currentClientSubject.next(client);
    }

    public get usuario(): Objects.UserProfile {
        return this.currentUserValue;
    }

    public get current_user(): Objects.CurrentUser {
        return {
            rut: this.currentUserValue.rut,
            phone: this.currentUserValue.phone,
            apellido: this.currentUserValue.apellido,
            nombre: this.currentUserValue.nombre,
            userId: this.currentUserValue.userId,
            email: this.currentUserValue.email
        }
    }

    private async getClient(userId: string, token: string): Promise<Objects.Response<Objects.Cliente>> {

        const httpOptions = {
            headers: new HttpHeaders({ Authorization: 'Bearer ' + token }),
            params: this.tools.toHttpParams({ userId: userId })
        };

        return await this.http.get<Objects.Response<Objects.Cliente>>(`${environment.apiUrl}${environment.routes.getCliente}`, httpOptions).toPromise();
    }

    public async set_user_info(user_info: any) {
        await this.getClient(user_info.sub, this.oauthService.getAccessToken())
            .then((response) => {
                if (response.code === 200) {
                    user_info = { ...user_info, ...response.data };
                } else {
                    let message_error: string = '';
                    if (response.message.includes("FAENA_USER_LOCKED")) {
                        message_error = "FAENA_USER_LOCKED"
                    } else if (response.message.includes("FAENA_USER_NOT_FOUND")) {
                        message_error = "FAENA_USER_NOT_FOUND"
                    } else if (response.message.includes("USER_NOT_FOUND")) {
                        message_error = "USER_NOT_FOUND"
                    } else {
                        message_error = "USER_ERROR"
                    }

                    throw new Error(message_error);
                }
            })
            .catch((error) => {
                console.log("ERROR", error.message)
                this.logout_exist_fusion_auth(error.message);
            });

        this.storage.setItem('currentUser', user_info);
        this.currentUserSubject.next(user_info);
    }

    public async reloadClient(userId: string) {
        let current_user = this.currentUserValue;
        await this.getClient(userId, current_user.access_token)
            .then((response) => {
                if (response.code === 200) {
                    current_user = { ...current_user, ...response.data };
                }
            });

        this.storage.setItem('currentUser', current_user);
        this.currentUserSubject.next(current_user);
    }

    private get_error_msg(key: string): string {
        let response = "";
        switch(key) {
            case "FAENA_USER_LOCKED": 
                response = "Tu usuario está asociado a una faena que no tiene acceso a esta plataforma.";
                break;
            case "FAENA_USER_NOT_FOUND": 
                response = "Tu usuario está asociado a una faena con problemas, contactenos a laboratoriolubricantes@copec.cl.";
                break;
            case "USER_NOT_FOUND": 
                response = "Tu usuario no tiene acceso a esta plataforma.";
                break;
            case "USER_ERROR": 
                response = "Tu usuario tiene problemas en el acceso a esta plataforma, contactenos a laboratoriolubricantes@copec.cl.";
                break;
            default:
                response = "Tu usuario tiene problemas en el acceso a esta plataforma, contactenos a laboratoriolubricantes@copec.cl.";
                break;
        }
        return response;
    }

    logout(): void {
        this.storage.clear();
        this.currentUserSubject.unsubscribe();
        this.currentClientSubject.unsubscribe();
        const uri = `${environment.fusionAuth.uri}/oauth2/logout?client_id=${environment.fusionAuth.clientID}`;
        window.location.href = uri;
    }

    logout_exist_fusion_auth(option: string): void {
        console.log("logout_exist_fusion_auth", option);

        alert(this.get_error_msg(option));

        this.storage.clear();
        this.currentUserSubject.unsubscribe();
        this.currentClientSubject.unsubscribe();
        // this.storage.setItem(option, true);
        const uri = `${environment.fusionAuth.uri}/oauth2/logout?client_id=${environment.fusionAuth.clientID}`;
        window.location.href = uri;
    }

}
