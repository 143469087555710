<div class="modal__contanier d-flex justify-content-between flex-column h-100">
  <div class="modal__header align-items-center is-sticky">
    <h2 class="d-flex align-items-center modal__title">
      <img src="assets/images/icono-muestra.svg" alt="">
      <b>Muestra</b>
    </h2>
    <a mat-dialog-close class="text-black-50 btn btn-link text-decoration-none" href="#" [routerLink]="" (click)="close()">
      <em class="bi bi-x-lg"></em>
  </a>
  </div>
  <div class="modal__content">
    <div class="row">
    <div class="d-flex col-12 row px-5 col-md-6">
      <dl class="description col-12 p-0 mb-1">
        <dd class="description__definition text-lg text-dark ">{{data.muestra.muestraId}}</dd>
        <dt class="description__term text-primary txt--gray m-0">Código etiqueta</dt>
      </dl>
      <dl class="description col-12 p-0 mb-1">
        <dd class="description__definition text-lg text-dark ">{{data.muestra.correlativo}}</dd>
        <dt class="description__term text-primary txt--gray m-0">Nº laboratorio</dt>
      </dl>
    </div>
    <div class="d-flex col-12 px-5 row col-md-6 mb-1">
          <dl class="description col-12 p-0 mt-3 d-flex justify-content-center align-content-end  ">
            <dd class="description__definition text-md text-black-50 ">{{data.muestra.cliente.nombre}}</dd>
            <dt class="description__term text-primary txt--gray m-0 d-block">{{data.muestra.cliente.faena.nombre}}</dt>
          </dl>
          <dl class="description col-12 p-0 mb-1">
            <dd class="description__definition text-lg text-dark ">{{data.muestra.solicitudAnalisis}}</dd>
            <dt class="description__term text-primary txt--gray m-0">N° solicitud</dt>
          </dl>
    </div>
  </div>
    <mat-accordion class="bg-darkblue " [hidden]="!visibleCollpase">
      <mat-expansion-panel hideToggle class="mat-elevation-z0 mx-3 mt-2" (opened)="stepOpenState = true" (closed)="stepOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex justify-content-between align-items-center">
            <dl class="description">
              <dd class="description__definition txt--normal text-white">
                {{model.fechaActual.fecha | date: 'dd-MM-yyyy, H:mm'}}
              </dd>
              <dt class="description__term text-primary txt--gray">{{model.enumFechas[model.fechaActual.tipo]}}</dt>
            </dl>
            <div>
              <em *ngIf="!stepOpenState" class="bi bi-plus-square-fill"></em>
              <em *ngIf="stepOpenState" class="bi bi-dash-square-fill"></em>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex justify-content-between flex-column gap-4 mt-4 mb-4">
          <dl class="description">
            <dd class="description__definition txt--normal text-white">{{model.registroFrasco.recibido ? (model.registroFrasco.recibido | date: 'dd-MM-yyyy, H:mm') : 'No asignado' }}</dd>
            <dt class="description__term text-primary txt--gray">Frasco recepcionado</dt>
          </dl>
          <dl class="description">
            <dd class="description__definition txt--normal text-white">{{this.model.registroFrasco.ingresadoLote ? (this.model.registroFrasco.ingresadoLote | date: 'dd-MM-yyyy, H:mm') : '---' }}</dd>
            <dt class="description__term text-primary txt--gray">Frasco ingresado al lote</dt>
          </dl>
          <dl class="description">
            <dd class="description__definition txt--normal text-white">{{model.registroFrasco.enviadoLaboratorio ? (model.registroFrasco.enviadoLaboratorio | date: 'dd-MM-yyyy, H:mm') : '---' }}</dd>
            <dt class="description__term text-primary txt--gray">Enviada al laboratorio</dt>
          </dl>
          <dl class="description">
            <dd class="description__definition txt--normal text-white">{{model.registroFrasco.analisisFinalizado ? (model.registroFrasco.analisisFinalizado | date: 'dd-MM-yyyy, H:mm') : '---' }}</dd>
            <dt class="description__term text-primary txt--gray">Análisis finalizado</dt>
          </dl>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="plan pb-0" [hidden]="!visibleCollpase">
      <div class="plan__graph">
        <div class="ring-chart">
          <svg viewBox="0 0 36 36" class="circular-chart">
            <path class="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
            <path class="circle" [attr.stroke-dasharray]="percent+', 100'" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
            <g class="circle-chart__info">
              <text class="circle-chart__percent" x="18" y="23" alignment-baseline="central" text-anchor="middle" font-size="16">
                {{getFilteredEnsaysLength()}}
            </text>
                        </g>
          </svg>
        </div>
      </div>
      <div class="plan__header">
        <span class="text-dark text-uppercase txt--md">{{data.muestra.planAnalisis?.nombre}}</span>
        <span class=" txt--gray">Muestras pendientes</span>
      </div>
    </div>
  </div>
  <div class="modal__foot justify-content-end gap-4 p-4 pt-4" >
    <div>
      <button class="btn btn-small btn-primary" *ngIf="data.muestra.estado!='reported' && data.muestra.estado!='in_analysis'" (click)="edit()">EDITAR MUESTRA</button>

    </div>
  </div>
</div>
