<div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3">
      <div class="col-12">
        <h3>Mi perfil</h3>

      </div>
      <div class="row">

        <div class="offset-md-3 col-md-6">
          <div class="card text-dark mb-3 border">
            <div class="card-body">
              <div class="row">
                <div class="col col-lg-3">
                  <p class="card-text mb-2">Nombre   </p>
                  <p class="card-text mb-2">Apellido   </p>
                  <p class="card-text mb-2">Teléfono   </p>
                  <p class="card-text mb-2">Email  </p>

                </div>
                <div class="col col-lg-6 ">
                  <p class="card-text mb-2"><b>{{model.usuario.nombre | titlecase}}&nbsp;</b>   </p>
                  <p class="card-text mb-2"><b>{{model.usuario.apellido | titlecase}}&nbsp;</b> </p>
                  <p class="card-text mb-2"><b>{{model.usuario.phone}}&nbsp;</b>  </p>
                  <p class="card-text mb-2"><b>{{model.usuario.email}}&nbsp;</b></p>
                </div>

                <div class="col col-lg-3 text-end">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="gray" class="bi bi-person-badge-fill" viewBox="0 0 16 16">
                      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z"></path>
                    </svg>
                </div>
                <div class="col-12 text-end mt-1">
                  <a [routerLink]="" (click)="editarUsuario()"><i class="bi bi-pencil"></i>
                    Editar</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="offset-md-3 col-md-6 ">
          <div *ngFor="let cliente of model.usuario.clientes" class="card text-dark mb-3 border">
            <div class="card-body">
              <div class="row">
                <div class="col-4 col-lg-3 ">
                  <p class="card-text mb-2"> Empresa </p>
                  <p class="card-text mb-2">Faena</p>

                </div>
                <div class="col-6 col-lg-6 ">
                  <p class="card-text mb-1"><b>{{cliente.razonSocial}}&nbsp;</b></p>
                  <p *ngFor="let faena of cliente.faenas" class="card-text mb-1">
                    <b>{{faena.numeroSap}} - {{faena.nombre}}&nbsp;</b>
                  </p>
                </div>
                <div class="col-2 col-lg-3 text-end ">
                  <p class="card-text mb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="gray" class="bi bi-building" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"></path>
                      <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"></path>
                    </svg>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
</div>
