<div class="p-1 mt-3">
    <div class="content__actions d-flex justify-content-between align-items-center mb-3">
        <div class="searchbox">
            <div class="searchbox--toggle">
                <em class="bi bi-person-badge-fill" matTooltip="Buscar por Nº frasco" [ngClass]="{'active': !typeSearch}" (click)="typeSearch = !typeSearch"></em>
                <span>/</span>
                <em class="bi bi-file-earmark-break-fill" matTooltip="Buscar por términos" [ngClass]="{'active': typeSearch}" (click)="typeSearch = !typeSearch"></em>
            </div>
            <input type="text" *ngIf="!typeSearch" placeholder="Buscar por Nº frasco" blockSpecialCharacters [(ngModel)]="filterSampleId">
            <input type="text" *ngIf="typeSearch" placeholder="Buscar por términos" [formControl]="myControl" blockSpecialCharacters #inputSearch (input)="keyupInputSearch(inputSearch.value)">
            <button class="searchbox--button" *ngIf="!typeSearch" (click)="buscarPorId()">
                <em class="bi bi-search"></em>
            </button>
        </div>
        <div class="form__field">
            <label class="label mb-1 d-block">Fecha toma de muestra</label>
            <div class="form__range">
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Inicio" [(ngModel)]="range.start">
                    <input matEndDate placeholder="Término" [(ngModel)]="range.end">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                    <mat-date-range-picker-actions>
                        <button mat-button matDateRangePickerCancel class="btn-action-calendar cancel" (click)="picker.close()"><em class="bi bi-x-lg"></em> Cancelar</button>
                        <button matDateRangePickerApply class="btn-action-calendar" (click)="getMuestras()"><em class="bi bi-check-lg"></em> Aplicar</button>
                    </mat-date-range-picker-actions>
                </mat-date-range-picker>
            </div>
            <span *ngIf="!calculo()" class="text-danger">
                El rango debe ser inferior a 31 días
            </span>
        </div>
    </div>
    <div class="content__actions d-flex justify-content-between align-items-center">
        <div class="col d-flex justify-content-start">
            <div class="form__field">                
                <ng-select class="ng-select ng-select-full-options" (change)="filtrarEstadoMuestra()" [(ngModel)]="model.estado_muestras" [disabled]="model.isLoading" [clearable]="true" name="estado" style="width: 160px;" [placeholder]="'Todas'">
                    <ng-option *ngFor="let estado of model.estados_muestras" select [value]="estado">{{estado}}</ng-option>
                </ng-select>
            </div>
            <div class="visualized-filter">
                <mat-radio-group name="visualized-filter" required [(ngModel)]="visualizedFilter" (change)="filterVisualizedSamples()" class="" [disabled]="model.isLoading || model.muestras.length===0">
                    <mat-radio-button color="primary" value="all" [checked]="true"><i class="bi bi-envelope"></i> Todas</mat-radio-button>
                    <mat-radio-button color="primary" value="visualized"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-check" viewBox="0 0 16 16">
                            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686" />
                        </svg> Visualizadas</mat-radio-button>
                    <mat-radio-button color="primary" value="not_visualized">
                        <div class="not_visualized__label">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-exclamation" viewBox="0 0 16 16">
                                <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1.5a.5.5 0 0 1-1 0V11a.5.5 0 0 1 1 0m0 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                            </svg>
                            <span class="not_visualized__label__text">No visualizadas</span>
                            <span class="not_visualized__label__circle" *ngIf="hasNotVisualized">•</span>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>            
            </div>        
        </div>        
        <div class="col d-flex justify-content-end align-items-center ">
            <div class="alert alert--info">
                <figure class="alert__figure d-flex align-items-center justify-content-center">
                    <em class="bi bi-info-circle-fill ico--small"></em>
                </figure>
                <div class="alert__message white-wrap">El máximo de descargas masiva de reportes es de {{model.limite_seleccion}} muestras.</div>
            </div>
            
            <button class="btn btn-primary btn-lg" (click)="descargarSeleccionados()" [disabled]="model.muestras_seleccionadas.length===0 || model.isDownloading">
                <span *ngIf="!model.isDownloading">
                    Descargar <span class="badge rounded-pill bg-light text-dark">{{model.muestras_seleccionadas.length}}</span>
                </span>
                <span *ngIf="model.isDownloading">
                    Descargando <mat-spinner matSuffix [diameter]="18" style="margin-left: 8px; margin-right: 8px; display: inherit;"></mat-spinner>
                </span>
            </button>
        </div>        
    </div>
</div>
<div class="card">
    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2 w-100">

            <ng-container matColumnDef="seleccion">
                <th mat-header-cell *matHeaderCellDef> <input type="checkbox" (change)="checkTodos($event)" [(ngModel)]="model.checkAll" [checked]="model.checkAll" [disabled]="model.muestras_encontradas.length === 0" /> </th>
                <td mat-cell *matCellDef="let element">
                    <input type="checkbox" value="true" *ngIf="element.estado === 'reported'" [checked]="element.seleccionado" (change)="element.seleccionado = !element.seleccionado;check(element.seleccionado)" [disabled]="(!element.seleccionado && model.limiteSeleccionCompleto)">
                </td>
            </ng-container>

            <ng-container matColumnDef="frascos">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº Frasco</th>
                <td mat-cell *matCellDef="let element"> {{element.muestraId!}} </td>
            </ng-container>

            <ng-container matColumnDef="lab">
                <th mat-header-cell *matHeaderCellDef>Nº Laboratorio</th>
                <td mat-cell *matCellDef="let element"> {{element.correlativo!}} </td>
            </ng-container>

            <ng-container matColumnDef="solucitud">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>N° Solicitud</th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.solicitudAnalisis}} </td>
            </ng-container>

            <ng-container matColumnDef="lubricante">
                <th mat-header-cell *matHeaderCellDef>Lubricante</th>
                <td mat-cell *matCellDef="let element">
                    <span class="d-block">{{element.lubricante?.nombre}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="componente">
                <th mat-header-cell *matHeaderCellDef>Componente</th>
                <td mat-cell *matCellDef="let element">
                    <span class="d-block">{{element.componente.componenteId}}</span>
                    <small>{{element.componente.tipoComponente.nombre}}</small>
                </td>
            </ng-container>

            <ng-container matColumnDef="equipo">
                <th mat-header-cell *matHeaderCellDef>Equipo</th>
                <td mat-cell *matCellDef="let element">
                    <span class="d-block">{{element.componente.equipo?.equipoId}}</span>
                    <small>{{element.componente.equipo?.tipoEquipo?.nombre}}</small>
                </td>
            </ng-container>

            <ng-container matColumnDef="toma">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Toma de muestra</th>
                <td mat-cell *matCellDef="let element"> {{element.fechaMuestreo | date: 'dd-MM-yyyy'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="envio">
                <th mat-header-cell *matHeaderCellDef>Envío a laboratorio</th>
                <td mat-cell *matCellDef="let element"> {{element.ingresadoLote|date:'dd-MM-yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="finalizado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Finalizado</th>
                <td mat-cell *matCellDef="let element"> {{element.analisisFinalizado|date:'dd-MM-yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="resultado">
                <th mat-header-cell *matHeaderCellDef>Resultado</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.estado != 'reported'" class="d-flex align-items-center gap-1">
                        <span></span>
                    </div>
                    <div *ngIf="element.estado == 'reported'" class="d-flex align-items-center gap-1">
                        <span class="indicator" [ngClass]="{
                    'process': element?.statesample?.key == 'analisis',
                    'normal': element?.statesample?.key == 'normal',
                    'danger': element?.statesample?.key == 'alerta',
                    'warning': element?.statesample?.key == 'precaucion'
                }"></span>
                        <span class="indicator-text">{{element.statesample.label}}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <ng-template #popContentDetail>
                        <div class="mb-2" *ngIf="element.estado === 'reported'">
                            <a (click)="getPDF(element)" class="d-flex aling-items-center gap-2">
                                <i class="bi bi-arrow-down-square"></i>
                                <span>Descargar informe</span>
                            </a>
                        </div>
                        <div>
                            <a *ngIf="false" href="javascript:void(0);" (click)="openGallery(element)" class="d-flex aling-items-center gap-2">
                                <i class="bi bi-image"></i>
                                <span>Imágenes ensayos ({{element.imagenes?.length}})</span>
                            </a>
                        </div>
                    </ng-template>
                    <span class="text-dark" placement="start" [ngbPopover]="popContentDetail" [autoClose]="true" #pDetail="ngbPopover" *ngIf="element.estado === 'reported'">
                        <i class="bi bi-three-dots-vertical"></i>
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'unreaded': row.estado === 'reported' && !row.visualizadoWebcliente}"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="11">
                    <span *ngIf="model.isLoading">Cargando muestras<mat-spinner matSuffix [diameter]="18" style="margin-left: 8px;display: inherit;"></mat-spinner></span>
                    <span *ngIf="!model.isLoading">No se han encontrado elementos...</span>
                </td>
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" (page)="handlePageEvent($event)" [length]="model.pagination.totalElements" [pageSize]="model.pagination.pageSize" [pageIndex]="model.pagination.page" showFirstLastButtons></mat-paginator>
    </div>
</div>
<br>
<p class="text-muted" *ngIf="!model.isLoading">
    <a type="button" class="link-secondary" (click)="getMuestras()" [routerLink]="" data-toggle="tooltip" data-placement="top" title="{{model.requestDate| date :'HH:mm:ss'}}">
        <i class="bi bi-arrow-clockwise"></i>
        <span *ngIf="model.requestDate !==null">
            Actualizado {{model.requestDate | amLocale:'es' | amTimeAgo}}
        </span>
    </a>
</p>