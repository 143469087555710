<div class="modal__contanier">
  <div class="modal__header">
    <h2><b>¿Estás seguro?</b></h2>
  </div>
  <div class="modal__content justify-content-start d-flex">
    <p>Estos cambios pueden alterar la muestra N° {{data.muestraId}}<br class="d-none d-lg-block"/> del cliente {{data.cliente}} de la faena {{data.faena}}.</p>
  </div>
  <div class="modal__foot pt-4 justify-content-between align-items-center flex-row gap-3">
    <a class="btn btn-icon btn-small btn-secondary order-1 order-md-0" href="javascript:void(0)" [mat-dialog-close]="false">CANCELAR</a>
    <a class="btn btn-icon btn-small btn-primary order-0 order-md-1" href="javascript:void(0)" (click)="confirmUpdateSample();">CONFIRMAR</a>
  </div>
</div>
