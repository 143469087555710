import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './shared/auth/authentication.service';
import { SpinnerService } from './shared/services/spinner.service';
import {NavigationEnd, Router} from '@angular/router'
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';
import { AuthService } from './shared/auth/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnDestroy  {
  title = 'npl';
  diplayLoading = false;
  loadingSubscription!: Subscription;
  routeReport: boolean = false;

  constructor(
    public authService: AuthenticationService,
    public spinner: SpinnerService,
    private _changeDetectorRef: ChangeDetectorRef,
    private router : Router,
    private authService_oid: AuthService
  ) {
    if (environment.datadog.enabled) {
      datadogRum.init({
        applicationId: environment.datadog.app,
        clientToken: environment.datadog.token,
        service: environment.datadog.service,
        env: environment.datadog.env,
        site: 'datadoghq.com',
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.startSessionReplayRecording();
    }
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {        
        if((e.url).includes('reports2')){
          this.routeReport = true;
        }else{
          this.routeReport = false;
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.loadingSubscription = this.spinner.loading$.pipe().subscribe(
      (status: boolean) => {        
        this.diplayLoading = status;
        this._changeDetectorRef.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
