import { HttpClient, HttpContext, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Tools } from "src/app/shared";
import { Enums } from "src/app/shared/models/constants";
import { environment } from "src/environments/environment";
import { EquipoVerModels } from "../equipo-ver-models/equipo-ver.models";

@Injectable({
    providedIn: 'root'
})
export class EquipoVerService {

    constructor(
        private http: HttpClient,
        private tools: Tools
    ){}

    GetEquipoInfo(request: EquipoVerModels.GetEquipoVerRequest): Promise<EquipoVerModels.GetEquipoVerResponse> {
        return this.http.get<EquipoVerModels.GetEquipoVerResponse>(`${environment.apiUrl}${environment.routes.equipo}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise(); 
    }

    DeleteEquipo(request:EquipoVerModels.DeleteEquipoRequest): Observable<EquipoVerModels.DeleteEquipoResponse> {
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            }),
            body: {
                clienteId: request.clienteId,
                faenaId: request.faenaId,
                equipoId: request.equipoId
            }
        }
        return this.http.delete<EquipoVerModels.DeleteEquipoResponse>(`${environment.apiUrl}${environment.routes.equipo}`, options);
    }
}