import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subscription } from "rxjs";
import { EquipoVerComponent } from "src/app/mantenedores/mant-equipos/mant-equipos-views/equipo-ver/equipo-ver-views/equipo-ver.component";
import { AuthenticationService } from "src/app/shared/auth/authentication.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { MantComponentesModels } from "../../mant-componentes-models/mant-componentes.models";
import { MantComponentesViewModels } from "../../mant-componentes-models/mant-componentes.view.models";
import { MantComponentesService } from "../../mant-componentes-services/mant-componentes.service";
import { ComponenteNuevoComponent } from "../componente-nuevo/componente-nuevo-views/componente-nuevo.component";
import { ComponenteVerComponent } from "../componente-ver/componente-ver-views/componente-ver.component";
import { EventEmitterService } from '../../../../shared/services/event-emitter.service';
import { ToastService } from "src/app/shared/components/ng-toast/toast-service";
import { MoverComponentComponent } from "../mover-component/mover-component.component";
import { MatDialog } from "@angular/material/dialog";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
    selector: 'app-mant-componentes',
    templateUrl: './mant-componentes.component.html',
    styleUrls: ['./mant-componentes.component.css']
})
export class MantComponentesComponent implements OnInit, OnDestroy{

    displayedColumns: string[] = ['select','componenteId', 'componente', 'equipo'];

    dataSource = new MatTableDataSource<MantComponentesModels.Componente>([]);
    selection = new SelectionModel<MantComponentesModels.Componente>(true, []);
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    myControl = new FormControl();
    filteredOptions!: Observable<MantComponentesModels.Componente[]>;
    subscribers: Subscription[] = [];

    model: MantComponentesViewModels.ViewModel = {
        pagination: { page: 0, pageSize: 10, totalElements: 0 },
        componentes: [],
        componentesEncontrados: [],
        componenteFiltro: '',
        alertaSinResultado:false,
        cargandoComponentes:false,
        forceRefresh: false,
    };

    enumStatus: { [key: string]: string } = {
        analisis: 'En análisis',
        normal: 'Normal',
        precaucion: 'Precaución',
        alerta: 'En alerta'
    };

    constructor(
        private authService: AuthenticationService,
        private service: MantComponentesService,
        private spinner: SpinnerService,
        private modalService: NgbModal,
        private eventEmiiterSrv:EventEmitterService,
        private toastService: ToastService,
        private dialog:MatDialog
    ){

    }

    ngOnInit(): void{
        setTimeout(()=>{
            this.getComponentes();
        },500);



        this.eventEmiiterSrv.event("FLOW_MANTENEDOR").subscribe(result=>{
            this.model.componenteFiltro = '';
            this.getComponentes();

            switch(result.accion){
                case 'NUEVO':
                    this.toastService.show(`El componente ${result.id} fue creado`, { classname: 'bg-success text-light', delay: 2000 });
                    break;
                case 'EDITAR':
                    this.toastService.show(`El componente ${result.id} fue actualizado`, { classname: 'bg-primary text-light', delay: 2000 });
                    break;
                case 'ELIMINAR':
                    this.toastService.show(`El componente ${result.id} fue eliminado`, { classname: 'bg-danger text-light', delay: 2000 });
                    break;
                default:
                    break;
            }
        });
    }

    ngOnDestroy() {
        this.eventEmiiterSrv.completeEvent("FLOW_MANTENEDOR");
        this.destroySubs();
    }

    private destroySubs() {
        this.subscribers.forEach(s=>{
            s.unsubscribe();
        })
    }

    recargarTabla(): void {
        this.model.componentesEncontrados=[];
        this.LoadPaginate();
        this.getComponentes();
    }

    getComponentes(): void {
        this.selection.clear();
        this.dataSource.data=[];
        this.model.componentesEncontrados=[];
        this.model.fechaCargaSolicitudes= new Date();
        this.destroySubs();
        const request: MantComponentesModels.GetComponentesRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId
        };
        this.model.cargandoComponentes = true;
        this.service.GetComponents(request).then(response => {
            if (response.success && response.code === 200) {
                this.model.componentes = response.data;
                this.model.componentesEncontrados = this.model.componentes;
                this.dataSource = new MatTableDataSource<MantComponentesModels.Componente>(this.model.componentesEncontrados);
                setTimeout(()=>{
                    this.LoadPaginate();
                },500);
                this.model.cargandoComponentes = false;
            }
        });
    }

    enterInputSearch(): void{
        this.model.pagination.page = 0;
        this.filtrarComponentes();
    }

    filtrarComponentes(): void {
        this.spinner.off(true);
        if(this.model.componenteFiltro != ''){
            if (typeof(this.model.componenteFiltro) === 'string') {
                this.model.componentesEncontrados = !this.model.componenteFiltro && this.model.componenteFiltro === '' ? [] :
                    this.model.componentes.filter(option =>
                        option.componenteId.toLowerCase().includes(this.model.componenteFiltro.toLowerCase()) ||
                        option.tipoComponente.nombre.toLowerCase().includes(this.model.componenteFiltro.toLowerCase()) ||
                        option.marca.toLowerCase().includes(this.model.componenteFiltro.toLowerCase())  ||
                        option.modelo?.toLowerCase().includes(this.model.componenteFiltro.toLowerCase()) ||
                        option.ubicacion?.toLowerCase().includes(this.model.componenteFiltro.toLowerCase()) ||
                        option.equipo?.equipoId?.toLowerCase().includes(this.model.componenteFiltro.toLowerCase()) ||
                        option.equipo?.marca?.toLowerCase().includes(this.model.componenteFiltro.toLowerCase()) ||
                        option.equipo?.modelo?.toLowerCase().includes(this.model.componenteFiltro.toLowerCase()) ||
                        option.equipo?.tipoEquipo.nombre.toLowerCase().includes(this.model.componenteFiltro.toLowerCase()) ||
                        option.equipo?.alias?.find(e=>e.toLowerCase().includes(this.model.componenteFiltro.toLowerCase())) ||
                        option.equipo?.tags?.find(e=>e.toLowerCase().includes(this.model.componenteFiltro.toLowerCase()))
                        );
                if (this.model.componentesEncontrados.length === 0){
                    this.model.alertaSinResultado=true;
                } else {
                    this.model.alertaSinResultado=false;
                    this.dataSource = new MatTableDataSource<MantComponentesModels.Componente>(this.model.componentesEncontrados);
                    this.LoadPaginate();
                }
            } else {
            }
        }else{
            this.model.componentesEncontrados = this.model.componentes;
            this.model.alertaSinResultado=false;
            this.LoadPaginate();
        }
    }

    handlePageEvent(event: PageEvent): void {
        this.model.pagination.totalElements = event.length;
        this.model.pagination.pageSize = event.pageSize;
        this.model.pagination.page = event.pageIndex;

        this.LoadPaginate();
    }

    LoadPaginate(): void {
        this.dataSource.sortingDataAccessor = (componente: MantComponentesModels.Componente, sortHeaderId: string) => {
            switch(sortHeaderId) {
                case 'componenteId': return componente.componenteId;
                case 'componente': return componente.tipoComponente ? componente.tipoComponente.nombre + componente.marca : '';
                case 'equipo': return componente.equipo ? componente.equipo.equipoId : '';
                default: return 1;
            }
        }

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    verComponente(componente: MantComponentesModels.Componente): void{
        const modalRef=this.modalService.open(ComponenteVerComponent, {
            centered:true,
            size: 'xl'
        });
        modalRef.componentInstance.componenteInput.componente = Object.assign({}, componente) ;
        modalRef.componentInstance.componenteInput.equipo = Object.assign({}, componente.equipo) ;

        modalRef.result.then( r => {
            if(r === "actualizar"){
                this.getComponentes();
            }
        })
    }

    verEquipo(componente: MantComponentesModels.Componente): void{
        const modalRef=this.modalService.open(EquipoVerComponent, {
            centered:true,
            size: 'xl'
        });

        modalRef.componentInstance.equipoInput.equipo = Object.assign({}, componente.equipo) ;
        modalRef.componentInstance.equipoInput.vieneDesdeComponentente = Object.assign({}, true) ;
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    nuevoComponente(): void{
        const modalRef=this.modalService.open(ComponenteNuevoComponent, {
            centered:true,
            size: 'lg'
        });

        modalRef.result.then( r => {
            if(r === "actualizar"){
                this.getComponentes();
            }
        })
    }

    modalMoverComponentes(): void{
        const modalRef=this.dialog.open(MoverComponentComponent, {
            panelClass: 'ds-modal',
            data: this.selection.selected.map(x=>x.componenteId),
        });

        modalRef.afterClosed().subscribe( r => {
            if(r === "actualizar"){
                this.getComponentes();
            }
        })

    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.selection.select(...this.dataSource.data);
        }
    }

    masterUntoggle() {
        if (this.isAllSelected()) {
            this.selection.select(...this.dataSource.data);
        } else {
            this.selection.clear();
        }
    }


    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    checkboxLabel(row?: MantComponentesModels.Componente): string {
        if (!row) {
          return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.componenteId}`;
    }

    unchecked(row: MantComponentesModels.Componente){
        let found = this.selection.selected.find(x => x.componenteId == row.componenteId);
        if (found) {
            this.selection.deselect(found);
        }
    }
}
