import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {  SolicitudDeAnalisisModels } from '../../mant-solicitud-analisis-models/mant-solicitud-analisis.models';

@Component({
    selector: 'popover-editar-transporte',
    templateUrl: './editar-transporte.component.html',
    styleUrls: ['./editar-transporte.component.css']
})
export class PopoverEditarTransporteComponent implements OnInit {
    @Input() transporte?:SolicitudDeAnalisisModels.Transporte={
        courier:'',
        numeroOT:''
    };
     model:SolicitudDeAnalisisModels.Transporte={
        numeroOT:'',
        courier:''
    };
    @Output() resultado = new EventEmitter <SolicitudDeAnalisisModels.Transporte | undefined>();


    ngOnInit(): void{  
        if(this.transporte){
            this.model =Object.assign({}, this.transporte) ;
        }
    }

    cancelar(){
        this.resultado.emit();
    }

    aceptar(){
        this.resultado.emit(this.model);        
    }

    esValido():boolean{
        return (this.model.courier?.trim() !== '' &&  this.model.numeroOT?.trim() !== '' && this.model.numeroOT?.trim().length <= 16);
    }
    
}
