


export class UtilsTools {

    removeAccent(cadena:string){
        const acentos:{[key:string]:string} = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
        return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
     }

     inIframe () {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
      }

    isDev():boolean{
        return window.location.host.split(":")[0] ==='localhost';
    }

}