<main class="content" id="inicio">
  <div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3">
      <div class="col-md-6">
        <h3 id="home-saludo">Hola {{ this.authService.currentUserValue.nombre }} {{ this.authService.currentUserValue.apellido }}</h3>
        <p>Ingresaste a: [ {{ this.authService.currentClientValue.razonSocial | lowercase }} - {{ this.authService.currentClientValue.faena.nombre | lowercase  }} ] <a class="text-primary" [routerLink]="" (click)="cambioClienteFaena();" *ngIf="this.authService.currentUserValue.clientes.length > 1">Cambiar</a></p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mb-5 ">
        <img src="../../../assets/images/bienvenida.svg" alt="" class="img-fluid img-bienvenida">
      </div>

      <div class="col-md-4 text-center mb-3 d-flex">
        <button class="bg-btn-inicio" [routerLink]="['/mantenedor-equipos']">
          <img src="../../../assets/images/icono-truck.svg" alt="" class="mb-1">
          <p>EQUIPOS Y COMPONENTES</p>
        </button>
      </div>

      <div class="col-md-4 text-center mb-3 d-flex">
        <button class="bg-btn-inicio" [routerLink]="['/busqueda-componente']">
          <img src="../../../assets/images/icono-agregar-box.svg" alt="" class="mb-1">
          <p>AGREGAR MUESTRAS</p>
        </button>
      </div>

      <div class="col-md-4 text-center mb-3 d-flex">
        <button class="bg-btn-inicio" [routerLink]="['/solicitudes-de-analisis']">
          <img src="../../../assets/images/icono-box-seam.svg" alt="" class="mb-1">
          <p>MUESTRAS Y SOLICITUDES</p>
        </button>
      </div>
    </div>

  </div>
</main>
