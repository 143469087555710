import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { ReportesModels } from '../models/reportes.models';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { ReportesService } from '../services/reportes.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-reporte-componentes',
    templateUrl: './reporte-componentes.component.html',
    styleUrls: ['./reporte-componentes.component.css'],
})
export class ReporteComponentesComponent implements OnInit {

    generation: boolean = false;

    optionsFaena: ReportesModels.Faena[] = [];
    selectedChore?: ReportesModels.Faena[];

    printed: boolean = false;
    printedLoading: boolean = true;
    printOptions: AnimationOptions = {
        path: '/assets/images/cloud-print.json',
    };
    printCheckOptions: AnimationOptions = {
        path: '/assets/images/black-check.json',
    };

    timerId: any;
    timeout_exist_error: any;
    informe: ReportesModels.ReporteComponente = {
        trx_complete: false,
        file_name: '',
        url: '',
    };
    faenaSelected:any;
    constructor(
        private authService: AuthenticationService,
        private service: ReportesService,
        private spinner: SpinnerService,
        private _snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.getFaenas();
    }

    clear() {
        this.informe = {
            trx_complete: false,
            file_name: '',
            url: '',
        };
        clearInterval(this.timerId);
        clearTimeout(this.timeout_exist_error);
        this.selectAllChores(true);
    }

    download_file() {
        window.open(this.informe.url, '_blank');
        this._snackBar.open(`Descarga reporte exitosa.`, '', {
            duration: 5000,
            panelClass: [`success-snackBar`],
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
        });

        this.clear();
        this.generation = false;
        this.printed = false;
        this.printedLoading = true;
    }

    private getFaenas(): void {


        const request: ReportesModels.GetFaenasRequest = {
            clienteId: this.authService.currentClientValue.clienteId
        };
        this.service.getFaenas(request, true).subscribe(response => {
            if (response.code == 200) {
                const cliente_paso = this.authService.currentUserValue.clientes.find(c => c.clienteId === this.authService.currentClientValue.clienteId);
                this.optionsFaena = response.data.filter(x => cliente_paso?.faenas.find(f => f.faenaId == x.solicitanteId));
                this.selectAllChores(true);
            }
        });


    }

    public selectAllChores(checked: boolean): void {
        this.selectedChore = checked ? this.optionsFaena : [];
    }

    validaAdmin(): boolean {
        if (this.authService.currentUserValue.roles) {
            let roles = this.authService.currentUserValue.roles!;

            if (roles.includes("Admin")) {
                return true
            }
        }
        return false;
    }

    getReport() {
        this.spinner.on

        console.log(this.selectedChore?.map(f => f.numeroSap));

        const request: ReportesModels.SetReporteComponenteRequest = {
            id_cliente: this.authService.currentClientValue.clienteId,
            faenas: this.selectedChore?.map(f => f.numeroSap),
            is_wc: true,
        };
        this.service.SetReportComponente(request).then((response) => {
            if (response.success) {
                this.printed = true;

                this.timerId = setInterval(() => {
                    this.revisarUrl(response.data.clienteId, response.data.trx_report);
                    this.validaResult();
                }, 5000);

                this.timeout_exist_error = setTimeout(() => {
                    clearInterval(this.timerId);
                    this._snackBar.open(`Hubo un error al generar el informe, realizar nuevamente.`, '', {
                        duration: 5000,
                        panelClass: [`success-snackBar`],
                        verticalPosition: 'bottom',
                        horizontalPosition: 'right',
                    });
                }, 300000);
            }
        }).finally(()=>{
            this.spinner.off();
        });
    }

    private revisarUrl(cliente: any, trx: any) {
        let rq: ReportesModels.GetReporteComponenteRequest = {
            cliente_id: cliente,
            trx: trx,
        };
        this.service.GetReporteComponente(rq).then((rsp) => {
            if (rsp.success) {
                this.informe = rsp.data;
            } else {
                this.informe = {
                    trx_complete: true,
                    file_name: '',
                    url: '',
                };
            }
        }).catch(error => {
            this.informe = {
                trx_complete: true,
                file_name: '',
                url: '',
            };
        });
    }

    validaResult() {
        if (this.informe.trx_complete && this.informe.url) {
            clearInterval(this.timerId);
            this._snackBar.open(`Reporte generado.`, '', {
                duration: 5000,
                panelClass: [`success-snackBar`],
                verticalPosition: 'bottom',
                horizontalPosition: 'right',
            });
            this.printedLoading = false;
        } else if (this.informe.trx_complete && this.informe.is_empty) {
            this.printed = false;
            this.printedLoading = true;

            clearInterval(this.timerId);
            this.printed = false
            this._snackBar.open(`No se han encontrado coincidencias al generar el informe, intente nuevamente.`, '', {
                duration: 5000,
                panelClass: [`success-snackBar`],
                verticalPosition: 'bottom',
                horizontalPosition: 'right',
            });
            this.informe = {
                trx_complete: false,
                file_name: '',
                url: '',
            };
        } else if (this.informe.trx_complete && this.informe.url == '') {
            this.printed = false;
            this.printedLoading = true;

            clearInterval(this.timerId);
            this.printed = false;
            this._snackBar.open(`Hubo un error al generar el informe, realizar nuevamente.`, '', {
                duration: 5000,
                panelClass: [`success-snackBar`],
                verticalPosition: 'bottom',
                horizontalPosition: 'right',
            });
            this.informe = {
                trx_complete: false,
                file_name: '',
                url: '',
            };
        }
    }
}
