import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ChartType, Row } from 'angular-google-charts';
import { ReportsModels } from '../../../models/reports.models';

@Component({
  selector: 'pollution-chart',
  templateUrl: './pollution.component.html',
  styleUrls: ['./pollution.component.css']
})
export class PollutionChartComponent implements OnInit {
  chart:any;

   imgSrc:string='';
   isLoading:boolean=true;

   @Input() isPrinting:boolean=false;
   @Input() data:Row[]=[
    ['1', 1.2, 1.0, 0.2, 0.2],
    ['2', 3.0, 0.2, 0.4, 0.2],
    ['3', 2.0, 1.2, 0.2, 0.2],
    ['4', 2.3, 0.4, 0.2, 0.2],
    ['5', 3.9, 0.6, 0.3, 0.2]
   ];
   @Output() event = new EventEmitter<boolean>();

     configChart:ReportsModels.GoogleChart={
      title : 'Contaminación',
      type: ChartType.LineChart,
      data:this.data,
       columnNames :['Si', 'Na','K','V'],
       options : {

            pointSize: 5,
            hAxis: {
              gridlines: {
                count: 5
              },
              scaleType:'string'
            },
            vAxis:{
              gridlines: {
                count: 7
              }
             },
              series: {
              0:{
                labelInLegend:'Si'
              },
              1:{
                labelInLegend:'Na'
              },
              2:{
                labelInLegend:'K'
              },
              3: {
                labelInLegend:'V'
              }
            },
            legend: { position: 'bottom',textStyle: {fontSize: 5}},
            chartArea: {
              left: '20%', width: '70%', right :'10%'
            },
          },
          width : 150,
          height : 150,
    };
  constructor() { }

  ngOnInit(): void {
  }
  onReadypqFeCuChart(event:any){
    this.imgSrc=event.chart.getImageURI();
    this.isLoading=false;
    this.chart= event.chart;
  }
  ngDoCheck(){
    this.configChart.data=this.data;
  }

  onReadyImage(){
    this.event.emit(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['isPrinting'] && this.chart){
      this.chart.setSelection([])
    }
  }
}
