export namespace Constants {

 
    export const Ensayo={
        estado:{
            analisis: 'analisis',
            normal: 'normal',
            precaucion: 'precaucion',
            alerta:'alerta',
            porRepetir:'por_repetir'
        }
        
    }
    
    export const Muestra={
        estado:{
            pending_approval:'pending_approval',
            reported:'reported'
        }
    }
}
