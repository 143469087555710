import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { ComponenteVerComponent } from "src/app/mantenedores/mant-componentes/mant-componentes-views/componente-ver/componente-ver-views/componente-ver.component";
import { AuthenticationService } from "src/app/shared/auth/authentication.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { EquipoVerModels } from "../equipo-ver-models/equipo-ver.models";
import { EquipoVerViewModels } from "../equipo-ver-models/equipo-ver.view.models";
import { EquipoVerService } from "../equipo-ver-services/equipo-ver.service";
import { EquipoNuevoComponent } from "../../equipo-nuevo/equipo-nuevo-views/equipo-nuevo.component";
import { EventEmitterService } from "src/app/shared/services/event-emitter.service";
import { ComponenteAsignarEquipoComponent } from "src/app/mantenedores/mant-componentes/mant-componentes-views/comp-asignar-equipo/comp-asignar-equipo-views/comp-asignar-equipo.component";
import { ModalConfirmService } from "src/app/shared/services/modalConfirm.service";
import { Objects } from "src/app/shared";
import { LogService } from "src/app/shared/services/log.service";

@Component({
    selector: 'app-equipo-ver',
    templateUrl: './equipo-ver.component.html',
    styleUrls: ['./equipo-ver.component.css']
})
export class EquipoVerComponent implements OnInit{

    displayedColumns: string[] = ['componenteId','tipo', 'marca', 'modelo', 'poscicion', 'ultimaMuestra', 'estado', 'accion'];

    dataSource = new MatTableDataSource<EquipoVerModels.Componente>([]);

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    myControl = new FormControl();
    filteredOptions!: Observable<EquipoVerModels.Componente[]>;

    @Input() equipoInput: EquipoVerModels.EquipoInput = {
        equipo: {
            clienteId: '',
            faenaId: '',
            equipoId: '',
            tipoEquipo: {
                tipoEquipoId: '',
                nombre: ''
            },
            marca: '',
            modelo: '',
            descriptor: '',
            alias: [],
            tags: [],
            componentes: [],
            removable: false,
        },
        vieneDesdeMuestra: false,
        guardoDesdeMuestra: false,
    }

    model: EquipoVerViewModels.ViewModel = {
        pagination: { page: 0, pageSize: 10, totalElements: 0 },
        equipo: {
            clienteId: '',
            faenaId: '',
            equipoId: '',
            tipoEquipo: {
                tipoEquipoId: '',
                nombre: ''
            },
            marca: '',
            modelo: '',
            descriptor: '',
            alias: [],
            tags: [],
            componentes: [],
            removable: false,
        },
        alertaSinResultado: false,
        cargandoComponentes: false,
        componentesEquipo: [],
        componentesEncontrados: [],
        componenteFiltro: '',
        tipoEquipos: [],
        marcas: [],
        modelos: [],
        verOtraMarca: false,
        verOtroModelo: false,
        selectable: true,
        removable: true,
        desdeComponente: false,
    }

    enumStatus: { [key: string]: string } = {
        analisis: 'En análisis',
        normal: 'Normal',
        precaucion: 'Precaución',
        alerta: 'En alerta'
    };

    constructor(
        private authService: AuthenticationService,
        private service: EquipoVerService,
        public dialogRef: NgbActiveModal,
        private spinner: SpinnerService,
        private modalService: NgbModal,
        private eventEmiiterSrv: EventEmitterService,
        private modalConfirmService: ModalConfirmService,
        private _logService: LogService
    ){}

    ngOnInit(): void{
        this.model.equipo = this.equipoInput.equipo;
        this.model.componentesEquipo = this.equipoInput.equipo.componentes;
        this.model.desdeComponente = this.equipoInput.vieneDesdeComponentente!;

        const equipoId = this.equipoInput.equipo.equipoId;
        if (equipoId) {
            this.getEquipoInfo(equipoId);
        }
    }

    getEquipoInfo(equipoId: string) :void{
        if(this.model.desdeComponente){
            this.model.cargandoComponentes = true;
        }else{
            this.LoadPaginate();
        }

        const request: EquipoVerModels.GetEquipoVerRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId,
            equipoId: equipoId
        };
        this.service.GetEquipoInfo(request)
            .then(response => {
                if (response.success) {
                    this.model.equipo = response.data[0];
                    this.model.componentesEquipo = this.model.equipo.componentes!;
                    this.LoadPaginate();
                }else{
                }
            })
            .catch(error => {
            })
            .finally(() => {
                this.model.cargandoComponentes = false;
            })
    }

    cerrar(){
        if(this.equipoInput.estaEditando){
            this.eventEmiiterSrv.sendResult("FLOW_MANTENEDOR", {accion: 'EDITAR', id: this.model.equipo.equipoId});
            this.dialogRef.close();
        }else if(this.equipoInput.guardoDesdeMuestra){
            this.eventEmiiterSrv.sendResult("FLOW_NUEVO_EQUIPO_MUESTRA", 'FINAL');
            this.dialogRef.close();
        }
        else{
            this.dialogRef.close({result:'cancelar'});
        }
    }

    editar(){
        this.equipoInput.equipo = this.model.equipo;
        this.equipoInput.vieneDesdeVerEquipo = true;
        this.dialogRef.close();
        const modalRef=this.modalService.open(EquipoNuevoComponent, {
            centered:true,
            size: 'lg'
        });

        modalRef.componentInstance.equipoInput = Object.assign({}, this.equipoInput) ;
    }

    verComponente(componente: EquipoVerModels.Componente){
        let componenteInput: EquipoVerModels.ComponenteInput = {
            componente: componente,
            equipo: this.model.equipo,
            tieneEquipoCargado: true,
            vieneDesdeEquipo: true,
        }

        this.dialogRef.close();
        const modalRef=this.modalService.open(ComponenteVerComponent, {
            centered:true,
            size: 'xl'
        });
        modalRef.componentInstance.componenteInput = Object.assign({}, componenteInput) ;
    }

    handlePageEvent(event: PageEvent): void {
        this.model.pagination.totalElements = event.length;
        this.model.pagination.pageSize = event.pageSize;
        this.model.pagination.page = event.pageIndex;
        this.LoadPaginate();
    }

    LoadPaginate(): void {
        const start = this.model.pagination.page == 0 ? this.model.pagination.page : (this.model.pagination.pageSize * this.model.pagination.page);
        const end = (this.model.pagination.pageSize + start);
        this.model.pagination.totalElements = this.model.componentesEquipo.length;
        const records = this.model.componentesEquipo.slice(start, end);
        this.dataSource = new MatTableDataSource<EquipoVerModels.Componente>(records);
        this.dataSource.sort = this.sort;
    }

    agregarComponente(): void{
        let equipoOutput: EquipoVerModels.EquipoInput = {
            equipo: this.model.equipo,
            vieneDesdeVerEquipo: true,
            vieneDesdeMuestra: this.equipoInput.vieneDesdeMuestra
        }
        this.dialogRef.close();

        const modalRef=this.modalService.open(ComponenteAsignarEquipoComponent, {
            centered:true,
            size: 'md'
        });
        modalRef.componentInstance.equipoInput = Object.assign({}, equipoOutput) ;
    }

    eliminarEquipo(): void{
        if(this.model.equipo.removable){
            const modalRef=this.modalConfirmService.openStatic({title:'Eliminar equipo',msg:`¿Desea eliminar el equipo ${this.model.equipo.equipoId}?`,buttonClassname:'btn-danger',titleButtonOK:'Si, eliminar'});
            modalRef.result.then(isOK=>{
                if(isOK){
                    this.confirmarEliminar();
                }
            });
        }else{
            if(this.model.equipo.componentes){
                this.modalConfirmService.openStatic(
                    {
                        title:'Eliminar equipo',
                        msg:`El equipo ${this.model.equipo.equipoId}, no se puede eliminar porque aún tiene componentes asociados`, onlyClose: true
                    }
                );
            }else{
                this.modalConfirmService.openStatic(
                    {
                        title:'Eliminar equipo',
                        msg:`El equipo ${this.model.equipo.equipoId}, no se puede eliminar porque tiene historias de muestras asociadas`, onlyClose: true
                    }
                );
            }
        }
    }

    private confirmarEliminar(): void{
        this.spinner.on();
        const request: EquipoVerModels.DeleteEquipoRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId,
            equipoId: this.model.equipo.equipoId
        };
        this.service.DeleteEquipo(request).subscribe(data => {
            if(data.success){

                let rq: Objects.LogInput = {
                    accion: 'Delete',
                    elemento: this.model.equipo.equipoId,
                    tipoElemento: 'Equipo',
                    usuario: this.authService.usuario.nombre,
                    usuarioId: this.authService.usuario.userId,
                    ambiente: 'Web Cliente'
                  }
                this._logService.createLog(rq, true);

                this.eventEmiiterSrv.sendResult("FLOW_MANTENEDOR", {accion: 'ELIMINAR', id: this.model.equipo.equipoId});
                this.spinner.off();
                this.dialogRef.close();
            }
        });
    }
}
