import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { CajaMuestrasViewModels } from '../../caja-muestras-models/caja-muestras.view.models';
import { Router } from '@angular/router';
import { SampleBoxDataProvider } from '../../../shared/services/sampleBoxDataProvider.service';
import { Objects } from 'src/app/shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditarMuestraComponent } from '../../../ingreso-muestra/editar-muestra/editar-views/editar-muestra.component';
import { ToastService } from '../../../shared/components/ng-toast/toast-service';
import { ModalConfirmService } from '../../../shared/services/modalConfirm.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { CajaMuestrasModels } from '../../caja-muestras-models/caja-muestras.models';
import { CajaDeMuestrasService } from '../../caja-muestras-services/caja-muestras.service';
@Component({
    selector: 'caja-muestras-componente',
    templateUrl: './caja-muestras.component.html',
    styleUrls: ['./caja-muestras.component.css'],
    encapsulation: ViewEncapsulation.None

})
export class CajaDeMuestrasComponent {

    model: CajaMuestrasViewModels.ViewModel = {
        data: {
            clienteId:'',
            faenaId:'',
            fechaActualizacion:new Date(),
            fechaCreacion: new Date(),
            muestras:[]
        }
    };
    planesAnalisisCondicionComercial: CajaMuestrasModels.PlanAnalisis[] = [];
    loadCondicionComercial: boolean = false;
    errorPlan:{[componenteId:string]:boolean} = {};
    loadCaja:boolean=false;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        public sampleBoxData:SampleBoxDataProvider,
        private service: CajaDeMuestrasService,
        private modalService: NgbModal,
        private modalConfirmService: ModalConfirmService,
        public toastService: ToastService,
        protected spinner:SpinnerService
            ) {
        this.model.data=sampleBoxData.sampleBoxData;
        if(!sampleBoxData.lastUpdateSampleBoxData){
            this.spinner.startLoading();
        }
        sampleBoxData.sampleBoxDataObserver.subscribe(data=>{
                this.model.data=data;
                this.getPlanesAnalisis();
        });
    }
    
    irABusquedaComponente(){
        this.router.navigate(['/busqueda-componente']);
    }

    editarMuestra(muestra:Objects.RegistroMuestra){
        const modalRef=this.modalService.open(EditarMuestraComponent, {
            centered:true,
            size: 'lg'
        });

        modalRef.componentInstance.model.registroMuestra= Object.assign({}, muestra) ;
        modalRef.result.then(resp=>{
            if(resp.result === 'actualizar'){
                this.sampleBoxData.loadCajaDeMuestras(true).then(data=>{
                    data.muestras.forEach((d,index)=>{
                          if(d.muestraId ===muestra.muestraId){
                             data.muestras[index]=resp.muestra;
                          }
                    });
                    this.sampleBoxData.setMuestras(data.muestras).then(r=>{
                        if(r.code==200){
                            this.toastService.show(`La muestra ${muestra.muestraId} fue actualizada`, { classname: 'bg-primary text-light', delay: 2000 });
                            this.errorPlan[muestra.muestraId]=!this.planesAnalisisCondicionComercial.some(p=>p.planAnalisisId===muestra.planAnalisis?.planAnalisisId);
                        }
                    });
                });
            }else if(resp.result === 'eliminar'){
                this.eliminarMuestra(muestra);
            }

        });

    }
    confirmarEnviarCajaDeMuestras(){
        const modalRef=this.modalConfirmService.openStatic({title:'Enviar caja de muestras',msg:`¿Deseas enviar la caja de muestras?`,buttonClassname:'btn-success',titleButtonOK:'Si, enviar'});
             modalRef.result.then(isOK=>{
            if(isOK){
                this.enviarCajaDeMuestras();
            }
          });
    }
    enviarCajaDeMuestras(){
        this.loadCaja=true;
        this.sampleBoxData.process().then(r=>{
            if(r.code==200){
                this.toastService.show(`La caja de muestra fue enviada`, { classname: 'bg-primary text-light', delay: 2000 });
                let comprobante = JSON.stringify(r.data);
                this.router.navigate(['/sample-box-comprobante'], {
                    queryParams: {comprobanteCajaMuestra: comprobante}
                    , skipLocationChange: true
                });
             }
        }).finally(()=>{
            this.recargarTabla();
        });
    }

    eliminarMuestra(muestra:Objects.RegistroMuestra){

         const modalRef=this.modalConfirmService.open({title:'Eliminar Muestra',msg:'¿Deseas eliminar el envase de muestra <strong>'+muestra.muestraId+'</strong> de la caja de muestras?',titleButtonOK:'Si, eliminar'},{
            backdrop:'static',
            backdropClass:'customBackdrop',
            centered:true
          });
          modalRef.result.then(isOK=>{
            if(isOK){
                this._eliminaMuestra(muestra);
            }

          });
    }

    _eliminaMuestra(muestra:Objects.RegistroMuestra){

        this.sampleBoxData.loadCajaDeMuestras(true).then(data=>{
            this.sampleBoxData.setMuestras(data.muestras.filter(rm=>{
                return rm.muestraId !== muestra.muestraId
            })).then(r=>{
                if(r.code==200){
                        this.toastService.show(`La muestra ${muestra.muestraId} fue eliminada`, { classname: 'bg-primary text-light', delay: 2000 });
                        delete this.errorPlan[muestra.muestraId];

                }
            });
        });

    }
    esHoy(date?:any):boolean{
            let now = new Date();
            let dt =new Date(date);
            return date?(dt.getDay()+dt.getMonth()+dt.getFullYear()) == (now.getDay()+now.getMonth()+now.getFullYear()) :false;
    }
    recargarTabla(){
        this.sampleBoxData.loadCajaDeMuestras(true).finally(()=>{
            this.loadCaja=false;
        });
    }

    getPlanesAnalisis() {
        this.spinner.startLoading();
        this.loadCondicionComercial = true;
        this.model.data.muestras.forEach(c=>{
            this.errorPlan[c.muestraId]=true;
        });
        let request: CajaMuestrasModels.GetCondicionComercialRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId
        }
        this.service.GetCondicionComercial(request).then(response => {
            if (response.success && response.code === 200) {
                this.planesAnalisisCondicionComercial = response.data.planes;
                this.model.data.muestras.forEach(c => {
                    this.errorPlan[c.muestraId] = !this.planesAnalisisCondicionComercial.some(p => p.planAnalisisId === c.planAnalisis?.planAnalisisId);
                });
            }
        }).finally(()=>{
            this.spinner.stopLoading();
            this.loadCondicionComercial = false;
        });

    }

    validaPlan(){
        return !Object.values(this.errorPlan).some(error => error === true);
    }
}
