import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Sampletools } from 'src/app/shared/tools/sample.tools';
import { SampleViewModels } from '../models/sample.view.models';
import { Enums } from 'src/app/shared/models/enums';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { SampleModels } from '../models/sample.models';
import { SampleService } from '../services/sample.services';
import * as moment from 'moment';
import { Constants } from 'src/app/modules/reports2/models/constants';

@Component({
  selector: 'app-sample-detail',
  templateUrl: './sample-detail.component.html',
  styleUrls: ['./sample-detail.component.css']
})
export class SampleDetailComponent implements OnInit {
    refreshDetailBatch: boolean = false;
    is_request_call: boolean = false;
    load = false;
    sampleOpenState = false;
    stepOpenState = false;
    planOpenState = false;
    visibleCollpase: boolean = false;
    subscribers: { name: string; sub: Subscription; active?: boolean }[] = [];
    value: string = '0';
    current_color: String = '#C5D0D5';
    sampleTools: Sampletools = new Sampletools();
    model: SampleViewModels.ViewModel = {
        plansAnalisis: {},
        registroFrasco: {
            enviadoLaboratorio: '',
            ingresadoLote: '',
            analisisFinalizado: '',
        },
        analysis: [],
        results: [],
        traduccion: {
            resultadoEnsayo: Enums.ResultadoGeneralEnsayo,
        },
        fechaActual: {
            fecha: '',
            tipo: '',
        },
        enumFechas: Enums.EstadosFechaMuestra,
        planResultados: {},
    };
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<SampleDetailComponent>,
        private serviceSample: SampleService,
        public authService: AuthenticationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private eventSrv: EventEmitterService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            muestra: SampleModels.RegistroMuestra;
        }
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.model.planResultados[this.data.muestra.muestraId] = this.data.muestra.planAnalisis;
        this.checkAnalysis();
    }

    ngOnInit(): void {
        this.eventSrv.event(this.eventSrv.eventNames.UPDATED_SAMPLE).subscribe((sample) => {
            if (this.data.muestra.muestraId === sample.muestraId && this.model.plansAnalisis) {
                this.data.muestra = sample;
                this.joinData();
            }
        });
        this.loadData();
    }

    private loadData() {
        this.is_request_call = true;
        this.joinData();
        this.data.muestra.traces?.forEach((t) => {
            if (t.fecha.indexOf('T') === -1) {
                t.fecha = moment(t.fecha, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm');
            }
        });
        this.model.registroFrasco.enviadoLaboratorio = !this.data.muestra.traces?.find((t) => t.estado == 'in_analysis')?.fecha
            ? ''
            : moment(this.data.muestra.traces?.find((t) => t.estado == 'in_analysis')?.fecha).format('YYYY-MM-DDTHH:mm');
        this.model.registroFrasco.ingresadoLote = !this.data.muestra.traces?.find((t) => t.estado == 'assigned_batch')?.fecha
            ? ''
            : moment(this.data.muestra.traces?.find((t) => t.estado == 'assigned_batch')?.fecha).format('YYYY-MM-DDTHH:mm');
        this.model.registroFrasco.recibido = !this.data.muestra.traces?.find(
            (t) => t.estado == 'received' || t.estado == 'received_observations'
        )?.fecha
            ? ''
            : moment(this.data.muestra.traces?.find((t) => t.estado == 'received' || t.estado == 'received_observations')?.fecha).format(
                  'YYYY-MM-DDTHH:mm'
              );
        this.model.registroFrasco.analisisFinalizado = !this.data.muestra.traces?.find((t) => t.estado == 'pending_approval')?.fecha
            ? ''
            : moment(this.data.muestra.traces?.find((t) => t.estado == 'pending_approval')?.fecha).format('YYYY-MM-DDTHH:mm');
        this.getLastDate();
    }


    ngOnDestroy(): void {
        this.destroySubs();
    }


    joinData(): void {
        if (!this.model.plansAnalisis[this.data.muestra.planAnalisis.planAnalisisId]) {
            return;
        }
        this.model.planResultados[this.data.muestra.muestraId] = Object.assign(
            [],
            this.model.plansAnalisis[this.data.muestra.planAnalisis.planAnalisisId]
        );

        //this.data.muestra.planAnalisis.ensayos =  Object.assign([],this.model.plansAnalisis[this.data.muestra.planAnalisis.planAnalisisId].ensayos);

        /** si la muestra se encuentra en estado reportada se filtran los ensayos a solo los que se utilizaron */
        this.checkAnalysis();
        this.model.planResultados[this.data.muestra.muestraId].ensayos?.forEach((ensayo) => {
            // ensayo.result = this.model.results.find(result=>result.ensayo.ensayoId===ensayo.ensayoId)
            let resultados = this.data.muestra.resultados?.filter((r) => r.ensayo.ensayoId === ensayo.ensayoId);
            if (resultados && resultados.length >= 1) {
                let ultimoResultado = resultados[resultados.length - 1];
                ensayo.result = ultimoResultado;
            } else {
                ensayo.result = this.data.muestra.resultados?.find((r) => r.ensayo.ensayoId === ensayo.ensayoId);
            }
        });
        this.load = true;
    }

    /**
     * Verifica si los ensayos estan de acuerdo al estado de la muestra, de ser reportada solo considera ensayos con resultados.
     */
    private checkAnalysis() {
        if (this.data.muestra.estado === Constants.Muestra.estado.reported) {
            this.model.planResultados[this.data.muestra.muestraId].ensayos = this.model.planResultados[
                this.data.muestra.muestraId
            ].ensayos?.filter((e) => this.data.muestra.resultados?.some((r) => r.ensayo.ensayoId == e.ensayoId));
            this.data.muestra.resultados?.forEach((r) => {
                if (
                    !this.model.planResultados[this.data.muestra.muestraId].ensayos?.some((ensayo) => ensayo.ensayoId === r.ensayo.ensayoId)
                ) {
                    this.model.planResultados[this.data.muestra.muestraId].ensayos?.push(r.ensayo);
                }
            });
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.visibleCollpase = true;
        }, 200);
    }

    close() {
        //this.data.muestra.resultados = this.model.results;
        this.dialogRef.close({ data: this.data,refresh:this.refreshDetailBatch });
    }

    private getLastDate(): void {
        let fechas = this.data.muestra.traces?.filter((t) => t.estado == this.data.muestra.estado);
        if (fechas && fechas.length != 0) {
            if (fechas[0].estado === 'reported') {
                this.model.fechaActual = {
                    fecha: moment(this.data.muestra.fechaInforme).format('YYYY-MM-DDTHH:mm'),
                    tipo: fechas[0].estado,
                };
            } else {
                this.model.fechaActual = {
                    fecha: (fechas[0].fecha.indexOf('T') === -1
                        ? moment(fechas[0].fecha, 'DD/MM/YYYY HH:mm:ss')
                        : moment(fechas[0].fecha)
                    ).format('YYYY-MM-DDTHH:mm'),
                    tipo: fechas[0].estado,
                };
            }
        }
    }

    private addSubscribe(name: string, sub: Subscription) {
        this.destroySubs(name);
        this.subscribers.push({ name: name, sub: sub });
    }
    private destroySubs(name?: string) {
        this.subscribers
            .filter((s) => !name || s.name === name)
            .forEach((s) => {
                s.sub.unsubscribe();
                s.active = false;
            });
    }
    get percent(): number {
        let total = this.model.planResultados[this.data.muestra.muestraId].ensayos?.filter((e) => e.result?.stamp !== 'descartar').length;
        let resolved = this.model.planResultados[this.data.muestra.muestraId].ensayos?.filter(
            (e) => e.result && (e.result.stamp == 'cargado' || e.result.stamp == 'no_realizable')
        ).length;

        let percent: number = 0;
        0;
        if (total && resolved) {
            percent = (resolved * 100) / total;
        }
        return percent;
    }

    edit() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                id: this.data.muestra.muestraId,
                muestra: JSON.stringify(this.data.muestra)
            },
            skipLocationChange: true,
        };
        this.router.navigate(['/muestra/editar'], navigationExtras);
        this.dialog.closeAll();
    }

    childsTabReport: { [ref: string]: { bottleNumber: string; tab: Window; sample?: SampleModels.RegistroMuestra } } = {};

    public getPdf(): void {
        const ref = new Date().getTime();
        const url = '/reports2?ref=' + ref;
        const muestra: SampleModels.RegistroMuestra = this.data.muestra;
        let tab = window.open(url, '_blank');
        if (tab) {
            this.childsTabReport[ref] = { tab: tab, bottleNumber: muestra.muestraId, sample: muestra };
        }
        window.focus();
    }

    @HostListener('window:message', ['$event'])
    onMessage(e: any) {
        if (e.data.action === 'showReport' && e.origin === window.location.origin) {
            switch (e.data.event) {
                case 'init':
                    let ref = this.childsTabReport[e.data.ref];
                    // console.log("ref", ref)
                    if (ref) {
                        ref.tab.window.postMessage(
                            { action: 'loadReport', bottleNumber: ref.bottleNumber, sample: ref.sample },
                            window.location.origin
                        );
                    }
                    break;
                default:
                    break;
            }
        }
    }

    resultadoEnPlanActual(ensayo: SampleModels.Ensayo) {
        const ensa = this.model.plansAnalisis[this.data.muestra.planAnalisis.planAnalisisId].ensayos?.find(
            (e) => e.ensayoId === ensayo.ensayoId
        );
        if (!ensa) {
            return false;
        }

        let result = this.data.muestra.resultados?.filter((re) => re.ensayo.ensayoId == ensayo.ensayoId) ?? [];

        result.sort((a, b) => {
            return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
        });
        if (result.length && result[result.length - 1].stamp == 'descartar' && this.data.muestra.estado != 'reported') {
            return false;
        }
        return true;
    }
    getFilteredEnsaysLength(): number {
        if (!this.model.planResultados[this.data.muestra.muestraId]?.ensayos) {
            return 0; // or whatever default value you want to return
        }

        return this.model.planResultados[this.data.muestra.muestraId].ensayos?.filter((e) => e.result?.stamp !== 'descartar').length ?? 0;
    }
}

