<div class="row mb-2 mb-xl-3">
  <div class="col-md-6 order-2 order-md-1">

    <h3>
      <a class="link-primary link--icon order-0 order-md-1" routerLinkActive="active"
        [routerLink]="['/condiciones-comerciales']">
        <em class="bi bi-chevron-left"></em>
      </a>
      Pendiente de facturación
    </h3>
  </div>
</div>

<div class="row">
  <div class="col-12 mb-3">
    <form class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-start gap-3 flex-wrap"
      #searchForm="ngForm">
      <div class="form__field">
        <label class="label mb-1 d-block">Fecha desde</label>
        <input class="form-control" type="date" name="period_from" value="2022-01-28" [(ngModel)]="model.period_from"
          required (keyup)="set_max_date($event)" />
      </div>
      <div class="form__field">
        <label class="label mb-1 d-block">Fecha hasta</label>
        <input class="form-control" type="date" name="period_to" value="2022-01-28" [(ngModel)]="model.period_to"
          required (keyup)="set_max_date($event)" />
      </div>
      <div class="form__field">
        <label class="label mb-1 d-block">Estado pedido</label>
        <ng-select [items]="model.estados" bindLabel="label" class="ng-select ng-select-full-options" name="estado"
          placeholder="Seleccionar" #status="ngModel" blockSpecialCharacters [(ngModel)]="model.estados_seleccion"
          [appendTo]="'body'" [clearable]="true" [multiple]="false" (change)="changeEstadoPedido()">
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              <span class="c-gray"> No existen datos </span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="form__field align-self-sm-end">
        <span class="label mb-1 d-lg-block">&nbsp;</span>
        <mat-checkbox [color]="'primary'" class="mb-2" #chkCobradoCeBe name="chkCobradoCeBe"
          [(ngModel)]="isCheckedCobradoCeBe" (change)="changeCobradoACeBe()">Cobrado a CeBe</mat-checkbox>
      </div>
      <button class="btn btn-secondary ms-sm-auto me-sm-0 " (click)="enviarProformas()" [disabled]="selection.selected.length==0">ENVIAR DOCUMENTOS</button>
      <button type="button" class="btn btn-secondary me-sm-0"
        [disabled]="searchForm.invalid || is_request_call" (click)="get_documents(true)">
        BUSCAR DOCUMENTO
        <mat-spinner *ngIf="is_request_call" matSuffix [diameter]="18"
          style="float: right; margin-left: 8px"></mat-spinner>
      </button>

    </form>
  </div>
  <div class="col-12">
    <div role="alert" class="alert alert-info text-start justify-content-center" *ngIf="model.docsVentas.length === 0">
      <div class="alert-message d-flex flex-column justify-content-center align-items-center p-3">
        <em class="bi bi-search ico--big mb-3"></em>
        <h4 class="alert-heading mb-0 text-center">
          <b>Realiza una búsqueda</b>
          <br />
          <span>para visualizar los documentos de venta que necesites.</span>
        </h4>
      </div>
    </div>
    <div class="table-responsive p-1" *ngIf="model.docsVentas.length > 0">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z2 w-100">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()"
                          [color]="'primary'"
                          >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)"
                          [color]="'primary'"
                          >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="document">
          <th mat-header-cell *matHeaderCellDef>N° DOCUMENTO</th>
          <td mat-cell *matCellDef="let element">{{ element.documentoVentasId }}</td>
        </ng-container>
        <ng-container matColumnDef="cliente">
          <th mat-header-cell *matHeaderCellDef>Cliente</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.cliente.nombre }}</span><br /><small>{{ element.cliente.clienteId }}</small>
          </td>
        </ng-container>
        <ng-container matColumnDef="faena">
          <th mat-header-cell *matHeaderCellDef>Faena</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.cliente.faena.nombre }}</span><br /><small>{{ element.cliente.faena.faenaId }}</small>
          </td>
        </ng-container>
        <ng-container matColumnDef="creador">
          <th mat-header-cell *matHeaderCellDef>Creador</th>
          <td mat-cell *matCellDef="let element">{{ element.usuario.nombre }}</td>
        </ng-container>
        <ng-container matColumnDef="fechaCreacion">
          <th mat-header-cell *matHeaderCellDef>FECHA<br />CREACIÓN</th>
          <td mat-cell *matCellDef="let element">{{ element.fecha | date : "dd-MM-yyyy" }}</td>
        </ng-container>
        <ng-container matColumnDef="periodo">
          <th mat-header-cell *matHeaderCellDef>Periodo</th>
          <td mat-cell *matCellDef="let element">
            <span>Desde {{ element.desde_periodo }}</span><br /><span>Hasta {{ element.hasta_periodo }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="estado">
          <th mat-header-cell *matHeaderCellDef>ESTADO<br />PEDIDO<br />CON PRECIO</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center gap-1" *ngIf="element.pedido_cost.planes_analisis.length > 0">
              <span class="dot success"></span>
              <span class="dot warning" *ngIf="false"></span>
              <span class="dot danger" *ngIf="false"></span>
              {{ element.pedido_cost.estado.toLowerCase()==='facturado' || (element.pedido_cost.estado.toLowerCase()==='compensado' && !element.pedido_cost.is_envio_cebe) ? "Facturado" : "Pendiente" }}            </div>
            <div class="d-flex align-items-center gap-1" *ngIf="element.pedido_cost.planes_analisis.length == 0">
              <span>---</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef>Total</th>
          <td mat-cell *matCellDef="let element">{{ element.pedido_cost.total_pedido | number }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openDocument(row)"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" (page)="handlePageEvent($event)"
        [length]="model.pagination.totalElements" [pageSize]="model.pagination.pageSize"
        [pageIndex]="model.pagination.page" showFirstLastButtons class="mat-elevation-z2"></mat-paginator>
    </div>
  </div>
</div>
