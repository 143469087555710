<div class="row mb-2 mb-xl-3">
  <div class="col-md-6 order-2 order-md-1">
    <h3>Condiciones comerciales</h3>
  </div>
</div>

<div class="row" *ngIf="model">
  <div class="col-12 col-md-6">
    <mat-accordion class="ds-accordion d-block mb-4">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Planes de análisis contratados</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-2">
            <div class="figure">
              <em class="bi bi-thermometer-half"></em>
            </div>
          </div>
          <div class="col-10">
            <table class="table my-0 table-borderless">
              <thead>
                <tr>
                  <th scope="col" style="width: 70%">Plan</th>
                  <th scope="col" style="width: 30%">Precio</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let plan of model.condicionComercial.planes; let i = index">
                  <tr *ngIf="i < showItems || showAll">
                    <td>{{ plan.nombre }}</td>
                    <td>${{ plan.precio | number }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <button class="link" type="button" (click)="toggleShowAll()" *ngIf="model.condicionComercial.planes.length > 3">
              {{ showAll ? "Ver menos" : "Ver más" }}
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="ds-accordion d-block mb-4">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Tipo de transporte al laboratorio</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row align-items-center">
          <div class="col-2">
            <div class="figure">
              <em class="bi bi-truck"></em>
            </div>
          </div>
          <div class="col-10">
            <p class="mb-0 text">Transportista de Copec retira y transporta las muestras</p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="col-12 col-md-6">
    <mat-accordion class="ds-accordion d-block mb-4">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Precios de insumos</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-2">
            <div class="figure">
              <em class="bi bi-box-seam"></em>
            </div>
          </div>
          <div class="col-10">
            <table class="table my-0 table-borderless">
              <thead>
                <tr>
                  <th scope="col">Insumo</th>
                  <th scope="col">Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let insumo of model.condicionComercial.insumos">
                  <td>{{ insumo.nombre }}</td>
                  <td>${{ insumo.precio | number }}</td>
                </tr>
              </tbody>
            </table>
            <button class="link" type="button" (click)="toggleShowAll()" *ngIf="model.condicionComercial.insumos.length > 3">
              {{ showAll ? "Ver menos" : "Ver más" }}
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
   <mat-accordion class="ds-accordion d-block mb-4" *ngIf="validarAdmin()">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Pendientes de facturación y CEBE</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row align-items-center">
          <div class="col-2">
            <div class="figure hightlight">
              <em class="bi bi-cash-coin"></em>
            </div>
          </div>
          <div class="col-10">
            <div class="d-flex align-items-center justify-content-between">
              <h2 class="debt" [htmlTooltip]="tooltipContent" matTooltipPosition="below">
                $ {{ formatearNumero(facturacionNumbers.total) }}
              </h2>
              <a href="javascript:void(0)" [routerLink]="['/pendiente-facturacion']" class="btn btn-primary">VER MÁS</a>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
