import { Component, OnInit, ViewEncapsulation, Input, ChangeDetectorRef } from '@angular/core';


import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { SolicitudDeAnalisisViewModels } from '../solicitud-models/solicitud.view.models';
import { SolicitudDeAnalisisService } from '../solicitud-services/solicitud.service';
import { SolicitudDeAnalisisModels } from '../solicitud-models/solicitud.models';
import { AuthenticationService } from '../../../auth/authentication.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
    selector: 'muestra-solicitud-de-analisis',
    templateUrl: './solicitud.component.html',
    styleUrls: ['./solicitud.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SolicitudDeAnalisisComponent implements OnInit {
    @Input() solicitudAnalisisId: string = '';
    @Input() idComponent: string = new Date().getTime().toString();
    @Input() esModal: boolean = false;
    initiated: boolean = false;

    model?: SolicitudDeAnalisisViewModels.ViewModel;

    constructor(private service: SolicitudDeAnalisisService,
        public authService: AuthenticationService,
        private spinner: SpinnerService,
        private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.initiated = true
    }

    public descargarOrdenAnalisis(solicitudNro?: string) {
        this.solicitudAnalisisId = solicitudNro || this.solicitudAnalisisId;
        this.obtenerDatosDeSolicitud().then(() => {
            this.cd.detectChanges();
            this.exportPDF();
        });
    }
    public cargarDatosDeSolicitud() {
        this.obtenerDatosDeSolicitud();
    }

    private obtenerDatosDeSolicitud(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let req: SolicitudDeAnalisisModels.GetSolicitudDeAnalisisRequest = {
                clienteId: this.authService.currentClientValue.clienteId,
                solicitudAnalisisId: this.solicitudAnalisisId,
                faenaId: this.authService.currentClientValue.faena.faenaId
            };
            this.service.GetSolicitudAnalisis(req).then(resp => {
                if (resp.code === 200) {
                    this.setValues(resp);
                }
                resolve(true)
            }).catch(e => reject(false));
        })

    }

    private setValues(resp: SolicitudDeAnalisisModels.GetSolicitudDeAnalisisResponse) {
        let advLub = resp.data.solicitud.muestras.find(m => m.lubricante.lubricanteId === "0") ? true : false;
        this.model = {
            solicitud: resp.data,
            advLubricante: advLub
        };

        let muestras = this.model.solicitud.solicitud.muestras;
        let cantPag = muestras.length / 42;
        if (cantPag <= 1) {
            this.model.paginas = [muestras];
        } else {
            let array = [];
            let cantInt = Math.trunc(cantPag);
            let diff = cantPag - cantInt;
            for (let index = 0; index < cantInt; index++) {

                const element = muestras.slice(index * 42, 42 * index + 42);
                array.push(element);
            }

            if (diff > 0) {
                let ultimos = diff * 42;
                const element = muestras.slice(-ultimos);
                array.push(element);

            }

            this.model.paginas = array;

        }
    }

    public exportPDF_old(): void {

        let DATA = document.getElementById(this.idComponent)?.parentElement;
        const doc = new jsPDF('p', 'pt', 'a4');
        const options = {
            background: 'white',
            scale: 3,
            allowTaint: true,
            scrollX: 0, scrollY: -window.scrollY
        };
        if (DATA) {
            DATA.style.display = 'block';
            this.spinner.startLoading();
            html2canvas(DATA, options).then((canvas) => {
                const img = canvas.toDataURL('image/PNG');
                const bufferX = 15;
                const bufferY = 15;
                const imgProps = (doc as any).getImageProperties(img);
                const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
                return doc;
            })
                .then((docResult) => {
                    docResult.save(`solicitud_de_analisis_${this.model?.solicitud.solicitud.solicitudAnalisisId}.pdf`);
                }).catch(e => {
                }).finally(() => {
                    this.spinner.stopLoading();
                });
            DATA.style.display = this.esModal ? 'block' : 'none';
        }
    }

    public exportPDF_bad(): void {


        let DATA = document.getElementById(this.idComponent)?.parentElement;
        DATA!.style.display = 'block';

        const doc = new jsPDF('p', 'pt', "legal", false);
        doc.html(DATA!, {
            autoPaging: 'text',
            x: 10,
            y: -45,
            margin: [30, 0, 30, 0],
            html2canvas: {
                scale: 0.85,
            },
            callback: function () {
                doc.save('solicitud_analisis.pdf');
            }
        });
    }


    public exportPDF(): void {


        const pdf = new jsPDF('p', 'pt', "legal", false);
        const file_name = 'solicitud_analisis.pdf';

        let DATA = document.getElementById(this.idComponent)?.parentElement;
        DATA!.style.display = 'block';

        console.log(document.getElementsByClassName("convert-on-pdf"))

        const convertElements = document.getElementsByClassName("convert-on-pdf");
        const elements = Array.from(convertElements) as HTMLElement[];

        console.log(elements.length);
        if (elements.length > 0) {
            Promise.all(
                elements.map(async (element) => {
                    const canvas = await html2canvas(element);
                    element.replaceWith(canvas);
                }),
            ).then(() => {
                pdf.html(DATA!, {
                    autoPaging: 'text',
                    x: 10,
                    y: -30,
                    margin: [10, 0, 10, 0],
                    html2canvas: {
                        scale: 0.85,
                    },
                    callback: (generatedPdf) => {
                        generatedPdf.save(file_name)
                        this.initiated = false;
                    },
                });
            });
        } else {
            pdf.html(DATA!, {
                callback: (generatedPdf) => {
                    generatedPdf
                        .save(file_name)
                    this.initiated = false;
                },
            });
        }
    }

    public mostrarSolicitud() {
        this.initiated = true;
        this.esModal = true;
    }

    ngOnDestroy() {
        this.initiated = false;
        this.esModal = false;
    }
}
