import { Subject, BehaviorSubject } from 'rxjs';

export class RequestCounterTools {
    public inProgress:boolean=false;
    public requests:number=0;
    private counter:number=0
    public loading$: Subject<boolean> ;

    constructor() {
        this.loading$= new BehaviorSubject(this.inProgress);
    }
       


  startRequest(){
    this.request('start');
  }

  endRequest(){
    this.request('finish');
  }

  request(status:string){

    switch (status) {
        case 'finish':
            if(this.requests>0){
              this.requests--;
            }
            break;
        case 'start':
        default:
            this.requests++;
            this.counter++;

        break;
    }
    this.checkStatus();
  }

  private checkStatus() {
        if (this.requests < 1) {
            this.inProgress = false;
        } else {
            this.inProgress = true;
        }
        this.loading$.next(this.inProgress)
  }

   public isUsed():boolean{
     return this.counter>0
   } 
}