import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CartaAutRetiroModels } from '../carta-models/carta.models';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CartaAutRetiroService {

    constructor(
        private http: HttpClient) {
    }
  

    setSolicitudAnalisisEstado(request:CartaAutRetiroModels.SetSolicitudDeAnalisisEstadoRequest): Observable<CartaAutRetiroModels.SetSolicitudDeAnalisisEstadoResponse> {
        return this.http.post<CartaAutRetiroModels.SetSolicitudDeAnalisisEstadoResponse>(`${environment.apiUrl}${environment.routes.setSolicitudAnalisisEstado}`, request);
    }
}
