<div class="paginator mat-elevation-z2">
  <div class="d-flex justify-content-start align-items-center gap-2">
    <small>Items por p&aacute;gina</small>
    <ng-select class="ng-select pager" style="overflow: visible;" dropdownPosition="top" name="dataFilter" [(ngModel)]="selectedNumRegister" (change)="changeNumberPage()" [clearable]="false">
      <ng-option *ngFor="let n of registers" [value]="n">{{n}}</ng-option>
    </ng-select>
  </div>
  <button class="btn btn--only-icon" [disabled]="paginatorParams.isLoading" *ngIf="iterator > 1"  (click)="backPage();">
    <em class="bi bi-chevron-left"></em>
  </button>
  <ul class="pages">
      <li *ngFor="let page of pagesNumbers" [ngClass]="{'actualPage': page == iterator}" (click)="changePage(page)">{{page}}</li>
      <li *ngIf="paginatorParams.hasNext">...</li>
  </ul>
  <button class="btn btn--only-icon" [disabled]="paginatorParams.isLoading" *ngIf="paginatorParams.hasNext || iterator < maxPages" (click)="nextPage();">
    <em class="bi bi-chevron-right"></em>
  </button>
</div>
