import { Component, OnInit, Inject } from '@angular/core';

import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { ComprobanteMuestraViewModels } from '../comprobante-models/comprobante.view.models';
import { ActivatedRoute, Router } from '@angular/router';
import { SampleBoxDataProvider } from '../../../shared/services/sampleBoxDataProvider.service';


@Component({
    selector: 'comprobante',
    templateUrl: './comprobante.component.html',
    styleUrls: ['./comprobante.component.css']
})
export class ComprobanteMuestraComponent implements OnInit {

    model: ComprobanteMuestraViewModels.ViewModel = {
        muestras:[],
        registroMultple:false
        };
    constructor(
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        public appData:SampleBoxDataProvider
    ) {        
        let registroMuestras = this.route.snapshot.queryParams.registroMuestras;
        this.model.muestras= JSON.parse(registroMuestras);
        this.model.registroMultple = this.model.muestras.length >1;
        this.model.sampleBoxData=appData.sampleBoxData; 
        appData.sampleBoxDataObserver.subscribe(data=>{
            this.model.sampleBoxData=data;
        });
    }

    ngOnInit(): void {
    }
    irAbusquedaComponente(){
        this.router.navigate(['/busqueda-componente']);
    }

    irACajaDeMuestras(){
        this.router.navigate(['/sample-box']);
    }
    
}
