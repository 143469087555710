<div class="modal__contanier">
  <div class="modal__header">
    <h2>Fotos de la muestra</h2>
    <a href="#" [routerLink]="" [mat-dialog-close]="false">
      <em class="bi bi-x-lg"></em>
    </a>
  </div>
  <ng-container>
    <div class="modal__content">
      <div class="grid grid--gallery">
        
        <div class="d-flex flex-column gap-2" *ngFor="let imagen of data.muestra.imagenes">
          <img class="rounded" src="{{imagen.url}}" alt="">
          <span>{{imagen.ensayoNombre}}</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
