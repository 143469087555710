<form #registraMuestraForm="ngForm">

    <div class="modal-header">
        <h5 class="modal-title">Editar muestra</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="cancelar()"></button>
    </div>
    <div class="modal-body">
        <div class="" id="editar-muestra">
            <div class="row">
                <div class="col">
                    <div class="titulo">
                        <h3>Muestra <strong>{{model.registroMuestra.muestraId}}</strong></h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="mb-3">
                                <label class="form-label">Equipo</label>
                                <input type="text" readonly="" class="form-control-plaintext" value="{{model.registroMuestra.componente?.equipo?.tipoEquipo?.nombre}}">
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Nombre</label>
                                <input type="text" readonly="" class="form-control-plaintext" [value]="model.registroMuestra.componente?.tipoComponente?.nombre">
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Marca</label>
                                <input type="text" readonly="" class="form-control-plaintext" value="{{model.registroMuestra.componente?.equipo?.marca}}">
                            </div>
                            <div class="mb-0">
                                <label class="form-label">Modelo</label>
                                <input type="text" readonly="" class="form-control-plaintext" value="{{model.registroMuestra.componente?.equipo?.modelo}}">
                            </div>

                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">

                            <div class="mb-3">
                                <label class="form-label">ID del componente</label>
                                <input type="text" readonly="" class="form-control-plaintext" value="{{model.registroMuestra.componente?.componenteId}}">
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Componente</label>
                                <input type="text" readonly="" class="form-control-plaintext" value="{{model.registroMuestra.componente?.tipoComponente?.nombre}}">
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Marca</label>
                                <input type="text" readonly="" class="form-control-plaintext" value="{{model.registroMuestra.componente?.marca}}">
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Modelo</label>
                                <input type="text" readonly="" class="form-control-plaintext" value="{{model.registroMuestra.componente?.modelo}}">
                            </div>
                            <div class="mb-0">
                                <label class="form-label">Posición</label>
                                <input type="text" readonly="" class="form-control-plaintext" value="{{model.registroMuestra.componente?.ubicacion}}">
                            </div>


                        </div>
                    </div>

                </div>
                <div class="col">
                    <div class="mb-3">
                        <label class="form-label">Ingreso de muestra</label>
                        <input type="text" readonly="" class="form-control-plaintext" value="{{model.registroMuestra.fechaIngreso | date :'dd/MM/yyyy'}} por {{model.registroMuestra.usuario?.nombre}}">
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Lubricante</label><mat-spinner *ngIf="model.cargando.lubricantes" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                        <ng-select id="lubricanteIdSeleccionado" name="lubricanteIdSeleccionado" [(ngModel)]="model.registroMuestra.lubricante" #selectLubricante="ngModel" #select [items]="model.lubricantes" name="lubricante" bindLabel="nombre" y [clearable]="false"
                            [ngClass]="{'is-invalid':selectLubricante.invalid && (selectLubricante.dirty || selectLubricante.touched)}" required>

                            <ng-template ng-footer-tmp *ngIf="!model.verOtrosLubricantes">
                                <a (click)="verOtrosLubricantes(select);false">
                                    ver m&aacute;s ...
                                </a>
                            </ng-template>
                            <ng-template ng-footer-tmp *ngIf="model.verOtrosLubricantes">
                                <div class="row">
                                    <div class="col">
                                        <a (click)="verLubricantesRecomendados(select);false">
                                            Volver
                                        </a>
                                    </div>
                                    <div class="col text-end">
                                        <a (click)="seleccionarOtroLubricante(select);false">
                                            Otro
                                        </a>
                                    </div>
                                </div>
                            </ng-template>

                        </ng-select>
                        <div *ngIf="selectLubricante.invalid && (selectLubricante.dirty || selectLubricante.touched)" class="text-danger">
                            <div *ngIf="selectLubricante.errors?.required">
                                Debe seleccionar lubricante.
                            </div>
                        </div>
                    </div>
                    <div class="mb-3" *ngIf="model.registroMuestra.lubricante?.lubricanteId==='0'">
                        <label class="form-label">Otro lubricante</label>
                        <input type="text" id="otroLubricante" name="otroLubricante" class="form-control" maxlength="100" [(ngModel)]="model.registroMuestra.otroLubricante" #otroLubricante="ngModel" [ngClass]="{'is-invalid':otroLubricante.invalid 
                                    && (otroLubricante.dirty || otroLubricante.touched)}" required>
                        <div *ngIf="otroLubricante.invalid && (otroLubricante.dirty || otroLubricante.touched)" class="text-danger">
                            <div *ngIf="otroLubricante.errors?.required">
                                Debe ingresar otro lubricante.
                            </div>
                        </div>

                        <div class="alert alert-warning mt-2" role="alert">
                            <div class="alert-message">
                                <strong>Importante!</strong> Deberá incluir adicionalmente una muestra de este lubricante nuevo, <strong>sin uso</strong>, para que el laboratorio pueda analizarlo.
                            </div>
                        </div>
                    </div>


                    <div class="mb-3">
                        <label class="form-label">Plan de análisis</label><mat-spinner *ngIf="model.cargando.planAnalisis" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                        <ng-select [(ngModel)]="model.registroMuestra.planAnalisis" blockSpecialCharacters #selectedFaena [clearable]="false" name="planAnalisis" bindLabel="nombre" [disabled]="model.cargando.planAnalisis" required [items]="model.planesAnalisis"
                            #selectContratoPlanAnalisisId="ngModel" [ngClass]="{'is-invalid':selectContratoPlanAnalisisId.invalid && (selectContratoPlanAnalisisId.dirty || selectContratoPlanAnalisisId.touched)}">
                            <ng-template ng-label-tmp let-item="item">
                                <span>{{item.nombre}}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <span>{{item.nombre}}</span>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="selectContratoPlanAnalisisId.invalid && (selectContratoPlanAnalisisId.dirty || selectContratoPlanAnalisisId.touched)" class="text-danger">
                            <div *ngIf="selectContratoPlanAnalisisId.errors?.required">
                                Debe seleccionar plan de análisis.
                            </div>
                        </div>





                    </div>
                    <div class="mb-3">
                        <label class="form-label">Fecha toma de muestra</label>
                        <input type="date" class="form-control" id="fechaTomaMuestra" name="fechaTomaMuestra" onkeydown="return false" max="{{ model.todaysdate | date :'yyyy-MM-dd' }}" validarFecha [(ngModel)]="model.registroMuestra.fechaMuestreo" #fechaTomaMuestra="ngModel" [ngClass]="{'is-invalid':fechaTomaMuestra.invalid 
                        && (fechaTomaMuestra.dirty || fechaTomaMuestra.touched)}" required>
                        <div *ngIf="fechaTomaMuestra.invalid && (fechaTomaMuestra.dirty || fechaTomaMuestra.touched)" class="text-danger">
                            <div *ngIf="fechaTomaMuestra.errors?.required">
                                Debe seleccionar la fecha de toma de muestra.
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Uso total del componente <em>(opcional)</em></label>
                        <div class="row">
                            <div class="col">
                                <input type="text" id="usoTotalComponente" name="usoTotalComponente" maxlength="10" class="form-control" numeric decimals="0" [(ngModel)]="model.registroMuestra.usoTotalComponente">
                            </div>
                            <div class="col">
                                <select class="form-select" name="unidadUsoTotalComponente" id="unidadUsoTotalComponente" aria-label="Default select example" [(ngModel)]="model.registroMuestra.unidadUsoTotalComponente">
                                    <option value="horas">horas</option>
                                    <option value="km">km</option>
                                </select>
                                <div class="text-danger">
                                    <div *ngIf="isRequeredUnidadUsoTotalComponente()">
                                        Debe seleccionar unidad.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="mb-3">
                        <label class="form-label">Uso desde el cambio del lubricante <em>(opcional)</em></label>
                        <div class="row">
                            <div class="col">
                                <input type="text" id="usoCambioLubricante" name="usoCambioLubricante" class="form-control" numeric decimals="0" maxlength="10" [(ngModel)]="model.registroMuestra.usoCambioLubricante">
                            </div>
                            <div class="col">
                                <select class="form-select" name="unidadUsoCambioLubricante" id="unidadUsoCambioLubricante" aria-label="Default select example" [(ngModel)]="model.registroMuestra.unidadUsoCambioLubricante">
                                    <option selected="">horas</option>
                                    <option>km</option>
                                </select>
                                <div class="text-danger">
                                    <div *ngIf="isRequeredUnidadUsoCambioLubricante()">
                                        Debe seleccionar unidad.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="mb-3">
                        <label class="form-label">Relleno desde el último cambio <em>(opcional)</em></label>
                        <div class="row">
                            <div class="col">
                                <input type="text" id="rellenoDesdeUltimoCambio" name="rellenoDesdeUltimoCambio" maxlength="10" class="form-control" numeric decimals="0" [(ngModel)]="model.registroMuestra.rellenoDesdeUltimoCambio">
                            </div>
                            <div class="col mt-1">Litros</div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">COMENTARIO <em>(opcional 75 caracteres)</em></label>
                        <div class="row">
                            <div class="col">
                                <textarea name="comment" id="comment" rows="3" class="form-control" maxlength="75"  min="1" [(ngModel)]="model.registroMuestra.comentarioCliente"></textarea>
                            </div>                            
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-light" (click)="eliminarMuestra()"><i class="bi bi-trash"></i> Eliminar muestra</button>
        <div>
            <button type="button" class="btn btn-light" (click)="cancelar()">Cancelar</button>
            <button type="button" class="btn btn-primary" [disabled]="registraMuestraForm.invalid || isRequeredRadiosUnidades()" (click)="actualizarMuestra()">Guardar cambios</button>
        </div>
    </div>
</form>