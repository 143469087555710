<nav class="navbar navbar-expand navbar-light navbar-bg justify-content-between">
    <a class="sidebar-toggle d-flex">
        <em class="hamburger align-self-center"></em>
    </a>
    <div class="d-flex justify-content-end align-items-center gap-3">
      <div class="navbar-collapse collapse" *ngIf="this.authService.currentUserValue">
          <a class="btn btn-outline-secondary d-flex align-items-center" [routerLink]="" (click)="cambioClienteFaena();">
            <em class="bi bi-building align-middle me-md-2"></em>
            <span class="d-none d-md-block">Cambiar cliente o faena</span>
          </a>
      </div>
      <a class="btn btn-primary d-flex align-items-center" [routerLink]="['/busqueda-componente']">
        <em class="bi bi-plus-lg align-middle me-md-2"></em>
        <span class="d-none d-md-block">Agregar muestra</span>
      </a>
      <button class="btn btn-icon btn-outline-secondary" [routerLink]="['/sample-box']">
        <span class="notify">{{ sampleBoxData?.muestras?.length || 0}}</span>
        <i class="bi bi-box-seam"></i>
      </button>
      <ul class="navbar-nav navbar-align">
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle p-0 d-flex align-items-center" href="#" data-bs-toggle="dropdown">
                <img src="./assets/images/user_void.jpg" class="avatar img-fluid rounded me-md-1" alt="{{ this.authService.currentUserValue.name }}" />
                  <span class="text-dark d-none">{{ this.authService.currentUserValue.nombre }} {{ this.authService.currentUserValue.apellido }} - <span class="capitalize">{{ this.authService.currentClientValue.razonSocial | lowercase }}</span> - <span span class="capitalize">{{ this.authService.currentClientValue.faena.nombre | lowercase  }}</span></span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
                <div class="dropdown-item">
                  <div class="d-flex flex-column">
                    <strong class="text-dark">{{ this.authService.currentUserValue.nombre }} {{ this.authService.currentUserValue.apellido }}</strong>
                    <small class="text-dark">{{ this.authService.currentClientValue.razonSocial }} - {{ this.authService.currentClientValue.faena.nombre }}</small>
                  </div>
                </div>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" [routerLink]="['/mi-perfil']"><em class="align-middle me-1" data-feather="user"></em> Mi perfil</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" [routerLink]="['/administracion-usuarios']"><em class="align-middle me-1" data-feather="settings"></em> Administrar usuarios</a>
                <a class="dropdown-item" [routerLink]="['/condiciones-comerciales']"><em class="align-middle me-1" data-feather="settings"></em> Condiciones comerciales</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" [routerLink]="" (click)="logOut()">Cerrar sesión</a>
            </div>
        </li>
    </ul>
    </div>
</nav>
