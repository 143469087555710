<div id="caja-de-muestras" *ngIf="model && model.input">
    <div class="modal-header">
        <h5 class="modal-title">Solicitud de análisis</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-7">Creado por <b> {{model.input.solicitud.usuario.nombre | titlecase}} {{model.input.solicitud.usuario.apellido | titlecase}}</b> el {{(model.input.solicitud.fechaCreacion) | date :'dd/MM/yyyy'}} a las {{model.input.solicitud.horaCreacion}} hrs. <br>Total de muestras <strong>{{this.model.muestras.length}}</strong></div>
                <div class="col-md-5 ms-auto text-end">Solicitud de Análisis <br>
                    <h1><b> {{model.input.solicitud.solicitudAnalisisId}}</b></h1>
                </div>
            </div>

            <div class="row  text-end">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                    <input type="search" class="form-control mb-4" placeholder="Buscar..." [(ngModel)]="model.filtroDeBusqueda" (input)="filter()">
                </div>
            </div>

            <div class="row justify-content-center ">
                <div class="col">
                    <div class="card" *ngIf="model.muestras.length != 0">
                        <div class="table-responsive">
                            <table class="table table-hover my-0">
                                <tbody>
                                    <tr *ngFor="let muestra of model.muestras">
                                        <td class="align-top col-icono">
                                            <ng-template #popContent style="width: 350px;">
                                                <div class="row" #popContent  >
                                                    <div class="col-6">Toma de muestra </div>
                                                    <div class="col-6 mb-2"> <b> {{muestra.fechaMuestreo | date :'dd/MM/yyyy'}}  </b> </div>
                                                    <div class="col-6">Plan de análisis </div>
                                                    <div class="col-6 mb-2"> <b> {{muestra.planAnalisis.nombre}}</b> </div>
                                                    <div class="col-6">Uso componente </div>
                                                    <div class="col-6 mb-2"> <b>{{muestra.usoTotalComponente?muestra.usoTotalComponente:'-'}} {{muestra.unidadUsoTotalComponente?muestra.unidadUsoTotalComponente:'km'}} </b></div>
                                                    <div class="col-6">Uso lubricante </div>
                                                    <div class="col-6 mb-2"> <b>{{muestra.usoCambioLubricante?muestra.usoCambioLubricante:'-'}} {{muestra.unidadUsoCambioLubricante?muestra.unidadUsoCambioLubricante:'km'}}</b> </div>
                                                    <div class="col-6">Relleno </div>
                                                    <div class="col-6 mb-2"> <b>{{muestra.rellenoDesdeUltimoCambio?muestra.rellenoDesdeUltimoCambio:'-'}} litros</b> </div>
                                                    <hr *ngIf="muestra.lubricante?.lubricanteId ==='0'">
                                                    <div class="col-2" *ngIf="muestra.lubricante?.lubricanteId ==='0'">
                                                       <img src="assets/images/icono-muestra-alerta.svg"  width="36px" alt="">
                                                      </div>
                                                    <div class="col-10" *ngIf="muestra.lubricante?.lubricanteId ==='0'"> Lubricante nuevo, debe enviarse una muestra sin uso</div>
                                                </div>
                                            </ng-template>
                                            <img src="assets/images/icono-muestra.svg" alt="" *ngIf="muestra.lubricante?.lubricanteId !=='0'" [ngbPopover]="popContent" #p="ngbPopover" placement="end"  popoverClass="w250px" triggers="mouseenter:mouseleave">
                                            <img src="assets/images/icono-muestra-alerta.svg" alt="" *ngIf="muestra.lubricante?.lubricanteId ==='0'" [ngbPopover]="popContent" #p="ngbPopover" placement="end"  popoverClass="w250px" triggers="mouseenter:mouseleave">
                                        </td>
                                        <td class="align-top">
                                            <strong>Envase {{muestra.muestraId}}</strong><br> {{muestra.lubricante?.lubricanteId ==='0' ? muestra.otroLubricante:muestra.lubricante?.nombre}}<br>
                                        </td>
                                        <td class="align-top"><strong>Componente ID {{muestra.componenteId}}</strong><br>{{muestra.componente.tipoComponente.nombre}}<br>{{muestra.componente.marca}} {{muestra.componente.modelo}}</td>
                                        <td class="align-top"><strong>Equipo</strong><br>{{muestra.componente.equipo?.tipoEquipo?.nombre}} <br>{{muestra.componente.equipo?.marca}} {{muestra.componente.equipo?.modelo}}</td>
                                        <td class="align-top" >
                                            <strong>{{muestra.observaciones ? "Con Observaciones" : model.estadoMuestra[muestra.estado ? muestra.estado : ''] }}</strong>
                                            &nbsp;<em class="bi bi-exclamation-triangle-fill text-warning" *ngIf="muestra.observaciones" data-toggle="tooltip" data-placement="top" [title]="muestra.observaciones"></em>
                                            <span *ngIf="muestra.observaciones">
                                                <br>{{muestra.observaciones}}
                                            </span>
                                            <br> <span data-toggle="tooltip" data-placement="top" title="{{muestra.fechaIngreso| amLocale:'es' | amTimeAgo}}"> {{ esHoy(muestra.fechaIngreso)? 'Hoy':'El ' + (muestra.fechaIngreso | date :'dd/MM/yyyy') }} a las {{muestra.fechaIngreso | date :'HH:mm'}} hrs.</span>
                                            <br>Por {{muestra.usuario.nombre}} {{muestra.usuario.apellido}}
                                        </td>
                                        <td class="align-middle">
                                          <button type="button" class="btn btn-link" *ngIf="muestra.estado === 'reported'" (click)="loadSampleAndGetPDF(muestra.muestraId)">
                                            <i class="bi bi-file-earmark-arrow-down" style="font-size: 16px;"></i>
                                          </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="alert alert-info text-start" role="alert" *ngIf="model.muestras.length == 0">
                        <div class="alert-message">
                            <h4 class="alert-heading">Nada por aquí!</h4>
                            <p>No hemos encontrado ningun envase que contenga el texto que buscas, por favor ajusta tu búsqueda.</p>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal" (click)="descargaSolicitudMod.descargarOrdenAnalisis(model.input.solicitud.solicitudAnalisisId)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file align-middle me-2"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>Descargar Solicitud de Análisis</button>
    </div>

    <muestra-solicitud-de-analisis #descargaSolicitudMod></muestra-solicitud-de-analisis>

</div>
