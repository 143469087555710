<div class="modal__contanier">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h2>Reporte</h2>
    <a href="javascript:void(0);" [mat-dialog-close]="false">
      <em class="bi bi-x-lg"></em>
    </a>
  </div>
  <div class="modal__content">
    <div class="d-flex flex-column align-items-center text-center gap-2">
    <ng-container >
      <p>Listo, el reporte ha sido creado</p>
  </ng-container>
    
  </div>
</div>
<div class="modal__foot justify-content-md-center gap-3 align-items-center">
  <button class="btn btn-primary btn--small btn--first" (click)="goTolink()">Descargar</button>
</div>
</div>
