import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Objects } from 'src/app/shared';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { SampleBoxDataProvider } from 'src/app/shared/services/sampleBoxDataProvider.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import {MatDialog} from '@angular/material/dialog';
import { Renderer2 } from '@angular/core';
import { ModalSelectFaenaComponent } from 'src/app/home/modal-select-faena/modal-select-faena.component';

@Component({
    selector: 'app-nav-header',
    templateUrl: './nav-header.component.html',
    styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent {

    sampleBoxData?: Objects.SampleBoxData;

    constructor(
        private router: Router,
        public authService: AuthenticationService,
        public appData: SampleBoxDataProvider,
        public dialog: MatDialog,
        private overlayContainer: OverlayContainer,
        private renderer: Renderer2
    ){
        appData.sampleBoxDataObserver.subscribe(data=>{
            this.sampleBoxData = data;
        });
    }

    logOut(): void {
        this.authService.logout();
    }

    cambioClienteFaena(): void {
      const dialogRef = this.dialog.open(ModalSelectFaenaComponent, {
        panelClass: 'ds-modal',
      });

      dialogRef.afterOpened().subscribe(() => {
        const backdrop = this.overlayContainer.getContainerElement().querySelector('.cdk-overlay-backdrop');
        this.renderer.addClass(backdrop, 'ds-modal-backdrop');
      });
    }
}
