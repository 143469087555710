import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { HttpClient, HttpContext } from "@angular/common/http";
import { ServiceSubjectTime } from "../tools/service-subject-time.tools";
import { ServiceSubjectTimeProvide } from "../tools/service-subject-provider.tools";
import { Tools } from "../tools/objects.tools";
import { Enums } from "../models/enums";

@Injectable({
    providedIn: 'root'
})
export class MuestraProviderService {
    subjetGetMuestras: ServiceSubjectTime<any,any>;

    constructor(
        serviceSubject: ServiceSubjectTimeProvide,
        private tools: Tools,
        private http: HttpClient,
    ) {

        this.subjetGetMuestras = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.muestras}`);


    }



  GetMuestras(request: any, opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Promise<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.routes.muestras}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();

       }


}
