import { Injectable } from "@angular/core";
import { ServiceSubjectTimeProvide } from "src/app/shared/tools/service-subject-provider.tools";
import { ServiceSubjectTime } from "src/app/shared/tools/service-subject-time.tools";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { ReportsModels } from "../models/reports.models";
import { Enums, Tools } from "src/app/shared";
import { HttpClient, HttpContext } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    subjetGetPlans:ServiceSubjectTime<ReportsModels.GetPlanesAnalisisRequest,ReportsModels.GetPlanesAnalisisResponse>;
    subjetGetEnsayos:ServiceSubjectTime<ReportsModels.GetEnsayosRequest,ReportsModels.GetEnsayosResponse>;
    subjetGetReport:ServiceSubjectTime<ReportsModels.GetReportRequest,ReportsModels.GetReportResponse>;
    subjetSetMuestraUpdate:ServiceSubjectTime<ReportsModels.SetMuestraUpdateRequest,ReportsModels.SetMuestraUpdateResponse>;


    constructor(
        private http: HttpClient,
        private tools: Tools,
        serviceSubject: ServiceSubjectTimeProvide,
    ) {
        this.subjetGetPlans = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.plananalisis}`);
        this.subjetGetEnsayos = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.ensayos}`);
        this.subjetGetReport = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.report}`);
        this.subjetSetMuestraUpdate = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.updateSample}`);

    }

    getPlanesAnalisis(request: ReportsModels.GetPlanesAnalisisRequest,  opts:{force:boolean, fnCallback?:()=>{}, spinner: boolean} = {force: false, spinner: false}): Observable<ReportsModels.GetPlanesAnalisisResponse> {
        return this.subjetGetPlans._httpGet(request,opts)
    }

    GetEnsayos(request: ReportsModels.GetEnsayosRequest, opts:{force:boolean, fnCallback?:()=>{}, spinner: boolean} = {force: false, spinner: false}): Observable<ReportsModels.GetEnsayosResponse> {
        return this.subjetGetEnsayos._httpGet(request,opts);
    }

    getReport(request: ReportsModels.GetReportRequest,  opts:{force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean} = {force: false, spinner: false}): Observable<ReportsModels.GetReportResponse> {
        return this.subjetGetReport._httpGet(request,opts)
    }

    setMuestraUpdate(request: ReportsModels.SetMuestraUpdateRequest,  opts:{force:boolean, fnCallback?:()=>{}, spinner: boolean} = {force: false, spinner: false}): Observable<ReportsModels.SetMuestraUpdateResponse> {
        return this.subjetSetMuestraUpdate.httpPost(request,opts)
    }



    GetImageSave(request: ReportsModels.GetPdfSaveRequest, opts: { spinner: boolean } = { spinner: true }): Promise<ReportsModels.GetPdfSaveResponse> {
        return this.http.get<ReportsModels.GetPdfSaveResponse>(`${environment.apiUrl}${environment.routes.imageSave}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    PutPdfFile(url: string, file: any, opts: { spinner: boolean } = { spinner: true }): Promise<any> {
        const result = fetch(url,{
            method: 'PUT',
            body: file
        })
        return result;
    }


}
