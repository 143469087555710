import { Injectable } from '@angular/core';
import { Subject,  Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    storeData:{[key:string]:any}={};


    setData(key:string,data:any):any{
        this.storeData[key]=data;
    }

    getData(key:string):any | undefined{
        return this.storeData[key];
    }

}
