import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { StorageTools } from 'src/app/shared/tools/storage.tools';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-oauth-callback',
    templateUrl: './oauth.callback.component.html',
    styleUrls: ['./oauth.callback.component.css'],
    encapsulation: ViewEncapsulation.None,
    host: { 'class': 'app-oauth-callback' }
})
export class OauthCallbackComponent implements OnInit {

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private storage: StorageTools,
        private oauthService: OAuthService) {
    }

    ngOnInit(): void {
                        
    }
}
