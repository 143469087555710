import { Component, OnInit, Input, Inject } from '@angular/core';

import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { RegistraMuestraModels } from '../editar-models/editar-muestra.models';
import { RegistraMuestraViewModels } from '../editar-models/editar-muestra.view.models';
import { RegistraService } from '../editar-services/editar-muestra.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { SampleBoxDataProvider } from '../../../shared/services/sampleBoxDataProvider.service';
import { Objects } from 'src/app/shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../../../shared/services/spinner.service';

@Component({
    selector: 'editar-muestra-componente',
    templateUrl: './editar-muestra.component.html',
    styleUrls: ['./editar-muestra.component.css']
})
export class EditarMuestraComponent implements OnInit {



    @Input() public inputMuestra?:Objects.RegistroMuestra;

    @Input()
    model: RegistraMuestraViewModels.ViewModel = {
        lubricanteOtro: {
            nombre: "Otro",
            lubricanteId: "0",
            code: "",
            tipo: undefined
        },
        textoLubricanteBuscado: '',
        lubricantes: [],
        lubricantesTodos: [],
        planesAnalisis: [],
        lubricantesFiltrados: [],
        verOtrosLubricantes: false,
        registroMuestra: {
            muestraId: '',
            otroLubricante: '',
            fechaMuestreo: ''
        },
        cargando:{
            lubricantes:false,
            planAnalisis:false
        },
        todaysdate:new Date()
    };
    constructor(
        private authService: AuthenticationService,
        private service: RegistraService,
        public appData: SampleBoxDataProvider,
        public dialogRef: NgbActiveModal,
        private spinner: SpinnerService

    ) {

    }

    ngOnInit(): void {

        const componenteId = this.model.registroMuestra.componente?.componenteId;
        if (componenteId) {
            this.getMuestraInfo(componenteId);
            this.getLubricantes();
        }
    }
    ngAfterViewInit() {
    }

    getLubricantes(){
        this.service.GetLubricantes()
        .then(response => {
            if (response.success) {
                this.model.lubricantes = response.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
                this.model.lubricantesTodos=this.model.lubricantes
            } else {
            }
        });
    }

    forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const forbidden = nameRe.test(control.value);
            return forbidden ? { forbiddenName: { value: control.value } } : null;
        };
    }

    searchLubricante(term: string, item: RegistraMuestraModels.Lubricante) {
        return item.nombre.toLowerCase().indexOf(term.toLowerCase()) > -1 || item.lubricanteId.toString().toLowerCase().indexOf(term.toLowerCase()) > -1;
    }

    filtrarOtrosLubricantes() {
        let term = this.model.textoLubricanteBuscado;
        this.model.lubricantesFiltrados = this.model.lubricantesTodos.filter(item => {
            return term.trim() === '' || item.nombre.toLowerCase().indexOf(term.toLowerCase()) > -1 || item.lubricanteId.toString().toLowerCase().indexOf(term.toLowerCase()) > -1;
        });
    }

    getMuestraInfo(componenteId: string): void {
        const request: RegistraMuestraModels.GetMuestraInfoRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId,
            componenteId: componenteId
        };
        this.spinner.off();
        this.model.cargando.planAnalisis=true;
        this.service.GetMuestraInfo(request)
            .then(response => {
                if (response.success) {
                    this.model.componente = response.data.componente;
                    this.model.lubricantes = response.data.lubricantes;
                    this.model.planesAnalisis = response.data.planesAnalisis;
                    this.model.cargando.planAnalisis=false;
                    this.model.lubricantesTodos = response.data.lubricantesTodos;
                    this.model.lubricantesFiltrados = response.data.lubricantes;
                    this.model.registroMuestra.componente = response.data.componente;

                } else {
                }
            })
            .catch(error => {
            });
    }

    verOtrosLubricantes(select: NgSelectComponent): void {
        this.model.verOtrosLubricantes = true;
        this.service.GetLubricantes().then(resp=>{
            if(resp.data){
                resp.data.push(this.model.lubricanteOtro);
                this.model.lubricantesFiltrados=resp.data;
            }
        });
    }
    seleccionarOtroLubricante(select: NgSelectComponent){
        this.model.registroMuestra.lubricante=this.model.lubricanteOtro;
        select.close();
    }

    verLubricantesRecomendados(select: NgSelectComponent): void {
        this.model.verOtrosLubricantes = false;
        this.model.lubricantesFiltrados = this.model.lubricantes;
    }


    isEmpty(value?: string): boolean {
        return !value || value.trim().length == 0;
    }

    isRequeredUnidadUsoCambioLubricante(): boolean {
        return !this.isEmpty(this.model.registroMuestra.usoCambioLubricante) && !this.model.registroMuestra.unidadUsoCambioLubricante;
    }
    isRequeredUnidadUsoTotalComponente(): boolean {
        return !this.isEmpty(this.model.registroMuestra.usoTotalComponente) && !this.model.registroMuestra.unidadUsoTotalComponente;
    }

    isRequeredRadiosUnidades(): boolean {
        return this.isRequeredUnidadUsoTotalComponente() || this.isRequeredUnidadUsoCambioLubricante();
    }

    cancelar(){
        this.dialogRef.close({result:'cancelar'});
    }

    actualizarMuestra(): void {
        this.model.registroMuestra.fechaIngreso=new Date();
        this.model.registroMuestra.usuario = this.authService.current_user;
        this.dialogRef.close({result:'actualizar',muestra:this.model.registroMuestra});
    }

    eliminarMuestra(){
        this.dialogRef.close({result:'eliminar',muestra:this.model.registroMuestra});
    }
}
