<div class="d-flex justify-content-between align-items-center mb-3">
  <h2 mat-dialog-title>Comentario Muestra</h2>
  <a mat-dialog-close (click)="close()">
    <em class="bi bi-x-lg"></em>
  </a>
</div>
<div mat-dialog-content class="d-flex flex-column">
  <textarea name="" id="" style="width: 100%; display: flex;" rows="2" maxlength="75" [(ngModel)]="comentario"></textarea>
  <span>({{comentario?.length ?? 0 }} de 75 carácteres)</span>
</div>
<div mat-dialog-actions class="d-flex justify-content-between mt-3">
  <button class="btn btn-secondary" (click)="close()">Cancelar</button>
  <button class="btn btn-primary" (click)="guardar()" [disabled]="!comentario || comentario === ''">Guardar</button>
</div>