import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'app-mat-dialog-content',
  templateUrl: './mat-dialog-content.component.html',
  styleUrls: ['./mat-dialog-content.component.css']
})
export class MatModalConfirmComponent {


  constructor(
    public dialogRef: MatDialogRef<MatModalConfirmComponent>,
    @ Inject(MAT_DIALOG_DATA) public data: {title:string,msg:string,titleButtonOK:string}) {}


    cancelar() {
      this.closeModal();
    }

    confirmar(){
      this.dialogRef.close(true);
    }

    closeModal() {
      this.dialogRef.close(false);
    }

  }

