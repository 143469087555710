import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "src/app/shared/auth/authentication.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { ComponenteVerComponent } from "../../componente-ver/componente-ver-views/componente-ver.component";
import { ComponenteNuevoModels } from "../componente-nuevo-models/componente-nuevo.models";
import { ComponenteNuevoViewModels } from "../componente-nuevo-models/componente-nuevo.view.models";
import { ComponenteNuevoService } from "../componente-nuevo-services/componente-nuevo.service";
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Router } from "@angular/router";
import { Objects } from "src/app/shared";
import { LogService } from "src/app/shared/services/log.service";
import { ToastService } from "src/app/shared/components/ng-toast/toast-service";

@Component({
    selector: 'app-componente-nuevo',
    templateUrl: './componente-nuevo.component.html',
    styleUrls: ['./componente-nuevo.component.css']
})
export class ComponenteNuevoComponent implements OnInit {

    @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete!: MatAutocompleteTrigger;
    myControl = new FormControl();
    filteredOptions!: Observable<ComponenteNuevoModels.Equipo[]>;

    @Input() compDesdeAsignarEquipo: ComponenteNuevoModels.ComponenteInput = {
        componente: {
            equipo: {
                equipoId: '',
                tipoEquipo: {
                    tipoEquipoId: '',
                    nombre: ''
                }
            },
            marca: '',
            ubicacion: '',
            clienteId: '',
            faenaId: '',
            descriptor: '',
            tipoComponente: {
                tipoComponenteId: '',
                nombre: ''
            },
            componenteId: '',
            modelo: ''
        },
        tieneEquipoCargado: false,
        estaEditado: false,
        sinEquipo: false,
        vieneDesdeBuscarMuestra: false
    }

    model: ComponenteNuevoViewModels.ViewModel = {
        componente: {
            equipo: {
                equipoId: '',
                tipoEquipo: {
                    tipoEquipoId: '',
                    nombre: ''
                }
            },
            marca: '',
            ubicacion: '',
            clienteId: '',
            faenaId: '',
            descriptor: '',
            tipoComponente: {
                tipoComponenteId: '',
                nombre: ''
            },
            componenteId: '',
            modelo: ''
        },
        equipo: {
            clienteId: '',
            faenaId: '',
            equipoId: '',
            tipoEquipo: {
                tipoEquipoId: '',
                nombre: ''
            },
            marca: '',
            modelo: '',
            descriptor: ''
        },
        equipoFiltro: '',
        equiposBuscador: [],
        lubricantes: [],
        tipoComponentes: [],
        marcas: [],
        modelos: [],
        verOtraMarca: false,
        verOtroModelo: false,
        otraMarca: '',
        otroModelo: '',
        tieneEquipo: false,
        estaEditando: false,
        idError: false,
        errorSetComponente: '',
        tieneErrorSetComponente: false,
        loadingComponenteId: false,
        idValidado: false,
        loadingMarca: false,
        loadingLubricante: false,
        loadingEquipos: false,
        loadingTipoEquipo: false,
        tipoEquipos: [],
        marcasEquipo: [],
        modelosEquipo: [],
        equipoAsociado: false,
        equipoInexistente: false,
        equipoInvalido: false,
        crearEquipo: false,
        verMasCamposEquipo: false,
        verOtroModeloEquipo: false,
        verOtraMarcaEquipo: false,
        otraMarcaEquipo: '',
        otroModeloEquipo: '',
        selectable: true,
        removable: true,
        idsEquipos: [],
        idEquipoError: false,
        idEquipoVacio: false,
        errorSinEquipo: false,
        vieneDesdeBuscarMuestra: false,
    }

    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    constructor(
        private authService: AuthenticationService,
        public dialogRef: NgbActiveModal,
        private modalService: NgbModal,
        private spinner: SpinnerService,
        private service: ComponenteNuevoService,
        private eventEmiiterSrv: EventEmitterService,
        private router: Router,
        private _logService: LogService,
        private toastService: ToastService
    ) { }

    ngOnInit(): void {

        this.spinner.off(true);
        this.model.componenteInmutado = Object.assign({}, this.compDesdeAsignarEquipo.componente);
        this.model.componente = this.compDesdeAsignarEquipo.componente ? this.compDesdeAsignarEquipo.componente : this.limpiaComponente();
        this.model.equipo = this.compDesdeAsignarEquipo.equipo ? this.compDesdeAsignarEquipo.equipo : this.model.equipo = this.limpiaEquipo();;

        this.model.equipoAntiguo = this.compDesdeAsignarEquipo.equipoAntiguo;
        this.model.estaEditando = this.compDesdeAsignarEquipo.estaEditado;
        this.model.vieneDesdeBuscarMuestra = this.compDesdeAsignarEquipo.vieneDesdeBuscarMuestra;
        if (this.compDesdeAsignarEquipo.equipo?.equipoId) {
            this.model.tieneEquipo = true;
            this.model.equipoAsociado = true;
        }
        if (this.model.vieneDesdeBuscarMuestra) {
            this.model.tieneEquipo = false;
            this.model.equipoAsociado = true;
        }

        this.getMarcasComponente();
        this.getTiposEquipos();
        this.getEquipos();

        this.configureWatchers();
    }

    validaId(): void {
        this.model.componente.componenteId = this.model.componente.componenteId.trim();

        this.model.idError = false;
        this.model.loadingComponenteId = true;
        let existeComponente: boolean = false;
        if (this.model.componente.componenteId.trim() !== '') {
            const request: ComponenteNuevoModels.GetComponenteVerRequest = {
                clienteId: this.authService.currentClientValue.clienteId,
                componenteId: this.model.componente.componenteId.trim(),
                faenaId: this.authService.currentClientValue.faena.faenaId
            };
            this.service.GetComponenteInfo(request)
                .then(response => {
                    if (response.success) {
                        if (response.data.length != 0) {
                            existeComponente = true;
                        }
                    }
                })
                .finally(() => {
                    this.model.idError = existeComponente;
                    this.model.loadingComponenteId = false;
                    this.model.idValidado = true;
                })
        } else {
            this.model.idError = false;
            this.model.loadingComponenteId = false;
        }
    }

    limipiaId(): void {
        this.model.idError = false;
        this.model.idValidado = false;
    }

    validaIdEquipo(): void {
        this.model.idEquipoVacio = false;
        this.model.idEquipoError = false;
        if (this.model.idsEquipos.includes(this.model.equipo.equipoId)) {
            this.model.idEquipoError = true;
        }
    }

    cambiaLubricante(editando: boolean = false): void {
        this.model.componente.lubricante = this.model.lubricantes.find(lub => lub.lubricanteId == this.model.componente.lubricante?.lubricanteId);
    }

    cambiaTipoComponente(editando: boolean = false): void {
        this.model.componente.tipoComponente = this.model.tipoComponentes.find(tipo => tipo.tipoComponenteId === this.model.componente.tipoComponente.tipoComponenteId)!;
    }

    cambiaMarca(editando: boolean = false): void {
        if (this.model.componente.marca === 'otro') {
            this.model.modelos = [];
            this.model.componente.modelo = '';
            this.model.verOtraMarca = true;
            this.model.verOtroModelo = false;
            this.model.otroModelo = '';
        } else {
            this.model.modelos = [];
            this.model.componente.modelo = editando ? this.model.componente.modelo : '';
            this.model.modelos = this.model.marcas.find(element => element.nombre === this.model.componente.marca)?.modelo!;
            this.model.verOtraMarca = false;
            this.model.verOtroModelo = false;
            this.model.otroModelo = '';
        }
    }

    cambiaMarcaEquipo(): void {
        if (this.model.equipo.marca === 'otro') {
            this.model.modelosEquipo = [];
            this.model.equipo.modelo = '';
            this.model.verOtraMarcaEquipo = true;
            this.model.verOtroModeloEquipo = false;
            this.model.otroModeloEquipo = '';
        } else {
            this.model.modelosEquipo = [];
            this.model.equipo.modelo = '';
            this.model.modelosEquipo = this.model.marcasEquipo.find(element => element.nombre === this.model.equipo.marca)?.modelo!;
            this.model.verOtraMarcaEquipo = false;
            this.model.verOtroModeloEquipo = false;
            this.model.otroModeloEquipo = '';
        }
    }

    cambiaModeloEquipo(): void {
        if (this.model.equipo.modelo === 'otro') {
            this.model.equipo.modelo = '';
            this.model.verOtroModeloEquipo = true;
        } else {
            this.model.verOtroModeloEquipo = false;
        }
    }

    cambiaModelo(): void {
        if (this.model.componente.modelo === 'otro') {
            this.model.verOtroModelo = true;
        } else {
            this.model.verOtroModelo = false;
        }
    }

    enterInputSearch(): void {
        let equipos: ComponenteNuevoModels.Equipo[] = [];
        if (typeof this.model.equipoFiltro === 'string') {
            equipos = this.model.equiposBuscador.filter(
                option => option.equipoId.toLowerCase().includes(this.model.equipoFiltro.toLowerCase())
            );

            if (equipos.length == 0) {
                this.model.equipoInexistente = true;
            } else if (equipos.length > 1) {
                this.model.equipoInvalido = true;
            } else {
                this.model.equipoInexistente = false;
                this.model.equipoInvalido = false;
                this.model.equipo = this.limpiaEquipo();
                this.model.equipo = equipos[0];
                this.model.tieneEquipo = true;
            }
        }
    }

    onSelectionChanged(event: MatAutocompleteSelectedEvent): void {
        this.selectedRow(event.option.value);
    }

    selectedRow(equipo: ComponenteNuevoModels.Equipo): void {
        this.model.equipo = this.limpiaEquipo();
        this.model.equipo = equipo;
        this.model.tieneEquipo = true;
    }

    displayFn(equipo: ComponenteNuevoModels.Equipo): string {
        return equipo ? `${equipo.equipoId} - ${equipo.tipoEquipo.nombre} ${equipo.marca} ${equipo.modelo}` : '';
    }

    private filter(name: string): ComponenteNuevoModels.Equipo[] {
        this.model.equipoInexistente = false;
        this.model.equipoInvalido = false;
        const filterValue = name.toLowerCase();
        let equiposFiltrados: ComponenteNuevoModels.Equipo[] = [];
        equiposFiltrados = this.filtroEquipo(filterValue).slice(0, 10);
        return equiposFiltrados;
    }

    private filtroEquipo(filterValue: string): ComponenteNuevoModels.Equipo[] {
        filterValue = filterValue.toLowerCase();
        return this.model.equiposBuscador.filter(option => option.equipoId.toLowerCase().includes(filterValue));
    }

    cambioAsociado(): void {
        this.model.errorSinEquipo = false;
        this.model.equipoInexistente = false;
        this.model.equipoInvalido = false;
        this.model.equipoAsociado = !this.model.equipoAsociado;
        this.model.crearEquipo = false;
        this.model.tieneEquipo = false;
        this.model.equipoFiltro = '';
        this.model.equipo = this.limpiaEquipo();
    }

    cambiaTipoEquipo(): void {
        this.model.errorSinEquipo = false;
    }

    cambiarEquipo(): void {
        this.model.tieneEquipo = false;
        this.model.equipoFiltro = '';
        this.model.equipo = this.limpiaEquipo();
    }

    private limpiaEquipo(): ComponenteNuevoModels.Equipo {
        let equipo: ComponenteNuevoModels.Equipo = {
            clienteId: '',
            faenaId: '',
            equipoId: '',
            tipoEquipo: {
                tipoEquipoId: '',
                nombre: ''
            },
            marca: '',
            modelo: '',
            descriptor: '',
            alias: [],
            tags: [],
            componentes: []

        }
        return equipo;
    }

    private limpiaComponente(): ComponenteNuevoModels.Componente {
        let componente: ComponenteNuevoModels.Componente = {
            equipo: {
                equipoId: '',
                tipoEquipo: {
                    tipoEquipoId: '',
                    nombre: ''
                }
            },
            marca: '',
            ubicacion: '',
            clienteId: '',
            faenaId: '',
            descriptor: '',
            tipoComponente: {
                tipoComponenteId: '',
                nombre: ''
            },
            componenteId: '',
            modelo: ''
        }
        return componente;
    }

    errorTipoComponente() {
        this.model.tieneErrorSetComponente = false;
    }

    guardarComponente(): void {

        if (this.model.componente.componenteId.trim() == '') {
        } else if (this.model.crearEquipo && this.model.equipo.equipoId.trim() == '') {

            this.model.idEquipoVacio = true;
        }
        else {
            if (this.model.equipoFiltro == '' && this.model.equipo.tipoEquipo.tipoEquipoId == '' && !this.model.equipo.equipoId) {

                this.model.errorSinEquipo = true;
            } else {
                this.spinner.on();
                this.model.componente.clienteId = this.authService.currentClientValue.clienteId;
                this.model.componente.faenaId = this.authService.currentClientValue.faena.faenaId;

                if (this.model.equipoAsociado) {
                    if (this.model.crearEquipo) {
                        let componenteEquipoNuevo = this.generarComponenteNuevoEquipo();
                        const request: ComponenteNuevoModels.SetComponenteNuevoEquipoRequest = {
                            componente: componenteEquipoNuevo
                        };
                        this.service.setComponenteNuevoEquipo(request).subscribe(data => {
                            console.log("setComponenteNuevoEquipo response => ", data);
                            if (data.success) {
                                let rq: Objects.LogInput = {
                                    accion: 'New',
                                    elemento: this.model.componente.componenteId,
                                    tipoElemento: 'Componente',
                                    usuario: this.authService.usuario.nombre,
                                    usuarioId: this.authService.usuario.userId,
                                    ambiente: 'Web Cliente'
                                }
                                this._logService.createLog(rq, true);

                                this.finalizarGuardar(componenteEquipoNuevo.equipo.equipoId);
                            } else {
                                this.model.tieneErrorSetComponente = true;
                                if (data.message.startsWith("PLAN_ANALISIS_NOT_FOUND")) {
                                    this.model.errorSetComponente = data.message.substr(data.message.indexOf(";") + 2);
                                } else {
                                    this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                                }
                                this.spinner.off();
                            }
                        });
                    } else {
                        let componeteEquipoAsignado = this.generarComponente();
                        const request: ComponenteNuevoModels.SetComponenteNuevoRequest = {
                            componente: componeteEquipoAsignado
                        };
                        this.service.setComponenteNuevo(request).subscribe(data => {
                            console.log("setComponenteNuevoEquipo response => ", data);
                            if (data.success) {

                                let rq: Objects.LogInput = {
                                    accion: 'New',
                                    elemento: this.model.componente.componenteId,
                                    tipoElemento: 'Componente',
                                    usuario: this.authService.usuario.nombre,
                                    usuarioId: this.authService.usuario.userId,
                                    ambiente: 'Web Cliente'
                                }
                                this._logService.createLog(rq, true);

                                this.finalizarGuardar(componeteEquipoAsignado.equipo.equipoId!);
                            } else {
                                this.model.tieneErrorSetComponente = true;
                                if (data.message.startsWith("PLAN_ANALISIS_NOT_FOUND")) {
                                    this.model.errorSetComponente = data.message.substr(data.message.indexOf(";") + 2);
                                } else {
                                    this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                                }
                                this.spinner.off();
                            }
                        });
                    }
                } else {
                    let componeteEquipoNoAsignado = this.generarComponente(true);
                    const request: ComponenteNuevoModels.SetComponenteNuevoRequest = {
                        componente: componeteEquipoNoAsignado
                    };
                    this.service.setComponenteNuevo(request).subscribe(data => {
                        console.log("setComponenteNuevoEquipo response => ", data);
                        if (data.success) {

                            let rq: Objects.LogInput = {
                                accion: 'New',
                                elemento: this.model.componente.componenteId,
                                tipoElemento: 'Componente',
                                usuario: this.authService.usuario.nombre,
                                usuarioId: this.authService.usuario.userId,
                                ambiente: 'Web Cliente'
                            }
                            this._logService.createLog(rq, true);

                            this.finalizarGuardar("");
                        } else {
                            this.model.tieneErrorSetComponente = true;
                            if (data.message.startsWith("PLAN_ANALISIS_NOT_FOUND")) {
                                this.model.errorSetComponente = data.message.substr(data.message.indexOf(";") + 2);
                            } else {
                                this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                            }
                            this.spinner.off();
                        }
                    });
                }
            }
        }

    }

    private generarComponenteNuevoEquipo(): ComponenteNuevoModels.ComponenteNuevoEquipo {
        console.log("this.model.componente.modelo", this.model.componente.modelo);
        let componente: ComponenteNuevoModels.ComponenteNuevoEquipo = {
            equipo: this.model.equipo,
            componenteId: this.model.componente.componenteId.trim(),
            marca: this.model.componente.marca != 'otro' ? this.model.componente.marca : this.model.otraMarca.trim(),
            modelo: this.model.componente.modelo != 'otro' ? this.model.componente.modelo : this.model.otroModelo.trim(),
            modeloOtro: this.model.componente.modelo == 'otro' ? true : false,
            ubicacion: this.model.componente.ubicacion ? this.model.componente.ubicacion.trim() : '',
            descriptor: this.model.componente.descriptor ? this.model.componente.descriptor.trim() : '',
            clienteId: this.model.componente.clienteId.trim(),
            faenaId: this.model.componente.faenaId.trim(),
            solicitante: this.model.componente.faenaId != '' ? this.model.componente.faenaId : this.authService.currentClientValue.faena.faenaId,
            tipoComponente: this.model.componente.tipoComponente,
            lubricante: this.model.componente.lubricante
        };
        componente.equipo.clienteId = this.authService.currentClientValue.clienteId;
        componente.equipo.faenaId = this.authService.currentClientValue.faena.faenaId;
        componente.equipo.componentes = [componente.componenteId];
        return componente;
    }


    private generarComponente(noAsignado: boolean = false): ComponenteNuevoModels.Componente {
        console.log("this.model.componente.modelo", this.model.componente.modelo);
        let componente: ComponenteNuevoModels.Componente = {
            equipo: {
                equipoId: noAsignado ? undefined : this.model.equipo.equipoId.trim(),
                tipoEquipo: {
                    tipoEquipoId: this.model.equipo.tipoEquipo.tipoEquipoId,
                    nombre: this.model.equipo.tipoEquipo.nombre.trim()
                }
            },
            componenteId: this.model.componente.componenteId.trim(),
            marca: this.model.componente.marca != 'otro' ? this.model.componente.marca : this.model.otraMarca.trim(),
            modelo: this.model.componente.modelo != 'otro' ? this.model.componente.modelo : this.model.otroModelo.trim(),
            modeloOtro: this.model.componente.modelo == 'otro' ? true : false,
            descriptor: this.model.componente.descriptor ? this.model.componente.descriptor.trim() : '',
            ubicacion: this.model.componente.ubicacion ? this.model.componente.ubicacion.trim() : '',
            clienteId: this.model.componente.clienteId.trim(),
            faenaId: this.model.componente.faenaId.trim(),
            solicitante: this.model.componente.faenaId != '' ? this.model.componente.faenaId : this.authService.currentClientValue.faena.faenaId,
            tipoComponente: this.model.componente.tipoComponente,
            lubricante: this.model.componente.lubricante
        };
        return componente;
    }

    private generaEquipo(equipoGet: ComponenteNuevoModels.EquipoGet, componentes: string[]): ComponenteNuevoModels.Equipo {
        console.log("equipoGet.marca", equipoGet.marca);
        let equipo = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId,
            equipoId: equipoGet.equipoId.trim(),
            tipoEquipo: equipoGet.tipoEquipo,
            marca: equipoGet.marca != 'otro' ? this.model.componente.marca : this.model.otraMarca.trim(),
            modelo: equipoGet.modelo != 'otro' ? equipoGet.modelo : this.model.otroModeloEquipo.trim(),
            modeloOtro: equipoGet.modelo == 'otro',
            descriptor: equipoGet.descriptor.trim(),
            alias: equipoGet.alias,
            tags: equipoGet.tags,
            componentes: componentes
        }

        return equipo;
    }

    private finalizarGuardar(equipoId: string): void {
        if (this.model.estaEditando) {
            if (this.model.equipoAntiguo?.equipoId != equipoId) {
                this.cambiarComponenteDeEquipo();
                this.compDesdeAsignarEquipo.sinEquipo = true;
            }
            this.compDesdeAsignarEquipo.componente = this.model.componente;
            this.compDesdeAsignarEquipo.equipo = this.model.equipo;
            this.compDesdeAsignarEquipo.vieneDesdeEditar = true;
            this.compDesdeAsignarEquipo.estaEditado = true;

            this.spinner.off();
            this.dialogRef.close();
            const modalRef = this.modalService.open(ComponenteVerComponent, {
                centered: true,
                size: 'xl'
            });
            modalRef.componentInstance.componenteInput = Object.assign({}, this.compDesdeAsignarEquipo);
        } else if (this.compDesdeAsignarEquipo.vieneDesdeBuscarMuestra) {
            this.dialogRef.close();
            let data = JSON.stringify(this.model.componente);
            this.router.navigate(['/agregar-muestra'], { queryParams: { data: data }, skipLocationChange: true });
        }
        else {
            this.spinner.off();
            this.dialogRef.close();
            this.eventEmiiterSrv.sendResult("FLOW_MANTENEDOR", { accion: 'NUEVO', id: this.model.componente.componenteId });
        }
    }


    private cambiarComponenteDeEquipo() {
        let ret = false;
        if (this.model.equipoAntiguo?.equipoId) {
            const request: ComponenteNuevoModels.GetEquipoRequest = {
                clienteId: this.authService.currentClientValue.clienteId,
                faenaId: this.authService.currentClientValue.faena.faenaId,
                equipoId: this.model.equipoAntiguo?.equipoId!
            };

            this.service.GetEquipoInfo(request)
                .then(response => {
                    if (response.success) {
                        const requestEquipo: ComponenteNuevoModels.SetEquipoNuevoRequest = {
                            equipo: this.cambiaComponentes(response.data[0])
                        };
                        this.service.setEquipoCambio(requestEquipo).subscribe(data => {
                            if (data.success) {

                                let rq: Objects.LogInput = {
                                    accion: 'Update',
                                    elemento: this.model.componente.componenteId,
                                    tipoElemento: 'Cambio componente equipo',
                                    subElemento: this.model.equipo.equipoId,
                                    tipoSubElemento: "Equipo",
                                    usuario: this.authService.usuario.nombre,
                                    usuarioId: this.authService.usuario.userId,
                                    ambiente: 'Web Cliente'
                                }
                                this._logService.createLog(rq, true);

                                ret = true;
                            } else {
                                this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                            }
                        });
                    } else {
                        this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                    }
                })
        }
        return ret;
    }

    private cambiaComponentes(equipo: ComponenteNuevoModels.EquipoGet) {
        let componentes: string[] = [];
        if (equipo.componentes) {
            equipo.componentes.forEach(element => {
                if (element.componenteId != this.model.componente.componenteId) {
                    componentes.push(element.componenteId);
                }
            });
        }

        return this.generaEquipo(equipo, componentes);
    }

    cancelarCrearEquipo(): void {
        this.model.equipoInexistente = false;
        this.model.equipoInvalido = false;
        this.model.crearEquipo = false;
        this.limpiaEquipo();
    }

    cancelar(): void {
        this.dialogRef.close();
        if (this.compDesdeAsignarEquipo.vieneDesdeVerComponente) {
            const modalRef = this.modalService.open(ComponenteVerComponent, {
                centered: true,
                size: 'xl'
            });
            modalRef.componentInstance.componenteInput.componente = Object.assign({}, this.model.componenteInmutado);
            modalRef.componentInstance.componenteInput.equipo = Object.assign({}, this.model.equipoAntiguo);
            modalRef.componentInstance.componenteInput.vieneDesdeEditar = Object.assign({}, this.model.estaEditando);
        }
    }

    addAlia(event: any): void {
        const value = (event.value || '').trim();
        if (value) {
            this.model.equipo.alias?.push(value);
        }
        event.input.value = '';
    }

    removeAlia(alia: string): void {
        const index = this.model.equipo.alias?.indexOf(alia);

        if (index != undefined && index >= 0) {
            this.model.equipo.alias?.splice(index, 1);
        }
    }

    addTag(event: any): void {
        const value = (event.value || '').trim();
        if (value) {
            this.model.equipo.tags?.push(value);
        }
        event.input.value = '';
    }

    removeTag(tag: string): void {
        const index = this.model.equipo.tags?.indexOf(tag);

        if (index != undefined && index >= 0) {
            this.model.equipo.tags?.splice(index, 1);
        }
    }

    private getMarcasComponente(): void {
        this.model.loadingMarca = true;
        this.model.loadingLubricante = true;
        let tipoMarcaModelo: ComponenteNuevoModels.tipoMarcaModeloComponente;
        const request: ComponenteNuevoModels.GetMarcaComponenteRequest = {
        };
        this.service.GetMarcaComponenteInfo(request)
            .then(response => {
                if (response.success) {
                    tipoMarcaModelo = response.data;
                    this.model.tipoComponentes = tipoMarcaModelo.tiposComponentes.sort((a, b) => a.nombre.localeCompare(b.nombre));
                    this.model.marcas = tipoMarcaModelo.marcas.sort((a, b) => a.nombre.localeCompare(b.nombre));
                } else {
                    this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                }
            })
            .finally(() => {
                if (this.model.componente.marca) {
                    this.cambiaMarca(true)
                }
                this.model.loadingMarca = false;

                if (this.model.componente.tipoComponente) {
                    this.cambiaTipoComponente(true);
                }
            })

        this.service.GetLubricantes()
            .then(response => {
                if (response.success) {
                    this.model.lubricantes = response.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
                } else {
                    this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                }
            })
            .finally(() => {
                this.model.loadingLubricante = false;
                if (this.model.componente.lubricante) {
                    this.cambiaLubricante(true);
                }
            })
    }

    private getEquipos(): void {
        this.model.loadingEquipos = true;
        const request: ComponenteNuevoModels.GetEquiposRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId
        };

        this.service.GetEquipos(request)
            .then(response => {
                if (response.success) {
                    this.model.equiposBuscador = response.data;
                } else {
                    this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                }
            })
            .finally(() => {
                if (this.model.equiposBuscador) {
                    this.model.equiposBuscador.forEach(element => {
                        this.model.idsEquipos.push(element.equipoId);
                    });
                }
                this.model.loadingEquipos = false;
            })
    }

    private getTiposEquipos(): void {
        this.model.loadingTipoEquipo = true;
        let tipoMarcaModelo: ComponenteNuevoModels.tipoMarcaModeloEquipo;
        const request: ComponenteNuevoModels.GetMarcaEquipoRequest = {
        };
        this.service.GetMarcaEquipoInfo(request)
            .then(response => {
                if (response.success) {
                    tipoMarcaModelo = response.data;
                    this.model.tipoEquipos = tipoMarcaModelo.tiposEquipos.sort((a, b) => a.nombre.localeCompare(b.nombre));
                    this.model.marcasEquipo = tipoMarcaModelo.marcas.sort((a, b) => a.nombre.localeCompare(b.nombre));
                } else {
                    this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                }
            })
            .finally(() => {
                this.model.loadingTipoEquipo = false;
            })
    }

    private configureWatchers(): void {
        this.filteredOptions = this.myControl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this.filter(name) : [])
            );
    }
}
