import { Component, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-registro-modal-comentario',
    templateUrl: './registro-modal-comentario.component.html',
    styleUrls: ['./registro-modal-comentario.component.css']
})
export class RegistroModalComentarioComponent implements OnInit {

    comentario?: string = ''

    constructor(
        public dialogRef: MatDialogRef<RegistroModalComentarioComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            comentario: string
        }
    ) {
        if (this.data) {
            this.comentario = this.data.comentario;
        }
    }

    ngOnInit(): void {

    }

    cancelar() {

    }

    guardar(): void {
        this.dialogRef.close({ status: 'saved', data: { comentario: this.comentario } });
    }

    close(): void {
        this.dialogRef.close({ status: 'cancel', data: { comentario: '' } });
    }
}
