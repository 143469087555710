import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgModalConfirmModels } from '../components/ng-modal-confirm/ng-modal-confirm-models/ng-modal-confirm.models';
import { NgModalConfirmComponent } from '../components/ng-modal-confirm/ng-modal-confirm-views/ng-modal-confirm.component';


@Injectable({ providedIn: 'root' })
export class ModalConfirmService {


    constructor(private modalService: NgbModal){

    }

    open(data:NgModalConfirmModels.NgModalConfirmData,opts:NgbModalOptions={centered:true}):NgbModalRef{
        let modalConfirm = this.modalService.open(NgModalConfirmComponent,opts); 
        modalConfirm.componentInstance.data=data;
        return modalConfirm;
    }
    openStatic(data:NgModalConfirmModels.NgModalConfirmData):NgbModalRef{
        let modalConfirm = this.modalService.open(NgModalConfirmComponent,{
            backdrop:'static',
            backdropClass:'customBackdrop',
            centered:true
          }); 
        modalConfirm.componentInstance.data=data;
        return modalConfirm;
    }
}