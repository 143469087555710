import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject, ViewEncapsulation, HostListener } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { MuestrasModels } from 'src/app/mantenedores/mant-muestras/mant-muestras-models/mant-muestras.models';
import { MuestrasService } from 'src/app/mantenedores/mant-muestras/mant-muestras-service/mant-muestras.service';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { StorageTools } from 'src/app/shared/tools/storage.tools';

@Component({
    selector: 'app-report-result-pivot',
    templateUrl: './report-result-pivot.component.html',
    styleUrls: ['./report-result-pivot.component.css'],
})
export class ReportResultPivotComponent implements OnInit {
    data: any = {
        muestra_id: '',
        muestra: {},
    };

    childsTabReport: { [ref: string]: { bottleNumber: string; tab: Window; sample?: MuestrasModels.Muestra } } = {};

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private storage: StorageTools,
        private oauthService: OAuthService,
        private serviceSample: MuestrasService
    ) {
        this.data.muestra_id = this.route.snapshot.params.id;
    }

    ngOnInit(): void {
        console.log("this.data.muestra_id", this.data.muestra_id);
        if (this.data.muestra_id) {
            this.getSample(this.data.muestra_id);
        } else {
            window.location.href = '/home';
        }
    }

    getPdf(): void {
        const ref = new Date().getTime();
        const url = '/reports2?ref=' + ref;
        const muestra: MuestrasModels.Muestra = this.data.muestra;
        let tab = window.open(url, '_blank');
        if (tab) {
            this.childsTabReport[ref] = { tab: tab, bottleNumber: muestra.muestraId, sample: muestra };
        }
        window.focus();
    }
    
    getSample(muestraId: string) {

        let request: MuestrasModels.GetMuestraRequest = {            
            laboratorioId: '',
            muestraId: muestraId
        };

        this.serviceSample.getMuestraById(request).then((response) => {            
            this.data.muestra = response.data[0];
            if (this.authService.currentClientValue.clienteId === this.data.muestra.cliente.clienteId && this.authService.currentClientValue.faena.faenaId === this.data.muestra.cliente.faena.faenaId) {
                window.postMessage({ action: 'loadReport', bottleNumber: this.data.muestra.muestraId, sample: this.data.muestra, close_to_home: true }, window.location.origin);
            } else {
                window.location.href = '/home';
            }
        });
    }
}
