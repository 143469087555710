export const environment = {
    production: false,
    fusionAuth: {
        uri: 'https://copec-sa.fusionauth.io',
        clientID: '632011d3-502a-4a90-9268-9bf876b35632',
        redirectURI: window.location.origin + "/oauth-callback",
    },
    authConfig: {
        issuer: 'https://copec-sa.fusionauth.io',
        clientId: '632011d3-502a-4a90-9268-9bf876b35632',
        redirectUri: window.location.origin + "/oauth-callback",
        responseType: 'code',
        requireHttps: true,
        timeoutFactor: 0.9,
        scope: 'openid profile email offline_access',
        strictDiscoveryDocumentValidation: true,
        showDebugInformation: false,
        oidc: true,
        disableAtHashCheck: true,
        customQueryParams: { 'flag_go': 'f', 'locale': 'ES_CL' }
    },
    apiUrl: 'https://rgnckbuqud.execute-api.us-west-2.amazonaws.com/stg/api/v1',
    routes: {
        token: '/token',
        getComponentIds: '/componente',
        getMuestraInfo: '/muestra/info',
        getCliente: '/cliente',
        getCajaMuestra: '/cajamuestra',
        setCajaMuestraProcess: '/cajamuestra/process',
        getLubricante: '/lubricante',
        categorias: '/lubricantes/categorias',
        setMuestraValid: '/muestra/validid',
        getSolicitudAnalisis: '/muestra/solicitudanalisis',
        setSolicitudAnalisisEstado: '/muestra/solicitudanalisis/estado',
        equipo: '/equipo',
        tipoEquipo: '/tipoequipo',
        componente: '/componente',
        component: '/component',
        moveComponentes: '/componente/move',
        tipoComponente: '/tipocomponente',
        usuario: '/usuario',
        changeMuestra:'/change/muestra/webcliente',
        reportHistorico: '/muestra/reporte/historico',
        componenteReporte: '/componente/reporte',
        solicitantes: '/solicitantes',
        getCondicionComercial: '/condicioncomercial',
        insumos: '/insumos',
        muestras: '/muestras',
        log: '/activity-log',
        imageSave: '/file/urlpresigned',
        plananalisis: '/plananalisis',
        protocolospersonalizado: '/protocolos/personalizado',
        protocolospersonalizadoReporte:'/protocolo/personalizado/reporte',
        ensayos: '/ensayos',
        report: '/report',
        report_massive: '/muestra/reporte/massive',
        updateSample: '/muestra/update',
        tableau: '/tableau',
        facturacion: {
            documento_ventas: '/facturacion/documento-ventas',
            send_documento_ventas: '/facturacion/send-documento-ventas',
            total_pendientes_facturacion: '/facturacion/total-pendientes-facturacion'

        },
    },
    external: {
        tableau: 'https://tableau.copec.cl/t/LaboratorioLubricantes/views/PortadaPrueba/Portada?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'
    },
    external_script:{
        tableau_copec_js: 'https://tableauqa.copec.cl/javascripts/api/tableau.embedding.3.latest.min.js'
    },
    datadog: {
        app: '14980e3d-1182-4553-9e63-a3bec35f4e31',
        token: 'pub8294e64c55f1426417c42bded68f9a34',
        service: 'npl',
        env: 'stg',
        enabled: false,
    },
    config: {
        limiteSeleccionDescarga: 100,
        maxIngresoMuestras: 25,
        api: {
            tiempoGeneralEspera: 120000
        },
        visualizedSampleDateLimit: '2024-01-15',
    }
};
