import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { EquipoVerComponent } from "src/app/mantenedores/mant-equipos/mant-equipos-views/equipo-ver/equipo-ver-views/equipo-ver.component";
import { Objects } from "src/app/shared";
import { AuthenticationService } from "src/app/shared/auth/authentication.service";
import { ToastService } from "src/app/shared/components/ng-toast/toast-service";
import { LogService } from "src/app/shared/services/log.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { ComponenteAsignarEquipoModels } from "../comp-asignar-equipo-models/comp-asignar-equipo.models";
import { ComponenteAsginarEquipoViewModels } from "../comp-asignar-equipo-models/comp-asignar-equipo.view.models";
import { CompAsignarEquipoService } from "../comp-asignar-equipo-services/comp-asignar-equipo.service";

@Component({
    selector: 'app-componente-asignar-equipo',
    templateUrl: './comp-asignar-equipo.component.html',
    styleUrls: ['./comp-asignar-equipo.component.css']
})
export class ComponenteAsignarEquipoComponent implements OnInit {

    @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete!: MatAutocompleteTrigger;
    myControl = new FormControl();
    filteredOptions!: Observable<ComponenteAsignarEquipoModels.Componente[]>;

    @Input() equipoInput: ComponenteAsignarEquipoModels.EquipoInput = {
        equipo: {
            clienteId: '',
            faenaId: '',
            equipoId: '',
            tipoEquipo: {
                tipoEquipoId: '',
                nombre: ''
            },
            marca: '',
            modelo: '',
            descriptor: '',
            alias: [],
            tags: [],
            componentes: []
        },
        vieneDesdeVerEquipo: false,
        vieneDesdeMuestra: false
    }

    model: ComponenteAsginarEquipoViewModels.ViewModel = {
        componente: {
            marca: '',
            ubicacion: '',
            clienteId: '',
            faenaId: '',
            solicitante: '',
            tipoComponente: {
                tipoComponenteId: '',
                nombre: ''
            },
            componenteId: '',
            modelo: '',
            descriptor: '',
        },
        equipo: {
            clienteId: '',
            faenaId: '',
            equipoId: '',
            tipoEquipo: {
                tipoEquipoId: '',
                nombre: ''
            },
            marca: '',
            modelo: '',
            descriptor: '',
            alias: [],
            tags: [],
            componentes: []
        },
        componenteFiltro: '',
        componentesEncontrados: [],
        esNuevoComponente: false,
        loadingEquipos: false,
        tieneComponente: false,
        componenteEstaAsignado: false,
        confirmoCambioEquipo: false,
        componentes: [],
        loadingIdComponente: false,
        loadingMarcaComponente: false,
        loadingLubricanteComponente: false,
        tipoComponentes: [],
        marcas: [],
        modelos: [],
        verOtraMarca: false,
        verOtroModelo: false,
        otraMarca: '',
        otroModelo: '',
        loadingMarca: false,
        loadingLubricante: false,
        lubricantes: [],
        idsComponentes: [],
        tieneErrorSetComponente: false,
        errorSetComponente: '',
        idError: false,
        componenteInvalido: false,
        componenteInexistente: false,
        alertaCambioEquipo: false,
        idComponenteVacio: false,
        equipoContieneComponente: false,
    }

    constructor(
        private authService: AuthenticationService,
        private service: CompAsignarEquipoService,
        private spinner: SpinnerService,
        private modalService: NgbModal,
        public dialogRef: NgbActiveModal,
        private _logService: LogService,
        public toastService: ToastService
    ) {
    }

    ngOnInit(): void {
        this.model.equipo = this.equipoInput.equipo;
        this.getComponentes();
        this.configureWatchers();
        this.getComponentesIds();
        this.getMarcasComponente();
    }

    cancelar() {
        this.dialogRef.close();
        const modalRef = this.modalService.open(EquipoVerComponent, {
            centered: true,
            size: 'xl'
        });
        modalRef.componentInstance.equipoInput.equipo = Object.assign({}, this.model.equipo);
    }

    getComponentes() {
        this.spinner.off(true);
        const request: ComponenteAsignarEquipoModels.GetComponentesRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId
        };
        this.model.loadingEquipos = true;
        this.service.GetComponentes(request)
            .then(response => {
                if (response.success) {
                    this.model.componentesEncontrados = response.data;
                } 
            })
            .catch()
            .finally(() => {
                this.model.loadingEquipos = false;
            });
    }

    enterInputSearch(): void {
        this.model.componenteInexistente = false;
        this.model.componenteInvalido = false;
        let componentes: ComponenteAsignarEquipoModels.Componente[] = [];
        if (typeof this.model.componenteFiltro === 'string') {
            componentes = this.model.componentesEncontrados.filter(
                option => option.componenteId.toLowerCase().includes(this.model.componenteFiltro.toLowerCase())
            );

            if (componentes.length == 0) {
                this.model.componenteInexistente = true;
            } else if (componentes.length > 1) {
                this.model.componenteInvalido = true;
            } else {
                this.model.esNuevoComponente = false;
                this.model.componente = this.limpiaComponente();
                this.model.componente = componentes[0];
                this.model.tieneComponente = true;
                if (this.model.componente.equipo! && this.model.componente.equipo.equipoId) {
                    if (this.model.equipo.equipoId === this.model.componente.equipo.equipoId) {
                        this.model.equipoContieneComponente = true;
                    } else {
                        this.model.componenteEstaAsignado = true;
                    }
                }
            }
        }
    }

    onSelectionChanged(event: MatAutocompleteSelectedEvent): void {
        this.model.equipoContieneComponente = false;
        this.model.componenteEstaAsignado = false;
        this.selectedRow(event.option.value);
    }

    selectedRow(componente: ComponenteAsignarEquipoModels.Componente): void {
        this.model.esNuevoComponente = false;
        this.model.componente = this.limpiaComponente();
        this.model.componente = componente;
        this.model.tieneComponente = true;
        if (this.model.componente.equipo! && this.model.componente.equipo.equipoId) {
            if (this.model.equipo.equipoId === this.model.componente.equipo.equipoId) {
                this.model.equipoContieneComponente = true;
            } else {
                this.model.componenteEstaAsignado = true;
            }
        }
    }

    displayFn(componente: ComponenteAsignarEquipoModels.Componente): string {
        return componente ? `${componente.componenteId} - ${componente.tipoComponente.nombre} ${componente.marca} ${componente.modelo ? componente.modelo : ''}` : '';
    }

    asignarEquipo(): void {
        this.spinner.on();
        if (this.model.componenteEstaAsignado) {
            if (this.model.confirmoCambioEquipo) {
                this.cambioEquipo();
            } else {
                this.model.alertaCambioEquipo = true;
            }
        } else {
            this.guardarComponente();
        }
    }

    aceptarCambioEquipo(): void {
        this.model.alertaCambioEquipo = false;
        this.model.confirmoCambioEquipo = !this.model.confirmoCambioEquipo
    }

    crearComponente() {
        this.model.esNuevoComponente = true;
        this.model.componenteFiltro = '';
        this.model.tieneComponente = false;
        this.model.componente = this.limpiaComponente();
    }

    validaId(): void {
        this.model.componente.componenteId = this.model.componente.componenteId.trim();
        this.model.idComponenteVacio = false;
        this.model.idError = false;
        if (this.model.idsComponentes.includes(this.model.componente.componenteId)) {
            this.model.idError = true;
        }
    }



    getComponentesIds(): void {
        this.model.loadingIdComponente = true;
        const request: ComponenteAsignarEquipoModels.GetComponentesRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId
        };
        this.service.GetComponentes(request)
            .then(response => {
                if (response.success) {
                    this.model.componentes = response.data;
                }
            })
            .catch()
            .finally(() => {
                if (this.model.componentes) {
                    this.model.componentes.forEach(element => {
                        this.model.idsComponentes.push(element.componenteId);
                    });
                }
                this.model.loadingIdComponente = false;
            });
    }

    getMarcasComponente(): void {
        this.model.loadingMarcaComponente = true;
        this.model.loadingLubricanteComponente = true;
        let tipoMarcaModelo: ComponenteAsignarEquipoModels.tipoMarcaModeloComponente;
        this.service.GetMarcaComponenteInfo()
            .then(response => {
                if (response.success) {
                    tipoMarcaModelo = response.data;
                    this.model.tipoComponentes = tipoMarcaModelo.tiposComponentes;
                    this.model.marcas = tipoMarcaModelo.marcas;
                }
            })
            .finally(() => {
                if (this.model.componente.marca) {
                    this.cambiaMarca(true)
                }
                this.model.loadingMarca = false;
            })

        this.service.GetLubricantes()
            .then(response => {
                if (response.success) {
                    this.model.lubricantes = response.data;
                }
            })
            .finally(() => {
                this.model.loadingLubricante = false;
            })
    }

    cambiaMarca(editando: boolean = false): void {
        if (this.model.componente.marca === 'otro') {
            this.model.modelos = [];
            this.model.componente.modelo = '';
            this.model.verOtraMarca = true;
        } else {
            this.model.modelos = [];
            this.model.componente.modelo = editando ? this.model.componente.modelo : '';
            this.model.modelos = this.model.marcas.find(element => element.nombre === this.model.componente.marca)?.modelo!;
            this.model.verOtraMarca = false;
        }
    }

    cambiaModelo() {
        if (this.model.componente.modelo === 'otro') {
            this.model.componente.modelo = '';
            this.model.verOtroModelo = true;
        } else {
            this.model.verOtroModelo = false;
        }
    }


    private cambioEquipo(): void {
        let componenteSet = this.generaComponenteSet();
        const request: ComponenteAsignarEquipoModels.SetComponenteRequest = {
            componente: componenteSet
        };
        this.service.SetComponente(request).subscribe(data => {
            if (data.success) {

                let rq: Objects.LogInput = {
                    accion: 'Update',
                    elemento: this.model.componente.componenteId,
                    tipoElemento: 'Componente',
                    usuario: this.authService.usuario.nombre,
                    usuarioId: this.authService.usuario.userId,
                    ambiente: 'Web Cliente'
                }
                this._logService.createLog(rq, true);

                this.cambiarComponenteDeEquipo();
            } else {
                this.model.tieneErrorSetComponente = true;
                if (data.message.startsWith("PLAN_ANALISIS_NOT_FOUND")) {
                    this.model.errorSetComponente = data.message.substr(data.message.indexOf(";") + 2);
                } else {
                    this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                }
            }
        });

    }

    private generaComponenteSet(): ComponenteAsignarEquipoModels.ComponenteSet {
        let componente: ComponenteAsignarEquipoModels.ComponenteSet = {
            equipo: {
                equipoId: this.model.equipo.equipoId.trim(),
                tipoEquipo: this.model.equipo.tipoEquipo
            },
            componenteId: this.model.componente.componenteId.trim(),
            marca: this.model.componente.marca != 'otro' ? this.model.componente.marca : this.model.otraMarca.trim(),
            modelo: this.model.componente.modelo != 'otro' ? this.model.componente.modelo : this.model.otroModelo.trim(),
            ubicacion: this.model.componente.ubicacion?.trim(),
            clienteId: this.model.componente.clienteId != '' ? this.model.componente.clienteId : this.authService.currentClientValue.clienteId,
            faenaId: this.model.componente.faenaId != '' ? this.model.componente.faenaId : this.authService.currentClientValue.faena.faenaId,
            solicitante: this.model.componente.faenaId != '' ? this.model.componente.faenaId : this.authService.currentClientValue.faena.faenaId,
            tipoComponente: this.model.componente.tipoComponente,
            lubricante: this.model.componente.lubricante,
            descriptor: this.model.componente.descriptor?.trim(),
        }
        return componente;
    }

    private cambiarComponenteDeEquipo() {
        let ret = false;
        if (this.model.componente.equipo?.equipoId) {
            const request: ComponenteAsignarEquipoModels.GetEquipoRequest = {
                clienteId: this.authService.currentClientValue.clienteId,
                faenaId: this.authService.currentClientValue.faena.faenaId,
                equipoId: this.model.componente.equipo.equipoId
            };

            this.service.GetEquipoInfo(request)
                .then(response => {
                    if (response.success) {

                        const requestEquipo: ComponenteAsignarEquipoModels.SetEquipoNuevoRequest = {
                            equipo: this.cambiaComponentes(response.data[0])
                        };

                        this.service.setEquipoCambio(requestEquipo).subscribe(data => {
                            if (data.success) {

                                let rq: Objects.LogInput = {
                                    accion: 'Update',
                                    elemento: this.model.componente.componenteId,
                                    tipoElemento: 'Componente',
                                    usuario: this.authService.usuario.nombre,
                                    usuarioId: this.authService.usuario.userId,
                                    ambiente: 'Web Cliente'
                                }
                                this._logService.createLog(rq, true);

                                ret = true;
                                this.spinner.off();
                                this.model.equipo.componentes?.push(this.model.componente);
                                this.dialogRef.close();

                                const modalRef = this.modalService.open(EquipoVerComponent, {
                                    centered: true,
                                    size: 'xl'
                                });
                                modalRef.componentInstance.equipoInput.equipo = Object.assign({}, this.model.equipo);
                                if (!this.equipoInput.vieneDesdeMuestra) {
                                    modalRef.componentInstance.equipoInput.estaEditando = Object.assign({}, true);
                                } else {
                                    modalRef.componentInstance.equipoInput.guardoDesdeMuestra = Object.assign({}, true);
                                }
                            } else {
                                this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                            }
                        });
                    }
                })
        }
        return ret;
    }

    private cambiaComponentes(equipo: ComponenteAsignarEquipoModels.Equipo) {
        let componentes: string[] = [];
        if (equipo.componentes) {
            equipo.componentes.forEach(element => {
                if (element.componenteId != this.model.componente.componenteId) {
                    componentes.push(element.componenteId);
                }
            });
        }

        return this.generaEquipo(equipo, componentes);
    }

    private generaEquipo(equipoGet: ComponenteAsignarEquipoModels.Equipo, componentes: string[]): ComponenteAsignarEquipoModels.EquipoSet {
        let equipo = {
            clienteId: this.authService.currentClientValue.clienteId.trim(),
            faenaId: this.authService.currentClientValue.faena.faenaId,
            equipoId: equipoGet.equipoId.trim(),
            tipoEquipo: equipoGet.tipoEquipo,
            marca: equipoGet.marca,
            modelo: equipoGet.modelo,
            descriptor: equipoGet.descriptor?.trim(),
            alias: equipoGet.alias,
            tags: equipoGet.tags,
            componentes: componentes
        }

        return equipo;
    }

    private guardarComponente(): void {
        if (this.model.componente.componenteId.trim() == '') {
            this.model.idComponenteVacio = true;
        } else {
            let componenteSet = this.generaComponenteSet();
            const request: ComponenteAsignarEquipoModels.SetComponenteRequest = {
                componente: componenteSet
            };

            this.service.SetComponente(request).subscribe(data => {
                if (data.success) {

                    let rq: Objects.LogInput = {
                        accion: 'Update',
                        elemento: this.model.componente.componenteId,
                        tipoElemento: 'Componente',
                        usuario: this.authService.usuario.nombre,
                        usuarioId: this.authService.usuario.userId,
                        ambiente: 'Web Cliente'
                    }
                    this._logService.createLog(rq, true);

                    this.spinner.off;
                    this.model.equipo.componentes?.push(this.model.componente);
                    this.dialogRef.close();

                    const modalRef = this.modalService.open(EquipoVerComponent, {
                        centered: true,
                        size: 'xl'
                    });
                    modalRef.componentInstance.equipoInput.equipo = Object.assign({}, this.model.equipo);
                    if (!this.equipoInput.vieneDesdeMuestra) {
                        modalRef.componentInstance.equipoInput.estaEditando = Object.assign({}, true);
                    } else {
                        modalRef.componentInstance.equipoInput.guardoDesdeMuestra = Object.assign({}, true);
                    }
                } else {
                    if (data.message.startsWith("PLAN_ANALISIS_NOT_FOUND")) {
                        this.model.tieneErrorSetComponente = true;
                        this.model.errorSetComponente = data.message.substr(data.message.indexOf(";") + 2);
                    } else {
                        this.toastService.show(`Se ha presentado un error indeterminado, contacte a un administrador.`, { classname: 'bg-danger text-light', delay: 2000 });
                    }
                    this.spinner.off();
                }
            });
        }
    }


    private limpiaComponente(): ComponenteAsignarEquipoModels.Componente {
        let componente = {
            marca: '',
            ubicacion: '',
            clienteId: '',
            faenaId: '',
            solicitante: '',
            tipoComponente: {
                tipoComponenteId: '',
                nombre: ''
            },
            componenteId: '',
            modelo: '',
            descriptor: '',
        }
        return componente;
    }

    private configureWatchers(): void {
        this.filteredOptions = this.myControl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this.filter(name) : [])
            );
    }

    private filter(name: string): ComponenteAsignarEquipoModels.Componente[] {
        this.model.componenteInexistente = false;
        this.model.componenteInvalido = false;
        const filterValue = name.toLowerCase();
        let componentesFiltrados: ComponenteAsignarEquipoModels.Componente[] = [];
        componentesFiltrados = this.filtroEquipo(filterValue).slice(0, 10);
        return componentesFiltrados;
    }

    private filtroEquipo(filterValue: string): ComponenteAsignarEquipoModels.Componente[] {
        filterValue = filterValue.toLowerCase();
        return this.model.componentesEncontrados.filter(option => option.componenteId.toLowerCase().includes(filterValue));
    }
}
