import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegistraMuestraModels } from '../registro-multiple-models/registro-multiple.models';
import { environment } from 'src/environments/environment';
import { Tools } from 'src/app/shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegistraService {

    constructor(
        private http: HttpClient,
        private tools: Tools) {
    }
    setMuestraValid(request:RegistraMuestraModels.setMuestraValidRequest): Observable<RegistraMuestraModels.setMuestraValidResponse> {
        return this.http.post<RegistraMuestraModels.setMuestraValidResponse>(`${environment.apiUrl}${environment.routes.setMuestraValid}`, request);
    }


}
