<div class="content__head">
  <div class="content__title mb-5">
    <h2 class="mb-0">Resultados históricos</h2>
    <p>Selecciona las variables para obtener una planilla con los resultados históricos de análisis de muestras.</p>
  </div>
</div>
<section class="content__container">
  <form #historico="ngForm">
    <div class="flex">
      <div class="flex-col">
        <div class="form__field mb-3">
          <label class="label mb-1 d-block">Faena</label>
          <ng-select
            id="reporte_componente_faena"
            blockSpecialCharacters
            #faenas="ngModel"
            (change)="filtradoComponente()"
            class="ng-select"
            name="faenas"
            placeholder="Seleccionar"
            [items]="optionsFaena"
            required
            [(ngModel)]="selectedFaena"
            [appendTo]="'body'"
            [ngModelOptions]="{ standalone: true }"
            [clearSearchOnAdd]="true"
            [closeOnSelect]="false"
            [multiple]="true"
            clearAllText="Clear"
            bindLabel="nombre"
          >
            <ng-template ng-header-tmp>
              <div class="py-2 d-flex align-items-center gap-1">
                <input type="checkbox" id="selectAllHeader" #selectAllHeader (change)="selectAllChores(selectAllHeader.checked)" />
                <label for="selectAllHeader">Seleccionar todos</label>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="d-flex align-items-center gap-1">
                <input
                  id="item-{{ index }}"
                  type="checkbox"
                  (change)="filtradoComponente()"
                  [ngModel]="item$.selected"
                  [ngModelOptions]="{ standalone: true }"
                />
                {{ item["faenaId"] }} - {{ item["nombre"] }}
              </div>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <div *ngIf="items.length === 1">
                <div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
                  {{ $any(item)["nombre"] }}
                </div>
              </div>
              <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{ items.length }} Seleccionados</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="form__field mb-3">
          <label class="label mb-1 d-block">Estado muestras</label>
          <ng-select class="ng-select" [appendTo]="'body'" placeholder="Todos" [(ngModel)]="state" name="resolucion">
            <ng-option class="bg-white" *ngFor="let item of stateOptions" [value]="item.value">{{ item.name }}</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="flex-col">
        <div class="form__field mb-3">
          <label class="label mb-1 d-block">Tipo de fecha</label>
          <mat-radio-group name="date" required [(ngModel)]="tipoFecha">
            <mat-radio-button color="primary" [value]="1" checked="true">Fecha toma de muestra</mat-radio-button>
            <mat-radio-button color="primary" [value]="2">Fecha solicitud de análisis</mat-radio-button>
            <mat-radio-button color="primary" [value]="3">Fecha informe</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="form__field mb-3" *ngIf="tipoFecha == 1">
          <label class="label mb-1 d-block">Fecha toma de muestra</label>
          <div class="form__range">
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Inicio" />
              <input matEndDate formControlName="end" placeholder="Término" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </div>
          <span *ngIf="!calculo()" class="text-danger"> El rango debe ser inferior a un año </span>
        </div>
        <div class="form__field mb-3" *ngIf="tipoFecha > 1">
          <label class="label mb-1 d-block">{{ tipoFecha == 3 ? "Fecha informe" : "Fecha solicitud de análisis" }}</label>
          <div class="form__range">
            <input
              class="input"
              type="date"
              max="{{ todaysdate | date : 'yyyy-MM-dd' }}"
              validarFecha
              [(ngModel)]="fechaUna"
              name="fechaUna"
              #fechaMuestreo="ngModel"
              required
              [ngClass]="{ 'is-invalid': fechaMuestreo.invalid && (fechaMuestreo.dirty || fechaMuestreo.touched) }"
            />
          </div>
          <div *ngIf="fechaMuestreo.invalid && (fechaMuestreo.dirty || fechaMuestreo.touched)" class="text-danger mt-1">
            <div *ngIf="fechaMuestreo.errors?.required">
              <span> Debe ingresar la fecha de toma de muestra.</span>
            </div>
            <div *ngIf="fechaMuestreo.invalid && !fechaMuestreo.errors?.required">
              <span>Formato incorrecto.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col">
        <div class="form__field mb-3">
          <label class="label mb-1 d-block">Código componente</label>
          <ng-select class="ng-select" [appendTo]="'body'" name="dummy" placeholder="Seleccionar" [(ngModel)]="selectedCid">
            <ng-option *ngFor="let df of components" [value]="df.componenteId">{{ df.componenteId }}</ng-option>
          </ng-select>
        </div>
        <div class="form__field mb-3" *ngIf="false">
          <label class="label mb-1 d-block">N° muestra</label>
          <input class="input" type="text" name="number" [(ngModel)]="nroFrasco" />
        </div>
      </div>
    </div>
    <div class="flex pt-5">
      <div class="flex-col text-center align-items-center">
        <button
          (click)="getReport()"
          [disabled]="!validarFechasAmbos() || !selectedFaena || historico.invalid"
          class="btn btn-primary btn--small btn--first mb-4 mb-md-0 order-1 order-md-3"
        >
          Buscar resultados
        </button>
      </div>
    </div>
  </form>
</section>

<div class="printed" *ngIf="printed">
  <ng-container *ngIf="printedLoading">
    <div class="printed-icon">
      <ng-lottie [options]="printOptions"></ng-lottie>
    </div>
    <h2>Generando reporte</h2>
    <p><b>No cierres esta ventana</b>, Una vez terminado el reporte, aparecerá un botón para que lo descargues.</p>
  </ng-container>
  <ng-container *ngIf="!printedLoading">
    <div class="printed-icon lottie-check">
      <ng-lottie [options]="printCheckOptions"></ng-lottie>
    </div>
    <h2>Reporte Generado</h2>
    <button class="btn btn--small btn--success" (click)="download_file()">Descargar reporte</button>
  </ng-container>
</div>
