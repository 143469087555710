import {
    Component,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    Input,
    ViewChild,
    ElementRef,
    HostListener,
    ViewEncapsulation,
} from '@angular/core';
import { ReportsViewModels } from '../../models/reports.view.models';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ReportsModels } from '../../models/reports.models';
import { ReportsService } from '../../services/reports.services';
import { SubscribersTools } from '../../../../shared/tools/subscribers.tools';
import { Enums } from 'src/app/shared';
import * as moment from 'moment';
import { Subject, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsTools } from 'src/app/shared/tools/utils.tools';
import { SnackBarService } from '../../../../shared/services/snackBar.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { MatDialog } from '@angular/material/dialog';
import { ModalGalleryComponent } from '../../components/modal-gallery/modal-gallery.component';
import { SpinnerService } from '../../../../shared/services/spinner.service';
import { RequestCounterTools } from '../../../../shared/tools/request-counter.tools';
import { AuthenticationService } from '../../../../shared/auth/authentication.service';
import { Sampletools } from '../../tools/sample.tools';
import { Constants } from '../../models/constants';

@Component({
    selector: 'reportExportPDF',
    templateUrl: './report-result-component.component.html',
    styleUrls: ['./report-result-component.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ReportExportPDF {
    element?: HTMLElement | null;

    @ViewChild('reportTop') reportTop?: ElementRef;
    @ViewChild('reportHeader') reportHeader?: ElementRef;
    @ViewChild('reportDetail') reportDetail?: ElementRef;
    @ViewChild('reportNoisOne') reportNoisOne?: ElementRef;
    @ViewChild('reportDetail_TableHead') reportDetail_TableHead?: ElementRef;
    @ViewChild('reportDetail_tableBody') reportDetail_tableBody?: ElementRef;
    @ViewChild('reportDetail_tableBody_generic') reportDetail_tableBody_g?: ElementRef;
    @ViewChild('reportFerro') reportFerro?: ElementRef;
    @ViewChild('reportTurb') reportTurb?: ElementRef;
    @ViewChild('reportMemb') reportMemb?: ElementRef;
    @ViewChild('reportEval') reportEval?: ElementRef;
    @ViewChild('reportEvalOne') reportEvalOne?: ElementRef;

    @Output() callBackUpdate = new EventEmitter<{ resp: boolean; name: string }>();
    @Output() loading$ = new EventEmitter<boolean>();

    @Input() sample?: ReportsModels.Muestra;
    @ViewChild('report_page_1') page1?: ElementRef;
    @Input() isVisible: boolean = false;
    utilsTool: UtilsTools = new UtilsTools();
    analysisPages: number = 1;
    isPrinting: boolean = false;
    isGeneratingPDF: boolean = false;
    letsPrint: boolean = false;
    subTool: SubscribersTools = new SubscribersTools();
    loading: RequestCounterTools = new RequestCounterTools();
    loadingPDF: RequestCounterTools = new RequestCounterTools();
    loadingResources: RequestCounterTools = new RequestCounterTools();
    resources: string[] = [];
    resourcesLoadedOnDraft: string[] = [];
    pdf = this.newJSPDF();
    draft = true;
    stepNumber: number = -1;
    obsvDraftEnd: Subject<boolean> = new BehaviorSubject(this.draft);
    obsvStep: Subject<number> = new BehaviorSubject(this.stepNumber);
    statusSample: string = '';
    downloadLink: boolean = false;
    close_to_home: boolean = false;
    enumClass: { reportState: { [key: string]: string } } = {
        reportState: {
            normal: 'success',
            precaucion: 'warning',
            alerta: 'danger',
        },
    };
    columnsMatrix: { [coor: string]: { rowspan: number } } = {};
    groupOrder: {
        [key: string]: { order: number; subType: { [key: string]: { order: number } }; analysis: { [code: string]: { order: number } } };
    } = {
        'ANALISIS FISICO-QUIMICO': {
            order: 0,
            subType: {},
            analysis: {
                '5': { order: 1 },
                '6': { order: 4 },
                '300': { order: 6 },
                '4': { order: 8 },
                '405': { order: 10 },
                '8': { order: 12 },
                '302': { order: 14 },
                '11': { order: 16 },
                '10': { order: 18 },
                '139': { order: 20 },
                '21': { order: 22 },
                '22': { order: 24 },
                '16': { order: 26 },
            },
        },
        'ESPECTOMETRIA AA': {
            order: 1,
            subType: {
                DESGASTE: { order: 1 },
                CONTAMINACION: { order: 2 },
                ADITIVOS: { order: 3 },
            },
            analysis: {
                '44': { order: 1 },
                '45': { order: 2 },
                '46': { order: 4 },
                '47': { order: 6 },
                '49': { order: 8 },
                '51': { order: 10 },
                '48': { order: 12 },
                '58': { order: 14 },
                '264': { order: 16 },
                '56': { order: 18 },
                '318': { order: 20 },
                '319': { order: 22 },
                '43': { order: 24 },
                '55': { order: 26 },
                '263': { order: 28 },
                '60': { order: 30 },
                '59': { order: 32 },
                '57': { order: 34 },
                '54': { order: 36 },
                '53': { order: 38 },
                '52': { order: 40 },
                '50': { order: 42 },
            },
        },
        'CONTEO PARTICULAS': {
            order: 3,
            subType: {},
            analysis: {
                '28': { order: 1 },
                '29': { order: 2 },
                '30': { order: 4 },
                '31': { order: 6 },
                '32': { order: 8 },
                '33': { order: 10 },
                '34': { order: 12 },
            },
        },
    };
    enumResultado: { [key: string]: string } = Enums.EstadosDeResultado;
    enumTdStatusClass: { [key: string]: string } = {
        alerta: 'txt--danger',
        normal: '',
        precaucion: 'txt--warning',
    };
    rowsPerPage_wGraphic = 17;
    rowsPerPage_woGraphic = 20;
    rowsPerPage_analysisOnly = 37;
    rowsPerPage_secondaryAnalysis = 6;

    model: ReportsViewModels.ViewModel = {
        ensayos: [],
        basePaginas: [],
        paginas: [{ tipos_ensayo: [] }],
        seleccionBase: {
            muestras: [],
            index: 0,
        },
        ferrografia: {
            codes: ['345', '346', '347', '348', '349', '350', '351', '352', '353', '354', '355'],
            order: {
                '345': 1,
                '2': 4,
                '346': 6,
                '347': 8,
                '316': 10,
                '348': 12,
                '349': 14,
                '350': 16,
                '351': 18,
                '352': 20,
                '353': 22,
                '354': 24,
                '355': 26,
            },
            input: {},
            isEditing: {},
        },
        ferrografia_tabla: { code: '355' },
        ferro_comentario_general: { code: '354' },

        membrana: {
            codes: ['200', '201', '202', '203', '204', '205', '206', '207', '208'],
            order: {
                '200': 1,
                '201': 2,
                '202': 3,
                '203': 4,
                '204': 5,
                '205': 6,
                '206': 7,
                '207': 8,
                '208': 9,
            },
            input: {},
            isEditing: {},
        },
        membrana_img_frasco: { code: '207' },
        membrana_img_filtro: { code: '208' },
        membrana_tabla_1: { code: '205' },
        membrana_tabla_2: { code: '206' },
        memb_comentario_general: { code: '204' },
        turbina: {
            '42': { order: 0 } ,
            '309': { order: 8 },

            '314': { order: 13 },

            '311': { order: 99 },
            '315': { order: 100 },
        },
        turbina_foot: {
            '311': { order: 0 },
            '315': { order: 1 },
        },

        clasificacion: {},
        defPlanEnsayos: {},
        muestras: [],
        contador: { muestras: { previas: 4, posterior: 0 } },
        limite: { muestras: { posterior: 1, previas: 4 } },

        datos: {
            muestra: [],
            analisis: {
                clasificacion: {},
                clasificacion_organizada: [],
                ferrografia: [],
                ferrografia_paginado: [],
                membrana: [],
                membrana_paginado: [],
                turbina: [],
                turbina_paginado: [],
                turbinaInferior: [],
            },
        },
        enumResultadoEnsayo: Enums.ResultadoGeneralEnsayo,
        enumEstadoMuestra: Enums.EstadosMuestraEnLote,

        inputTextComentario: { value: '', visible: false },
        pdfPageId: [],
        chartConfig: {
            viscosidad: {
                order: { VIS_40: 0, VIS_100: 1 },
                value: [],
            },
            pq_hierro_cobre: {
                order: { PQ: 0, HIERRO: 1, COBRE: 2 },
                value: [],
            },
            desgaste_aleacion: {
                order: { PLOMO: 0, ALUMINIO: 1, CROMO: 2, NIQUEL: 3 },
                value: [],
            },
            contaminacion: {
                order: { SILICIO: 0, SODIO: 1, POTASIO: 2, VANADIO: 3 },
                value: [],
            },
        },
        imageSaveUrl: {
            file_name: '',
            url: '',
        },
        images: {},
    };

    printed: boolean = false;
    options: AnimationOptions = {
        path: '/assets/images/cloud-print.json',
    };

    constructor(
        private router: Router,
        private snackSrv: SnackBarService,
        private spinner: SpinnerService,
        private reportsService: ReportsService,
        private _changeDetectorRef: ChangeDetectorRef,
        public authService: AuthenticationService,
        private sampleTls: Sampletools,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) {}

    animationCreated(animationItem: AnimationItem): void {}

    ngOnInit(): void {
        const ref = this.route.snapshot.queryParamMap.get('ref');
        if (ref) {
            window.opener?.postMessage({ action: 'showReport', event: 'init', ref: ref }, window.location.origin);
            this.downloadLink = true;
            this.isVisible = true;
        }

        this.loadingPDF.loading$.subscribe((isLoading) => {
            this.loading$.emit(isLoading);
            if (isLoading) {
                this.spinner.startLoading();
                if (this.utilsTool.inIframe()) {
                    window.parent.postMessage({ action: 'downloadPDF', event: 'startLoading' }, window.location.origin);
                }
            } else {
                this.spinner.stopLoading();
                if (this.utilsTool.inIframe()) {
                    window.parent.postMessage({ action: 'downloadPDF', event: 'stopLoading' }, window.location.origin);
                }
            }
        });

        this.model.datos.muestra = [
            { key: 'nroLab', etiqueta: 'Nº laboratorio', valor: (muestra) => muestra?.correlativo || 'Sin datos' },
            { key: 'nroFrasco', etiqueta: 'Nº frasco', valor: (muestra) => muestra?.muestraId || '' },
            { key: 'solicitud', etiqueta: 'Solicitud de análisis', valor: (muestra) => (muestra ? muestra.solicitudAnalisis : '') },
            {
                key: 'fechaMuestra',
                etiqueta: 'Fecha muestreo',
                valor: (muestra) => (muestra && muestra.fechaMuestreo ? muestra.fechaMuestreo : ''),
            },
            {
                key: 'fechaRecepcion',
                etiqueta: 'Fecha recepción',
                valor: (muestra) =>
                    muestra && muestra.fechaRecepcion
                        ? muestra?.fechaRecepcion
                        : muestra && muestra.fechaIngreso
                        ? muestra?.fechaIngreso
                        : '',
            },
            {
                key: 'fechaInforme',
                etiqueta: 'Fecha informe',
                valor: (muestra) => {
                    if (this.model.muestra?.muestraId === muestra?.muestraId) {
                        return muestra?.traces?.find((t) => t.estado === 'reported')?.fecha || moment().format('DD-MM-YYYY');
                    } else {
                        return muestra?.traces?.find((t) => t.estado === 'reported')?.fecha || '';
                    }
                },
            },
            { key: 'usoComp', etiqueta: 'Uso componente  (hrs/km)', valor: (muestra) => muestra?.usoTotalComponente || '' },
            { key: 'usoAceite', etiqueta: 'Uso aceite (hrs/km)', valor: (muestra) => muestra?.usoCambioLubricante || '' },
            { key: 'relleno', etiqueta: 'Rellenos (lts)', valor: (muestra) => muestra?.rellenoDesdeUltimoCambio || '' },
            { key: 'lubricante', etiqueta: 'Lubricante', valor: (muestra) => muestra?.lubricante.nombre || '' },
            { key: 'planAnalisis', etiqueta: 'Plan de análisis', valor: (muestra) => muestra?.planAnalisis?.nombre || '' },
            {
                key: 'estadoMuestra',
                etiqueta: 'Estado muestra',
                valor: (muestra) => {
                    if (this.model.muestra?.correlativo === muestra?.correlativo) {
                        return muestra ? this.sampleTls.statusSample(muestra).key : '';
                    } else {
                        return muestra?.resolucion?.toLocaleLowerCase() ?? '';
                    }
                },
            },
        ];

        this.subTool.addSubscribe(
            'callBackUpdate',
            this.callBackUpdate.subscribe((resp) => {
                if (!this.model.muestra) {
                    return;
                }
                let req: ReportsModels.SetMuestraUpdateRequest = {
                    muestraId: this.model.muestra.muestraId,
                    action_type: 'report_name',
                    reportName: resp.name,
                };
                this.reportsService
                    .setMuestraUpdate(req)
                    .toPromise()
                    .then((r) => {
                        if (r.code === 200 && this.model.muestra) {
                            this.model.muestra.reportName = resp.name;
                            this._cargarDatos();
                        }
                    })
                    .catch((e) => {
                        console.error('Ocurrio un problema al actualizar reporte:', e);
                        this.snackSrv.warning('Ocurrio un problema al actualizar reporte');
                    });
            })
        );
    }

    ngDoCheck() {}

    ngOnDestroy(): void {
        this.subTool.destroySubs();
    }

    stopPropagation(event: any) {
        event.stopPropagation();
    }

    _cargarDatos() {
        // return new Promise((resolve, reject) => {
            let request = [];
            this.model.paginas = [{ tipos_ensayo: [] }];
            this.loading.startRequest();
            request.push(this.getEnsayos());
            request.push(this._getPlanesAnalisis());

            request.push(this._getPreInforme());
            Promise.all(request)
                .then(() => {
                    this.draft = true;
                    let muestraModel = this.model.muestra;
                    if (muestraModel) {
                        let planEnsayos = this.checkAnalysis(muestraModel);


                        this.model.datos.analisis.clasificacion = {};
                        this.model.datos.analisis.ferrografia = [];
                        this.model.datos.analisis.ferrografia_tabla = undefined;
                        this.model.datos.analisis.membrana = [];
                        this.model.datos.analisis.membrana_img_frasco = undefined;
                        this.model.datos.analisis.membrana_img_filtro = undefined;
                        this.model.datos.analisis.membrana_tabla_1 = undefined;
                        this.model.datos.analisis.membrana_tabla_2 = undefined;
                        this.model.datos.analisis.memb_comentario_general = undefined;
                        this.model.datos.analisis.ferro_comentario_general = undefined;

                        this.model.datos.analisis.turbina = [];
                        this.model.datos.analisis.turbinaInferior = [];
                        this.analysisPages = 1;
                        let clasificacion: { [key: string]: { [key: string]: { ensayos: ReportsModels.Ensayo[] } } } = {};

                        planEnsayos
                            .filter(
                                (e) =>
                                    this.model.ferrografia.codes.indexOf(e.code) === -1 ||
                                    (this.model.datos.analisis.ferrografia.push(e) && false)
                            )
                            .filter(
                                (e) =>
                                    this.model.membrana.codes.indexOf(e.code) === -1 ||
                                    (this.model.datos.analisis.membrana.push(e) && false)
                            )

                            .filter((e) => !this.model.turbina[e.code] || (this.model.datos.analisis.turbina.push(e) && false))
                            .filter((e) => !this.model.turbina_foot[e.code] || (this.model.datos.analisis.turbinaInferior.push(e) && false))

                            .forEach((ensayo) => {
                                if (
                                    this.model.muestra?.ensayos_ocultos &&
                                    this.model.muestra?.ensayos_ocultos.length > 0 &&
                                    this.model.muestra?.ensayos_ocultos.find((e) => e === ensayo.ensayoId)
                                ) {
                                    ensayo.hide = true;
                                }
                                let tipo = ensayo.clasificacion && ensayo.clasificacion.tipo ? ensayo.clasificacion.tipo : '';
                                let subTipo = ensayo.clasificacion && ensayo.clasificacion.subTipo ? ensayo.clasificacion.subTipo : '';
                                ensayo.clasificacion = {
                                    subTipo: subTipo,
                                    tipo: tipo,
                                };

                                if (!clasificacion[tipo]) {
                                    clasificacion[tipo] = {
                                        [subTipo]: { ensayos: [] },
                                    };
                                }
                                if (!clasificacion[tipo][subTipo]) {
                                    clasificacion[tipo][subTipo] = { ensayos: [] };
                                }

                                clasificacion[ensayo.clasificacion.tipo][ensayo.clasificacion.subTipo].ensayos.push(ensayo);
                            });
                        let types = this.getOwnPropertyNames(clasificacion);
                        types.forEach((type) => {
                            let subTypes = this.getOwnPropertyNames(clasificacion[type]);
                            subTypes
                                .sort((a, b) => {
                                    if (a < b) {
                                        return -1;
                                    }
                                    if (a > b) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                .forEach((subType) => {
                                    clasificacion[type][subType].ensayos = clasificacion[type][subType].ensayos
                                        .sort((a, b) => {
                                            if (a.nombre < b.nombre) {
                                                return -1;
                                            }
                                            if (a.nombre > b.nombre) {
                                                return 1;
                                            }
                                            return 0;
                                        })
                                        .filter((e) => !e.hide);
                                });
                            this.model.datos.analisis.clasificacion[0] = clasificacion;
                        });
                        this.addDraftPage(clasificacion, 'one');
                        this.setColumsParams(clasificacion);
                        this.model.datos.analisis.membrana.forEach((e) => {
                            this.model.membrana.input[e.code] = this.ultimoResultado(e)?.value || '';
                            this.model.membrana.isEditing[e.code] = false;
                            if (this.model.membrana.input[e.code]) {
                                e.value = this.model.membrana.input[e.code];
                                e.image = this.getImagen(e);
                            } else {
                                e.hide = true;
                            }
                        });
                        this.model.datos.analisis.membrana.forEach((ensayo, index) => {
                            if (ensayo.code === this.model.membrana_img_frasco.code) {
                                ensayo.image = this.getImagen(ensayo);
                                this.model.datos.analisis.membrana_img_frasco = ensayo;
                                this.model.datos.analisis.membrana.splice(index, 1);
                            }
                        });
                        this.model.datos.analisis.membrana.forEach((ensayo, index) => {
                            if (ensayo.code === this.model.membrana_img_filtro.code) {
                                ensayo.image = this.getImagen(ensayo);
                                this.model.datos.analisis.membrana_img_filtro = ensayo;
                                this.model.datos.analisis.membrana.splice(index, 1);
                            }
                        });
                        this.model.datos.analisis.membrana.forEach((ensayo, index) => {
                            if (ensayo.code === this.model.memb_comentario_general.code) {
                                ensayo.image = this.getImagen(ensayo);
                                this.model.datos.analisis.memb_comentario_general = ensayo;
                                this.model.datos.analisis.membrana.splice(index, 1);
                            }
                        });

                        this.model.datos.analisis.membrana.forEach((ensayo, index) => {
                            if (ensayo.code === this.model.membrana_tabla_1.code) {
                                ensayo.image = this.getImagen(ensayo);
                                this.model.datos.analisis.membrana_tabla_1 = ensayo;
                                this.model.datos.analisis.membrana.splice(index, 1);
                            }
                        });
                        this.model.datos.analisis.membrana.forEach((ensayo, index) => {
                            if (ensayo.code === this.model.membrana_tabla_2.code) {
                                ensayo.image = this.getImagen(ensayo);
                                this.model.datos.analisis.membrana_tabla_2 = ensayo;
                                this.model.datos.analisis.membrana.splice(index, 1);
                            }
                        });

                        let membFilter = this.model.datos.analisis.membrana
                            .sort((a, b) => {
                                if (this.model.membrana.order[a.code] < this.model.membrana.order[b.code]) {
                                    return -1;
                                }
                                if (this.model.membrana.order[a.code] > this.model.membrana.order[b.code]) {
                                    return 1;
                                }
                                return 0;
                            })
                            .filter((e) => !e.hide);

                        if (membFilter.length > 0) {
                            this.addDraftPage(membFilter, 'memb');
                            membFilter
                                .filter((e) => e.image && !e.hide)
                                .forEach((e) => {

                                });
                        }
                        this.model.datos.analisis.ferrografia.forEach((e) => {
                            this.model.ferrografia.input[e.code] = this.ultimoResultado(e)?.value || '';
                            this.model.ferrografia.isEditing[e.code] = false;
                            if (this.model.ferrografia.input[e.code]) {
                                e.value = this.model.ferrografia.input[e.code];
                                e.image = this.getImagen(e);
                            } else {
                                e.hide = true;
                            }
                        });
                        this.model.datos.analisis.ferrografia.forEach((ensayo, index) => {
                            if (ensayo.code === this.model.ferrografia_tabla.code) {
                                ensayo.image = this.getImagen(ensayo);
                                this.model.datos.analisis.ferrografia_tabla = ensayo;
                                this.model.datos.analisis.ferrografia.splice(index, 1);
                            }
                        });
                        this.model.datos.analisis.ferrografia.forEach((ensayo, index) => {
                            if (ensayo.code === this.model.ferro_comentario_general.code) {
                                ensayo.image = this.getImagen(ensayo);
                                this.model.datos.analisis.ferro_comentario_general = ensayo;
                                this.model.datos.analisis.ferrografia.splice(index, 1);
                            }
                        });

                        let ferroFilter = this.model.datos.analisis.ferrografia
                            .sort((a, b) => {
                                if (this.model.ferrografia.order[a.code] < this.model.ferrografia.order[b.code]) {
                                    return -1;
                                }
                                if (this.model.ferrografia.order[a.code] > this.model.ferrografia.order[b.code]) {
                                    return 1;
                                }
                                return 0;
                            })
                            .filter((e) => !e.hide);

                        if (ferroFilter.length > 0) {
                            this.addDraftPage(ferroFilter, 'ferro');
                            ferroFilter
                                .filter((e) => e.image && !e.hide)
                                .forEach((e) => {

                                });
                        }

                        let turbinaSorts = this.model.datos.analisis.turbina.sort((a, b) => {
                            if (this.model.turbina[a.code].order < this.model.turbina[b.code].order) {
                                return -1;
                            }
                            if (this.model.turbina[a.code].order > this.model.turbina[b.code].order) {
                                return 1;
                            }
                            return 0;
                        });
                        this.addDraftPage(turbinaSorts, 'turb');

                        if (this.model.muestra?.comentarios) {
                            this.model.inputTextComentario.value = this.model.muestra.comentarios.comentario;
                        }

                        let dataEval = {
                            turbinaInferior: this.model.datos.analisis.turbinaInferior,
                            comentario: this.model.muestra?.comentarios?.comentario,
                        };
                        this.addDraftPage(dataEval, 'eval');
                    }
                    // resolve(true);
                })
                .catch((e) => {
                    // reject(e);
                })
                .finally(() => {
                    this.loading.endRequest();
                    this.stepNumber++;
                    this.obsvStep.next(this.stepNumber);
                });
        // });
    }

    addDraftPage(data: any, type: string) {
        this.model.paginas[0].tipos_ensayo.push({
            tipo: type,
            data: data,
        });
    }

    setColumsParams(classification: {
        [key: string]: {
            [key: string]: {
                ensayos: ReportsModels.Ensayo[];
            };
        };
    }) {
        let matrix: { [coor: string]: { rowspan: number } } = {};
        let coor: string = '';
        this.orderTypes(this.getOwnPropertyNames(classification)).forEach((type, indexType) => {
            let count: number = 1;
            let auxNorma: string = '';
            let subTypes = this.getOwnPropertyNames(classification[type]);

            this.orderSubTypes(subTypes, type).forEach((subtype, indexSubType) => {
                this.orderAnalysis(classification[type][subtype].ensayos, type).forEach((analysis, indexAnalysis) => {
                    if (indexSubType + indexAnalysis === 0) {
                        auxNorma = analysis.norma || '';
                        coor = indexType + '' + indexSubType + '' + indexAnalysis;
                    } else {
                        if (analysis.norma === auxNorma) {
                            count++;
                        } else {
                            matrix[coor] = { rowspan: count };
                            auxNorma = analysis.norma || '';
                            count = 1;
                            coor = indexType + '' + indexSubType + '' + indexAnalysis;
                        }
                    }
                });
            });

            if (coor && count > 0) {
                matrix[coor] = { rowspan: count };
                coor = '';
            }
        });
        this.columnsMatrix = matrix;
    }

    addLoadingResource(resource: string) {
        this.resources.push(resource);
        this.loadingResources.startRequest();
    }

    addAnalisysOne(pages: {
        [page: number]: {
            analysis: { [type: string]: { cutPage: number } };
            buffer: number;
        };
    }) {
        let clasificacion: { [key: string]: { [key: string]: { ensayos: ReportsModels.Ensayo[] } } } = {};
        let counter = this.model.datos.muestra.length;
        let nroPage = 0;
        let paginas: {
            tipos_ensayo: {
                tipo: string;
                data: any;
            }[];
        }[] = [];
        let types = this.getOwnPropertyNames(this.model.datos.analisis.clasificacion[0]);
        types.forEach((type) => {
            this.getOwnPropertyNames(this.model.datos.analisis.clasificacion[0][type]).forEach((subType) => {
                if (!clasificacion[type]) {
                    clasificacion[type] = {
                        [subType]: { ensayos: [] },
                    };
                }
                if (!clasificacion[type][subType]) {
                    clasificacion[type][subType] = {
                        ensayos: [],
                    };
                }
                this.model.datos.analisis.clasificacion[0][type][subType].ensayos.forEach((e) => {
                    counter++;
                    clasificacion[type][subType].ensayos.push(e);

                    if (counter === pages[nroPage].analysis.one.cutPage) {
                        paginas.push({
                            tipos_ensayo: [
                                {
                                    tipo: nroPage === 0 ? 'one' : 'generic',
                                    data: Object.assign({}, clasificacion),
                                },
                            ],
                        });
                        nroPage++;
                        clasificacion = {};
                        clasificacion[type] = {
                            [subType]: { ensayos: [] },
                        };
                    }
                });
            });
        });
        if (paginas.length === 0) {
            paginas.push({
                tipos_ensayo: [],
            });

            paginas[paginas.length - 1].tipos_ensayo.push({
                tipo: nroPage === 0 ? 'one' : 'generic',
                data: clasificacion,
            });
        }
        return paginas;
    }

    ngAfterContentChecked() {}
    ngAfterViewInit() {}
    ngOnChanges() {}

    containsTurbinaFerrografia(): boolean {
        return (
            this.model.datos.analisis.ferrografia.length > 0 ||
            this.model.datos.analisis.turbina.length > 0 ||
            this.model.datos.analisis.membrana.length > 0 ||
            this.model.datos.analisis.turbinaInferior.length > 0 ||
            this.model.datos.analisis.ferrografia_tabla != undefined ||
            this.model.datos.analisis.ferro_comentario_general != undefined ||
            this.model.datos.analisis.memb_comentario_general != undefined ||
            this.model.datos.analisis.membrana_img_filtro != undefined ||
            this.model.datos.analisis.membrana_img_frasco != undefined ||
            this.model.datos.analisis.membrana_tabla_1 != undefined ||
            this.model.datos.analisis.membrana_tabla_2 != undefined
        );
    }

    private processPages() {
        this.element = document.getElementById('page_0');
        if (this.draft && this.element) {
            this.element.style.display = 'block';
            if (this.element.offsetHeight === 0) {
                this.element.style.display = 'none';

                return;
            }

            this.draft = false;
            let maxHeight = 1150;

            let eHeight = this.element.offsetHeight;
            let force = true;
            if (eHeight && (maxHeight < eHeight || force)) {
                let bufferPage =
                    this.reportHeader?.nativeElement.offsetHeight +
                    this.reportTop?.nativeElement.offsetHeight +
                    this.reportDetail_TableHead?.nativeElement.offsetHeight;
                let pages: {
                    [page: number]: {
                        analysis: { [type: string]: { start: number; cutPage: number } };
                        buffer: number;
                    };
                } = {};
                let nroPage: number = 0;
                pages[nroPage] = {
                    analysis: { one: { start: 0, cutPage: 0 } },
                    buffer: bufferPage,
                };

                this.reportDetail_tableBody?.nativeElement.children.forEach(
                    (tr: { offsetHeight: number; textContent: string }, index: number) => {

                        pages[nroPage].buffer += tr.offsetHeight;
                        pages[nroPage].analysis['one'].cutPage = index;


                    }
                );

                if (this.reportEvalOne) {
                    pages[nroPage].buffer += this.reportEvalOne?.nativeElement.offsetHeight;
                }

                if (nroPage === 0 && pages[nroPage].buffer < this.reportNoisOne?.nativeElement.offsetHeight) {
                    pages[nroPage].buffer = this.reportNoisOne?.nativeElement.offsetHeight;
                }

                if (this.containsTurbinaFerrografia()) {
                    nroPage++;
                    pages[nroPage] = {
                        analysis: {},
                        buffer: 0,
                    };
                }

                pages[nroPage].analysis['ferro'] = { start: 0, cutPage: 0 };
                this.reportFerro?.nativeElement
                    .getElementsByTagName('tbody')[0]
                    .children.forEach((tr: { offsetHeight: number; textContent: string }, index: number) => {
                        if (index === 0) {
                            pages[nroPage].buffer += this.reportFerro?.nativeElement.getElementsByTagName('thead')[0].offsetHeight;
                        }

                        if (pages[nroPage].buffer + tr.offsetHeight < maxHeight) {
                            pages[nroPage].buffer += tr.offsetHeight;
                            pages[nroPage].analysis['ferro'].cutPage = index;
                        } else {
                            nroPage++;
                            pages[nroPage] = {
                                analysis: { ferro: { start: index, cutPage: index } },
                                buffer:
                                    index === 0
                                        ? this.reportFerro?.nativeElement.getElementsByTagName('thead')[0].offsetHeight + tr.offsetHeight
                                        : tr.offsetHeight,
                            };
                        }
                    });

                pages[nroPage].analysis['turb'] = { start: 0, cutPage: 0 };
                let cont = 0;
                this.reportTurb?.nativeElement
                    .getElementsByTagName('tbody')[0]
                    .children.forEach((tr: { offsetHeight: number; textContent: string }, index: number) => {
                        if (index === 0) {
                            pages[nroPage].buffer += this.reportTurb?.nativeElement.getElementsByTagName('thead')[0].offsetHeight;
                        }

                        if (pages[nroPage].buffer + tr.offsetHeight < maxHeight) {
                            pages[nroPage].buffer += tr.offsetHeight;
                            pages[nroPage].analysis['turb'].cutPage = index;
                            cont++;
                        } else {
                            cont = 1;
                            nroPage++;
                            pages[nroPage] = {
                                analysis: { turb: { start: index, cutPage: index } },
                                buffer:
                                    index === 0
                                        ? this.reportTurb?.nativeElement.getElementsByTagName('thead')[0].offsetHeight + tr.offsetHeight
                                        : tr.offsetHeight,
                            };
                        }
                        if (this.reportTurb?.nativeElement.getElementsByTagName('tbody')[0].children.length - 1 === index) {
                            pages[nroPage].buffer += tr.offsetHeight;
                        }
                    });

                if (this.reportEval?.nativeElement.offsetHeight + pages[nroPage].buffer < maxHeight) {
                    pages[nroPage].analysis['eval'] = { start: 0, cutPage: 0 };
                    pages[nroPage].buffer += this.reportEval?.nativeElement.offsetHeight;
                } else {
                    nroPage++;
                    pages[nroPage] = {
                        analysis: { eval: { start: 0, cutPage: 0 } },
                        buffer: this.reportTurb?.nativeElement.offsetHeight,
                    };
                }

                pages[nroPage].analysis['memb'] = { start: 0, cutPage: 0 };
                this.reportMemb?.nativeElement
                    .getElementsByTagName('tbody')[0]
                    .children.forEach((tr: { offsetHeight: number; textContent: string }, index: number) => {
                        if (index === 0) {
                            pages[nroPage].buffer += this.reportMemb?.nativeElement.getElementsByTagName('thead')[0].offsetHeight;
                        }

                        if (pages[nroPage].buffer + tr.offsetHeight < maxHeight) {
                            pages[nroPage].buffer += tr.offsetHeight;
                            pages[nroPage].analysis['memb'].cutPage = index;
                        } else {
                            nroPage++;
                            pages[nroPage] = {
                                analysis: { memb: { start: index, cutPage: index } },
                                buffer:
                                    index === 0
                                        ? this.reportMemb?.nativeElement.getElementsByTagName('thead')[0].offsetHeight + tr.offsetHeight
                                        : tr.offsetHeight,
                            };
                        }
                    });

                let order: { [key: string]: number } = {
                    ferro: 1,
                    turb: 2,
                    memb: 3,
                    eval: 4,
                };
                this.element.style.display = 'none';
                let paginas = this.addAnalisysOne(pages);
                this.createArrayFromNumber(nroPage + 1).forEach((nroPage, index) => {
                    this.getOwnPropertyNames(pages[index].analysis)
                        .filter((a) => a !== 'one')
                        .sort((a, b) => {
                            if (order[a] < order[b]) {
                                return -1;
                            }
                            if (order[a] > order[b]) {
                                return 1;
                            }
                            return 0;
                        })
                        .filter((a) => this.model.paginas[0].tipos_ensayo.find((te) => te.tipo === a)?.data)
                        .forEach((analisis) => {
                            if (!paginas[index]) {
                                paginas.push({
                                    tipos_ensayo: [],
                                });
                            }
                            switch (analisis) {
                                case 'ferro':
                                    let cut = pages[index].analysis['ferro'].cutPage + 1;
                                    let start = pages[index].analysis['ferro'].start;
                                    let data: ReportsModels.Ensayo[] =
                                        <ReportsModels.Ensayo[]>(
                                            this.model.paginas[0].tipos_ensayo.find((te) => te.tipo === 'ferro')?.data
                                        ) || [];

                                    paginas[index].tipos_ensayo.push({
                                        tipo: 'ferro',
                                        data: data.slice(start, cut),
                                    });
                                    break;
                                case 'memb':
                                    let cutM = pages[index].analysis['memb'].cutPage + 1;
                                    let startM = pages[index].analysis['memb'].start;
                                    let dataM: ReportsModels.Ensayo[] =
                                        <ReportsModels.Ensayo[]>this.model.paginas[0].tipos_ensayo.find((te) => te.tipo === 'memb')?.data ||
                                        [];

                                    paginas[index].tipos_ensayo.push({
                                        tipo: 'memb',
                                        data: dataM.slice(startM, cutM),
                                    });
                                    break;
                                case 'turb':
                                    let cutTurb = pages[index].analysis['turb'].cutPage + 1;
                                    let startTurb = pages[index].analysis['turb'].start;
                                    let dataTurb: ReportsModels.Ensayo[] =
                                        <ReportsModels.Ensayo[]>this.model.paginas[0].tipos_ensayo.find((te) => te.tipo === 'turb')?.data ||
                                        [];
                                    paginas[index].tipos_ensayo.push({
                                        tipo: 'turb',
                                        data: dataTurb.slice(startTurb, cutTurb),
                                    });
                                    break;
                                case 'eval':
                                    let dataEval: ReportsModels.Ensayo[] =
                                        <ReportsModels.Ensayo[]>this.model.paginas[0].tipos_ensayo.find((te) => te.tipo === 'eval')?.data ||
                                        [];
                                    paginas[index].tipos_ensayo.push({
                                        tipo: 'eval',
                                        data: dataEval,
                                    });
                                    break;
                                default:
                                    break;
                            }
                        });
                });
                this.model.paginas = paginas;
            } else {
                this.draft = false;
                this.resourcesLoadedOnDraft.forEach((resource) => {
                    this.recursoCargado(resource);
                });
            }
            this.element.style.display = this.isVisible ? 'block' : 'none';

            this.stepNumber++;
            this.obsvDraftEnd.next(this.draft);
            this.obsvStep.next(this.stepNumber);
        }
    }


    getImagen(ensayo: ReportsModels.Ensayo): string {
        let imagenes = this.model.muestra?.imagenes || [];
        let imagen = imagenes.find((i) => i.ensayoId === ensayo.ensayoId);
        return imagen ? imagen.url : '';
    }

    _getPreInforme() {
        return new Promise((resolve, reject) => {
            if (!this.model.muestra) {
                reject('No existe muestra');
                return;
            }
            let request: ReportsModels.GetReportRequest = {
                muestraId: this.model.muestra.muestraId,
                clienteId: this.model.muestra.cliente.clienteId,
                faenaId: this.model.muestra.cliente.faena.faenaId,
                callOrigin: 'webcliente',
                userId: this.authService.usuario.userId,
            };

            this.subTool.addSubscribe(
                'preinforme',
                this.reportsService.getReport(request, { force: true, ignoreStore: true, spinner: true }).subscribe(
                    (response) => {
                        if (response.code === 200) {
                            this.model.muestras = [];
                            this.model.muestras = Object.assign([], response.data.history);
                            this.model.muestras.push(Object.assign({}, response.data.muestra));
                            this.model.contador.muestras.previas = response.data.history.length;
                            this.model.cliente = response.data.cliente;
                            this.model.laboratorio = response.data.laboratorio;
                            this.model.muestra = response.data.muestra;

                            let charts = Object.getOwnPropertyNames(this.model.chartConfig);
                            charts.forEach((chartName) => {
                                let chartData = response.data.charts.find((c) => c.name === chartName);

                                if (chartData) {
                                    this.model.chartConfig[chartName].value = this.formatDataChartValue(
                                        chartData,
                                        this.model.chartConfig[chartName].order
                                    );
                                    let lengthMax = 0;
                                    this.model.chartConfig[chartName].value.forEach((e) => {
                                        lengthMax = e.length > lengthMax ? e.length : lengthMax;
                                    });
                                    this.model.chartConfig[chartName].value
                                        .filter((e) => lengthMax > e.length)
                                        .forEach((e) => {
                                            do {
                                                e.push(0);
                                            } while (e.length < lengthMax);
                                        });
                                    if (this.model.chartConfig[chartName].value.length > 0) {
                                        this.addLoadingResource(chartName);
                                    }
                                }
                            });
                            resolve(response.success);
                        } else if (response.code !== -1) {
                            reject(response.message);
                        }
                    },
                    (error) => {
                        this.loadingPDF.loading$.next(false);
                        console.error('Ocurrio un error:', error);
                    }
                )
            );
        });
    }

    private formatDataChartValue(
        chart: { name: string; value: { [key: string]: { index: string; value: string; fecha: string; hora: string }[] } },
        orderElements: { [key: string]: number }
    ) {
        let elements = Object.getOwnPropertyNames(chart.value);
        let values: any[] = [];
        elements
            .sort((a, b) => {
                if (a != '' && orderElements[a.toUpperCase()] < orderElements[b.toUpperCase()]) {
                    return -1;
                }
                if (orderElements[a] > orderElements[b]) {
                    return 1;
                }
                return 0;
            })
            .forEach((element, index) => {
                chart.value[element].forEach((data) => {
                    let arrayIndex = values.find((v) => v[0] === data.index);
                    if (arrayIndex) {
                        arrayIndex.push(Number(data.value));
                    } else {
                        values.push([data.index, Number(data.value)]);
                    }
                });
            });
        return values;
    }

    _getPlanesAnalisis(opts: { force: boolean; fnCallback?: () => {}; spinner: boolean } = { force: false, spinner: false }) {
        return new Promise((resolve) => {
            this.subTool.addSubscribe(
                'planes',
                this.reportsService
                    .getPlanesAnalisis(
                        {
                            planAnalisisId: this.model.muestra?.planAnalisis.planAnalisisId,
                        },
                        opts
                    )
                    .subscribe((response) => {
                        if (response.code == 200) {
                            this.model.defPlanEnsayos = {};
                            response.data.forEach((plan) => {
                                this.model.defPlanEnsayos[plan.planAnalisisId + ''] = plan;
                            });
                            resolve(response.success);
                        }
                    })
            );
        });
    }

    getEnsayos(opts: { force: boolean; fnCallback?: () => {}; spinner: boolean } = { force: false, spinner: false }) {
        return new Promise((resolve) => {
            this.subTool.addSubscribe(
                'ensayos',
                this.reportsService.GetEnsayos({}, opts).subscribe((response) => {
                    if (response.code == 200) {
                        this.model.ensayos = response.data;
                        resolve(response.success);
                    }
                })
            );
        });
    }

    get puedeVolver() {
        return this.model.seleccionBase.index > 0 && this.model.seleccionBase.muestras.length > 0;
    }

    get puedeAvanzar() {
        return this.model.seleccionBase.index < this.model.seleccionBase.muestras.length - 1;
    }

    get estadoMuestra() {
        if (this.model.muestra) {
            return this.sampleTls.statusSample(this.model.muestra).key;
        } else {
            return '';
        }
    }

    get puedeAprobar() {
        return this.model.muestra?.estado === Constants.Muestra.estado.pending_approval;
    }
    getClass(ensayo: ReportsModels.Ensayo) {
        let result = this.ultimoResultado(ensayo);
        let status = result?.estado?.status || '';
        return 'simple-line-bottom col-highlight ' + this.enumTdStatusClass[status];
    }

    createArrayFromNumberOfSamples(sum: number = 1) {
        let start = this.model.contador.muestras.previas - this.model.limite.muestras.previas;
        let length = this.model.limite.muestras.previas + this.model.limite.muestras.posterior + sum;
        return Array(length)
            .fill(length)
            .map((x, i) => start + i);
    }

    createArrayFromNumber(length: number = 1) {
        return Array(length)
            .fill(length)
            .map((x, i) => 1 + i);
    }

    getOwnPropertyNames(object: any) {
        if (object) {
            return Object.getOwnPropertyNames(object);
        } else {
            return [];
        }
    }
    orderTypes(array: string[]) {
        return array.sort((a, b) => {
            if (
                a != '' &&
                this.groupOrder[this.utilsTool.removeAccent(a).toUpperCase()].order <
                    this.groupOrder[this.utilsTool.removeAccent(b).toUpperCase()].order
            ) {
                return -1;
            }
            if (
                this.groupOrder[this.utilsTool.removeAccent(a).toUpperCase()].order >
                this.groupOrder[this.utilsTool.removeAccent(b).toUpperCase()].order
            ) {
                return 1;
            }
            return 0;
        });
    }

    orderSubTypes(subTypeArray: string[], type: string) {
        let subTypeArraySort = subTypeArray.sort((a, b) => {
            if (
                a != '' &&
                this.groupOrder[this.utilsTool.removeAccent(type).toUpperCase()].subType[this.utilsTool.removeAccent(a).toUpperCase()]
                    ?.order <
                    this.groupOrder[this.utilsTool.removeAccent(type).toUpperCase()].subType[this.utilsTool.removeAccent(b).toUpperCase()]
                        ?.order
            ) {
                return -1;
            }
            if (
                this.groupOrder[this.utilsTool.removeAccent(type).toUpperCase()].subType[this.utilsTool.removeAccent(a).toUpperCase()]
                    ?.order >
                this.groupOrder[this.utilsTool.removeAccent(type).toUpperCase()].subType[this.utilsTool.removeAccent(b).toUpperCase()]
                    ?.order
            ) {
                return 1;
            }
            return 0;
        });
        return subTypeArraySort;
    }

    orderAnalysis(analysis: ReportsModels.Ensayo[], type: string) {
        let analysisOrder = this.groupOrder[this.utilsTool.removeAccent(type).toUpperCase()].analysis;
        return analysis.sort((a, b) => {
            let aOrder = analysisOrder[String(a.code)]?.order;
            let bOrder = analysisOrder[String(b.code)]?.order;

            if (aOrder < bOrder || (aOrder > -1 && !bOrder)) {
                return -1;
            }
            if (aOrder > bOrder || (bOrder > -1 && !aOrder)) {
                return 1;
            }
            return 0;
        });
    }

    ensayoTipo: { [name: string]: { [code: string]: { order: number } } } = {};

    ultimoResultado(ensayo: ReportsModels.Ensayo, muestra?: ReportsModels.Muestra): ReportsModels.Resultado | undefined {
        let resultados = muestra ? muestra.resultados || [] : this.model.muestra?.resultados || [];
        resultados = resultados.filter((r) => r.ensayo.ensayoId === ensayo.ensayoId);
        let ultimoResultado = resultados
            ?.sort((a, b) => {
                if (a.ensayo.ensayoId < b.ensayo.ensayoId) {
                    return -1;
                }
                if (a.ensayo.ensayoId > b.ensayo.ensayoId) {
                    return 1;
                }
                return 0;
            })
            .pop();
        return ultimoResultado;
    }

    displayFormatNumberLimits(ensayo: ReportsModels.Ensayo): string {
        let result = '';
        let ultimoResultado = this.ultimoResultado(ensayo);
        let limites = ultimoResultado?.estado?.limites;
        if (!ultimoResultado || !limites) {
            return result;
        }
        let min = Number(limites.min);
        let max = Number(limites.max);

        switch (ensayo.code) {
            case '11':
            case '3':
                result += min ? 'MIN = ' + min : '';
                break;
            default:
                result = this.generalFormatLimit(min, max);
                break;
        }

        return result;
    }

    generalFormatLimit(min: number, max: number): string {
        let op = min > 0 && max > 0 ? 'LIMITS' : min > 0 ? 'ONLY_MIN' : 'ONLY_MAX';
        let result = '';
        switch (op) {
            case 'ONLY_MIN':
                result += 'MIN = ' + min;
                break;
            case 'ONLY_MAX':
                result += 'MAX = ' + max;
                break;
            case 'LIMITS':
                result += min + ' | ' + max;
                break;
            default:
                break;
        }
        return result;
    }

    buscarImagen(ensayo: ReportsModels.Ensayo, muestra?: ReportsModels.Muestra): ReportsModels.Imagen | undefined {
        let imagenes = muestra?.imagenes || [];
        return imagenes.find((r) => r.ensayoId === ensayo.ensayoId);
    }

    async generatePDF(print?: boolean): Promise<void> {
        if (this.model.muestra && (!this.model.muestra.reportName || print) && !this.isGeneratingPDF) {
            this.printed = true;
            this.isGeneratingPDF = true;
            this.spinner.startLoading();
            this.loadingPDF.startRequest();
            this.processPages();
            this.subTool.addSubscribe(
                'getPDF',
                this.obsvDraftEnd.subscribe((isDraft) => {
                    if (!isDraft) {
                        this.subTool.destroySubs('getPDF');
                        this.letsPrint = print === true;
                        this.subTool.addSubscribe(
                            'resources',
                            this.loadingResources.loading$.subscribe((isLoading) => {
                                if (!isLoading) {
                                    this.subTool.destroySubs('resources');
                                    this.exportPDFMultipages();
                                    this.loadingPDF.endRequest();
                                    this.isGeneratingPDF = false;
                                }
                            })
                        );
                    }
                })
            );
        } else if (this.model.muestra && this.model.muestra.url) {
            this.spinner.startLoading();
            let blob = await fetch(this.model.muestra.url).then((e) => e.blob());
            const url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.download = this.model.muestra.reportName?.split('/').pop() || 'report.pdf';
            link.dispatchEvent(new MouseEvent('click'));
            this.spinner.stopLoading();
        }
    }

    pdfCanvas(pdf: jsPDF, page: string): Promise<jsPDF> {
        let DATA = document.getElementById(page);

        return new Promise((resolve, reject) => {
            if (!DATA) {
                resolve(pdf);
                return;
            }

            const options = {
                background: 'white',
                scale: 3,
                allowTaint: true,
                useCORS: true,
                scrollX: 0,
                scrollY: -window.scrollY,
            };
            DATA.style.display = 'block';

            html2canvas(DATA, options)
                .then((canvas) => {
                    const contentDataURL = canvas.toDataURL('image/png', 1.0);
                    const bufferX = 15;
                    let imgWidth = pdf.internal.pageSize.getWidth() - 2 * bufferX;
                    let pageHeight = pdf.internal.pageSize.height;
                    let imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 0;
                    heightLeft -= pageHeight;
                    pdf.addImage(contentDataURL, 'PNG', bufferX, position, imgWidth, imgHeight, '', 'FAST');

                    let nextPage = this.model.pdfPageId.pop();
                    if (nextPage) {
                        pdf.addPage();
                        this.pdfCanvas(pdf, nextPage).then((d) => resolve(d));
                    } else {
                        resolve(pdf);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
            DATA.style.display = this.isVisible ? 'block' : 'none';
        });
    }

    private newJSPDF() {
        return new jsPDF('p', 'px', 'a4');
    }

    exportPDFMultipages() {
        this.isPrinting = true;
        let pages = Array(this.model.paginas.length)
            .fill(this.model.paginas.length)
            .map((x, i) => 'page_' + (this.model.paginas.length - i - 1));
        this.model.pdfPageId = [...pages];
        this.pdf = this.newJSPDF();

        this._changeDetectorRef.detectChanges();
        let firstPage = this.model.pdfPageId.pop();

        this.model.pdfPageId = [];
        if (this.model.datos.analisis.ferrografia.length != 0) {
            this.model.pdfPageId.push('ferro');
        }
        if (this.model.datos.analisis.membrana.length != 0) {
            this.model.pdfPageId.push('memb');
        }
        if (this.model.datos.analisis.turbina.length != 0) {
            this.model.pdfPageId.push('turb');
        }

        if (firstPage) {
            this.loadingPDF.startRequest();
            this.isPrinting = true;

            setTimeout(() => {
                this.pdfCanvas(this.pdf, firstPage!)
                    .then((docResult) => {
                        let pdfName = `${this.model.muestra?.componenteId}_${this.statusSample}_${this.model.muestra?.correlativo}`;
                        pdfName = pdfName.toUpperCase().replace(' ', '_');
                        let pdfFile: File;
                        pdfFile = new File([docResult.output('blob')], `${pdfName}.pdf`, {
                            type: 'application/pdf',
                        });
                        if (this.letsPrint) {
                            this.printed = false;
                            return docResult.save(`${pdfName}.pdf`);
                        } else {
                            this.subTool.addSubscribe(
                                'savePDF',
                                this.callBackUpdate.subscribe(() => {
                                    this.printed = false;
                                    return docResult.save(`${pdfName}.pdf`);
                                })
                            );
                            return this.upload_pdf(pdfFile);
                        }
                    })
                    .catch((e) => {
                        console.error('Problema al generar el pdf', e);
                    })
                    .finally(() => {
                        (<any>document.body).style.zoom = 1;
                        this.isPrinting = false;
                        this.loadingPDF.endRequest();
                    });
            }, 3000);
        }
    }
    private upload_pdf(pdfFile: File): void {
        this.loadingPDF.startRequest();

        let request: ReportsModels.GetPdfSaveRequest = {
            file_name: pdfFile.name,
            type_file: 'report_muestra',
            muestra_id: this.model.muestra?.muestraId!,
            content_type: 'application/pdf',
        };
        this.reportsService
            .GetImageSave(request)
            .then((response) => {
                if (response.code === 200) {
                    this.model.imageSaveUrl = response.data;
                    this.saveFile(this.model.imageSaveUrl, pdfFile);
                }
            })
            .finally(() => {
                this.loadingPDF.endRequest();
            });
    }

    private saveFile(imageUrl: ReportsModels.ImageSaveUrl, file: File): void {
        this.loadingPDF.startRequest();
        this.reportsService
            .PutPdfFile(imageUrl.url, file)
            .then((r) => {
                this.callBackUpdate.next({ resp: true, name: this.model.imageSaveUrl.file_name });
                if (this.utilsTool.inIframe()) {
                    window.parent.postMessage(
                        {
                            action: 'downloadPDF',
                            event: 'callBackUpdate',
                            response: { resp: true, name: this.model.imageSaveUrl.file_name },
                        },
                        window.location.origin
                    );
                }
                this.loadingPDF.endRequest();
            })
            .catch(() => {
                this.loadingPDF.endRequest();
            });
    }

    recursoCargado(resource: string) {
        if (!this.draft) {
            this.resources = this.resources.filter((r) => r !== resource);
            this.loadingResources.endRequest();
        } else {
            this.resourcesLoadedOnDraft.push(resource);
        }
    }

    @HostListener('window:message', ['$event'])
    onMessage(e: any) {
        if (e.data.action == 'downloadPDF' && e.origin === window.location.origin) {
            this.processPages();
            this.generatePDF(true);
        }

        if (e.data.action == 'aproveReport' && e.origin === window.location.origin) {
            this.processPages();
            this.generatePDF();
        }

        if (e.data.action == 'loadReport' && e.origin === window.location.origin) {
            if (e.data.bottleNumber) {
                this.model.muestra = e.data.sample;
                this._cargarDatos();
                this.close_to_home = e.data.close_to_home;
                this.isVisible = true;
                this.downloadLink = true;
            }
        }
    }

    windowClose() {
        if (this.close_to_home) {
            window.location.href = '/home';
        } else {
            window.close();
        }
    }

    showImages() {
        this.dialog.open(ModalGalleryComponent, {
            panelClass: 'ds-modal',
            data: { muestra: this.model.muestra },
        });
    }

    organizeAnalysis(classification: {
        [key: string]: {
            [key: string]: {
                ensayos: ReportsModels.Ensayo[];
            };
        };
    }) {
        let organized: {
            type: string;
            subtypes: { subtype: string; analysis: ReportsModels.Ensayo[] }[];
        }[] = [];
        this.orderTypes(this.getOwnPropertyNames(classification)).forEach((type, indexType) => {
            let count: number = 1;
            let auxNorma: string = '';
            let subTypes = this.getOwnPropertyNames(classification[type]);
            let typeOrg: {
                type: string;
                subtypes: { subtype: string; analysis: ReportsModels.Ensayo[] }[];
            } = {
                type: type,
                subtypes: [],
            };

            this.orderSubTypes(subTypes, type).forEach((subtype, indexSubType) => {
                let subtypeOrg: { subtype: string; analysis: ReportsModels.Ensayo[] } = {
                    subtype: subtype,
                    analysis: [],
                };
                console.log(subtype);
                this.orderAnalysis(classification[type][subtype].ensayos, type).forEach((analysis, indexAnalysis) => {
                    console.log(analysis.nombre, analysis.code);
                    subtypeOrg.analysis.push(analysis);
                });
                typeOrg.subtypes.push(subtypeOrg);
            });
            organized.push(typeOrg);
        });
        this.model.datos.analisis.clasificacion_organizada = organized;
    }



    private checkAnalysis(muestra: ReportsModels.Muestra) {
        let plan = this.model.defPlanEnsayos[muestra.planAnalisis.planAnalisisId];
        let ensayosFinales: ReportsModels.Ensayo[] = plan.ensayos;
        if (muestra.estado === Constants.Muestra.estado.reported) {
            let ensayos_delPlan_conResultados = plan.ensayos?.filter((e) =>
                muestra.resultados?.some((r) => r.ensayo.ensayoId == e.ensayoId)
            );
            let ensayos_sinPlan_conResultados: ReportsModels.Ensayo[] = [];
            muestra.resultados?.forEach((r) => {
                if (!ensayos_delPlan_conResultados?.some((ensayo) => ensayo.ensayoId === r.ensayo.ensayoId)) {
                    var ensayo_paso = this.model.ensayos.find((x) => x.ensayoId === r.ensayo.ensayoId);
                    if (ensayo_paso) {
                        ensayos_sinPlan_conResultados?.push(ensayo_paso);
                    }
                }
            });
            ensayosFinales = ensayos_delPlan_conResultados.concat(ensayos_sinPlan_conResultados);
        }
        return ensayosFinales;
    }
    membranaImgNombre(ensayo: ReportsModels.Ensayo) {
        let imagenNumero: ReportsModels.Ensayo[] = this.model.datos.analisis.membrana.filter((mf) => mf.image);
        let posi = imagenNumero.findIndex((fi) => fi === ensayo);
        if (posi != -1) {
            return `Imagen ${posi + 1}`;
        }
        return;
    }
    ferroImgNombre(ensayo: ReportsModels.Ensayo) {
        let imagenNumero: ReportsModels.Ensayo[] = this.model.datos.analisis.ferrografia.filter((ff) => ff.image);
        let posi = imagenNumero.findIndex((fi) => fi === ensayo);
        if (posi != -1) {
            return `Imagen ${posi + 1}`;
        }
        return;
    }

    ensayosSinImagen(tipo: string) {
        return tipo == 'ferro'
            ? this.model.datos.analisis.ferrografia.filter((ff) => !ff.image).length > 0
            : tipo == 'memb'
            ? this.model.datos.analisis.membrana.filter((mf) => !mf.image).length > 0
            : false;
    }

    cambiarNombreEnsayo(tipo: string) {
        if (tipo == 'Espectometría AA' || tipo == 'ESPECTOMETRIA AA') {
            return 'Espectrometría E.A.';
        }
        return tipo
    }

    historialCambiosCliente() {
        let mensaje = '';
        if (this.model.muestra?.traces_changes_webcliente && this.model.muestra?.traces_changes_webcliente?.length) {
            let trace = this.model.muestra?.traces_changes_webcliente[this.model.muestra?.traces_changes_webcliente.length - 1]
            const fecha = this.separaFecha(trace.fecha_modificacion);

            const fields = [
                { name: 'Fecha de muestra', value: trace.fechaMuestreo },
                { name: 'Plan de análisis', value: trace.planAnalisis },
                { name: 'Lubricante', value: trace.lubricante },
                { name: 'Relleno desde último cambio', value: trace.rellenoDesdeUltimoCambio },
                { name: 'Uso total componente', value: [trace.usoTotalComponente, trace.unidadUsoTotalComponente] },
                { name: 'Uso cambio lubricante', value: [trace.usoCambioLubricante, trace.unidadUsoCambioLubricante] },
                { name: 'Comentario cliente', value: trace.comentarioCliente },
                { name: 'Componente', value: trace.componenteId }
            ];

            fields.forEach(field => {
                mensaje += `${this.createMessage(field, fecha, trace)}`;
            });
        }
        return mensaje;
    }

    createMessage(field: any, fecha: string, trace: any) {
        if (field.name === 'Uso cambio lubricante' || field.name === 'Uso total componente') {
            return this.handleComponentUsage(field, fecha, trace);
        } else if (Array.isArray(field.value)) {
            return this.handleArrayValue(field, fecha, trace);
        }
        return '';
    }

    handleComponentUsage(field: any, fecha: string, trace: any) {
        if (field.value[0][0] != field.value[0][1] || field.value[1][0] != field.value[1][1]) {
            let oldValue = field.value[0][0];
            let newValue = field.value[0][1];
            let oldUnit = field.value[1][0] || '';
            let newUnit = field.value[1][1] || '';
            return this.generateMessage(field, oldValue, newValue, oldUnit, newUnit, fecha, trace);
        }
        return '';
    }

    handleArrayValue(field: any, fecha: string, trace: any) {
        if ((!field.value[0] && !field.value[1]) || (field.value[0] == field.value[1])) {
            return '';
        }
        if (!field.value[0]) {
            return `Se ha añadido el valor ${field.value[1]} al campo: ${field.name} por el usuario ${trace.usuario.nombre} ${trace.usuario.apellido} ${fecha} <br>`;
        } else if (!field.value[1]) {
            return `Se ha eliminado el valor ${field.value[0]} del campo: ${field.name} por el usuario ${trace.usuario.nombre} ${trace.usuario.apellido} ${fecha} <br>`;
        }
        return `Se ha modificado el valor ${field.value[0]}  por ${field.value[1]}  de: ${field.name}  por el usuario ${trace.usuario.nombre} ${trace.usuario.apellido} ${fecha} <br>`;
    }

    generateMessage(field: any, oldValue: any, newValue: any, oldUnit: any, newUnit: any, fecha: string, trace: any) {
        if (!oldValue && !newValue) {
            return '';
        }
        if (!oldValue) {
            return `Se ha establecido el valor de: ${field.name} a ${newValue} ${newUnit} por el usuario ${trace.usuario.nombre} ${trace.usuario.apellido} ${fecha} <br>`;
        } else if (!newValue) {
            return `Se ha eliminado el valor de: ${field.name} que era ${oldValue} ${oldUnit} por el usuario ${trace.usuario.nombre} ${trace.usuario.apellido} ${fecha} <br>`;
        }
        return `Se ha modificado el valor ${oldValue} ${oldUnit} por ${newValue} ${newUnit} de: ${field.name} por el usuario ${trace.usuario.nombre} ${trace.usuario.apellido} ${fecha} <br>`;
    }

    separaFecha(fechaIso: string) {
        let fecha = new Date(fechaIso);
        let dia = fecha.getDate();
        let mes = fecha.toLocaleString('es-ES', { month: 'long' });
        let hora = fecha.getHours();
        let minuto = fecha.getMinutes().toString().padStart(2, '0');
        return `el día ${dia} de ${mes} a las ${hora}:${minuto} hrs`;
    }

    getStateAttributes(estado: string): { stateClass: string, icon: string } {
        const stateAttributesMap: { [key: string]: { stateClass: string, icon: string } } = {
          'normal': { stateClass: 'txt--success', icon: 'bi bi-check-circle-fill' },
          'alerta': { stateClass: 'txt--danger', icon: 'bi bi-exclamation-triangle-fill' },
          'precaucion': { stateClass: 'txt--warning', icon: 'bi bi-exclamation-circle-fill' }
        };
        return stateAttributesMap[estado] || { stateClass: '', icon: '' };
      }

      get mostrar_recomendacionIA() {
        return (this.estadoMuestra=='alerta' || this.estadoMuestra == 'precaucion') && ( !this.loading.inProgress && this.model.muestra && this.model.muestra.planAnalisis.planAnalisisId && this.model.defPlanEnsayos[this.model.muestra.planAnalisis.planAnalisisId].evaluationIA && this.model.defPlanEnsayos)
    }
}
