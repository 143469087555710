import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tools, Enums } from 'src/app/shared';
import { ServiceSubjectTime } from 'src/app/shared/tools/service-subject-time.tools';
import { environment } from 'src/environments/environment';
import { TableauModels } from '../models/tableau.models';

@Injectable({
    providedIn: 'root'
})
export class TableauService {

    constructor(
        private http: HttpClient,
        private tools: Tools) {
    }

    GetTableau(request: TableauModels.GetTableauRequest): Promise<TableauModels.GetTableauResponse> {
        return this.http.get<TableauModels.GetTableauResponse>(`${environment.apiUrl}${environment.routes.tableau}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    }


}