import { Component, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SolicitudDeInsumosModels } from '../../solicitud-de-insumos-models/solicitud-de-insumos.models';
import { AuthenticationService } from '../../../shared/auth/authentication.service';

@Component({
    selector: 'modal-solicitud-de-insumos',
    templateUrl: './modal-solicitud.component.html',
    styleUrls: ['./modal-solicitud.component.css']
})
export class ModalSolicitudDeInsumosComponent implements OnInit {

    model:SolicitudDeInsumosModels.SetInsumoRequest | undefined;

    constructor(public dialogRef: NgbActiveModal,private authService:AuthenticationService){

    }

    ngOnInit(): void{
        this.model={
            bombaExtraccion:"0",
            faenaId:'',
            kitTomaMuestra12:"0",
            kitTomaMuestra48:"0",
            kitTomaMuestra12SP:"0",
            kitTomaMuestra48SP:"0",
            kitTomaMuestraTurbina:"0",
            solicitadoPor:this.authService.current_user,
            clienteId:this.authService.currentClientValue.clienteId
        };

    }

    cancelar(){
        this.dialogRef.close();
    }

    solicitar(){
        this.dialogRef.close(this.model);
    }
    isInvalid():boolean{
        let isOK=true;
        if(this.model){
            isOK= this.model.bombaExtraccion==="0" &&
            this.model.kitTomaMuestra12 ==="0" &&
            this.model.kitTomaMuestra12SP ==="0" &&
            this.model.kitTomaMuestraTurbina ==="0" &&
            this.model.kitTomaMuestra48 === "0" &&
            this.model.kitTomaMuestra48SP === "0"
        }
        return isOK;
    }
    maxLengthCheck(input:any){
        if (input.value.length >= input.maxLength){
            input.value = input.value.slice(0, input.maxLength)
        }
    }
    keyMaxLengthCheck(event:any,input:any){
        if (input.value.length == input.maxLength){
            event.preventDefault();
        }
    }
}
