<div class="row mb-2 mb-xl-3">
  <div class="col-md-6 order-2 order-md-1">
    <h3>Personalizar límites</h3>
  </div>
</div>

<div class="row justify-content-start justify-content-md-between align-items-md-end mb-2">
  <div class="order-3 order-md-1 text-start mt-2 col-6 col-md-6 col-lg-6 mb-2" >
    <div class="form-group text-start">
      <label for="faena" class="form-label">Faenas</label>
      <ng-select
        name="faena" [appendTo]="'body'" [placeholder]="'Seleccionar'" #faena="ngModel" [(ngModel)]="model.selectFaena"
        [multiple]="true" [searchable]="true" blockSpecialCharacters
        [clearable]="false" (ngModelChange)="filtrarComponentes(); this.filtroProtocolos()" >
        <ng-option *ngFor="let item of model.faenas.lista" [value]="item">{{item.faenaId}} - {{item.nombre}}</ng-option>
      </ng-select>
    </div>
  </div>
  <div class="order-2 order-md-3 text-start text-md-end mt-2 col-10 col-md-3 col-lg-3 mb-2" >
      <button class="btn btn-primary btn-lg" [routerLink]="" (click)="generarReporte()">
        DESCARGAR REPORTE
      </button>

  </div>
  <div class="order-1 order-md-2 text-start text-md-end mt-2 col-10 col-md-3 col-lg-3 mb-2" >
      <button class="btn btn-primary btn-lg" [routerLink]="" (click)="protocoloModal()">
        CREAR PROTOCOLO
      </button>
    </div>

</div>

<div class="row">
  <div class="col-8 col-md-6 col-lg-3 mt-4">
    <div class="form-group">
      <label for="tipoProtocolo" class="form-label">Tipo Protocolo</label>
      <ng-select name="tipoProtocolo"  [appendTo]="'body'" [placeholder]="'Seleccionar'" [clearable]="false" #tipoProtocolo="ngModel" [(ngModel)]="model.selectTipoProtocolo"
      (ngModelChange)="filtroProtocolos()" >
        <ng-option *ngFor="let item of model.tipoProtocolo.lista" [value]="item">{{item.tag}}</ng-option>
      </ng-select>
    </div>
  </div>
  <div class="col-8 col-md-6 col-lg-3 mt-4" *ngIf="model.selectTipoProtocolo.tag =='Lubricante'">
    <div class="form-group">
      <label for="tipoLubricante" class="form-label">Tipo Lubricante</label>
      <ng-select name="tipoLubricante" [appendTo]="'body'"  [placeholder]="'Seleccionar'" #tipoLubricante="ngModel" [(ngModel)]="model.selectLubricante" (ngModelChange)="filtroProtocolos()" >
        <ng-option *ngFor="let item of model.lubricante.lista" [value]="item">{{item.nombre}}</ng-option>
      </ng-select>
    </div>
  </div>
  <div class="col-8 col-md-6 col-lg-3 mt-4">
    <div class="form-group">
      <label for="tipoEquipo" class="form-label">Tipo Equipo</label>
      <ng-select name="tipoEquipo" [appendTo]="'body'" [placeholder]="'Seleccionar'"  #tipoEquipo="ngModel" [(ngModel)]="model.selectTipoEquipo" (ngModelChange)="filtroProtocolos()" >
        <ng-option *ngFor="let item of model.tipoEquipo.lista" [value]="item">{{item.nombre}}</ng-option>
      </ng-select>
    </div>
  </div>
  <div class="col-8 col-md-6 col-lg-3 mt-4">
    <div class="form-group">
      <label for="marcaEquipo" class="form-label">Marca Equipo</label>
      <ng-select name="marcaEquipo" [appendTo]="'body'" [placeholder]="'Seleccionar'"  #marcaEquipo="ngModel" [(ngModel)]="model.selectMarcaEquipo"
      (ngModelChange)="model.selecModeloEquipo = undefined; filtroProtocolos()" >
        <ng-option *ngFor="let item of model.marcaEquipo.lista" [value]="item">{{item.nombre}}</ng-option>
      ></ng-select>
    </div>
  </div>
  <div class="col-8 col-md-6 col-lg-3 mt-4">
    <div class="form-group">
      <label for="modeloEquipo" class="form-label">Modelo Equipo</label>
      <ng-select name="modeloEquipo" [appendTo]="'body'" [placeholder]="'Seleccionar'"  #modeloEquipo="ngModel" [disabled]="!model.selectMarcaEquipo" [(ngModel)]="model.selecModeloEquipo" (ngModelChange)="filtroProtocolos()" >
        <ng-option *ngFor="let item of model.selectMarcaEquipo?.modelo" [value]="item">{{item}}</ng-option>
      ></ng-select>
    </div>
  </div>
  <div class="col-8 col-md-6 col-lg-3 mt-4">
    <div class="form-group">
      <label for="tipoComponente" class="form-label">Tipo Componente</label>
      <ng-select name="tipoComponente" [appendTo]="'body'" [placeholder]="'Seleccionar'"  #tipoComponente="ngModel" [(ngModel)]="model.selectTipoComponente" (ngModelChange)="filtroProtocolos()" >
        <ng-option *ngFor="let item of model.tipoComponente.lista" [value]="item">{{item.nombre}}</ng-option>
      ></ng-select>
    </div>
  </div>
  <div class="col-8 col-md-6 col-lg-3 mt-4">
    <div class="form-group">
      <label for="marcaComponente" class="form-label">Marca Componente</label>
      <ng-select name="marcaComponente" [appendTo]="'body'" [placeholder]="'Seleccionar'"  #marcaComponente="ngModel" [(ngModel)]="model.selectMarcaComponente"
      (ngModelChange)="model.selectModeloComponente = undefined; filtroProtocolos()"
      >
        <ng-option *ngFor="let item of model.marcaComponente.lista" [value]="item">{{item.nombre}}</ng-option>
      ></ng-select>
    </div>
  </div>
  <div class="col-8 col-md-6 col-lg-3 mt-4">
    <div class="form-group">
      <label for="modeloComponente" class="form-label">Modelo Componente</label>
      <ng-select name="modeloComponente" [appendTo]="'body'" [placeholder]="'Seleccionar'"  #modeloComponente="ngModel" [disabled]="!model.selectMarcaComponente" [(ngModel)]="model.selectModeloComponente" (ngModelChange)="filtroProtocolos()" >
        <ng-option *ngFor="let item of model.selectMarcaComponente?.modelo" [value]="item">{{item}}</ng-option>
      ></ng-select>
    </div>
  </div>
  <div class="col-8 col-md-6 col-lg-3 mt-4">
    <div class="form-group">
      <label for="codigoComponente" class="form-label">Código Componente</label>
      <ng-select name="codigoComponente" [appendTo]="'body'"  [placeholder]="'Seleccionar'" [multiple]="true" [searchable]="true"
      [clearable]="true" #codigoComponente="ngModel" [(ngModel)]="model.selectIdComponentes" (ngModelChange)="filtroProtocolos()" >
        <ng-option *ngFor="let item of model.idComponentes.auxLista" [value]="item">{{item.componenteId}}</ng-option>
      ></ng-select>
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="col-12">
    <div class="card">
      <div class="table-responsive" *ngIf="model">
        <table mat-table [dataSource]="dataSource" matSort class="w-100" aria-describedby="Listado de Limites Personalizados">
          <ng-container matColumnDef="fechaModificacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Modificación</th>
            <td mat-cell *matCellDef="let row">{{row.fecha}} - {{row.hora}}</td>
          </ng-container>
          <ng-container matColumnDef="tipoProtocolo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Protocolo</th>
            <td mat-cell *matCellDef="let row">{{row.tipo | uppercase}}</td>
          </ng-container>
          <ng-container matColumnDef="tipoComponente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Componente</th>
            <td mat-cell *matCellDef="let row">{{nombrarC(row.tipoComponente)}}</td>
          </ng-container>
          <ng-container matColumnDef="marcaComponente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Marca Componente</th>
            <td mat-cell *matCellDef="let row">{{row.custom.marcaComponente}}</td>
          </ng-container>
          <ng-container matColumnDef="modeloComponente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Modelo Componente</th>
            <td mat-cell *matCellDef="let row">{{row.custom.modeloComponente}}</td>
          </ng-container>
          <ng-container matColumnDef="idComponentes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Código Componente</th>
            <td mat-cell *matCellDef="let row">
              <p  *ngFor="let item of validaString(row.custom.componenteId)">{{item}}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="tipoLubricante">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoría Lubricante</th>
            <td mat-cell *matCellDef="let row">{{nombreCatLub(row.categoriaLubricante)}}</td>
          </ng-container>
          <ng-container matColumnDef="Responsable">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Responsable</th>
            <td mat-cell *matCellDef="let row">{{row.usuario.nombre}}</td>
          </ng-container>
           <tr mat-header-row *matHeaderRowDef="displayedColumns" >
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="protocoloModal(row)">

          </tr>
          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cel text-center" colspan="8">
                  <span *ngIf="model.loadingProtocols">Cargando<mat-spinner  matSuffix [diameter]="18" style="margin-left: 8px;display: inherit;"></mat-spinner></span>
                  <span *ngIf="!model.loadingProtocols">No se han encontrado elementos...</span>
              </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="printed" *ngIf="printed">
  <ng-container *ngIf="printedLoading">
    <div class="printed-icon">
      <ng-lottie [options]="printOptions"></ng-lottie>
    </div>
    <h2>Generando reporte</h2>
    <p><b>No cierres esta ventana</b>, Una vez terminado el reporte, aparecerá un botón para que lo descargues.</p>
  </ng-container>
  <ng-container *ngIf="!printedLoading">
    <div class="printed-icon lottie-check">
      <ng-lottie [options]="printCheckOptions"></ng-lottie>
    </div>
    <h2>Reporte Generado</h2>
    <button id="reporte_componente_download_file" class="btn btn--small btn--success" (click)="descargarReporte()">Descargar reporte</button>
  </ng-container>
</div>
