<div class="fade show" id="modal-componente" tabindex="-1" aria-labelledby="modal-componente-label" style="display: block;" aria-modal="true" role="dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Componente</h5>
            <button type="button" class="btn-close"  aria-label="Close" (click)="cancelar()"></button>
        </div>
        <div class="modal-body text-start">
            <div class="row">
                <div class="col-12 col-lg-7">
                    <div class="card" id="datos-componente">
                        <div class="card-body">
                            <img src="../../../../../../assets/images/icono-sin.svg" alt="" id="icono-componente">
                            <h5 class="card-title">{{ model.componente.tipoComponente.nombre }} {{model.componente.componenteId}}</h5>
                            <table id="datos-componente-table">
                                <tbody><tr>
                                    <td class="align-top">Marca</td>
                                    <td class="align-top"><strong>{{model.componente.marca}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Modelo</td>
                                    <td class="align-top"><strong>{{model.componente.modelo}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Descripción</td>
                                    <td class="align-top"><strong>{{model.componente.descriptor}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Posición</td>
                                    <td class="align-top"><strong>{{model.componente.ubicacion}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Última muestra</td>
                                    <td class="align-top"><strong>{{model.componente.ultimaMuestra.muestraId}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Fecha muestreo</td>
                                    <td class="align-top"><strong>{{model.componente.ultimaMuestra.fechaMuestreo | date: 'dd-MM-yyyy'}}</strong></td>
                                </tr>
                                <tr>
                                    <td class="align-top">Estado</td>
                                    <td class="align-top">
                                        <div class="d-flex align-items-center gap-1">
                                            <span *ngIf="model.componente.ultimaMuestra.estado" class="indicator" [ngClass]="{
                                              'normal': model.componente.ultimaMuestra.estado == 'normal',
                                              'danger': model.componente.ultimaMuestra.estado === 'alerta',
                                              'warning': model.componente.ultimaMuestra.estado == 'precaucion'
                                          }"></span>
                                            <strong>{{enumStatus[model.componente.ultimaMuestra.estado]}}</strong>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>  
                </div>
                <div class="col-12 col-lg-5">
                    <div class="card" id="datos-equipo">
                        <div class="card-body">
                            <h5 class="card-title">{{model.equipo.tipoEquipo.nombre}} {{model.equipo.equipoId}}</h5>
                            <table>
                                <tbody>
                                    <tr>
                                        <td class="align-top">Marca</td>
                                        <td class="align-top"><strong>{{model.equipo.marca}}</strong></td>
                                    </tr>
                                    <tr>
                                        <td class="align-top">Modelo</td>
                                        <td class="align-top"><strong>{{model.equipo.modelo}}</strong></td>
                                    </tr>
                                    <tr>
                                        <td class="align-top">Alias</td>
                                        <td class="align-top"><strong *ngFor="let alia of model.equipo.alias">{{ alia }}&nbsp;</strong></td>
                                    </tr>
                                    <tr>
                                        <td class="align-top">Descripción</td>
                                        <td class="align-top"><strong>{{ model.equipo.descriptor }}</strong></td>
                                    </tr>
                                    <tr>
                                        <td class="align-top">Etiquetas</td>
                                        <td class="align-top">
                                            <span class="badge bg-secondary me-1" *ngFor="let tag of model.equipo.tags">{{ tag }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>  
                    </div>  
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <div>
                <button [disabled]="!model.equipoCargado"
                type="button" (click)="editar()" class="btn btn-light" data-bs-dismiss="modal"><i class="bi bi-pencil"></i> Editar
                <mat-spinner matSuffix *ngIf="model.loading" [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                </button>

                <button type="button" class="btn btn-light danger" data-bs-dismiss="modal"
                [disabled]="!model.equipoCargado"
                (click)="eliminarComponente()"><i class="bi bi-trash"></i> Eliminar
                <mat-spinner matSuffix *ngIf="model.loading" [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                </button>
            </div>
            <button (click)="agregarMuestra()" [disabled]="!model.equipoCargado" type="button" class="btn btn-primary" data-bs-dismiss="modal"><i class="bi bi-plus-lg"></i> Agregar muestra
                <mat-spinner matSuffix *ngIf="model.loading" [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
            </button>
        </div>
    </div>
</div>

    