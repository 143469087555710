<ng-container *ngIf="!routeReport">
    <div *ngIf="this.authService.currentUserValue" class="wrapper">
        <app-nav-menu *ngIf="this.authService.currentUserValue"></app-nav-menu>
        <div class="main">
            <app-nav-header *ngIf="this.authService.currentUserValue"></app-nav-header>
            <main class="content">
                <div class="container-fluid p-0 mnh-100">
                    <router-outlet></router-outlet>
                    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

                </div>
            </main>
            <app-footer *ngIf="this.authService.currentUserValue"></app-footer>
        </div>
    </div>
</ng-container>
<router-outlet *ngIf="routeReport"></router-outlet>
<router-outlet *ngIf="!this.authService.currentUserValue"></router-outlet>

<app-mat-spinner-overlay [overlay]="true" *ngIf="diplayLoading">
</app-mat-spinner-overlay>