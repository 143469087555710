<div id="logo-mobil">
    <h1>Mobil Assistance Oil Analysis</h1>
    <img src="/assets/images/logo-mobil.png" alt="Mobil Assistance Oil Analysis">
</div>
<div id="contenido">
    <p>Bienvenido al Laboratorio de Lubricantes <strong>Mobil Assistance</strong> de COPEC</p>
    <button type="button" (click)="goToAuth()">Ingresar</button>    
</div>
<div *ngIf="exist_user_platform" class="alert-access mt-4 d-flex align-items-center">
    <span class="alert-access-text">
        Tu usuario no tiene acceso a esta plataforma.
    </span>
</div>

<div *ngIf="faena_user_locked" class="alert-access mt-4 d-flex align-items-center">
    <span class="alert-access-text">
        Tu usuario está asociado a una faena que no tiene acceso a esta plataforma.
    </span>
</div>

<div *ngIf="faena_user_not_found" class="alert-access mt-4 d-flex align-items-center">
    <span class="alert-access-text">
        Tu usuario está asociado a una faena con problemas, contacte a un administrador.
    </span>
</div>

<div *ngIf="user_error" class="alert-access mt-4 d-flex align-items-center">
    <span class="alert-access-text">
        Tu usuario tiene problemas en el acceso a esta plataforma, contacta a un administrador.
    </span>
</div>

<div id="logo-copec">
    <h3>COPEC</h3>
</div>