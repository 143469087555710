<form #registroNuevoEquipo="ngForm">
    <div class="modal-header">
        <h5 class="modal-title" *ngIf="!model.estaEditando">Nuevo Equipo</h5>
        <h5 class="modal-title" *ngIf="model.estaEditando">Editar Equipo</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="cancelar()"></button>
    </div>
    <div class="modal-body">        
        <div class="form-group">
            <label for="tipo" class="form-label">Tipo de equipo</label>
            <mat-spinner *ngIf="model.loading" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
            <ng-select [(ngModel)]="model.equipo.tipoEquipo" blockSpecialCharacters #selectedTipo required [disabled]="model.loading" [clearable]="false" name="tipo">
                <ng-option *ngFor="let tipo of model.tipoEquipos" select [value]="tipo">{{tipo.nombre}}</ng-option>
            </ng-select>
        </div>
        <br>
        <div class="form-group" *ngIf="!model.estaEditando">
            <label for="equipoId" class="form-label">Código del equipo</label>
            <mat-spinner *ngIf="model.loadingEquipoId" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
            <input type="text" name="equipoId" idDirective id="equipoId" autocomplete="off" class="form-control" [(ngModel)]="model.equipo.equipoId" #codigoEquipo="ngModel" [readonly]="model.loadingEquipoId" value="{{ model.equipo.equipoId }}" [ngClass]="{'is-invalid':codigoEquipo.invalid && (codigoEquipo.dirty || codigoEquipo.touched)}" required (focusout)="validaId()" (keyup)="limipiaId()" maxlength="30">
            <div class="form-text">Código de equipo debe ir en mayúsculas</div>
            <div *ngIf="codigoEquipo.invalid && (codigoEquipo.dirty || codigoEquipo.touched)" class="text-danger">
                <div *ngIf="codigoEquipo.errors?.required">
                    Debe ingresar código del equipo.
                </div>
            </div>
            <div *ngIf="model.idError" class="text-danger">
                El código ya existe dentro del sistema.
            </div>
        </div>
        <div class="form-group" *ngIf="model.estaEditando">
            <label for="equipoId" class="form-label">Código del equipo</label>
            <br>
            <label><strong>{{ model.equipo.equipoId }}</strong></label>
        </div>
        <br>
        <div class="form-group">
            <label for="marca" class="form-label">Marca</label>
            <mat-spinner *ngIf="model.loading" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
            <ng-select [(ngModel)]="model.equipo.marca" blockSpecialCharacters #selectedMarca required (ngModelChange)="cambiaMarca()" [disabled]="model.loading" [clearable]="false" name="marca">
                <ng-option *ngFor="let marca of model.marcas" [value]="marca.nombre">{{marca.nombre}}</ng-option>
                <ng-option value="otro">Otro</ng-option>
            </ng-select>
        </div>
        <br>

        <div *ngIf="model.verOtraMarca">
            <div class="form-group">
                <label for="otraMarca" class="form-label">Otra marca</label>
                <input type="text" id="otraMarca" class="form-control" blockSpecialCharacters value="{{ model.otraMarca }}" name="otraMarca" [(ngModel)]="model.otraMarca" required #otraMarca="ngModel" maxlength="50">
                <div *ngIf="otraMarca.invalid && (otraMarca.dirty || otraMarca.touched)" class="text-danger">
                    <div *ngIf="otraMarca.errors?.required">
                        Debe ingresar marca del equipo.
                    </div>
                </div>
            </div>
            <br>
        </div>

        <div class="form-group">
            <label for="modelo" class="form-label">Modelo <em>(opcional)</em></label>
            <mat-spinner *ngIf="model.loading" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
            <ng-select [(ngModel)]="model.equipo.modelo" #selectedModelo blockSpecialCharacters (ngModelChange)="cambiaModelo()" [disabled]="model.loading" [clearable]="false" name="modelo">
                <ng-option *ngFor="let modelo of model.modelos" [value]="modelo">{{modelo}}</ng-option>
                <ng-option value="otro">Otro</ng-option>
            </ng-select>
        </div>
        <br>

        <div *ngIf="model.verOtroModelo">
            <div class="form-group">
                <label for="otroModelo" class="form-label">Otro modelo</label>
                <input type="text" id="otroModelo" class="form-control" blockSpecialCharacters value="{{ model.otroModelo }}" name="otroModelo" [(ngModel)]="model.otroModelo" required #otroModelo="ngModel" maxlength="50">
                <div *ngIf="otroModelo.invalid && (otroModelo.dirty || otroModelo.touched)" class="text-danger">
                    <div *ngIf="otroModelo.errors?.required">
                        Debe ingresar modelo del equipo.
                    </div>
                </div>
            </div>
            <br>
        </div>

        <a [routerLink]="" (click)="masCampos();false" *ngIf="!model.verMasCampos">Más campos opcionales</a>

        <div *ngIf="model.verMasCampos">

            <div class="form-group">
                <label for="alias" class="form-label">Alias <em>(opcional)</em></label>
                <mat-form-field class="example-chip-list">
                    <mat-chip-list #listAlias aria-label="alias selection">
                        <mat-chip *ngFor="let alia of model.equipo.alias" [selectable]="model.selectable" [removable]="model.removable" (removed)="removeAlia(alia)">
                            {{ alia }}
                            <span matChipRemove *ngIf="model.removable">x</span>
                        </mat-chip>
                        <input [matChipInputFor]="listAlias" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addAlia($event)" blockSpecialCharacters maxlength="20">
                    </mat-chip-list>
                </mat-form-field>
                <div class="form-text">Por ejemplo <em>Camión extractor</em></div>
            </div>
            <br>

            <div class="form-group">
                <label for="descriptor" class="form-label">Descripción <em>(opcional)</em></label>
                <input type="text" id="descriptor" class="form-control" value="" blockSpecialCharacters [(ngModel)]="model.equipo.descriptor" name="descriptor" maxlength="50">
                <div class="form-text">Por ejemplo <em>CAEX</em></div>
            </div>
            <br>

            <div class="form-group">
                <label for="tags" class="form-label">Agrupadores <em>(opcional)</em></label>
                <mat-form-field class="example-chip-list">
                    <mat-chip-list #listTags aria-label="tag selection">
                        <mat-chip *ngFor="let tag of model.equipo.tags" [selectable]="model.selectable" [removable]="model.removable" (removed)="removeTag(tag)">
                            {{ tag }}
                            <span matChipRemove *ngIf="model.removable">x</span>
                        </mat-chip>
                        <input [matChipInputFor]="listTags" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addTag($event)" blockSpecialCharacters maxlength="20">
                    </mat-chip-list>
                </mat-form-field>
                <div class="form-text">Por ejemplo <em>Área mina</em></div>
            </div>

            <br>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="cancelar()">Cancelar</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal" [disabled]="registroNuevoEquipo.invalid || model.idError || model.loadingEquipoId" (click)="guardarEquipo()">
            <span *ngIf="!model.estaEditando">
                Crear equipo
            </span>
            <span *ngIf="model.estaEditando">
                Guardar equipo
            </span>
        </button>
    </div>
</form>