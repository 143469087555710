

          <div class="row mb-2 mb-xl-3">
            <div class="col-md-6 order-2 order-md-1">
              <h3>Insumos</h3>
            </div>
          </div>

          <div class="row mb-2 text-end">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
            </div>
            <div class="col-md text-end order-1 order-md-2 mb-2 " id="BtnNuevaMuestraCaja"><a
                class="btn btn-primary btn-lg"[routerLink]=""
                (click)="solicitarInsumo()">
                Solicitar
                insumos</a>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="table-responsive" *ngIf="model">




                  <table mat-table [dataSource]="dataSource" matSort class="w-100">
                    <ng-container matColumnDef="solicitudInsumoId">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header >Solicitud</th>
                      <td class="pTopDown10" mat-cell *matCellDef="let solicitud" >
                          {{ solicitud.solicitudInsumoId}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="fecha">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha </th>
                      <td mat-cell *matCellDef="let solicitud">
                       {{ solicitud.fecha}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="solicitadoPor">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Solicitado por </th>
                      <td mat-cell *matCellDef="let solicitud">
                          {{ solicitud.solicitadoPor?.nombre}} {{ solicitud.solicitadoPor?.apellido}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="totalInsumos">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Insumos</th>
                      <td mat-cell *matCellDef="let solicitud" class="text-center" totalInsumos>
                        <ng-template #popContentDetail>
                          <div class="row">

                            <div class="col-10"><small>Kit de toma de muestra 12 unidades. Con polytube</small></div> <div class="col-2 mb-2 text-end border-start"> <b>{{solicitud.kitTomaMuestra12}}</b></div>
                            <div class="col-10"><small>Kit de toma de muestra 48 unidades. Con polytube</small>  </div><div class="col-2 mb-2 text-end border-start"> <b>{{solicitud.kitTomaMuestra48}}</b></div>
                            <div class="col-10"><small>Kit de toma de muestra 12 unidades. Sin polytube</small></div> <div class="col-2 mb-2 text-end border-start"> <b>{{solicitud.kitTomaMuestra12SP}}</b></div>
                            <div class="col-10"><small>Kit de toma de muestra 48 unidades. Sin polytube</small>  </div><div class="col-2 mb-2 text-end border-start"> <b>{{solicitud.kitTomaMuestra48SP}}</b></div>
                            <div class="col-10"><small>Bomba de extracción (vampiro)</small></div><div class="col-2 mb-2 text-end border-start"> <b>{{solicitud.bombaExtraccion}} </b></div>
                            <div class="col-10"><small>Kit de toma de muestras Turbina </small> </div> <div class="col-2 mb-2 text-end border-start"> <b>{{solicitud.kitTomaMuestraTurbina}}</b> </div>

                          </div>
                       </ng-template>
                        <a href="#" [routerLink]=""  [ngbPopover]="popContentDetail" [autoClose]="true" #pDetail="ngbPopover" >{{sumarInsumos(solicitud)}}</a>
                      </td>
                    </ng-container>
                     <tr mat-header-row *matHeaderRowDef="displayedColumns">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cel text-center" colspan="4">
                            <span *ngIf="model.cargando">Cargando solicitudes<mat-spinner  matSuffix [diameter]="18" style="margin-left: 8px;display: inherit;"></mat-spinner></span>
                        </td>
                    </tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" (page)="handlePageEvent($event)" [length]="model.pagination.totalElements" [pageSize]="model.pagination.pageSize" [pageIndex]="model.pagination.page" showFirstLastButtons></mat-paginator>

                </div>
              </div>
            </div>
          </div>
          <p class="text-muted" *ngIf="!model.cargando">
            <a type="button" class="link-secondary" (click)="getInsumos(true)" [routerLink]=""  data-toggle="tooltip" data-placement="top" title="{{model.fechaCargaSolicitudes| date :'HH:mm:ss'}}" >
                <i class="bi bi-arrow-clockwise"></i>
                <span *ngIf="model.fechaCargaSolicitudes !==null">
                    Actualizado {{model.fechaCargaSolicitudes  | amLocale:'es' | amTimeAgo}}
                </span>
            </a>

    </p>
