<google-chart #chart
[title]="viscChart.title"
[type]="viscChart.type"
[data]="viscChart.data"
[options]="viscChart.options"
[width]="viscChart.width"
[height]="viscChart.height" 
(ready)="onReady($event)"
>
</google-chart>
<img [src]="imgSrc" id="imgViscChart"  [hidden]="true"  (load)="onReadyImage()" >
<div class="d-flex justify-content-center loading" *ngIf="isLoading">
    <mat-spinner matSuffix [diameter]="18"   ></mat-spinner>
</div>