import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpResponse,
    HttpContextToken
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';
import { Enums } from '../models/constants';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private totalRequests = 0;

    constructor(private spinnerService: SpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {        
        this.totalRequests++;
        if (request.context.get(Enums.SPINNER_ENABLED) && !request.url.endsWith('/token')) {
            this.spinnerService.startLoading();
        }
         return next.handle(request).pipe(
            finalize(() => {
                this.totalRequests--;
                if (this.totalRequests === 0) {
                 this.spinnerService.stopLoading();
                }
            })
        );
    }
}