import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Tools } from 'src/app/shared';
import { SolicitudDeAnalisisModels } from '../mant-solicitud-analisis-models/mant-solicitud-analisis.models';
import { Observable } from 'rxjs';
import { ServiceSubjectTime } from '../../../shared/tools/service-subject-time.tools';

@Injectable({
    providedIn: 'root'
})
export class SolicitudDeAnalisisService {

    subjetGetSolicitudAnalisis:ServiceSubjectTime<SolicitudDeAnalisisModels.GetSolicitudDeAnalisisRequest,SolicitudDeAnalisisModels.GetSolicitudDeAnalisisResponse>;
    subjetGetCondicionComercial:ServiceSubjectTime<SolicitudDeAnalisisModels.GetCondicionComercialRequest,SolicitudDeAnalisisModels.GetCondicionComercialResponse>;
    constructor(
        private http: HttpClient,
        tools: Tools) {
        this.subjetGetSolicitudAnalisis = new ServiceSubjectTime(`${environment.apiUrl}${environment.routes.getSolicitudAnalisis}`,http,tools);
        this.subjetGetCondicionComercial = new ServiceSubjectTime(`${environment.apiUrl}${environment.routes.getCondicionComercial}`,http,tools);

    }
    getSolicitudAnalisis(request: SolicitudDeAnalisisModels.GetSolicitudDeAnalisisRequest,force:boolean=false): Observable<SolicitudDeAnalisisModels.GetSolicitudDeAnalisisResponse> {
       return this.subjetGetSolicitudAnalisis.httpGet(request,force);
    }

    setSolicitudAnalisisEstado(request:SolicitudDeAnalisisModels.SetSolicitudDeAnalisisEstadoRequest): Observable<SolicitudDeAnalisisModels.SetSolicitudDeAnalisisEstadoResponse> {
        return this.http.post<SolicitudDeAnalisisModels.SetSolicitudDeAnalisisEstadoResponse>(`${environment.apiUrl}${environment.routes.setSolicitudAnalisisEstado}`, request);
    }

    getCondicionComercial(request: SolicitudDeAnalisisModels.GetCondicionComercialRequest,force:boolean=false): Observable<SolicitudDeAnalisisModels.GetCondicionComercialResponse> {
        return this.subjetGetCondicionComercial.httpGet(request,force);
     }

}
