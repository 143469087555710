
    

      <div class="modal-header">
        <h4 class="modal-title" id="modal-title">{{data.title}}</h4>
      </div>
      <div class="modal-body">
        <p [innerHTML]="data.msg"></p>        
      </div>
      <div class="modal-footer">        
        <button type="button" ngbAutofocus class="btn btn-danger" (click)="confirmar()">{{data.titleButtonOK || 'Confirmar'}}</button>
      </div>