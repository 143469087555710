import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[blockEmailSpecialCharacters]',
})
export class BlockEmailSpecialCharactersDirective {
    regexStr = /[a-zA-Z0-9-@.ñÑ+_]/g;
    @HostListener('keypress', ['$event']) onKeyPress(event: any) {
        return new RegExp(this.regexStr).test(event.key);
    }
    @HostListener('paste', ['$event']) onPaste(e: any) {
        const pasteData = e.clipboardData.getData('text/plain');
        if (new RegExp(this.regexStr).test(pasteData)) {
            e.preventDefault();
        }
    }
}
